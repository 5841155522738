import { FC } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Card } from 'antd'
import { ReactComponent as ATLogo } from 'assets/at_logo.svg'
import AuthForm from './AuthForm'
import RecoveryForm from './RecoveryForm'
import RegistrationForm from './RegistrationForm'
import { ValidateOrdinance } from 'pages/validateOrdinance'

const AuthPage: FC = () => {
	return (
		<div className="wrapper at-jcc at-aic">
			<div className="at-bg">
				<Card
					style={{
						background: 'rgba(255, 255, 255,0.2)',
						backdropFilter: 'blur(4px)',
						padding: '1rem',
						width: '400px',
						height: '100vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						boxShadow: '0 0 1rem rgba(0,0,0,0.08)',
						borderRadius: 0
					}}
				>
					<ATLogo style={{ marginBottom: '1rem' }} />
					<BrowserRouter>
						<Routes>
							<Route path="/*" element={<AuthForm />} />
							<Route path="/password-reset" element={<RecoveryForm />} />
							<Route path="/registration" element={<RegistrationForm />} />
							<Route path="/validateOrdinance/:id" element={<ValidateOrdinance />} />
						</Routes>
					</BrowserRouter>
				</Card>
			</div>
		</div>
	)
}

export default AuthPage
