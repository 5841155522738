import { useAppSelector } from 'hooks/appReduxHook'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'

export const useWbsPermits = () => {
	const checkPermissions = useCheckPermissions()
	const { permissions } = useAppSelector(state => state.environment)
	return {
		canView: checkPermissions([PERMISSIONS.WbsView, PERMISSIONS.Admin]),
		canEdit: checkPermissions([PERMISSIONS.WbsCreator, PERMISSIONS.Admin]),
		canDelete: checkPermissions([PERMISSIONS.WbsDelete, PERMISSIONS.Admin]),
		canExport: checkPermissions([PERMISSIONS.ExportWbsToExcel, PERMISSIONS.Admin]),
		canImport: checkPermissions([PERMISSIONS.ImportWbsFromExcel, PERMISSIONS.Admin]),
		canSetDP: checkPermissions([PERMISSIONS.WbsDp, PERMISSIONS.Admin]),
		canSetSDU: checkPermissions([PERMISSIONS.WbsSdu, PERMISSIONS.Admin]),
		canSetDZ: checkPermissions([PERMISSIONS.WbsDzMtr, PERMISSIONS.Admin]),
		canSetDZSmr: checkPermissions([PERMISSIONS.WbsDzSmr, PERMISSIONS.Admin]),
		canChangeReady: checkPermissions([PERMISSIONS.WbsChangeReady, PERMISSIONS.Admin]),
		isOuter: checkPermissions([PERMISSIONS.WbsNoteContractor, PERMISSIONS.Admin]),
		canLocalView: checkPermissions([PERMISSIONS.LocalEstimateView, PERMISSIONS.Admin]),
		canLocalEdit: checkPermissions([
			PERMISSIONS.LocalEstimateCreate,
			PERMISSIONS.Admin
		]),
		canLocalExternal: checkPermissions([
			PERMISSIONS.EstimateExternal,
			PERMISSIONS.Admin
		]),
		canLocalDzMTR: checkPermissions([PERMISSIONS.EstimateDzMtr, PERMISSIONS.Admin]),
		canLocalDzSMR: checkPermissions([PERMISSIONS.EstimateDzSmr, PERMISSIONS.Admin]),
		canLocalSdu: checkPermissions([PERMISSIONS.EstimateSDU, PERMISSIONS.Admin]),
		canLocalImport: checkPermissions([
			PERMISSIONS.ImportEstimateCorrectionFromExcel,
			PERMISSIONS.Admin
		]),
		canLocalExport: checkPermissions([
			PERMISSIONS.ExportEstimateCorrectionToExcel,
			PERMISSIONS.Admin
		])
	}
}
