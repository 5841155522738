import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'hooks/appReduxHook'
import { adjustmentApi } from 'widgets/adjustment'
import { WbsApi } from '..'

export const useConstructionObjects = () => {
	const { project } = useAppSelector(state => state.environment)
	return useQuery({
		queryKey: ['constructionObjects', project],
		queryFn: async () => {
			return await WbsApi.getConstructionObjects().then(data => data)
		},
		refetchOnWindowFocus: false
	})
}

export const useContainerVisas = (containerId?: string, isAdjustment?: boolean) => {
	return useQuery({
		queryKey: [isAdjustment ? 'adjustmentContainerVisas' : 'containerVisas', containerId],
		queryFn: async () => {
			if (containerId) {
				return isAdjustment
					? await adjustmentApi.getContainerVisas(containerId, 'wbs').then(data => data)
					: await WbsApi.getContainerVisas(containerId).then(data => data)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}
