import axios from 'axios'
import IToken from 'interfaces/IToken'
import { Authenticate } from './AuthService'
import { BASEURL } from './ApiService'

let token: IToken =
	localStorage.absToken && localStorage.absToken !== 'undefined' ? JSON.parse(localStorage.absToken) : null
axios.defaults.baseURL = BASEURL

const LoaderService = async () => {
	const url = window.location.href.toString()
	if (url.includes('error'))
		window.location.href = window.location.toString().substring(0, window.location.toString().indexOf('#'))

	if (token && token.accessToken !== null) {
		return true
	} else {
		if (url.includes('access_token')) {
			let forgeAccessToken = null
			forgeAccessToken = url.split('#access_token=').pop()!.split('&')[0]
			return await checkAuth(forgeAccessToken)
		} else {
			return false
		}
	}
}

const checkAuth = async (ticket: string) => {
	const auth = await Authenticate({ ticket })
	return auth.success
}
export default LoaderService
