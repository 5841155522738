import { CloseOutlined, HolderOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Descriptions, Form, Input, Popconfirm } from 'antd'
import { FormInstance, FormListFieldData } from 'antd/es/form'
import { useAppSelector } from 'hooks/appReduxHook'
import { ICheckListTemplateSection } from 'interfaces/ITemplate'
import { FC, useEffect, useState } from 'react'
import { removeTemplateItem } from 'services/TemplatesService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import Item from './Item'

interface IProps {
	section: ICheckListTemplateSection
	isEdited: boolean
	form: FormInstance
	tempOrder: number
	idx: number
	onRefreshTemplatesTable: () => void
	currentTemplateType: string
	allDisabled: boolean
	onRemove: (idx: number) => void
}

const Section: FC<IProps> = ({
	section,
	isEdited,
	form,
	tempOrder,
	idx,
	onRefreshTemplatesTable,
	currentTemplateType,
	allDisabled,
	onRemove
}) => {
	const checkPermissions = useCheckPermissions()
	const [currentItem, setCurrentItem] = useState<FormListFieldData | null>(null)
	const [draggable, setDraggable] = useState(false)
	const [showConfirm, setShowConfirm] = useState(false)
	const [sectionCount, setSectionCount] = useState<number>(form.getFieldValue('sectionCount'))
	const { permissions, user } = useAppSelector(state => state.environment)
	const removeNow = async (name: number) => {
		if (section) {
			const id: string | null | undefined = section.items[name].id
			await removeTemplateItem(id)
				.then(res => console.log('OK: ', res))
				.then(() => onRefreshTemplatesTable())
				.catch(err => console.log('Not OK: ', err))
		}
	}

	useEffect(() => {
		setCurrentItem({ key: 0, name: 0 })
	}, [])

	useEffect(() => {
		form.setFieldValue(`sections${tempOrder}`, section)
	}, [section])

	const dragStartHandler = (e: React.DragEvent<HTMLDivElement>, field: FormListFieldData) => {
		setCurrentItem(field)
	}

	const dragEndHandler = (e: React.DragEvent<HTMLDivElement>) => {
		e.currentTarget.style.filter = 'blur(0px)'
		e.currentTarget.style.border = 'none'
	}

	const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.currentTarget.style.filter = 'blur(2px)'
		e.currentTarget.style.border = '1px solid #000'
	}

	const dropHandler = (
		e: React.DragEvent<HTMLDivElement>,
		field: FormListFieldData,
		move: (from: number, to: number) => void
	) => {
		e.preventDefault()
		e.currentTarget.style.filter = 'blur(0px)'
		e.currentTarget.style.border = 'none'
		return move(currentItem!?.key, field.key)
	}

	const sortList = (a: FormListFieldData, b: FormListFieldData) => {
		if (a.key! > b.key!) return 1
		else return -1
	}

	return (
		<Card className="at-workcard">
			<Popconfirm
				title="Удалить раздел?"
				placement="left"
				open={showConfirm}
				onOpenChange={e => !e && setShowConfirm(false)}
				onConfirm={e => {
					if (!allDisabled) {
						onRemove(idx)
					}
				}}
				onCancel={() => {
					setShowConfirm(false)
				}}
				okText="Удалить"
				cancelText="Отмена"
			>
				<CloseOutlined
					onClick={() => {
						user?.isAdmin ||
							(currentTemplateType === '0' &&
								checkPermissions([PERMISSIONS.ChecklistsTemplatesEditUksNdc])) ||
							(currentTemplateType === '1' &&
								checkPermissions([PERMISSIONS.ChecklistsTemplatesUkKc]) &&
								setShowConfirm(true))
					}}
					style={{
						fontSize: '16px',
						padding: '.5rem',
						fontWeight: 'bold',
						position: 'absolute',
						top: '-18px',
						right: '25px',
						cursor: 'pointer',
						zIndex: 1,
						background: '#f2f2f2',
						border: '1px solid #ddd',
						borderRadius: '50px'
					}}
				/>
			</Popconfirm>
			<Descriptions
				column={12}
				bordered
				labelStyle={{
					fontWeight: 'bold',
					width: '30%',
					alignItems: 'center',
					padding: '2px 8px'
				}}
				contentStyle={{
					width: '70%',
					padding: '0px 8px'
				}}
				style={{ marginBottom: section!?.items!?.length === 1 ? '24px' : 0 }}
				className="at-descriptions"
			>
				<Descriptions.Item
					label={
						<label>
							Раздел
							<label style={{ color: 'red' }}>*</label>
						</label>
					}
					span={12}
				>
					{allDisabled ? (
						<p style={{ padding: '4px', margin: 0 }}>{section.title}</p>
					) : (
						<Form.Item
							name={['sections', tempOrder, 'title']}
							rules={[{ required: true, message: 'Укажите название раздела' }]}
						>
							<Input disabled={allDisabled} onChange={e => console.log(e.target.value)} />
						</Form.Item>
					)}
				</Descriptions.Item>

				<Descriptions.Item label="Описание раздела" span={12}>
					{allDisabled ? (
						<p style={{ padding: '4px', margin: 0 }}>{section.description}</p>
					) : (
						<Form.Item name={['sections', tempOrder, 'description']}>
							<Input.TextArea disabled={allDisabled} />
						</Form.Item>
					)}
				</Descriptions.Item>
			</Descriptions>

			<Form.List name={['sections', tempOrder, 'items']}>
				{(fields, { add, move, remove }, { errors }) => {
					return (
						<>
							{fields.sort(sortList).map((field, index) => (
								<div
									key={field.key}
									draggable={draggable}
									onDragStart={e => dragStartHandler(e, field)}
									onDragLeave={e => dragEndHandler(e)}
									onDragEnd={e => dragEndHandler(e)}
									onDragOver={e => dragOverHandler(e)}
									onDrop={e => dropHandler(e, field, move)}
								>
									<HolderOutlined
										style={{
											fontSize: '16px',
											padding: '.5rem',
											fontWeight: 'bold',
											transform: 'rotate(90deg)',
											position: 'relative',
											left: '1rem',
											top: '1rem',
											zIndex: 1,
											cursor: 'grab',
											background: '#f2f2f2',
											border: '1px solid #ddd',
											borderRadius: '50px',
											opacity: fields.length > 1 ? 1 : 0
										}}
										draggable={false}
										onMouseOver={() => !allDisabled && setDraggable(true)}
										onMouseOut={() => !allDisabled && setDraggable(false)}
									/>
									<Item
										idx={idx}
										index={index}
										field={field}
										key={field.key}
										form={form}
										draggable={draggable}
										remove={remove}
										isEdited={isEdited}
										removeNow={removeNow}
										currentTemplateType={currentTemplateType}
										allDisabled={allDisabled}
									/>
								</div>
							))}
							<Button
								onClick={() => add()}
								style={{ width: '100%', marginTop: 16, padding: '0 15px' }}
								icon={<PlusOutlined />}
								disabled={allDisabled}
							>
								Добавить пункт
							</Button>
						</>
					)
				}}
			</Form.List>
		</Card>
	)
}

export default Section
