import { useQueryClient } from '@tanstack/react-query'
import { Form, Input, InputNumber, Modal, Radio, RadioChangeEvent, Select, message } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { IIssueSubType } from 'interfaces/IIssue'
import { FC, useEffect, useState } from 'react'
import { issueTypeService, issuesElementTranslate } from 'services/IssuesService'
import { IDictionaryForm } from './violationTypes.guide'

interface IAddDictionaryModalProps {
	show: boolean
	onClose: () => void
}

export const AddDictionaryModal: FC<IAddDictionaryModalProps> = ({ show, onClose }) => {
	const queryClient = useQueryClient()
	const { issueTypes, issueSubTypes } = useAppSelector(state => state.environment)
	const [currentSubTypes, setCurrentSubTypes] = useState<IIssueSubType[]>([])
	const [dictionaryForm] = Form.useForm<IDictionaryForm>()
	const [selectedType, setSelectedType] = useState<'type' | 'subType' | 'violationType'>()
	const options = [
		{ label: 'Основной', value: 'type' },
		{ label: 'Подтип', value: 'subType' },
		{ label: 'Тип нарушения', value: 'violationType' }
	]

	const onTypeChange = ({ target: { value } }: RadioChangeEvent) => {
		dictionaryForm.resetFields(['issueTypeId', 'issueSubTypeId', 'name'])

		setSelectedType(value)
	}

	const setInitForm = () => {
		dictionaryForm.resetFields()
		dictionaryForm.setFieldValue('type', 'type')
		setSelectedType('type')
	}

	const onFinish = async (dto: IDictionaryForm) => {
		switch (dto.type) {
			case 'subType':
				issueTypeService
					.addSubType(dto.name, dto!?.issueTypeId!, dto.order)
					.then(() => {
						queryClient.invalidateQueries({ queryKey: ['appShared'] })
						message.success('Подтип удачно создан')
					})
					.catch(err => {
						message.error('Произошла ошибка во время сохранения')
					})
					.finally(() => setInitForm())
				break
			case 'violationType':
				issueTypeService
					.addViolationType(dto.name, dto!?.issueSubTypeId!)
					.then(() => {
						queryClient.invalidateQueries({ queryKey: ['appShared'] })
						message.success('Тип нарушения удачно создан')
					})
					.catch(err => {
						message.error('Произошла ошибка во время сохранения')
					})
					.finally(() => setInitForm())
				break

			default:
				issueTypeService
					.addType(dto.name)
					.then(() => {
						queryClient.invalidateQueries({ queryKey: ['appShared'] })
						message.success('Тип удачно создан')
					})
					.catch(err => {
						message.error('Произошла ошибка во время сохранения')
					})
					.finally(() => setInitForm())
				break
		}
	}

	const onCancel = () => {
		dictionaryForm.resetFields()
		setSelectedType(undefined)
		onClose()
	}

	const onSetSubTypes = (typeId: string) => {
		setCurrentSubTypes(issueSubTypes!?.filter(type => type.issueTypeId === typeId))
	}

	useEffect(() => {
		if (show) setInitForm()
	}, [show])

	return (
		<Modal
			okButtonProps={{
				htmlType: 'submit',
				onClick: () => {
					dictionaryForm.submit()
				}
			}}
			title="Добавить"
			destroyOnClose={true}
			open={show}
			onCancel={onCancel}
			okText="Сохранить"
			cancelText="Закрыть"
		>
			<Form
				form={dictionaryForm}
				name="dictionaryForm"
				onFinish={onFinish}
				labelCol={{ span: 7 }}
				labelAlign="left"
				onValuesChange={changeValues => {
					if (typeof changeValues!?.issueTypeId !== 'undefined')
						onSetSubTypes(changeValues!?.issueTypeId!)
				}}
			>
				<Form.Item label="Тип" name="type" rules={[{ required: true, message: 'Укажите тип' }]}>
					<Radio.Group
						options={options}
						onChange={onTypeChange}
						value={selectedType}
						optionType="button"
						buttonStyle="solid"
						style={{ width: '100%' }}
					/>
				</Form.Item>
				{selectedType === 'subType' && (
					<Form.Item
						label="Основной"
						name="issueTypeId"
						rules={[{ required: true, message: 'Укажите связь' }]}
					>
						<Select>
							{issueTypes!
								?.filter(x => !x.isDeleted)!
								?.map((type, index) => (
									<Select.Option key={index} value={type.id}>
										{issuesElementTranslate(type.name)}
									</Select.Option>
								))}
						</Select>
					</Form.Item>
				)}
				{selectedType === 'violationType' && (
					<>
						<Form.Item
							label="Основной"
							name="issueTypeId"
							rules={[{ required: true, message: 'Укажите основной тип' }]}
						>
							<Select>
								{issueTypes!
									?.filter(x => !x.isDeleted)!
									?.map((type, index) => (
										<Select.Option key={index} value={type.id}>
											{issuesElementTranslate(type.name)}
										</Select.Option>
									))}
							</Select>
						</Form.Item>
						<Form.Item
							label="Подтип"
							name="issueSubTypeId"
							rules={[{ required: true, message: 'Укажите подтип' }]}
						>
							<Select disabled={!!!currentSubTypes.length}>
								{currentSubTypes!?.map((type, index) => (
									<Select.Option key={index} value={type.id}>
										{type.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
					</>
				)}
				<Form.Item
					label="Название"
					name="name"
					rules={[{ required: true, message: 'Введите название' }]}
				>
					<Input />
				</Form.Item>
				{selectedType === 'subType' && (
					<Form.Item
						label="Порядковый №"
						name="order"
						rules={[{ required: true, message: 'Введите порядковый номер' }]}
					>
						<InputNumber
							keyboard={false}
							controls={false}
							style={{ width: '100%' }}
							onKeyDown={event => {
								if (
									!/[0-9]|Backspace|Delete|ArrowLeft|ArrowDown|ArrowRight|ArrowUp/.test(event.key)
								) {
									event.preventDefault()
								}
							}}
						/>
					</Form.Item>
				)}
			</Form>
		</Modal>
	)
}
