import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface IWbsCols {
	price: boolean
	totalPrice: boolean
	sum: boolean
	totalSum: boolean
	noteSDU: boolean
	noteDZ: boolean
	noteDP: boolean
	isNominated: boolean
}
interface ILocalEstimateCols extends IWbsCols {
	confirmedVolume: boolean
}

interface IState {
	wbs: IWbsCols
	local: ILocalEstimateCols
	setVisibleColumns: (key: string, value: boolean, type: 'wbs' | 'local') => void
}
const columns = {
	wbs: {
		price: true,
		totalPrice: true,
		sum: true,
		totalSum: true,
		noteSDU: true,
		noteDZ: true,
		noteDP: true,
		isNominated: true
	},
	local: {
		price: true,
		totalPrice: true,
		sum: true,
		totalSum: true,
		noteSDU: true,
		noteDZ: true,
		noteDP: true,
		isNominated: true,
		confirmedVolume: true
	}
}

export const useEstimateColumnConfig = create(
	persist<IState>(
		(set, get) => ({
			...columns,
			setVisibleColumns: (key, value, type) => {
				set(() => ({ [type]: { ...get()[type], [key]: value } }))
			}
		}),
		{
			name: 'estimate-column-config',
			storage: createJSONStorage(() => localStorage)
		}
	)
)
