import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IIssuesReportData, ITrafficLight, ITrafficLightReport } from 'interfaces/ILightReport'

const projects: string[] = []
const report: ITrafficLightReport[] = []
const issues: IIssuesReportData = {
	critical: [],
	notCritical: []
}

const trafficLightReport = createSlice({
	name: 'trafficLightReport',
	initialState: {
		selectedProjects: projects,
		report: report,
		issues: issues
	},
	reducers: {
		setTrafficLight(state, action: PayloadAction<ITrafficLight>) {
			state.report = action.payload.report
			state.issues = action.payload.issue
		},
		setProjectsState(state, action: PayloadAction<string[]>) {
			state.selectedProjects = action.payload
		},
		resetTrafficLight(state) {
			state.report = report
			state.issues = issues
		}
	}
})

export const { setTrafficLight, setProjectsState, resetTrafficLight } = trafficLightReport.actions

export default trafficLightReport.reducer
