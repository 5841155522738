import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { IEstimateCreation } from '../model/estimate-related-documents-schema'

export const useEstimateRelatedDocumentsColumns = () => {
	const columns = useMemo<ColumnsType<IEstimateCreation>>(
		() => [
			{
				dataIndex: 'type',
				title: 'Тип',
				width: 100,
				align: 'center'
			},
			{
				dataIndex: 'name',
				title: 'Наименование',
				width: 100,
				align: 'center',
				render: (value, record) => (
					<NavLink
						to={
							`/${
								record?.type === 'Корректировка ВОР' || record?.type === 'ВОР' ? 'unmodel' : 'local'
							}/` + record?.id
						}
						target="_blank"
					>
						{value}
					</NavLink>
				)
			},
			{
				dataIndex: 'date',
				title: 'Дата',
				width: 80,
				align: 'center',
				render: (_, record) =>
					record?.date &&
					dayjs(record?.date).isValid() &&
					dayjs(record?.date).format('DD.MM.YYYY HH:mm')
			}
		],
		[]
	)
	return columns
}
