import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { IIssue, IIssueDTOExtended, IIssuesFilters, IIssueSort } from 'interfaces/IIssue'
import { IUser } from 'interfaces/IUser'
interface IDTOAction {
	prop: string
	value: string | Date | dayjs.Dayjs | number | any[] | null | undefined
}
interface IDTOFilters {
	filters: IIssuesFilters
}

interface IResetProps {
	statusId: string
	projectId: string
}

const user = localStorage.getItem('user')
const parsedUser: IUser = user ? JSON.parse(user) : null

const issueFilters: IIssuesFilters | undefined = {}

const issueSort: IIssueSort = {
	titleSort: undefined,
	ownerSort: undefined
}

const issueInitialDTO: IIssueDTOExtended = {
	id: null,
	autodeskId: null,
	ownerId: null,
	response: '',
	responseDate: null,
	responder: '',
	title: '',
	description: '',
	locationDescription: '',
	actPoints: '',
	dueDate: null,
	closeVersion: 0,
	issueStatusId: '',
	workPackageId: null,
	issueTypeId: '',
	issueSubTypeId: '',
	projectId: '',
	contractorId: '',
	subcontractorId: null,
	violationTypeId: '',
	violationId: undefined,
	constructionObjectId: null,
	prefix: null,
	number: null,
	createdAt: null,
	attachments: [],
	attachmentsCount: 0,
	reasonDescription: null,
	ordinance: null,
	comments: [],
	checklist: null,
	contractId: undefined,
	contactPersonId: undefined,
	buildingPermitId: null,
	phase: '',
	building: '',
	responsibleManager: null,
	isMobile: null,
	owner: null,
	systemId: null,
	checklistTemplateName: null,
	checkListType: null,
	system: null
}


const issueListState = {
	currentPage: 1,
	totalItems: 0
}

const issueDtoKeys = Object.keys(issueInitialDTO).map(key => key)

const issueSlice = createSlice({
	name: 'issue',
	initialState: {
		init: issueInitialDTO,
		dto: issueInitialDTO,
		filters: issueFilters,
		listState: issueListState,
		sort: issueSort
	},
	reducers: {
		setIssueFilters(state, action: PayloadAction<IDTOFilters>) {
			state.filters = action.payload.filters
		},
		resetIssueFilters(state) {
			state.filters = issueFilters
		},
		setIssueSort(state, action: PayloadAction<IIssueSort>) {
			state.sort = action.payload
		},
		resetIssuesState(state) {
			state.filters = issueFilters
			state.listState = issueListState
			state.sort = issueSort
		},
		setCurrentPage(state, action: PayloadAction<number>) {
			state.listState.currentPage = action.payload
		},
		setTotalItems(state, action: PayloadAction<number>) {
			state.listState.totalItems = action.payload
		},
		setDto(state, action: PayloadAction<IIssue>) {
			Object.entries(action.payload).forEach(row => {
				if (issueDtoKeys.some(key => key === row[0]) && row[0] !== 'contractor') {
					state.dto = {
						...state.dto,
						[row[0]]: row[1]
					}
					state.init = {
						...state.init,
						[row[0]]: row[1]
					}
				}
				if (row[0] === 'response' && row[1] !== null) {
					for (const [key, value] of Object.entries(row[1])) {
						if (key === 'responder') {
							state.dto.responder = value as string
							state.init.responder = value as string
						}
						if (key === 'date') {
							state.dto.responseDate = value as Date
							state.init.responseDate = value as Date
						}
						if (key === 'answer') {
							state.dto.response = value as string
							state.init.response = value as string
						}
					}
				}
				if (row[0] === 'dueDate') {
					state.dto.dueDate = row[1]
					state.init.dueDate = row[1]
				}
				if (row[0] === 'cancellationReason') {
					state.dto.reasonDescription = action.payload.cancellationReason!?.reasonDescription!
					state.init.reasonDescription = action.payload.cancellationReason!?.reasonDescription!
				}
				if (
					(row[0].includes('issue') ||
						row[0].includes('Type') ||
						row[0].includes('owner') ||
						row[0].includes('contractor') ||
						row[0].includes('constructionObject') ||
						row[0].includes('workPackage') ||
						row[0].includes('contactPerson') ||
						row[0].includes('buildingPermit') ||
						row[0].includes('violation') ||
						row[0].includes('system') ||
						row[0] === 'contract' ||
						row[0] === 'contractorId') &&
					row[1] !== null
				) {
					let prop = row[0] + 'Id'
					let objValue
					for (const [key, value] of Object.entries(row[1])) {
						if (key === 'id') objValue = value
					}

					state.dto = {
						...state.dto,
						[prop]: objValue
					}
					state.init = {
						...state.init,
						[prop]: objValue
					}
				}
				if (row[0].includes('attachments')) {
					console.log('attachments:', row[1])
				}
			})
		},

		setDtoValue(state, action: PayloadAction<IDTOAction>) {
			console.log('action :>> ', action);
			if (issueDtoKeys.some(key => key === action.payload.prop)) {
				if (action.payload.prop === 'response') {
					state.dto = {
						...state.dto,
						response: action.payload.value as string,
						responseDate: new Date(Date.now()),
						responder: parsedUser.id !== null ? parsedUser.id : ''
					}
				// } else if (action.payload.prop === 'owner') {
				// 	state.dto = {
				// 		...state.dto,
				// 		owner:
				// 	}
				} else {
					state.dto = {
						...state.dto,
						[action.payload.prop]: action.payload.value
					}
				}
			}
		},

		resetDto(state, action: PayloadAction<IResetProps>) {
			const clearDto = {
				...issueInitialDTO,
				projectId: action.payload.projectId,
				issueStatusId: action.payload.statusId
			}
			state.init = clearDto
			state.dto = clearDto
		}
	}
})

export const {
	setIssueSort,
	setIssueFilters,
	resetIssueFilters,
	resetIssuesState,
	setCurrentPage,
	setTotalItems,
	setDto,
	setDtoValue,
	resetDto
} = issueSlice.actions

export default issueSlice.reducer
