import { useQueryClient } from '@tanstack/react-query'
import { Card, Col, Row } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect } from 'react'
import { WbsStatusReportApi } from '../api/wbs-status-report-api'
import { IWbsStatusReportFilters } from '../model/wbs-status-report-interface'
import { useWbsStatusReportPagination } from '../model/wbs-status-report-queries'
import { useWbsStatusReportState } from '../model/wbs-status-report-state'
import { WbsStatusReportCreate } from './WbsStatusReportCreate'
import { WbsStatusReportTable } from './WbsStatusReportTable'

interface IProps {}

export const WbsStatusReport: FC<IProps> = () => {
	const project = useAppSelector(state => state.environment.project)
	const filtersStatus: IWbsStatusReportFilters = {
		containerIds: undefined,
		projectIds: [project.id],
		containerStatusId: undefined,
		createdFrom: null,
		createdTo: null
	}
	const queryClient = useQueryClient()
	const { data: wbsStatusReportData } = useWbsStatusReportPagination(filtersStatus)
	// const { mutate: wbsStatusReportData } = useWbsStatusReport()
	const { filters, currentPage, resetData, setData } = useWbsStatusReportState()

	useEffect(() => {
		WbsStatusReportApi.getWbsStatusReport({
			settings: filters,
			limit: 50,
			offset: currentPage === 1 ? 0 : currentPage * 50 - 50
		}).then(data => {
			setData(data.containers.data)
		})
	}, [currentPage])

	return (
		<Card>
			<Row justify="space-between">
				<Col>
					<Row gutter={8}>
						<Col>
							<WbsStatusReportCreate />
						</Col>
						{/* <Col>
							<WbsStatusReportCreate download />
						</Col> */}
					</Row>
				</Col>
				{/* {!!wbsStatusReportData!?.length && (
					<Col>
						<Button
							danger
							onClick={() => {
								resetData()
								useWbsStatusReportState.setState({ totalItems: 1 })
							}}
						>
							Сбросить
						</Button>
					</Col>
				)} */}
			</Row>
			<WbsStatusReportTable />
		</Card>
	)
}
