import { Button } from 'antd'
import { FC, useState } from 'react'
import { useRegulatoryRequestState } from 'widgets/regulatory-request'
import { useShallow } from 'zustand/react/shallow'

interface IProps {
	id?: string
	estimatePosition?: string
}

export const AsmRequestNci: FC<IProps> = ({ id, estimatePosition }) => {
	const [showModal, setShowModal] = useState(false)
	const RegulatoryRequestToggleShow = useRegulatoryRequestState(
		useShallow(state => state.toggleShow)
	)
	return (
		<>
			{/* <AsmRequestNciModal
				show={showModal}
				onClose={() => setShowModal(false)}
				id={id}
				estimatePosition={estimatePosition}
			/> */}
			<Button type="primary" onClick={() => RegulatoryRequestToggleShow(true)}>
				Запрос в НСИ
			</Button>
		</>
	)
}
