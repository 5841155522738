import { BookOutlined, ProjectOutlined, QuestionCircleFilled } from '@ant-design/icons'
import { UserMenu } from 'UI/widgets/userMenu'
import { Button, Col, Flex, Modal, Row, Tooltip, Typography } from 'antd'
import { ReactComponent as ATLogo } from 'assets/at_logo_white.svg'
import { CopyToClipboard } from 'components/CopyToClipboard'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect, useState } from 'react'
import { NavLink, useLocation, useMatch } from 'react-router-dom'
import { useRegulatoryRequestState } from 'widgets/regulatory-request'
import WbsNotificationDrawer from 'widgets/wbs/ui/WbsNotificationDrawer'
import { useShallow } from 'zustand/react/shallow'
import ATMenu from './ATMenu'
import EnvironmentConfig from './EnvironmentConfig'

const { Title } = Typography
const DashboardHeader: FC = () => {
	const { pathname } = useLocation()
	const unmodelMatch = useMatch('/unmodel/*')
	const environment = useAppSelector(state => state.environment)
	const [showEnvConfig, setShowEnvConfig] = useState(false)
	const RegulatoryRequestToggleShow = useRegulatoryRequestState(
		useShallow(state => state.toggleShow)
	)

	useEffect(() => {
		if (!environment.project || environment.project.id.length === 0) {
			setShowEnvConfig(true)
		}
	}, [showEnvConfig])

	const info = () => {
		Modal.info({
			zIndex: 99999,
			icon: <QuestionCircleFilled rev={undefined} />,
			title: <Title level={5}>Нужна помощь?</Title>,
			content: (
				<>
					Если у Вас возникли сложности во время работы или другой вопрос, связанный с работой
					приложения, а также предложения по улучшению работы приложения, напишите, пожалуйста, на{' '}
					<a href="mailto:digital@ndeco.ru">digital@ndeco.ru</a>
					<br />
					Спасибо!
				</>
			),
			onOk() {}
		})
	}

	return (
		<>
			<EnvironmentConfig open={showEnvConfig} onClose={() => setShowEnvConfig(false)} />
			<Row className="dashboard__header" justify="space-between" align="middle">
				<Col flex={1}>
					<Row gutter={16} align="middle">
						<Col className="dashboard__header-logo">
							<NavLink to="/">
								<ATLogo />
							</NavLink>
						</Col>

						<Col>
							<ATMenu />
						</Col>
						<Col>
							<Flex gap={4} align="center">
								<Button
									style={{ color: '#fff' }}
									icon={<ProjectOutlined />}
									type="text"
									onClick={() => setShowEnvConfig(true)}
								>
									{environment.project ? environment.project.name : 'Проект не выбран'}
								</Button>
								<CopyToClipboard
									ghost
									text={environment?.project?.name!}
									tooltip="Скопировать название проекта"
								/>
							</Flex>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row gutter={16}>
						{!!unmodelMatch && (
							<Col>
								<WbsNotificationDrawer />
							</Col>
						)}
						{(pathname.slice(-7, pathname.length) === 'unmodel' ||
							pathname.slice(-5, pathname.length) === 'local') && (
							<Col>
								{/* <AsmRequestNci /> */}
								<Button type="primary" onClick={() => RegulatoryRequestToggleShow(true)}>
									Запрос в НСИ
								</Button>
							</Col>
						)}
						<Col>
							<NavLink to="/releases">
								<Tooltip title="История обновлений">
									<Button className="at-btn-menu" icon={<BookOutlined />} />
								</Tooltip>
							</NavLink>
						</Col>
						<Col>
							<Button icon={<QuestionCircleFilled />} type="primary" onClick={info} />
						</Col>
						<Col>
							<UserMenu />
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	)
}

export default DashboardHeader
