import { PieChartFilled } from '@ant-design/icons'
import { Button, Col, Flex, Row } from 'antd'
import { ArcElement, Chart as ChartJS, Legend, Title, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { ISSUE_STATUSES } from 'enums'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useIssueAnalytics } from 'hooks/useIssueAnalytics'
import { FC, ReactNode } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useNavigate } from 'react-router-dom'
import { resetIssueFilters, setIssueFilters } from 'store/issueSlice'

ChartJS.register(ArcElement, Tooltip, Legend, Title, ChartDataLabels)

interface IProps {}

enum filterSettings {
	OpenIssues = 'Открыто',
	CloseIssues = 'Закрыто',
	ReadyForControlIssues = 'Готово к проверке'
	// FixOut = 'Просрочено'
}
export const IssueAnalyticsWidget: FC<IProps> = props => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { diagramsContractor } = useAppSelector(state => state.environment)
	const { data: analytics, isSuccess } = useIssueAnalytics()
	const values = Object.keys(filterSettings).map((_, index) =>
		analytics ? Object.values(analytics)[index] : 0
	)
	const labels = Object.values(filterSettings).map(v => v)
	const customLabels = labels.map((label, index) => `${label}: ${values[index]}`)
	const backgroundColor = ['#f9b058', '#57a0bb', '#a47faa', '#bf656e']
	const data = {
		data: {
			labels: customLabels,
			datasets: [
				{
					data: values,
					backgroundColor: backgroundColor,
					borderColor: ['transparent'],
					borderWidth: 1
				}
			]
		},
		width: 320,
		height: 320,
		options: {
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false
				},
				datalabels: {
					color: '#fff',
					formatter: (value: any) => {
						return value || null
					}
				}
			}
		}
	}

	const goToData = (filter: string) => {
		dispatch(resetIssueFilters())
		switch (filter) {
			case 'OpenIssues':
				dispatch(
					setIssueFilters({
						filters: {
							contractorId: diagramsContractor !== 'all' ? diagramsContractor : undefined,
							status: [ISSUE_STATUSES.open]
						}
					})
				)
				break
			case 'FixOut':
				dispatch(
					setIssueFilters({
						filters: {
							contractorId: diagramsContractor !== 'all' ? diagramsContractor : undefined,
							status: [ISSUE_STATUSES.open, ISSUE_STATUSES.ready_to_inspect, ISSUE_STATUSES.closed],
							fixedOut: true
						}
					})
				)
				break
			case 'FixOutOpen':
				dispatch(
					setIssueFilters({
						filters: {
							contractorId: diagramsContractor !== 'all' ? diagramsContractor : undefined,
							status: [ISSUE_STATUSES.open, ISSUE_STATUSES.ready_to_inspect],
							fixedOut: true
						}
					})
				)
				break
			case 'FixOutClosed':
				dispatch(
					setIssueFilters({
						filters: {
							contractorId: diagramsContractor !== 'all' ? diagramsContractor : undefined,
							status: [ISSUE_STATUSES.closed],
							fixedOut: true
						}
					})
				)
				break
			case 'ReadyForControlIssues':
				dispatch(
					setIssueFilters({
						filters: {
							contractorId: diagramsContractor !== 'all' ? diagramsContractor : undefined,
							status: ['5758e9f3-b35b-4d97-8616-aa3bbb96131f']
						}
					})
				)
				break
			case 'CloseIssues':
				dispatch(
					setIssueFilters({
						filters: {
							contractorId: diagramsContractor !== 'all' ? diagramsContractor : undefined,
							status: ['2d9df9da-5340-4f2a-ad30-77a24bb1bfea']
						}
					})
				)
				break
			case 'allIssuesContractor':
				dispatch(
					setIssueFilters({
						filters: {
							contractorId: diagramsContractor !== 'all' ? diagramsContractor : undefined,
							status: [ISSUE_STATUSES.open, ISSUE_STATUSES.closed, ISSUE_STATUSES.ready_to_inspect]
						}
					})
				)
				break
		}
		navigate('/control/issues')
	}

	return (
		<Row
			wrap={false}
			gutter={12}
			style={{
				height: '100%',
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: '#f2f2f2'
			}}
		>
			<Col span={8}>
				<Flex vertical gap={8} style={{ height: '100%' }}>
					<Col flex={1} style={{ padding: '1rem', background: '#fefefe' }}>
						<h2 style={{ margin: 0 }}>Всего замечаний:</h2>
						<Button
							style={{ height: 'auto', fontSize: '3rem', fontWeight: 'bold', color: '#1677ff' }}
							type="link"
							onClick={() => goToData('allIssuesContractor')}
						>
							{analytics?.TotalIssues}
						</Button>
					</Col>
					<Col flex={1} style={{ padding: '1rem', background: '#fefefe' }}>
						<h2>Замечания на проверке:</h2>
						<p style={{ fontStyle: 'italic', color: 'gray', margin: 0 }}>
							Замечания, которые необходимо проверить
						</p>
						<Button
							style={{ height: 'auto', fontSize: '3rem', fontWeight: 'bold', color: '#1677ff' }}
							type="link"
							onClick={() => goToData('ReadyForControlIssues')}
						>
							{analytics?.ReadyForControlIssues}
						</Button>
					</Col>
					<Col flex={1} style={{ padding: '1rem', background: '#fefefe' }}>
						<h2 style={{ margin: 0 }}>Просроченные открытые:</h2>
						<Button
							style={{ height: 'auto', fontSize: '3rem', fontWeight: 'bold', color: '#bf656e' }}
							type="link"
							onClick={() => goToData('FixOutOpen')}
						>
							{analytics?.FixedOutOpen ?? 0}
						</Button>
					</Col>
					<Col flex={1} style={{ padding: 0 }}>
						<Flex justify="stretch" gap={8} align="middle" style={{ height: '100%' }}>
							<div style={{ flex: 1, padding: '1rem', background: '#fefefe' }}>
								<h3 style={{ margin: 0, fontSize: 13, height: 25 }}>Всего просроченных:</h3>
								<Button
									style={{ height: 'auto', fontSize: '2rem', fontWeight: 'bold', color: '#bf656e' }}
									type="link"
									onClick={() => goToData('FixOut')}
								>
									{analytics?.FixOut}
								</Button>
							</div>
							<div style={{ flex: 1, padding: '1rem', background: '#fefefe' }}>
								<h3 style={{ margin: 0, fontSize: 13, height: 25 }}>Просроченные закрытые:</h3>
								<Button
									style={{ height: 'auto', fontSize: '2rem', fontWeight: 'bold', color: '#bf656e' }}
									type="link"
									onClick={() => goToData('FixOutClosed')}
								>
									{analytics?.fixedOutClosed ?? 0}
								</Button>
							</div>
						</Flex>
					</Col>
				</Flex>
			</Col>
			<Col flex={1} style={{ padding: 16, background: '#fefefe' }}>
				<h2>Замечания по статусам:</h2>
				<Row align="middle" gutter={8} style={{ height: '100%' }}>
					<Col lg={{ span: 24 }} xl={{ span: 12 }}>
						{isSuccess && <Doughnut {...data} />}
					</Col>
					<Col lg={{ span: 24 }} xl={{ span: 12 }}>
						<Flex gap={16} vertical justify="center" align="center" style={{ height: '100%' }}>
							{labels.map((item, index) => (
								<LegendButton
									title={item}
									value={values[index]}
									icon={<PieChartFilled style={{ color: backgroundColor[index] }} />}
									onClick={() => goToData(Object.keys(filterSettings)[index])}
								/>
							))}
						</Flex>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

const LegendButton = ({
	title,
	value,
	icon,
	onClick
}: {
	title: string
	value: number
	icon?: ReactNode
	onClick?: () => void
}) => {
	return (
		<Row
			align="middle"
			gutter={8}
			justify="start"
			style={{ width: '100%', cursor: 'pointer' }}
			wrap={false}
			onClick={onClick}
		>
			{icon && <Col>{icon}</Col>}
			<Col>
				<Button block type="link">
					{title}
				</Button>
			</Col>
			<Col flex={1} />
			<Col>
				<b>{value}</b>
			</Col>
		</Row>
	)
}

/* <Row align="middle">
		<Col>
			<p style={{ margin: 0 }}>
				Просроченных <b>открытых:</b>
			</p>
		</Col>
		<Col>
			<Button
				style={{
					height: 'auto',
					fontSize: '1.2rem',
					fontWeight: 'bold',
					color: '#bf656e'
				}}
				type="link"
				onClick={() => goToData('FixOut')}
			>
				{analytics?.fixedOutOpen ?? 0}
			</Button>
		</Col>
	</Row>
	<Row align="middle">
		<Col>
			<p style={{ margin: 0 }}>
				Просроченных <b>закрытых:</b>
			</p>
		</Col>
		<Col>
			<Button
				style={{
					height: 'auto',
					fontSize: '1.2rem',
					fontWeight: 'bold',
					color: '#bf656e'
				}}
				type="link"
				onClick={() => goToData('FixOut')}
			>
				{analytics?.fixedOutClosed ?? 0}
			</Button>
		</Col>
	</Row> */
