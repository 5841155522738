import { Button } from "antd"
import { ITextItem } from "interfaces/IAnalytics"
import { FC } from "react"
import ReactQuill from "react-quill"

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{ 'header': 2 }],               // custom button values
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', 'normal', 'large', 'huge'] }],  // custom dropdown
  // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  [{ 'align': [] }],

  ['clean']                                         // remove formatting button
]

interface IProps {
  textItem: ITextItem | null
  setTextItem: (item: ITextItem | null) => void
  handleChangeText: () => void
  setEditorVisible: (visibility: boolean) => void
}

const Editor: FC<IProps> = ({
  textItem,
  setTextItem,
  handleChangeText,
  setEditorVisible
}) => {

  return (
    <>
      <ReactQuill
        theme="snow"
        value={textItem?.content}
        onChange={
          e => setTextItem({
            id: textItem?.id ? textItem?.id : '',
            content: e
          })}
        modules={{
          toolbar: toolbarOptions
        }}
      />
      <Button
        onClick={handleChangeText}
        style={{ marginTop: 16, marginRight: 8 }}
        type='primary'
      >
        Сохранить
      </Button>
      <Button onClick={() => {
        setTextItem(null)
        setEditorVisible(false)
      }} style={{ marginTop: 16 }}>
        Отмена
      </Button>
    </>
  )
}

export default Editor