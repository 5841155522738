import { FilterOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, Row, Table, Tag, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { usePermissions } from 'hooks/usePermissions'
import { IProject } from 'interfaces/IEnvironment'
import { IUser } from 'interfaces/IUser'
import { FC, useEffect, useState } from 'react'
// import { setCurrentPage, setPermissionFilters } from 'store/environmentSlice'
import { setPageTitle } from 'store/rootSlice'
import { setCurrentPage } from 'store/settingsUsersSlice'
import { useUserCardState } from 'widgets/user-card/model/user-card-state'
import PermissionsFilters from './PermissionsFilters'
import UserDrawer from './UserDrawer'
interface ITabPermissionsProps {
	message: (m: string, type: string) => void
	loader: (x: boolean) => void
	projects: IProject[] | []
}

const TabPermissions: FC<ITabPermissionsProps> = ({ message, loader }) => {
	const dispatch = useAppDispatch()
	const queryClient = useQueryClient()
	const { data: users } = usePermissions()
	const permissionListState = useAppSelector(state => state.settingsUsers.listState)
	const permissionFilters = useAppSelector(state => state.settingsUsers.filters)
	const [showPermissionFilter, setShowPermissionFilter] = useState<boolean>(false)
	const [filtered, setFiltered] = useState(false)
	const [showUserDrawer, setShowUserDrawer] = useState(false)
	const [selectedUser, setSelectedUser] = useState<IUser | null>(null)
	const { setSelectedUserId } = useUserCardState()
	// useEffect(() => {
	// 	getUsersList()
	// }, [filteredUsers, filteredProjects, filteredCompanies])
	const usersColumns: ColumnsType<IUser> = [
		{
			title: 'Пользователь',
			width: 350,
			filtered: false,
			render: (_, record) => (
				<div className="at-fxdc">
					<span>{`${record.firstName!} ${record.lastName!} `}</span>
					<span style={{ color: '#aaa' }}>{record.email!}</span>
				</div>
			)
		},
		{
			title: 'Проекты',
			filtered: false,
			render: (_, record) =>
				record.projects!?.map(prj => (
					<Tag
						color={'#1677ff'}
						style={{
							fontSize: 14,
							padding: 4,
							margin: 3,
							color: '#f0f0f0'
						}}
					>
						{prj.name}
					</Tag>
				))
		},
		{
			title: 'Компания',
			filtered: false,
			render: (_, record) => <div className="at-fxdc">{record.contractor!?.name!}</div>
		}
	]

	useEffect(() => {
		dispatch(setPageTitle({ subTitle: 'Пользователи' }))
	}, [])

	useEffect(() => {
		dispatch(setPageTitle({ subTitle: 'Проекты' }))
	}, [])

	useEffect(() => {
		dispatch(setPageTitle({ subTitle: 'Компания' }))
	}, [])

	const onPagination = (page: number) => {
		dispatch(setCurrentPage(page))
	}

	const onUserDrawerClose = (mustUpdate: boolean) => {
		if (mustUpdate) queryClient.invalidateQueries(['permissionsList'])
		setSelectedUser(null)
		setShowUserDrawer(false)
	}
	return (
		<>
			<PermissionsFilters
				open={showPermissionFilter}
				onClose={() => setShowPermissionFilter(false)}
			/>
			{/* <UserCard /> */}
			<UserDrawer
				user={selectedUser}
				open={showUserDrawer}
				onClose={mustUpdate => onUserDrawerClose(mustUpdate)}
			/>
			<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
				<Col>
					<Row gutter={8} justify="start" style={{ marginBottom: '1rem' }}>
						{/* <Col> */}
						<Tooltip title="Фильтры">
							<Button
								type={
									Object.entries(permissionFilters!).some(x => typeof x[1] !== 'undefined')
										? 'primary'
										: 'default'
								}
								size="middle"
								onClick={() => {
									setShowPermissionFilter(true)
								}}
								icon={<FilterOutlined rev={undefined} />}
							>
								Фильтры
							</Button>
						</Tooltip>
						{/* </Col> */}
						{/* <Col>
							<Input.Search
								allowClear
								addonAfter={false}
								placeholder="Поиск..."
								onSearch={value => onSearch(value)}
								style={{ width: 300 }}
							/>
						</Col> */}
					</Row>
				</Col>
				<Table
					// loading={{ indicator: <LoadingOutlined spin rev={undefined} />, spinning: isLoading }}
					rowKey={record => record.id}
					size="small"
					bordered
					dataSource={users}
					columns={usersColumns}
					pagination={{
						// defaultPageSize: 50,
						current: permissionListState!.currentPage!,
						pageSize: 50,
						total: permissionListState!.totalItems!,
						hideOnSinglePage: true,
						showSizeChanger: false,
						onChange: onPagination
					}}
					scroll={{ y: '60vh', x: '100%' }}
					onRow={record => ({
						onDoubleClick: () => {
							setSelectedUser(record)
							setShowUserDrawer(true)
						}
					})}
				/>
			</div>
		</>
	)
}
export default TabPermissions
