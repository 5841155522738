import { adjustmentApi } from 'widgets/adjustment'
import { create } from 'zustand'
import { ILocalEstimate, ILocalEstimateRegistryFilters, LocalEstimateRegistryApi } from '..'

interface IState {
	correctionRecord?: ILocalEstimate
	adjustmentRecord?: ILocalEstimate
	// containerToCopy?: ILocalEstimate
	containerToRemove?: ILocalEstimate
	currentPage: number
	editableRow?: ILocalEstimate
	filters: ILocalEstimateRegistryFilters
	showFilters: boolean
	totalItems?: number
	sortState?: number
	onCorrectionCreate: (containerId: string, name: string) => Promise<number>
	onAdjustmentCreate: (containerId: string, name: string) => Promise<number>
	// onCopy: (containerId: string, formData: ILocalEstimateWbsCopyForm) => Promise<number>
	onPagination: (currentPage: number) => void
	onRemove: (containerId: string) => Promise<number>
	resetFilters: () => void
	setCorrectionRecord: (correctionRecord: ILocalEstimate | undefined) => void
	setAdjustmentRecord: (adjustmentRecord: ILocalEstimate | undefined) => void
	// setContainerToCopy: (containerToCopy: ILocalEstimate | undefined) => void
	setContainerToRemove: (containerToRemove: ILocalEstimate | undefined) => void
	setEditableRow: (row: ILocalEstimate | undefined) => void
	setFilters: (filters: ILocalEstimateRegistryFilters) => void
	setSearch: (search: string) => void
	setShowFilters: (state: boolean) => void
	setTotalItems: (totalItems: number) => void
	setSortState: (sortState?: number) => void
}

const initial = {
	currentPage: 1,
	editableRowId: null,
	showFilters: false,
	sortState: undefined,
	filters: {
		constructionObjectId: undefined,
		containerStatusId: undefined,
		createdDate: undefined,
		ready: undefined,
		search: undefined,
		typeSectionId: undefined,
		authorId: undefined
	}
}

export const useLocalEstimateRegistryState = create<IState>((set, get) => ({
	...initial,
	onCorrectionCreate: async (containerId, name) =>
		await LocalEstimateRegistryApi.createAdjustment(containerId, name),
	onAdjustmentCreate: async (containerId, name) =>
		await adjustmentApi.create(containerId, name, 'local'),
	// onCopy: async (containerId, formData) =>
	// 	await LocalEstimateRegistryApi.copyContainer(containerId, formData),
	onPagination: currentPage => set(() => ({ currentPage })),
	onRemove: async containerId => await LocalEstimateRegistryApi.removeContainer(containerId),
	setCorrectionRecord: correctionRecord => set(() => ({ correctionRecord })),
	setAdjustmentRecord: adjustmentRecord => set(() => ({ adjustmentRecord })),
	// setContainerToCopy: containerToCopy => set(() => ({ containerToCopy })),
	setContainerToRemove: containerToRemove => set(() => ({ containerToRemove })),
	setEditableRow: row => set(() => ({ editableRow: row })),
	setFilters: filters => {
		set(() => ({ filters: { ...filters, search: get().filters.search }, currentPage: 1 }))
	},
	setSortState: sortState => {
		set(() => ({ sortState }))
	},
	setSearch: search =>
		set(() => ({
			filters: { ...get().filters, search: search.length ? search : undefined },
			currentPage: 1
		})),
	setShowFilters: state => set(() => ({ showFilters: state })),
	setTotalItems: totalItems => set(() => ({ totalItems })),
	resetFilters: () =>
		set(() => ({
			filters: {
				authorId: undefined,
				constructionObjectId: undefined,
				containerStatusId: undefined,
				createdDate: undefined,
				ready: undefined,
				typeSectionId: undefined
			}
		}))
}))
