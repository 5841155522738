import { useQuery } from '@tanstack/react-query'
import { IChecklistResponsibleUser } from 'interfaces/IChecklist'
import { getResponsibleUsers } from 'services/ChecklistsService'

export const useResponsibleUsers = (templateType: number) => {
	return useQuery<IChecklistResponsibleUser[]>({
		queryKey: ['ResponsibleUsers', templateType],
		queryFn: async () => {
			return typeof templateType === 'number'
				? await getResponsibleUsers({ templateType: templateType}).then(data => data)
				: []
		}
	})
}
