import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Typography, Input, Button, Tooltip, message, Row, Col } from 'antd'
import axios, { AxiosError } from 'axios'
import { QuestionCircleOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import { BASEURL } from 'services/ApiService'
const { Title } = Typography

const passwordFormatInfo =
	'Пароль должен содержать не менее 8 символов и включать как минимум одну цифру, одну прописную, одну строчную букву и один спец символов(прим. ?!@#$%^&*)'

const RecoveryForm: FC = () => {
	const navigate = useNavigate()
	const [recoveryForm] = Form.useForm()
	const [canSave, setCanSave] = useState(true)

	const url = window.location.href.toString()
	const recoveryData = {
		email: url.split('?Email=').pop()!.split('&')[0],
		code: url.split('&RecoveryCode=').pop()
	}

	const validateForm = () => {
		const errors = recoveryForm
			.getFieldsError()
			.map(err => err.errors.length)
			.reduce((partialSum, a) => partialSum + a, 0)
		setCanSave(errors > 0)
	}

	const onRecoveryFormFinish = async () => {
		const recoveryDto = {
			email: recoveryData.email,
			recoveryCode: recoveryData.code,
			newPassword: recoveryForm.getFieldValue('confirm')
		}
		await axios({
			baseURL: BASEURL,
			headers: {
				Accept: 'application/json'
			},
			url: `/v1/reset-password`,
			method: 'post',
			data: recoveryDto
		})
			.then(response => {
				if (response.status === 200) {
					message.success('Новый пароль установлен', 5)
					navigate('/')
				} else {
					message.error('Произошла ошибка, обратитесь в службу поддержки', 5)
				}
			})
			.catch((error: AxiosError) => message.error('Произошла ошибка, обратитесь в службу поддержки', 5))
	}

	const onRecoveryFormFailed = () => {}

	return (
		<>
			<Title level={4} style={{ width: '100%', textAlign: 'center' }}>
				Восстановление пароля
			</Title>
			<Form
				name="recoveryForm"
				form={recoveryForm}
				onFinish={onRecoveryFormFinish}
				onFinishFailed={onRecoveryFormFailed}
				onFieldsChange={validateForm}
				autoComplete="off"
			>
				<Form.Item
					name="password"
					rules={[
						{ required: true, message: 'Поле не должно быть пустым' },
						{
							pattern: RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{8,}$'),
							message: passwordFormatInfo
						}
					]}
				>
					<Input.Password
						className="at-input"
						placeholder="Пароль"
						allowClear
						iconRender={visible => (
							<Row gutter={8} align="middle">
								<Col>{visible ? <EyeTwoTone rev={undefined} /> : <EyeInvisibleOutlined rev={undefined} />}</Col>
								<Col>
									<Tooltip title={passwordFormatInfo}>
										<QuestionCircleOutlined rev={undefined} />
									</Tooltip>
								</Col>
							</Row>
						)}
					/>
				</Form.Item>
				<Form.Item
					name="confirm"
					dependencies={['password']}
					hasFeedback
					rules={[
						{
							required: recoveryForm.getFieldValue('password') !== null,
							message: 'Поле не может быть пустым!'
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve()
								}
								return Promise.reject(new Error('Пароли не совпадают'))
							}
						})
					]}
				>
					<Input.Password className="at-input" placeholder="Пароль ещё раз" allowClear />
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit" className="at-btn at-btn--fill" disabled={canSave}>
						Восстановить
					</Button>
				</Form.Item>
				<Button className="at-btn at-btn--fill" type="link" onClick={() => navigate('/')}>
					На страницу авторизации
				</Button>
			</Form>
		</>
	)
}

export default RecoveryForm
