import { Button, Col, DatePicker, Drawer, Form, List, Row, Select, Typography } from 'antd'
import { FC, useEffect, useState } from 'react'

import locale from 'antd/es/date-picker/locale/ru_RU'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IConstructionObject } from 'interfaces/IConstructionObject'
import { IContract } from 'interfaces/IContract'
import { IContractor } from 'interfaces/IEnvironment'
import { IIssueStatus, IIssueSubType, IIssueType, IIssuesFilters } from 'interfaces/IIssue'
import { getAuthors } from 'services/IssuesService'
import { uniqueSet } from 'services/helperService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setCurrentPage, setIssueFilters } from 'store/issueSlice'

const { Option } = Select
const { Text } = Typography

interface IFilterItem {
	title: string | React.ReactNode
	formItemName: string
	list:
		| IIssueStatus[]
		| IIssueType[]
		| IIssueSubType[]
		| IContract[]
		| IContractor[]
		| IConstructionObject[]
		| null
	date: boolean
	disabled: boolean
}

interface IIssuesFiltersProps {
	open: boolean
	onClose: () => void
}

const IssuesFilters: FC<IIssuesFiltersProps> = ({ open, onClose }) => {
	const checkPermissions = useCheckPermissions()
	const dispatch = useAppDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const [issueFiltersForm] = Form.useForm()
	const dueDateField = Form.useWatch('dueDate', issueFiltersForm)
	const {
		project,
		user: currentUser,
		issueStatuses: statuses,
		issueTypes,
		issueSubTypes,
		contractors,
		contracts,
		permissions,
		workPackages
	} = useAppSelector(state => state.environment)
	const issueFilters = useAppSelector(state => state.issues.filters)
	const [subTypes, setSubTypes] = useState<IIssueSubType[]>(
		uniqueSet([...issueSubTypes!]!?.sort((a, b) => a.name.localeCompare(b.name))!, 'name')
	)
	const [subContractors, setSubContractors] = useState<IContractor[]>([])
	const [owners, setOwners] = useState<any[]>([])
	const allCompanies = checkPermissions([PERMISSIONS.AllCompanies])
	const hasBoolean = [
		{
			id: '0',
			name: 'Нет'
		},
		{
			id: '1',
			name: 'Да'
		}
	]

	const filterList: IFilterItem[] = [
		{
			title: 'Статус',
			formItemName: 'status',
			list: [...statuses!]!?.filter(
				(status: IIssueStatus) =>
					status!?.identityName!?.includes('ready')! ||
					status!?.identityName!?.includes('closed')! ||
					status!?.identityName!?.includes('cancel')! ||
					status!?.identityName!?.includes('open')!
			),
			date: false,
			disabled: false
		},
		{
			title: 'Пакет СМР',
			formItemName: 'workPackageId',
			list: workPackages,
			date: false,
			disabled: false
		},
		{
			title: 'Тип',
			formItemName: 'issueTypeId',
			list: issueTypes?.filter(t => !t.isDeleted) || [],
			date: false,
			disabled: false
		},
		{
			title: 'Подтип',
			formItemName: 'issueSubTypeId',
			list: subTypes?.filter(t => !t.isDeleted) || [],
			date: false,
			disabled: false
		},
		{
			title: 'Генподрядчик',
			formItemName: 'contractorId',
			list: [...contractors!]!?.sort((a, b) => a.name.localeCompare(b.name))!,
			date: false,
			disabled: !checkPermissions([PERMISSIONS.AllCompanies])
		},
		{
			title: 'Субподрядчик',
			formItemName: 'subContractorId',
			list: subContractors!?.sort((a, b) => a.name.localeCompare(b.name))!,
			date: false,
			disabled: false
		},
		{
			title: 'Автор',
			formItemName: 'ownerId',
			list: owners,
			date: false,
			disabled: false
		},
		{ title: 'Дата создания', formItemName: 'createdAt', list: null, date: true, disabled: false },
		{ title: 'Дата устранения', formItemName: 'dueDate', list: null, date: true, disabled: false },
		{
			title: 'Наличие предписания',
			formItemName: 'hasOrdinance',
			list: hasBoolean,
			date: false,
			disabled: false
		},
		{
			title: 'Наличие чек-листа',
			formItemName: 'hasChecklist',
			list: hasBoolean,
			date: false,
			disabled: false
		},
		{
			title: 'Просроченные',
			formItemName: 'fixedOut',
			list: hasBoolean,
			date: false,
			disabled: false
		},
		{
			title: 'Mobile',
			formItemName: 'isMobile',
			list: hasBoolean,
			date: false,
			disabled: false
		}
	]

	const [filterItems, setFilterItems] = useState<IFilterItem[]>(filterList)

	useEffect(() => {
		issueFiltersForm.setFieldsValue({
			...issueFilters,
			dueDate:
				issueFilters.dueDate !== null &&
				dayjs(issueFilters.dueDate).isValid() &&
				issueFilters.fixedOut
					? dayjs(issueFilters.dueDate)
					: null,
			fixedOut:
				typeof issueFilters.fixedOut !== 'undefined'
					? Number(issueFilters.fixedOut).toString()
					: undefined
		})
	}, [])
	// const init = () => {
	// 	getSubContractors(contracts!)
	// 	issueFiltersForm.setFieldsValue(filters)
	// }

	// useEffect(() => {
	// 	init()
	// }, [])

	useEffect(() => {
		if (!allCompanies) {
			checkAllCompanies()
		}
	}, [open])

	const getIssuesAuthors = async () => {
		setIsLoading(true)
		await getAuthors(project?.id!)
			.then(response => {
				setOwners(
					response.map((owner: { ownerId: string; name: string }) => ({
						id: owner.ownerId,
						name: owner.name
					}))
				)
			})
			.finally(() => setTimeout(() => setIsLoading(false), 200))
	}

	const checkAllCompanies = () => {
		const cId = currentUser?.contractorId!
		issueFiltersForm.setFieldValue('contractorId', cId)
		onChangeFilter('contractorId', cId)
	}

	useEffect(() => {
		setFilterItems(filterList)
	}, [
		dueDateField,
		statuses,
		issueTypes,
		subTypes,
		contractors,
		subContractors,
		owners,
		workPackages
	])

	// useEffect(() => {
	// 	if (dueDateField === null) issueFiltersForm.setFieldValue('fixedOut', undefined)
	// }, [dueDateField])

	useEffect(() => {
		getIssuesAuthors()
	}, [project])

	useEffect(() => {
		getSubContractors(contracts!)
	}, [contracts])

	const onFilters = (filters: IIssuesFilters, show: boolean) => {
		if (filters) {
			dispatch(
				setIssueFilters({
					filters: {
						...issueFilters,
						contractorId: filters.contractorId,
						status: filters.status,
						issueSubTypeId: filters.issueSubTypeId,
						issueTypeId: filters.issueTypeId,
						subContractorId: filters.subContractorId,
						ownerId: filters.ownerId,
						createdAt: filters.createdAt,
						dueDate: filters.dueDate,
						hasChecklist: filters.hasChecklist,
						hasOrdinance: filters.hasOrdinance,
						workPackageId: filters.workPackageId,
						fixedOut: filters.fixedOut,
						isMobile: filters.isMobile
					}
				})
			)
			if (show) {
				onClose()
			}
		}
		dispatch(setCurrentPage(1))
	}

	const onChangeFilter = (field: string, value: any) => {
		const filters: IIssuesFilters = issueFiltersForm.getFieldsValue()
		if (field === 'contractorId') {
			if (allCompanies) {
				issueFiltersForm.setFieldValue('subContractorId', undefined)
				issueFiltersForm.setFieldValue('constuctionObjectId', undefined)
			}
			if (typeof filters.contractorId !== 'undefined') {
				getSubContractors(contracts!, filters)
			} else {
				getSubContractors(contracts!)
			}
		}

		if (field === 'issueTypeId') {
			if (typeof filters.issueTypeId !== 'undefined') {
				const currentSubTypes = issueSubTypes!?.filter(subType => subType.issueTypeId === value)
				setSubTypes(currentSubTypes!?.sort((a, b) => a.name.localeCompare(b.name)))
			} else {
				setSubTypes(
					uniqueSet([...issueSubTypes!]!?.sort((a, b) => a.name.localeCompare(b.name))!, 'name')
				)
			}
		}
		if (field === 'status' && !!value.length === false) {
			issueFiltersForm.setFieldValue('status', undefined)
		}
	}

	const getSubContractors = (contractList: IContract[], filters?: IIssuesFilters) => {
		if (contractList!.length > 0) {
			const objects: IContract[] = filters
				? contractList!?.filter(
						(contract: IContract) => contract!?.contractor!?.id! === filters!?.contractorId!
				  )
				: contractList!
			let subs: IContractor[] = []
			objects
				.flatMap((contract: IContract) => contract.subContractor)
				.forEach(obj => {
					if (obj !== null)
						if (!subs.some(item => item?.id! === obj?.id!)) {
							subs.push(obj)
						}
				})

			setSubContractors(subs!?.sort((a, b) => a.name.localeCompare(b.name)))
		}
	}

	const onConfirmFilters = () => {
		onFilters(issueFiltersForm.getFieldsValue(), true)
	}

	const onResetFilters = () => {
		issueFiltersForm.resetFields()
		issueFiltersForm.submit()
		// dispatch(resetIssueFilters())
		setSubTypes(
			uniqueSet([...issueSubTypes!]!?.sort((a, b) => a.name.localeCompare(b.name))!, 'name')
		)
		// onClose()
	}

	const checkTypeName = (name: string) => {
		switch (name) {
			case 'Safety':
				return 'Безопасность'
			case 'Quality':
				return 'Качество'
			default:
				return name
		}
	}

	return (
		<Drawer
			open={open}
			onClose={onClose}
			placement="left"
			title="Фильтры"
			className="at-drawer-left"
			footer={
				<Row gutter={8} justify="end">
					<Col>
						<Button ghost danger onClick={onResetFilters}>
							Сбросить
						</Button>
					</Col>
					<Col>
						<Button type="primary" htmlType="submit" onClick={() => issueFiltersForm.submit()}>
							Применить
						</Button>
					</Col>
				</Row>
			}
		>
			<Form form={issueFiltersForm} name="issueFiltersForm" onFinish={onConfirmFilters}>
				<List
					loading={isLoading}
					dataSource={filterItems}
					renderItem={(item, index) => (
						<Row align="middle" gutter={8}>
							<Col style={{ flex: '0 0 100%' }}>
								<Text strong>{item.title}:</Text>
							</Col>
							<Col style={{ flex: '1 0 100%' }}>
								<Form.Item key={index} name={item.formItemName}>
									{!item.date ? (
										<Select
											loading={isLoading}
											mode={item.formItemName === 'status' ? 'multiple' : undefined}
											showSearch
											allowClear
											optionFilterProp="children"
											disabled={item.disabled || isLoading || item.list!?.length === 0}
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? '')
													.toString()
													.toLowerCase()
													.localeCompare((optionB?.label ?? '').toString().toLowerCase())
											}
											style={{ width: '100%' }}
											placeholder="Не выбран"
											onChange={value => onChangeFilter(item.formItemName, value)}
										>
											{item.list!?.map(option => (
												<Option
													key={option.id}
													value={
														item.formItemName === 'hasOrdinance' ||
														item.formItemName === 'hasChecklist' ||
														item.formItemName === 'isMobile'
															? option.id === '1'
																? true
																: false
															: option.id
													}
												>
													{item.formItemName === 'issueTypeId'
														? checkTypeName(option.name)
														: option.name}
												</Option>
											))}
										</Select>
									) : (
										<DatePicker
											locale={locale}
											// defaultValue={
											// 	item.formItemName === 'dueDate' ? dayjs(String(dueDateDefault), 'DD.MM.YYYY') : undefined
											// }
											style={{ width: '100%' }}
											format={'DD.MM.YYYY'}
											onChange={(date, dateSting) => onChangeFilter(item.formItemName, date!)}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>
					)}
				/>
			</Form>
		</Drawer>
	)
}
export default IssuesFilters
