import { Button, List, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { IIssuesReportDataItem } from 'interfaces/ILightReport'
import { FC } from 'react'

interface IProps {
	data: IIssuesReportDataItem[] | undefined
	onOpenIssue: (issueId: string) => void
}

const AllIssuesTable: FC<IProps> = ({ data, onOpenIssue }) => {
	const columns: ColumnsType<IIssuesReportDataItem> = [
		{
			dataIndex: 'mainTitle',
			title: 'Замечания по МКД',
			children: [
				{
					dataIndex: 'templateTitle',
					width: '18%'
				},
				{
					dataIndex: 'projectName',
					width: '18%'
				},
				{
					dataIndex: 'projectName',
					ellipsis: true,
					render: (_, record) => (
						<List
							size="small"
							itemLayout="vertical"
							dataSource={record.issues}
							renderItem={item => (
								<List.Item key={item.id} style={{ padding: 0 }}>
									<Button
										type="link"
										block
										onClick={() => onOpenIssue(item.id)}
										style={{ padding: 0 }}
									>
										<span
											style={{
												textAlign: 'left',
												textOverflow: 'ellipsis',
												whiteSpace: 'nowrap',
												width: '100%',
												overflow: 'hidden'
											}}
										>
											{item.issueName}
										</span>
									</Button>
								</List.Item>
							)}
						/>
					)
				}
			]
		}
	]

	return (
		<>
			<Table
				className="light-report-table-issues"
				dataSource={data}
				columns={columns}
				pagination={false}
				bordered
				size="small"
			/>
		</>
	)
}

export default AllIssuesTable
