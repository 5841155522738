import { create } from 'zustand'

interface IPlannedDatesState {
	pagination: {
		currentPage: number
		totalItems?: number
	}
	onPagination: (page: number) => void
}
const initState = {
	pagination: { currentPage: 1 }
}

export const usePlannedDatesState = create<IPlannedDatesState>((set, get) => ({
	...initState,
	onPagination: page => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
		set(() => ({
			pagination: {
				currentPage: page,
				totalPages: get().pagination.totalItems ?? 0
			}
		}))
	}
}))
