import {
	FileImageOutlined,
	FilePdfOutlined,
	FileZipOutlined,
	LoadingOutlined
} from '@ant-design/icons'
import { Button, Empty, Row, Spin, Table, message } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { IOrdinance } from 'interfaces/IOrdinance'
import { IFileAttachment } from 'interfaces/IPluginFiles'
import { FC, useEffect, useState } from 'react'
import { downloadFile } from 'services/AdminService'
import { getOrdinanceAttachment } from 'services/OrdinanceService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'

interface IOrdinanceAttachmentsProps {
	ordinance: IOrdinance
}

export const OrdinanceAttachments: FC<IOrdinanceAttachmentsProps> = ({ ordinance }) => {
	const checkPermissions = useCheckPermissions()
	const { user: currentUser } = useAppSelector(state => state.environment)
	const [isLoading, setIsLoading] = useState(false)
	const [fileList, setFileList] = useState<IFileAttachment[]>([])
	const { permissions } = useAppSelector(state => state.environment)
	const canApprove = checkPermissions([PERMISSIONS.OrdinanceApproval])

	useEffect(() => {
		getFileList()
	}, [])

	const getFileList = async () => {
		setIsLoading(true)
		getOrdinanceAttachment(ordinance.id)
			.then(data =>
				setFileList(
					// data
					data.filter(
						f =>
							f.statusId === 4 ||
							(ordinance.createdBy.id === currentUser?.id && f.statusId === 5) ||
							currentUser?.isAdmin ||
							canApprove
					)
				)
			)
			.finally(() => setIsLoading(false))
	}

	const fileListColumns: ColumnsType<IFileAttachment> = [
		{
			title: 'Тип',
			dataIndex: 'extension',
			width: '50px',
			align: 'center',
			render: (_, record) => {
				if (record.extension.replace('.', '') === 'pdf') {
					return <FilePdfOutlined style={{ color: 'red' }} />
				} else if (
					record.extension.replace('.', '') === 'png' ||
					record.extension.replace('.', '') === 'jpeg' ||
					record.extension.replace('.', '') === 'jpg'
				) {
					return <FileImageOutlined style={{ color: '#feb300' }} />
				} else {
					return <FileZipOutlined style={{ color: 'blue' }} />
				}
			}
		},
		{
			title: 'Имя файла',
			render: (_, record) => (
				<Button
					size="middle"
					type="text"
					block
					style={{ textAlign: 'left' }}
					onClick={() =>
						downloadFile(record.id)
							.then(response => {
								const href = URL.createObjectURL(response.data)
								const link = document.createElement('a')
								link.href = href
								link.setAttribute('download', record.name + record.extension!)
								document.body.appendChild(link)
								link.click()
								document.body.removeChild(link)
								URL.revokeObjectURL(href)
							})
							.catch(() => message.error('Во время загрузки файла произошла ошибка'))
					}
				>
					{record.name}
				</Button>
			)
		},
		{
			title: 'Дата загрузки',
			width: '15%',
			render: (_, record) => dayjs(record.uploadDate).format('HH:mm DD.MM.YYYY')
		}
	]

	return (
		<>
			{!isLoading ? (
				fileList?.length !== 0 ? (
					<Table
						size="small"
						pagination={false}
						dataSource={fileList}
						columns={fileListColumns}
						rowKey={record => record.id}
					/>
				) : (
					<Empty />
				)
			) : (
				<Row justify="center">
					<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
				</Row>
			)}
		</>
	)
}
