import {
	CheckSquareOutlined,
	CloseSquareOutlined,
	FileExclamationOutlined,
	LoadingOutlined,
	ReloadOutlined
} from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import History from 'UI/history'
import {
	Button,
	Col,
	Drawer,
	Form,
	Modal,
	Popconfirm,
	Row,
	Spin,
	Tabs,
	Tooltip,
	message
} from 'antd'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { IContract } from 'interfaces/IContract'
import { IOrdinance, IOrdinanceIssue } from 'interfaces/IOrdinance'
import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getContracts } from 'services/ContractsService'
import { deleteIssueAttachment } from 'services/IssuesService'
import {
	deleteOrdinancePrintFile,
	getOrdinance,
	setOrdinanceIsApprove,
	updateOrdinance
} from 'services/OrdinanceService'
import { accessVerification } from 'shared/accessVerification'
import { PERMISSIONS } from 'shared/constants'
import { checkPermissionByProject } from 'shared/helpers'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setEnvironmentProject } from 'store/environmentSlice'
import { setDtoUpd } from 'store/ordinanceSlice'
import { useFineState } from 'widgets/fine-card/model/fine-card-state'
import { IOrdinanceFineFilters } from 'widgets/fine/model/fine-interface'
import { useOrdinanceFineState } from 'widgets/fine/model/fine-state'
import EliminationActModal from '../eliminationActModal'
import { OrdinanceAttachments, OrdinanceDetail, OrdinanceIssues } from './ordinanceTabs'
import { Fines } from './ordinanceTabs/fines'

interface IOrdinanceDrawerProps {
	ordinanceId?: string
	open: boolean
	onClose: () => void
}

const OrdinanceDrawer: FC<IOrdinanceDrawerProps> = ({ ordinanceId, open, onClose }) => {
	const checkPermissions = useCheckPermissions()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { id: currentId } = useParams()
	const pathname = useLocation().pathname
	const dispatch = useAppDispatch()
	const { setToCreate } = useFineState()
	const [showDrawer, setShowDrawer] = useState(false)
	const { user, permissions } = useAppSelector(state => state.environment)
	const ordinanceUpd = useAppSelector(state => state.ordinance.upd)
	const [isLoading, setIsLoading] = useState(true)
	const [ordinance, setOrdinance] = useState<IOrdinance>()
	const [ordinanceIssues, setOrdinanceIssues] = useState<IOrdinanceIssue[]>([])
	const [ordinanceForm] = Form.useForm()
	const [activeKey, setActiveKey] = useState('details')
	const [showApprove, setShowApprove] = useState(false)
	const [showUnApprove, setShowUnApprove] = useState(false)
	const [confirmLoading, setConfirmLoading] = useState(false)
	const [selectedIssueRows, setSelectedIssueRows] = useState<string[]>([])
	const [eliminationActPreview, setEliminationActPreview] = useState(false)
	const [contractorId, setContractorId] = useState<string | null>(null)
	const [canEdit, setCanEdit] = useState(false)
	const [canApprove, setCanApprove] = useState(false)
	const {
		setSelectedOrdinanceFine,
		setShowOrdinanceFineDetail,
		selectedOrdinanceFine,
		setShowDrawer: setShowDrawerFine
	} = useOrdinanceFineState()

	const canDeleteFiles: boolean = checkPermissions([PERMISSIONS.AttachmentsOrdinanceDeleted])
	const allCompanies = checkPermissions([PERMISSIONS.AllCompanies])
	const ordinanceView = checkPermissions([PERMISSIONS.OrdinanceView])

	const onTabChange = (key: string) => {
		setActiveKey(key)
	}

	const [contracts, setContracts] = useState<IContract[]>([])
	const { project } = useAppSelector(state => state.environment)

	useEffect(() => {
		getContracts({
			contractorId: contractorId!,
			projectId: project?.id!
		}).then(data => setContracts(data))
	}, [])

	useEffect(() => {
		const init = async (id: string) => {
			await getCurrentOrdinance(id)
		}
		if (pathname.includes('ordinance') && currentId) {
			init(currentId)
		} else {
			ordinanceId && init(ordinanceId)
		}
	}, [ordinanceId, currentId])

	const getCurrentOrdinance = async (id: string) => {
		setIsLoading(true)
		await getOrdinance(id ?? currentId!)
			.then(data => {
				if (data.ordinance && data.ordinance.id) {
					checkPermissionByProject(
						Object.keys(PERMISSIONS).findIndex(p => p === 'OrdinanceView') + 1,
						data.ordinance.project.id
					).then(validate => {
						if (!validate) {
							onDrawerClose()
							Modal.warning({
								title: 'Внимание',
								content:
									'Предписание не доступно по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
								onOk: () => navigate('/')
							})
						} else {
							const userProject = user?.projects?.find(p => p.id === data.ordinance.project.id)
							if (project.id !== data.ordinance.project.id && userProject) {
								dispatch(
									setEnvironmentProject({
										option: 'project',
										data: userProject!
									})
								)
							}
							setOrdinance(data.ordinance)
							setOrdinanceIssues(data.ordinance.issues!)
							setShowDrawer(true)
						}
					})
				}
			})
			.catch((err: AxiosError) => {
				if (!allCompanies && contractorId !== user?.contractorId) {
					onDrawerClose()
					Modal.warning({
						title: 'Внимание',
						content:
							'Предписание не доступно по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
						onOk: () => navigate('/')
					})
				} else if (ordinanceView === false) {
					Modal.warning({
						title: 'Внимание',
						content:
							'Предписание не доступно по ссылке, поскольку у Вас не хватает прав. Обратитесь к Администратору',
						onOk: () => navigate('/')
					})
				} else {
					const data = err.response?.data! as IErrorDetail
					message.error(data.detail)
					onClose()
				}
			})
			.finally(() =>
				setTimeout(() => {
					setIsLoading(false)
				}, 200)
			)
	}

	useEffect(() => {
		if (ordinance!) {
			setCanEdit(
				checkPermissions([PERMISSIONS.OrdinanceEdit]) &&
				!(ordinance!?.isApproved! || ordinance!?.isDeleted!)
			)
			setCanApprove(
				checkPermissions([PERMISSIONS.OrdinanceApproval]) &&
				!(ordinance!?.isApproved! || ordinance!?.isDeleted!)
			)
			ordinanceForm.setFieldsValue(ordinance)
			dispatch(setDtoUpd(ordinance))
			setContractorId(
				(ordinance.issues && ordinance!?.issues[0]!?.contractor!?.id) ||
				ordinance!?.contractor!?.id!
			)
		}
	}, [ordinance])

	const onApprove = async (id: string, state: boolean) => {
		setConfirmLoading(true)
		let errorMessage: string | null = null
		await setOrdinanceIsApprove(id, state)
			.then(() => {
				getCurrentOrdinance(id).then(() => {
					message.success(
						`Предписание ${state
							? 'согласовано. Файл предписания появится через некоторое время.'
							: 'не согласовано.'
						}`
					)
				})
			})
			.catch(error => (errorMessage = error!?.response!?.data!?.detail!))
			.finally(() =>
				setTimeout(() => {
					setShowApprove(false)
					setShowUnApprove(false)
					setConfirmLoading(false)
					errorMessage !== null && message.error(errorMessage)
				}, 200)
			)
	}

	const onCheckedIssue = (issueId: string) => {
		if (!selectedIssueRows.includes(issueId)) {
			setSelectedIssueRows(prev => [...prev, issueId])
		} else {
			setSelectedIssueRows(selectedIssueRows.filter(row => row !== issueId))
		}
	}

	const eliminationActHandler = () => {
		setEliminationActPreview(true)
	}

	const onEliminationActClose = (mustUpdate?: boolean) => {
		setSelectedIssueRows([])
		setEliminationActPreview(false)
		if (mustUpdate) getCurrentOrdinance(ordinance?.id!)
	}

	const onOrdinanceUpdSuccess = async () => {
		await updateOrdinance(ordinance?.id! ?? currentId!, ordinanceUpd)
			.then(() => {
				getCurrentOrdinance(ordinance?.id!)
				message.success('Предписание обновлено')
				queryClient.invalidateQueries({ queryKey: ['ordinancesList'] })
			})
			.catch((error: AxiosError) => {
				message.error('Произошла ошибка во время обновления')
				console.log('error', error)
			})
	}
	const onOrdinanceUpdError = () => {
		message.error('Заполните все обязательные поля')
	}

	const onDrawerClose = () => {
		setContractorId(null)
		setShowDrawer(false)
		setTimeout(() => onClose(), 200)
		pathname.includes('ordinance') && currentId && navigate('/control/ordinance')
	}

	const onDeleteAttachment = async (
		type: 'ordinance' | 'issue',
		attachmentId: string,
		issueId?: string
	) => {
		if (type === 'ordinance') {
			await deleteOrdinancePrintFile(ordinance?.id!)
				.then(async () => await getCurrentOrdinance(ordinance?.id!))
				.catch((error: AxiosError<IErrorDetail>) => message.error(error.response?.data.detail))
		} else {
			if (issueId)
				await deleteIssueAttachment(issueId, attachmentId)
					.then(async () => await getCurrentOrdinance(ordinance?.id!))
					.catch((error: AxiosError<IErrorDetail>) => message.error(error.response?.data.detail))
		}
	}

	const openOrdinanceFine = (ordinanceFine: IOrdinanceFineFilters) => {
		setSelectedOrdinanceFine(ordinanceFine)
		setShowOrdinanceFineDetail(true)
	}

	return (
		<>
			<EliminationActModal
				open={eliminationActPreview}
				ordinance={ordinance!}
				selectedIssueRows={selectedIssueRows}
				ordinanceIssues={ordinanceIssues}
				onClose={(mustUpdate?: boolean) => onEliminationActClose(mustUpdate)}
			/>
			<Drawer
				destroyOnClose={true}
				open={showDrawer}
				onClose={onDrawerClose}
				width={window.outerWidth < 1300 ? '75vw' : '50vw'}
				title={`Предписание №${ordinance!?.name ? ordinance!?.name : ordinance!?.number} от ${dayjs(
					ordinance!?.createdAt
				).format('DD.MM.YYYY г.')}`}
				className={`at-drawer-status at-drawer-status--${ordinance!?.status!?.identityName!?.toLowerCase()}`}
				styles={{
					body: { backgroundColor: '#f2f2f2', paddingTop: 0 },
					footer: { width: '100%' }
				}}
				extra={
					<Row gutter={16} align="middle">
						{canEdit && (
							<Col>
								<Button
									htmlType="submit"
									onClick={() => ordinanceForm.submit()}
									ghost
									style={{ borderColor: '#fff' }}
								>
									Сохранить
								</Button>
							</Col>
						)}
						<Col style={{ display: 'flex', alignItems: 'center' }}>
							<span>{ordinance!?.status!?.name!}</span>
						</Col>
					</Row>
				}
				footer={
					<Row gutter={8}>
						{activeKey === 'details' && !ordinance?.isAutoCreated && (
							<>
								<Col>
									<Tooltip title={!canApprove && 'У Вас нет прав для согласования'}>
										<Popconfirm
											title="Согласовать?"
											open={showApprove}
											onConfirm={() => onApprove(ordinance!?.id, true)}
											okText="Подтвердить"
											okButtonProps={{
												icon: confirmLoading && <LoadingOutlined rev={undefined} />,
												loading: confirmLoading
											}}
											onCancel={() => setShowApprove(false)}
										>
											<Button
												icon={<CheckSquareOutlined rev={undefined} />}
												onClick={() => setShowApprove(true)}
												disabled={
													!canApprove ||
													ordinance?.isApproved === true ||
													ordinance?.isDeleted === true ||
													ordinance?.name === null
												}
											>
												Согласовать
											</Button>
										</Popconfirm>
									</Tooltip>
								</Col>
								<Col>
									<Tooltip title={!canApprove && 'У Вас нет прав для не согласования'}>
										<Popconfirm
											title="Не согласовать?"
											open={showUnApprove}
											onConfirm={() => onApprove(ordinance!?.id, false)}
											okText="Подтвердить"
											okButtonProps={{
												icon: confirmLoading && <LoadingOutlined rev={undefined} />,
												loading: confirmLoading
											}}
											onCancel={() => setShowUnApprove(false)}
										>
											<Button
												icon={<CloseSquareOutlined rev={undefined} />}
												onClick={() => setShowUnApprove(true)}
												disabled={
													!canApprove ||
													ordinance?.isApproved === true ||
													ordinance?.isDeleted === true ||
													ordinance?.name === null
												}
											>
												Не согласовать
											</Button>
										</Popconfirm>
									</Tooltip>
								</Col>
							</>
						)}
						{activeKey === 'issues' && (
							<Col>
								<Button
									size="middle"
									disabled={selectedIssueRows.length === 0}
									onClick={eliminationActHandler}
									icon={<FileExclamationOutlined rev={undefined} />}
								>
									Сформировать акт об устранении
								</Button>
							</Col>
						)}
						<Col>
							{activeKey === 'fines' &&
								accessVerification(user, permissions, [PERMISSIONS.OrdinanceFineCreate]) && (
									<Button
										size="middle"
										onClick={() => {
											ordinance?.contract.isNotOrdinanceFine
												? Modal.info({
													content: 'На указанный договор не предусмотрено создание штрафов',
													width: window.innerWidth - (window.innerWidth * 70) / 90
												})
												: setToCreate(ordinance, project, user!)
											setShowDrawerFine(true)
										}}
									>
										Создать штраф
									</Button>
								)}
						</Col>
					</Row>
				}
			>
				{!isLoading ? (
					<Form
						name="ordinanceForm"
						form={ordinanceForm}
						onFinish={() => onOrdinanceUpdSuccess()}
						onFinishFailed={() => onOrdinanceUpdError()}
					>
						<Tabs
							style={{ width: '100%', minHeight: '100%' }}
							activeKey={activeKey}
							size="large"
							tabBarGutter={16}
							onChange={onTabChange}
							tabBarExtraContent={
									<Tooltip title="Обновить">
										<Button size="middle" onClick={() => getCurrentOrdinance(ordinance?.id!)} icon={<ReloadOutlined />} />
									</Tooltip>
							}
							items={[
								{
									label: 'Детали',
									key: 'details',
									children: (
										<OrdinanceDetail
											ordinance={ordinance!}
											issues={ordinanceIssues!}
											canEdit={canEdit}
											canDeleteFiles={canDeleteFiles}
											onDeleteAttachment={onDeleteAttachment}
											contractorId={contractorId}
											onUpdate={() => getCurrentOrdinance(ordinance?.id!)}
										/>
									)
								},
								{
									label: 'Замечания',
									key: 'issues',
									children: (
										<OrdinanceIssues
											ordinance={ordinance!}
											canDeleteFiles={canDeleteFiles}
											// onDeleteAttachment={onDeleteAttachment}
											onDeleteAct={() => getCurrentOrdinance(ordinance?.id!)}
											issues={ordinanceIssues!}
											onChange={() => {
												setSelectedIssueRows([])
												getCurrentOrdinance(ordinance?.id!)
											}}
											issuesForAct={selectedIssueRows}
											onIssuesSelect={(issue: string) => onCheckedIssue(issue)}
											onUpdate={() => getCurrentOrdinance(ordinance?.id!)}
										/>
									)
								},
								{
									label: 'Файлы',
									key: 'files',
									children: <OrdinanceAttachments ordinance={ordinance!} />
								},
								{
									label: 'История',
									key: 'history',
									children: <History id={ordinance!?.id} target={'ordinance'} />
								},
								{
									label: 'Штрафы',
									key: 'fines',
									children: <Fines ordinance={ordinance!} />,
									disabled: !checkPermissions([PERMISSIONS.OrdinanceFine])
								}
							]}
							/>
					</Form>
				) : (
					<div
						style={{
							display: 'flex',
							width: '100%',
							height: '100%',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Spin
							indicator={<LoadingOutlined style={{ fontSize: 48 }} spin rev={undefined} />}
							style={{ color: 'gray' }}
						/>
					</div>
				)}
			</Drawer>
		</>
	)
}
export default OrdinanceDrawer
