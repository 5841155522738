import { ATBad, ATGood } from 'UI/icons'
import { Descriptions, DescriptionsProps, Space } from 'antd'
import dayjs from 'dayjs'
import { IValidateOrdinance } from 'interfaces/IOrdinance'
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { validateOrdinance } from 'services/OrdinanceService'

interface IValidateOrdinanceProps {}

export const ValidateOrdinance: FC<IValidateOrdinanceProps> = () => {
	const location = useLocation()
	const ordinanceId = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
	const [validate, setValidate] = useState<IValidateOrdinance>()

	useEffect(() => {
		getInfo()
	}, [])

	const getInfo = async () => {
		await validateOrdinance(ordinanceId)
			.then(data => setValidate(data))
			.catch(() => {})
	}

	const items: DescriptionsProps['items'] = [
		{
			key: 1,
			label: 'Регистрационный номер',
			span: 2,
			children: <b>{validate?.name}</b>
		},
		{
			key: 2,
			label: 'Дата предписания',
			span: 2,
			children: dayjs(validate?.createdAt).isValid()
				? dayjs(validate?.createdAt).format('DD.MM.YYYY HH:mm')
				: '-'
		},
		{
			key: 3,
			label: 'Проект',
			span: 2,
			children: validate?.project?.name
		},
		{
			key: 4,
			label: 'Контрагент',
			span: 2,
			children: validate?.contractor?.name
		}
	]

	return (
		<Space direction="vertical" align="center" style={{ width: '100%' }}>
			{validate && validate?.name ? (
				<>
					<Descriptions
						labelStyle={{
							fontWeight: 'bold',
							width: '20%',
							alignItems: 'center',
							padding: '4px 8px'
						}}
						contentStyle={{
							width: '80%',
							padding: '4px 8px',
							textAlign: 'end'
						}}
						bordered
						items={items}
						layout="horizontal"
						column={2}
					/>
					{validate.status ? (
						<ATGood style={{ fontSize: 128 }} />
					) : (
						<ATBad style={{ fontSize: 128 }} />
					)}
					<strong style={{ textTransform: 'uppercase' }}>
						Замечания {!validate?.status && 'не'} устранены
					</strong>
				</>
			) : (
				<center>
					По документу нет информации. Обратитесь в тех поддержку для решения проблемы.
				</center>
			)}
			<a href="/">На страницу входа</a>
		</Space>
	)
}
