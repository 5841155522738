import {
	BellOutlined,
	FileDoneOutlined,
	MessageOutlined,
	SisternodeOutlined,
	UsergroupAddOutlined
} from '@ant-design/icons'
import Icon from '@ant-design/icons/lib/components/Icon'
import LoaderSpinner from 'UI/loaderSpinner'
import {
	Button,
	Card,
	Divider,
	Drawer,
	Flex,
	Form,
	Pagination,
	Select,
	Space,
	Tag,
	Tooltip,
	Typography
} from 'antd'
import dayjs from 'dayjs'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { mainSelectProps } from 'shared/helpers'
import {
	useNotificationTypesListQuery,
	useNotificationsQuery,
	useWbsListQuery
} from '../model/wbs-notification-query'
import { useNotificationsState } from '../model/wbs-notification-state'
import { INotificationsFilters } from '../model/wbs-notifications-interface'

export const WbsNotificationDrawer: FC<{}> = () => {
	const [isOpened, setIsOpened] = useState(false)

	const { onPagination, currentPage, totalItems, limit, setNotificationsFilters } =
		useNotificationsState()
	const { data, isFetching } = useNotificationsQuery()
	const { data: notificationTypesList } = useNotificationTypesListQuery()
	const { data: wbsList } = useWbsListQuery()

	const { Text } = Typography
	const navigate = useNavigate()

	const [wbsNotificationsFiltersForm] = Form.useForm<INotificationsFilters>()

	const onReset = () => {
		wbsNotificationsFiltersForm.resetFields()
		wbsNotificationsFiltersForm.submit()
	}

	const onSetFilters = () => {
		setNotificationsFilters(wbsNotificationsFiltersForm.getFieldsValue())
	}

	const cardIcon = (typeId: string) => {
		switch (typeId) {
			case 'Сhat Was Opened For Container':
				return MessageOutlined
			case 'Change Status Container':
				return SisternodeOutlined
			case 'Сhat Was Closed For Container':
				return FileDoneOutlined
			case 'Assigned Container Editors':
				return UsergroupAddOutlined
			default:
				return BellOutlined
		}
	}

	const cardIconBackground = (typeId: string) => {
		switch (typeId) {
			case 'Сhat Was Opened For Container':
				return '#ffe7ba'
			case 'Change Status Container':
				return '#bae0ff'
			case 'Сhat Was Closed For Container':
				return '#efdbff'
			case 'Assigned Container Editors':
				return '#b8e1d2'
			default:
				return '#f0f0f0'
		}
	}

	return (
		<>
			<Tooltip title="Уведомления">
				<Button icon={<BellOutlined />} onClick={() => setIsOpened(true)} />
			</Tooltip>

			<Drawer
				title="Уведомления"
				className="at-drawer-notifications"
				width={'35vw'}
				open={isOpened}
				onClose={() => setIsOpened(false)}
				footer={
					<Flex justify="flex-end" align="flex-end">
						<Pagination
							size="small"
							onChange={onPagination}
							total={totalItems}
							current={currentPage}
							pageSize={limit}
							hideOnSinglePage
						/>
					</Flex>
				}
			>
				<Form
					form={wbsNotificationsFiltersForm}
					name="notificationsFilters"
					onFinish={onSetFilters}
				>
					<Flex gap="small">
						<Form.Item name="containerId" style={{ marginBottom: 0, width: '200px' }}>
							<Select
								{...mainSelectProps}
								allowClear
								placeholder="ВОР"
								options={
									wbsList?.map(c => ({
										key: c.id,
										label: c.name,
										value: c.id
									})) ?? []
								}
							/>
						</Form.Item>
						<Form.Item name="notificationTypeId" style={{ marginBottom: 0, width: '200px' }}>
							<Select
								{...mainSelectProps}
								placeholder="Тип уведомления"
								options={
									notificationTypesList
										?.filter(l => l.objectNameRu === 'ВОР')
										?.map(c => ({
											key: c.id,
											label: c.nameRu ? c.nameRu : c.name,
											value: c.id
										})) ?? []
								}
							/>
						</Form.Item>
						<Button htmlType="button" onClick={onReset}>
							Сбросить
						</Button>
						<Button type="primary" htmlType="submit">
							Применить
						</Button>
					</Flex>
				</Form>

				<Divider />
				<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
					{!isFetching ? (
						data?.length ? (
							data.map((item, index) => (
								<Card
									size="small"
									key={item.id}
									title={
										<Space direction="vertical" style={{ padding: '.5rem', rowGap: 0 }}>
											<Button
												type="link"
												style={{ padding: 0, textAlign: 'left', whiteSpace: 'break-spaces' }}
												onClick={() => {
													navigate(`/unmodel/${item.container?.id}`)
													window.location.reload()
												}}
											>
												{item.container?.name ?? 'Не указан'}
											</Button>
											<Tag color={`#${item.container.status.color}`}>
												{item.container.status?.name}
											</Tag>
										</Space>
									}
									extra={
										<Text type="secondary">
											{dayjs(item.date).isValid()
												? dayjs(item.date).format('DD.MM.YYYY HH:mm')
												: '-'}
										</Text>
									}
								>
									<Flex gap="middle" align="center">
										<Flex
											justify="center"
											align="center"
											style={{
												padding: '12px',
												background: cardIconBackground(item.type.name),
												borderRadius: '8px'
											}}
										>
											<Tooltip title={item.type.nameRu}>
												<Icon
													component={
														cardIcon(item.type.name) as React.ForwardRefExoticComponent<any>
													}
													style={{
														width: '24px',
														height: '24px',
														fontSize: '24px',
														color: '#787878'
													}}
												/>
											</Tooltip>
										</Flex>
										<Space direction="vertical">
											<div>{item.text?.replace(/Для работы с ВОР.*/gm, '')}</div>

											<Text type="secondary">
												<strong>Проект: </strong>
												{item.project?.name ?? 'Не указан'}
											</Text>
										</Space>
									</Flex>
								</Card>
							))
						) : (
							'Нет уведомлений'
						)
					) : (
						<LoaderSpinner />
					)}
				</Space>
			</Drawer>
		</>
	)
}

export default WbsNotificationDrawer
