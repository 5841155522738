import { useQuery } from "@tanstack/react-query"
import { useShallow } from "zustand/react/shallow"
import { EstimateCreationFromVorApi } from "../api/estimate-related-documents-api"
import { useEstimateRelatedDocumentsState } from "./estimate-related-documents-state"

export const useEstimateRelatedDocuments = (id: string, type: 'wbs' | 'local') => {
	const { showModal } = useEstimateRelatedDocumentsState(
		useShallow(state => ({
			showModal: state.showModal
		}))
	)
	return useQuery({
		queryKey: ['estimate-relatedDocuments', showModal, id!],
		queryFn: async () => {
			const relatedDocuments = await EstimateCreationFromVorApi.get(id!, type)
			.then(res => res)
			useEstimateRelatedDocumentsState.setState({idExpand: [relatedDocuments?.id!]})
			return relatedDocuments 
		},
		refetchOnWindowFocus: false
	})
}