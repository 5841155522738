import { FileTextOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { FC } from 'react'
import { IFine } from '../model/fine-interface'
import { useOrdinanceFineState } from '../model/fine-state'

interface IProp {
	record: IFine
}

export const ButtonForOrdinance: FC<IProp> = ({ record }) => {
	const { openOrdinance } = useOrdinanceFineState()

	return (
		<Tooltip
			title={`Предписание №${
				record.ordinance!?.name
					? record.ordinance!?.name
					: record.ordinance!?.number
					? record.ordinance!?.number
					: ''
			}`}
		>
			<Button
				icon={<FileTextOutlined />}
				type="text"
				block
				onClick={() => openOrdinance(true, record.ordinance.id)}
			/>
		</Tooltip>
	)
}
