import History from 'UI/history'
import { Drawer, Empty } from 'antd'
import { FC } from 'react'
import { useAdjustmentState } from '../model/adjustment-state'

interface IProps {
	type: 'wbs' | 'local'
}

export const AdjustmentHistory: FC<IProps> = ({ type = 'wbs' }) => {
	const state = useAdjustmentState
	const { container, showHistory } = state()

	return (
		<Drawer
			open={showHistory}
			className="asm-card-drawer"
			onClose={() => state.setState({ showHistory: false })}
			destroyOnClose={true}
			width={window.innerWidth <= 1600 ? '90vw' : '70vw'}
			styles={{
				body: { background: '#fafafa' },
				footer: { padding: '1rem 2rem' }
			}}
			title={`История ${type === 'local' ? 'Локальной сметы' : 'ВОР'} - ${container?.name}`}
		>
			{container ? <History id={container.id} target={type} /> : <Empty />}
		</Drawer>
	)
}
