import { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin, Modal } from 'antd'
import LoaderService from './services/LoaderService'
import AuthPage from './pages/authPage'
import Dashboard from './UI/dashboard/Dashboard'
import { getAppUserPermissions, getUserInfo, Logout } from './services/AuthService'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { setUser } from 'store/userSlice'
import { setEnvironment, setEnvironmentPermissions } from 'store/environmentSlice'
import { getIssueStatusList } from 'services/IssuesService'
import _ from 'lodash'
import { useShared } from 'hooks/useShared'
import { IProject } from 'interfaces/IEnvironment'
import { useQuery } from '@tanstack/react-query'
import CookiesModal from 'widgets/cookies/CookiesModal'
import { maintanceService } from 'services/MaintanceService'
import { MaintanceOnProgress } from 'components/MaintanceOnProgress'
import { useMaintance } from 'hooks/useMaintance'
import { MaintanceNotification } from 'components/MaintanceNotification'
import useMaintanceState from 'store/maintanceState'

const App = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [isAuth, setIsAuth] = useState<boolean>(false)
	const dispatch = useAppDispatch()
	const project = useAppSelector(state => state.environment.project)
	const currentUser = useAppSelector(state => state.environment.user)
	const { isLoading: sharedIsLoading } = useShared(project!)
	const {maintance, isMaintance, setMaintanceNotification} = useMaintanceState()

	useEffect(() => {
		async function init() {
			const res = await LoaderService()
			if (res) {
				setIsAuth(true)
				setTimeout(() => setIsLoading(false), 200)
			} else {
				setIsAuth(false)
				setTimeout(() => setIsLoading(false), 200)
			}
		}
		init()
		if (currentUser !== null && project !== null) {
			getAppUserPermissions(project!?.id!).then(data => {
				dispatch(setEnvironmentPermissions(data))
			})
		}
	}, [])

	useEffect(() => {
		setIsLoading(sharedIsLoading)
	}, [sharedIsLoading])

	useEffect(() => {
		const user = localStorage.user && localStorage.user !== 'undefined' ? JSON.parse(localStorage.user) : null
		async function getUser() {
			await getUserInfo()
				.then(data => {
					localStorage.setItem('user', JSON.stringify(data))
					dispatch(setUser(data))
				})
				.catch(() => Logout())
				.finally(() => (window.location.href = '/'))
		}
		if (isAuth && user === null) {
			getUser()
		}
		async function getStatuses() {
			const statuses = await getIssueStatusList()
			if (statuses) dispatch(setEnvironment({ state: 'issueStatuses', data: statuses }))
		}
		isAuth && getStatuses()
	}, [isAuth])

	const {data: maintanceData} = useQuery(['maintanceNotification'], maintanceService.getMaintanceNotification, 
	{
		enabled: isAuth,
		refetchInterval: 30*60*1000,
		onSuccess: (data)=>{
			setMaintanceNotification(data)
			},
			onError: (error) => {
			console.error('Failed to fetch maintenance notification', error);
		}
	})
	useMaintance(maintance.startTime, maintance.endTime)

	useEffect(()=> 
		maintanceData && setMaintanceNotification(maintanceData)
		,[maintanceData])

	const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin rev={undefined} />

	useQuery(['checkProject'], async () => {
		const lsProject = localStorage.getItem('project')
		const parsedProject: IProject = lsProject ? JSON.parse(lsProject!) : null

		if (parsedProject !== null && !_.isEqual(parsedProject, project)) {
			Modal.info({
				title: 'Внимание!',
				content: 'Изменился проект! Приложение будет перезапущено!',
				centered: true,
				okText: 'Перезагрузка',
				onOk: () => {
					window.location.reload()
				}
			})
		}
		return false
	})
 
	return (
		<> 
			{
			isMaintance ? <MaintanceOnProgress/> : (
			isLoading ? (
				<div className="wrapper at-jcc at-aic">
					<div className="at-bg">
						<Spin indicator={antIcon}></Spin>
					</div>
				</div>
			) : <>
				<CookiesModal />
				{maintance.date && <MaintanceNotification/>}
				{!isAuth
					? <AuthPage />
					: <Dashboard />
				}
			</>)}
		</>
	)
}

export default App
