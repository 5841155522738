import {
	Card,
	Col,
	DatePicker,
	Descriptions,
	Divider,
	Form,
	FormInstance,
	Input,
	Modal,
	Row,
	Select,
	Switch
} from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { ITemplate } from 'interfaces/ITemplate'
import { FC, useEffect, useState } from 'react'
import { getTemplatesOrders } from 'services/TemplatesService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { resetDto, setDtoValue } from 'store/templatesSlice'
import Sections from './sections/Sections'

const { Option } = Select

interface IProps {
	form: FormInstance
	editedTemplate: ITemplate | null
	onRefreshTemplatesTable: () => void
	creatorStatus: null | 0 | 1 | 2
}

const TemplateForm: FC<IProps> = ({
	form,
	editedTemplate,
	onRefreshTemplatesTable,
	creatorStatus
}) => {
	const checkPermissions = useCheckPermissions()
	const dispatch = useAppDispatch()
	const templateDto = useAppSelector(state => state.templates.dto)
	const { templateTypes } = useAppSelector(state => state.templates.data)
	const [currentTemplateType, setCurrentTemplateType] = useState<string | null>(null)
	const [description, setDescription] = useState<string>(editedTemplate?.description || '')
	const [title, setTitle] = useState<string>(editedTemplate?.title || '')
	const [location, setLocation] = useState<string>(editedTemplate?.location || '')
	const workPackages = useAppSelector(state => state.environment.workPackages)
	const [allDisabled, setAllDisabled] = useState<boolean>(
		creatorStatus === null ||
			(creatorStatus !== 2 &&
				editedTemplate !== null &&
				editedTemplate?.templateType !== creatorStatus)
	)
	const { permissions } = useAppSelector(state => state.environment)
	const [checkedOrders, setCheckedOrders] = useState<number[]>([])

	useEffect(() => {
		if (editedTemplate) {
			setCurrentTemplateType(templateTypes[editedTemplate.templateType])
		}
		const init = async () => {
			await getTemplatesOrders().then(data => setCheckedOrders(data.checkedOrders))
		}
		init()
	}, [])

	const onTemplateTypeChange = (value: string) => {
		if (currentTemplateType !== null) {
			return Modal.confirm({
				title: 'Внимание!',
				content: 'Все изменения будут удалены',
				onOk: () => {
					form.setFieldValue('sections', undefined)
					form.setFieldValue('isCommon', false)
					setCurrentTemplateType(value)
					dispatch(resetDto())
				},
				onCancel: () => {
					form.setFieldValue('templateType', currentTemplateType)
				}
			})
		} else {
			setCurrentTemplateType(value)
		}
	}

	return (
		<>
			<Card className="at-workcard" style={{ width: '100%' }}>
				<Descriptions
					title={
						<Row justify="space-between" align="middle">
							<Col style={{ fontSize: '16px' }} flex={1}>
								Основные параметры:
							</Col>
							{currentTemplateType === 'УКС НДК' && (
								<Col span={7}>
									<Row align="middle">
										<Col>Шифр: </Col>
										<Col flex={1}>
											<Form.Item name="cipher">
												<Input
													placeholder="Введите шифр"
													disabled={!checkPermissions([PERMISSIONS.ChecklistsTemplatesEditUksNdc])}
												/>
											</Form.Item>
										</Col>
									</Row>
								</Col>
							)}

							{currentTemplateType === 'УК КС' && templateDto!?.isCommon! && (
								<Col span={7}>
									<Row align="middle">
										<Col>Порядковый номер: </Col>
										<Col flex={1}>
											<Form.Item
												name="order"
												rules={[{ required: true, message: 'Укажите порядковый номер' }]}
											>
												<Select
													allowClear
													disabled={!checkPermissions([PERMISSIONS.ChecklistsTemplatesUkKc])}
													showSearch
													style={{ width: '100%' }}
													options={Array.from(Array(100).keys()).map(item => ({
														value: item + 1,
														label: item + 1,
														disabled:
															editedTemplate?.order !== item + 1 &&
															checkedOrders.some(x => x === item + 1)
													}))}
												/>
											</Form.Item>
										</Col>
									</Row>
								</Col>
							)}
						</Row>
					}
					column={12}
					bordered
					labelStyle={{
						fontWeight: 'bold',
						width: '30%',
						alignItems: 'center',
						padding: '2px 8px'
					}}
					contentStyle={{
						width: '70%',
						padding: '0px 8px'
					}}
					className="at-descriptions"
				>
					<Descriptions.Item
						label={
							<label>
								Название
								<label style={{ color: 'red' }}>*</label>
							</label>
						}
						span={12}
					>
						{allDisabled ? (
							<p style={{ padding: '4px', margin: 0 }}>{title}</p>
						) : (
							<Form.Item
								name="title"
								rules={[
									{ required: true, message: 'Укажите название (минимум 3 символа)', min: 3 }
								]}
							>
								<Input value={title} disabled={allDisabled} />
							</Form.Item>
						)}
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<label>
								Тип чек-листа
								<label style={{ color: 'red' }}>*</label>
							</label>
						}
						span={12}
					>
						{allDisabled ? (
							<p style={{ padding: '4px', margin: 0 }}>{currentTemplateType}</p>
						) : (
							<Form.Item
								name="templateType"
								rules={[{ required: true, message: 'Укажите тип чек-листа' }]}
							>
								<Select
									onChange={onTemplateTypeChange}
									value={currentTemplateType}
									style={{ width: '100%', textOverflow: 'ellipsis' }}
									disabled={allDisabled}
								>
									{templateTypes.map((item, index) => (
										<Option key={index} value={item}>
											{item}
										</Option>
									))}
								</Select>
							</Form.Item>
						)}
					</Descriptions.Item>

					{currentTemplateType === 'УКС НДК' && (
						<Descriptions.Item label={<label>Пакет работ</label>} span={12}>
							<Form.Item name="workPackageId">
								<Select
									style={{ width: '100%', textOverflow: 'ellipsis' }}
									disabled={allDisabled}
									allowClear
								>
									{workPackages?.map((item, index) => (
										<Option key={index} value={item.id}>
											{item.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Descriptions.Item>
					)}

					<Descriptions.Item
						span={12}
						labelStyle={{ border: 'none' }}
						label="Общий для всех проектов"
					>
						<Form.Item name="isCommon">
							<Switch
								size="small"
								title="Общий для всех проектов"
								// defaultChecked={templateDto ? templateDto.isCommon : false}
								checked={templateDto.isCommon}
								onChange={e =>
									dispatch(
										setDtoValue({
											prop: 'isCommon',
											value: e
										})
									)
								}
								disabled={allDisabled}
							/>
						</Form.Item>
					</Descriptions.Item>

					{editedTemplate && (
						<>
							<Descriptions.Item label="Дата создания" span={12}>
								<p style={{ padding: '4px', margin: 0 }}>
									{dayjs(editedTemplate?.createdAt).format('DD.MM.YYYY')}
								</p>
							</Descriptions.Item>

							<Descriptions.Item label="Автор" span={12}>
								<p style={{ padding: '4px', margin: 0 }}>{editedTemplate?.createdBy}</p>
							</Descriptions.Item>
						</>
					)}
					{/* 
					{currentTemplateType === 'УК КС' && (
						<Descriptions.Item label="Плановая дата передачи системы" span={12}>
							{!currentUser!?.isAdmin! ? (
								<p style={{ padding: '4px', margin: 0 }}>{dayjs(editedTemplate!?.plannedDate!).format('DD.MM.YY')}</p>
							) : (
								<Form.Item name="plannedDate" rules={[{ required: false }]}>
									<DatePicker
										locale={locale}
										style={{ width: '100%' }}
										format={'DD.MM.YYYY'}
										disabledDate={current => {
											let pastDate = dayjs().format('YYYY-MM-DD')
											return current && current < dayjs(pastDate, 'YYYY-MM-DD')
										}}
									/>
								</Form.Item>
							)}
						</Descriptions.Item>
					)} */}

					{/* <Descriptions.Item label="Срок на проведение чек-листа" span={12}>
						{allDisabled ? (
							<p style={{ padding: '4px', margin: 0 }}>{dayjs(editedTemplate?.period).format('DD.MM.YY')}</p>
						) : (
							<Form.Item name="period" rules={[{ required: false }]}>
								<DatePicker
									locale={locale}
									style={{ width: '100%' }}
									format={'DD.MM.YYYY'}
									disabledDate={current => {
										let pastDate = dayjs().format('YYYY-MM-DD')
										return current && current < dayjs(pastDate, 'YYYY-MM-DD')
									}}
									disabled={allDisabled}
								/>
							</Form.Item>
						)}
					</Descriptions.Item> */}

					{currentTemplateType === 'УК КС' && (
						<>
							<Descriptions.Item label="Срок гарантий по договору застройщика" span={12}>
								{allDisabled ? (
									<p style={{ padding: '4px', margin: 0 }}>
										{dayjs(editedTemplate?.quarantyPeriod).format('DD.MM.YY')}
									</p>
								) : (
									<Form.Item name="quarantyPeriod" rules={[{ required: false }]}>
										<DatePicker
											locale={locale}
											style={{ width: '100%' }}
											format={'DD.MM.YYYY'}
											disabledDate={current => {
												let pastDate = dayjs().format('YYYY-MM-DD')
												return current && current < dayjs(pastDate, 'YYYY-MM-DD')
											}}
											disabled={allDisabled}
										/>
									</Form.Item>
								)}
							</Descriptions.Item>

							<Descriptions.Item label="Ответственный за устранение от застройщика" span={12}>
								{allDisabled ? (
									<p style={{ padding: '4px', margin: 0 }}>
										{editedTemplate?.assignedbyElimination}
									</p>
								) : (
									<Form.Item name="assignedByElimination" rules={[{ required: false }]}>
										<Input disabled={allDisabled} />
									</Form.Item>
								)}
							</Descriptions.Item>
						</>
					)}

					<Descriptions.Item label="Описание" span={12}>
						{allDisabled ? (
							<p style={{ padding: '4px', margin: 0 }}>{description}</p>
						) : (
							<Form.Item style={{ width: '100%' }} name="description">
								<Input.TextArea
									value={description}
									onChange={e => setDescription(e.target.value)}
									autoSize={{
										minRows: 4
									}}
									disabled={allDisabled}
								/>
							</Form.Item>
						)}
					</Descriptions.Item>

					{currentTemplateType !== 'УК КС' && (
						<Descriptions.Item label="Локация" span={12}>
							{allDisabled ? (
								<p style={{ padding: '4px', margin: 0 }}>{location}</p>
							) : (
								<Form.Item name="location">
									<Input
										value={location}
										onChange={e => setLocation(e.target.value)}
										disabled={allDisabled}
									/>
								</Form.Item>
							)}
						</Descriptions.Item>
					)}

					{editedTemplate && (
						<Descriptions.Item label="Дата обновления" span={12}>
							<p style={{ padding: '4px', margin: 0 }}>
								{dayjs(editedTemplate?.updatedAt).format('DD.MM.YYYY')}
							</p>
						</Descriptions.Item>
					)}

					{currentTemplateType === 'УК КС' && (
						<>
							<Descriptions.Item
								span={12}
								labelStyle={{ border: 'none' }}
								label="Критичен для управляющей компании"
							>
								<Form.Item name="checkboxUk">
									<Switch
										size="small"
										title="Критичен для управляющей компании"
										defaultChecked={editedTemplate ? editedTemplate.checkboxUk : false}
										disabled={allDisabled}
									/>
								</Form.Item>
							</Descriptions.Item>

							<Descriptions.Item span={12} labelStyle={{ border: 'none' }} label="Посекционно">
								<Form.Item name={['bySection']}>
									<Switch
										size="small"
										title="Посекционно"
										defaultChecked={editedTemplate ? editedTemplate!?.bySection! : false}
										disabled={allDisabled}
									/>
								</Form.Item>
							</Descriptions.Item>
						</>
					)}
					<Descriptions.Item span={12} labelStyle={{ border: 'none' }} label="Активный">
						<Form.Item name={['isActive']}>
							<Switch
								size="small"
								title="Активный"
								defaultChecked={editedTemplate ? editedTemplate.isActive : false}
								disabled={allDisabled}
							/>
						</Form.Item>
					</Descriptions.Item>
				</Descriptions>
			</Card>
			<Divider />

			<Sections
				form={form}
				isEdited={!!editedTemplate}
				editedTemplate={editedTemplate}
				onRefreshTemplatesTable={onRefreshTemplatesTable}
				currentTemplateType={currentTemplateType === 'УКС НДК' ? '0' : '1'}
				allDisabled={allDisabled}
			/>
		</>
	)
}

export default TemplateForm
