import { useQuery } from '@tanstack/react-query'
import { IOrdinance } from 'interfaces/IOrdinance'
import { getOrdinanceList } from 'services/OrdinanceService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setTotalItemsOrdinance } from 'store/ordinanceSlice'
import { useAppDispatch, useAppSelector } from './appReduxHook'

export const useOrdinances = () => {
	const projectId = useAppSelector(state => state.environment.project?.id)
	const checkPermissions = useCheckPermissions()
	const { permissions, user } = useAppSelector(state => state.environment)
	const allCompanies = checkPermissions([PERMISSIONS.AllCompanies])
	const filters = useAppSelector(state => state.ordinance.filters)
	const currentPage = useAppSelector(state => state.ordinance.listState.currentPage)
	const dispatch = useAppDispatch()
	return useQuery<IOrdinance[]>({
		queryKey: ['ordinancesList', projectId, filters, currentPage],
		queryFn: async () => {
			const ordinanceList = await getOrdinanceList({
				filters: {
					...filters,
					contractorId: allCompanies ? filters.contractorId : user?.contractorId!
				},
				offset: currentPage === 1 ? 0 : currentPage! * 50 - 50
			}).then(data => data)
			dispatch(setTotalItemsOrdinance(ordinanceList.totalItems))
			return ordinanceList!.data
		},
		refetchOnWindowFocus: false
	})
}
