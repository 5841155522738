import { useQueryClient } from '@tanstack/react-query'
import AttentionModal from 'UI/modals/AttentionModal'
import { Button, Drawer, Form, message } from 'antd'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import {
	ICheckListTemplateSection,
	ITemplate,
	ITemplateDTO,
	ITemplateItem /* ITemplatesData */
} from 'interfaces/ITemplate'
import { FC, useEffect, useState } from 'react'
import { addCheckListTemplate } from 'services/TemplatesService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { resetDto, setDtoValue } from 'store/templatesSlice'
import TemplateForm from './TemplateForm'

interface ICreateTemplateProps {
	open: boolean
	onClose: () => void
	editedTemplate: ITemplate | null
	creatorStatus: null | 0 | 1 | 2
	// checkPermissions: (currentPermits: string[]) => boolean
}

const TemplateDrawer: FC<ICreateTemplateProps> = ({
	open,
	onClose,
	editedTemplate,
	creatorStatus
	// checkPermissions
}) => {
	const checkPermissions = useCheckPermissions()
	const queryClient = useQueryClient()
	const [createTemplateForm] = Form.useForm()
	const dispatch = useAppDispatch()
	const { templateFilters, viewerStatus } = useAppSelector(state => state.templates.data)
	const { project } = useAppSelector(state => state.environment)
	const [attention, setAttention] = useState<boolean>(false)
	const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(false)

	useEffect(() => {
		if (open) {
			dispatch(resetDto())
			dispatch(
				setDtoValue({
					prop: 'isCommon',
					value: editedTemplate!?.isCommon!
				})
			)
		}
	}, [open])

	const onCancelModal = () => {
		setSaveBtnDisabled(false)
		setAttention(false)
		createTemplateForm.resetFields()
		clearDto()
		onClose()
	}
	const handleOnClose = () => {
		if (!saveBtnDisabled /* !_.isEqual(initTemplate, currentTemplate) */) {
			setAttention(true)
		} else {
			onCancelModal()
		}
	}
	const onRefreshTemplatesTable = () => {
		queryClient.invalidateQueries(['templates'])
		// getCheckListTemplates({
		// 	filters: templateFilters,
		// 	templateType: viewerStatus
		// })
		// 	.then(data => {
		// 		dispatch(setTemplates(data))
		// 	})
		// 	.catch(error => message.error(error.message))
	}

	const clearDto = () => {
		dispatch(
			setDtoValue({
				prop: 'bySection',
				value: false
			})
		)
	}

	const onCreateTemplate = async (template: ITemplateDTO) => {
		const mainId = project!.id
		const quarantyPeriodData =
			template.quarantyPeriod === null
				? null
				: dayjs(template.quarantyPeriod as unknown as dayjs.Dayjs).format('YYYY-MM-DD')

		const templatesData: ITemplateDTO[] = []

		const sections = template.sections
		sections.forEach((section: ICheckListTemplateSection, sectionIndex: number) => {
			section.items.forEach((item: ITemplateItem, itemIndex: number) => {
				item.order = itemIndex
				return item
			})
			section.order = sectionIndex
			return section
		})

		templatesData.push({
			...template,
			projectId: mainId,
			templateType: template.templateType === 'УКС НДК' ? 0 : 1,
			sections: sections,
			period: dayjs(template.period as unknown as dayjs.Dayjs).format('YYYY-MM-DD'),
			plannedDate: template.plannedDate
				? dayjs(template.plannedDate as unknown as dayjs.Dayjs).format('YYYY-MM-DD')
				: null,
			quarantyPeriod: quarantyPeriodData
		})
		if (editedTemplate) {
			templatesData[0].id = editedTemplate.id
		}

		await addCheckListTemplate({ template: templatesData, templateType: viewerStatus })
			.then(status => {
				if (status === 200) {
					message.success('Шаблон успешно сохранен')
				}
				queryClient.invalidateQueries(['templateUkNdk'])
				onRefreshTemplatesTable()
				clearDto()
				onClose()
				createTemplateForm.resetFields()
			})
			.catch(error => {
				const data = error.response?.data! as IErrorDetail
				message.error(data.detail)
			})
	}

	const checkEmptySections = (sections: ICheckListTemplateSection[]) => {
		return sections && !!!sections!?.length
			? null
			: sections!?.some(section => {
					return !!section!?.items!?.length
			  })
	}

	useEffect(() => {
		if (editedTemplate) {
			createTemplateForm.setFieldsValue({
				...editedTemplate,
				templateType: editedTemplate?.templateType === 0 ? 'УКС НДК' : 'УК КС'
			})
		}
	}, [editedTemplate])

	useEffect(() => {
		if (editedTemplate) {
			const UKS_NDC = checkPermissions([PERMISSIONS.ChecklistsTemplatesEditUksNdc])
			const UK_KC = checkPermissions([PERMISSIONS.ChecklistsTemplatesEditUkKc])
			if (!UK_KC && editedTemplate.templateType === 1) {
				setSaveBtnDisabled(true)
			}
			if (!UKS_NDC && editedTemplate.templateType === 0) {
				setSaveBtnDisabled(true)
			}
		}
	}, [editedTemplate])

	const onTemplateSave = (value: any) => {
		if (checkEmptySections(value.sections)) {
			onCreateTemplate(value)
		} else if (checkEmptySections(value.sections) === null) {
			message.error('Нельзя сохранить шаблон без разделов!')
		} else {
			message.error('Нельзя сохранить шаблон с разделами без пунктов!')
		}
	}

	return (
		<>
			{attention && (
				<AttentionModal
					title="Внимание"
					description="Не сохранённые данные будут утеряны!"
					opened={attention}
					onOk={() => {
						onCancelModal()
					}}
					onCancel={() => setAttention(false)}
				/>
			)}
			<Drawer
				destroyOnClose={true}
				open={open}
				onClose={handleOnClose}
				width={window.outerWidth < 1300 ? '75vw' : '50vw'}
				title={(!editedTemplate ? 'Создание' : 'Редактирование') + ' шаблона чек-листа'}
				bodyStyle={{ backgroundColor: '#fafafa' }}
				extra={
					<Button
						htmlType="submit"
						onClick={() => createTemplateForm.submit()}
						disabled={saveBtnDisabled}
					>
						{!editedTemplate ? 'Создать' : 'Сохранить'}
					</Button>
				}
			>
				<Form name="createTemplateForm" form={createTemplateForm} onFinish={onTemplateSave}>
					<TemplateForm
						form={createTemplateForm}
						editedTemplate={editedTemplate}
						onRefreshTemplatesTable={onRefreshTemplatesTable}
						creatorStatus={creatorStatus}
					/>
				</Form>
			</Drawer>
		</>
	)
}

export default TemplateDrawer
