import { IBase } from 'interfaces/IBase'
import { IAuthor } from 'interfaces/IEnvironment'
import { IReportNotifications } from 'interfaces/INotification'
import { IPagination } from 'interfaces/IPagination'
import { IHOCReport, IReport, IReportResponse, IReportResponseError } from 'interfaces/IReports'
import { IReportNotificationsFilter } from 'pages/adminPage/ReportNotificationsModal'
import { API } from './ApiService'

export const reportService = {
	async getNotes() {
		return await API({
			url: `/v1/reports/noteReport`,
			method: 'get'
		}).then(response => response.data)
	},
	async setNote(note: { order?: number; content: string }) {
		return await API({
			url: `/v1/reports/noteReport`,
			method: 'post',
			data: note
		}).then(response => response.data)
	},
	async editNote(note: { id: string; order?: number; content: string }) {
		return await API({
			url: `/v1/reports/noteReport/${note.id}`,
			method: 'patch',
			data: note
		}).then(response => response.data)
	},
	async deleteNote(id: string) {
		return await API({
			url: `/v1/reports/noteReport/${id}`,
			method: 'delete'
		}).then(response => response.data)
	},
	async getReport(type: string, dto?: IReport) {
		return await API<IReportResponse | IReportResponseError>({
			url: `/v1/reports/${type}`,
			method: 'post',
			data: dto
		}).then(response => response.data)
	},
	async getContractors(projects: string[]) {
		return await API<IBase[]>({
			url: `/v1/reports/contractors`,
			method: 'post',
			data: { projectId: projects }
		}).then(response => response.data)
	},
	async getAuthors(projects: string[]) {
		return await API<IAuthor[]>({
			url: `/v1/reports/authors`,
			method: 'post',
			data: { projectId: projects }
		}).then(response => response.data)
	},
	async getHocReport(settings: any) {
		return await API<IHOCReport[]>({
			url: `/v1/reports/HOCReport`,
			method: 'get',
			params: { ...settings }
		}).then(response => response.data)
	},
	async getReportNotification({
		filters,
		limit = 20,
		offset = 0,
		desc = true
	}: {
		filters?: IReportNotificationsFilter
		limit?: number
		offset?: number
		desc?: boolean
	}) {
		return await API<IPagination<IReportNotifications[]>>({
			url: '/v1/reports/reportNotification',
			method: 'get',
			params: { limit, offset, ...filters, desc }
		}).then(response => response)
	}
}
