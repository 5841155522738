import { Row } from '@tanstack/table-core'
import { Row as AntRow, Col } from 'antd'
import { memo, useCallback } from 'react'
import { findNestedObj } from 'shared/helpers'
import { useEstimatePositionState } from 'widgets/estimate-position-card'
import { useShallow } from 'zustand/react/shallow'

import modal from 'antd/es/modal'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { IAdjustmentPosition, IAdjustmentSection } from '../model/adjustment-schema'
import { useAdjustmentState } from '../model/adjustment-state'
import { AdjustmentPositionChat } from './AdjustmentPositionChat'
import { AdjustmentPositionCopy } from './AdjustmentPositionCopy'
import { AdjustmentPositionDelete } from './AdjustmentPositionDelete'
import { AdjustmentPositionMove } from './AdjustmentPositionMove'

interface IProps {
	row: IAdjustmentPosition
	callBack?: () => void
	mainRow?: Row<IAdjustmentPosition>
}

const ellipsisCell = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap'
}
export const AdjustmentPositionTitle = memo(function (params: IProps) {
	const { row, callBack, mainRow } = params
	const checkPermissions = useCheckPermissions()
	const { setToEdit } = useEstimatePositionState()
	const { container, sections } = useAdjustmentState(
		useShallow(state => ({
			container: state.container,
			sections: state.sections
		}))
	)

	const currentSection = useCallback(() => {
		return (
			(sections &&
				findNestedObj<IAdjustmentSection>(sections, 'id', row.containerSectionId)?.children
					?.length) ??
			1
		)
	}, [row])

	const canEdit = checkPermissions([PERMISSIONS.AdjustmentCreate])

	return (
		<AntRow style={{ width: '100%' }} align="middle" justify="space-between" wrap={false}>
			<Col
				flex={1}
				onClick={() => (callBack ? callBack() : null)}
				onDoubleClick={() =>
					row.isDeleted
						? modal.error({
								title: 'Позиция сметы удалена',
								content: 'С этой позицией сметы работать нельзя'
						  })
						: container &&
						  setToEdit(row.id, { id: row.containerSectionId, name: row.workName }, container?.id)
				}
			>
				<div style={ellipsisCell}>{`${row.order} - ${row.workName}`}</div>
			</Col>
			<Col>
				<AntRow gutter={8} align="middle" wrap={false}>
					{!container?.ready && !row.isDeleted && (
						<Col>
							<AntRow gutter={8} align="middle" wrap={false} className="app-wbs-move-controls">
								{currentSection() > 1 && canEdit && (
									<Col>
										<AdjustmentPositionMove
											sectionId={row.containerSectionId}
											rowId={row.id}
											row={mainRow}
										/>
									</Col>
								)}

								{canEdit && (
									<Col>
										<AdjustmentPositionCopy id={row.id} sectionId={row.containerSectionId} />
									</Col>
								)}

								{canEdit && row.isUnrelated && (
									<Col>
										<AdjustmentPositionDelete id={row.id} sectionId={row.containerSectionId} />
									</Col>
								)}
							</AntRow>
						</Col>
					)}
					{!row.isDeleted && (
						<Col>
							<AdjustmentPositionChat row={row} />
						</Col>
					)}
				</AntRow>
			</Col>
		</AntRow>
	)
})
