import { useAppDispatch } from 'hooks/appReduxHook'
import { FC, useEffect } from 'react'
import { setPageTitle } from 'store/rootSlice'

interface IHelpPageProps {}

const HelpPage: FC<IHelpPageProps> = () => {
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Помощь' }))
	}, [])
	return <div className="dashboard__block" style={{ display: 'flex', alignItems: 'center' }}></div>
}
export default HelpPage
