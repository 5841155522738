import { Button, Card, Form, Input, DatePicker, Descriptions } from 'antd'
import { FC } from 'react'
import ReactQuill from 'react-quill'
import dayjs from 'dayjs'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { ReleaseNoteService } from 'services/release-note.service'
import { IReleaseNote, IReleaseNoteCreateDto, IReleaseNoteUpdateDto } from 'interfaces/release-note.interface'
import { useQueryClient } from '@tanstack/react-query'
interface IReleaseNotesEditorProps {
	onCancel: () => void
	toEdit?: IReleaseNote
}

const toolbarOptions = [
	['bold', 'italic', 'underline', 'strike'],
	['blockquote', 'code-block'],
	[{ header: [2, 3, false] }],
	[{ list: 'ordered' }, { list: 'bullet' }],
	[{ script: 'sub' }, { script: 'super' }],
	[{ indent: '-1' }, { indent: '+1' }],
	[{ direction: 'rtl' }],
	[{ size: ['small', 'normal', 'large', 'huge'] }],
	[{ color: [] }, { background: [] }],
	[{ font: [] }],
	[{ align: [] }],
	['clean']
]

const formatDate = 'YYYY-MM-DDTHH:mm:ss.sssZ'

export const ReleaseNotesEditor: FC<IReleaseNotesEditorProps> = ({ onCancel, toEdit }) => {
	const queryClient = useQueryClient()
	const [noteForm] = Form.useForm<IReleaseNoteUpdateDto>()
	const onSave = async () => {
		const formData = noteForm.getFieldsValue()
		const data: IReleaseNoteCreateDto = {
			number: formData.number!,
			create: dayjs(formData.create).isValid() ? dayjs(formData.create).format(formatDate) : dayjs().format(formatDate),
			description: formData.description || ''
		}
		if (typeof toEdit === 'undefined') {
			await ReleaseNoteService.create(data).then(() => {
				queryClient.invalidateQueries(['releaseNotes'])
				onCancel()
			})
		} else {
			await ReleaseNoteService.update(toEdit.id, data).then(() => {
				queryClient.invalidateQueries(['releaseNotes'])
				noteForm.resetFields()
				onCancel()
			})
		}
	}

	return (
		<Card>
			<Form name="noteForm" form={noteForm} onFinish={onSave}>
				<Descriptions
					column={12}
					bordered
					labelStyle={{
						fontWeight: 'bold',
						width: '20%',
						alignItems: 'center',
						padding: '8px'
					}}
					contentStyle={{
						width: '80%',
						padding: '8px'
					}}
				>
					<Descriptions.Item label="Номер версии" span={12}>
						<Form.Item
							name="number"
							rules={[{ required: true, message: 'Укажите версию' }]}
							initialValue={toEdit && toEdit.number}
						>
							<Input />
						</Form.Item>
					</Descriptions.Item>

					<Descriptions.Item label="Дата" span={12}>
						<Form.Item
							name="create"
							rules={[{ required: true, message: 'Укажите дату выпуска версии' }]}
							initialValue={toEdit && dayjs(toEdit.create).isValid() ? dayjs(toEdit.create) : dayjs()}
						>
							<DatePicker
								onChange={e => console.log(dayjs(e).format('YYYY-MM-DD'))}
								locale={locale}
								style={{ width: '100%' }}
								format={'DD.MM.YYYY'}
								disabledDate={current => {
									let pastDate = dayjs().format('YYYY-MM-DD')
									return current && current < dayjs(pastDate, 'YYYY-MM-DD')
								}}
							/>
						</Form.Item>
					</Descriptions.Item>

					<Descriptions.Item label="Описание" span={12}>
						<Form.Item
							name="description"
							rules={[{ required: true, min: 3, message: 'Укажите описание выпуска' }]}
							initialValue={toEdit && toEdit.description}
						>
							<ReactQuill
								modules={{
									toolbar: toolbarOptions
								}}
								theme="snow"
							/>
						</Form.Item>
					</Descriptions.Item>
				</Descriptions>
				<Button htmlType="submit" style={{ marginTop: 16, marginRight: 8 }} type="primary">
					Сохранить
				</Button>
				<Button onClick={onCancel} style={{ marginTop: 16 }}>
					Отмена
				</Button>
			</Form>
		</Card>
	)
}
