import React from 'react'; 
import type { CountdownProps } from 'antd'; 
import {  Statistic } from 'antd'; 
import { ReactComponent as ATLogo } from 'assets/at_logo.svg' 
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'; 
import useMaintanceState from 'store/maintanceState';

const { Countdown } = Statistic; 

export const MaintanceOnProgress: React.FC = () => { 
    const onFinish: CountdownProps['onFinish'] = () => { 
        window.location.reload()
    }

    const {maintance} = useMaintanceState()
    const convertToTimestamp = (endTime: string) => {
        const [hours, minutes] = endTime?.split(':').map(Number);
        const result = new Date();
        result.setHours(hours,minutes,0,0);
        return result.getTime();
    };

 
    return ( 
        <div className="at-bg" 
            style={{ 
                flexDirection: 'column', 
                height: '100vh', 
                maxWidth: '100%' 
            }}> 
            <ATLogo style={{ marginBottom: '1rem' }} /> 
            <p style={{ fontSize: '20px' }}> 
                В настоящее время на сайте ведутся технические работы. Время до завершения работ: 
            </p> 
            <Countdown value={
                maintance.endTime ? convertToTimestamp(maintance.endTime) : 0
            } onFinish={onFinish} /> 
        </div>         
    ); 
}

