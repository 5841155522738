import { Alert, Col, Row, Skeleton, Space } from 'antd'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect } from 'react'
import { IEstimatePositionComment } from '../model/estimate-position-comments-state'

interface IProps {
	isLoading: boolean
	comments: IEstimatePositionComment[]
}

export const EstimatePositionComments: FC<IProps> = props => {
	const user = useAppSelector(state => state.environment.user)
	const { isLoading, comments } = props

	useEffect(() => {
		if (!!comments.length) {
			document
				.querySelector(`.ant-alert[data-key="${comments[comments.length - 1].id}"]`)
				?.scrollIntoView({ behavior: 'smooth' })
		}
	}, [comments, isLoading])

	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			{isLoading ? (
				<Skeleton active />
			) : (
				comments
					.sort((a, b) => dayjs(a.createdAt).diff(dayjs(b.createdAt)))
					.map(item => (
						<Alert
							data-key={item.id}
							key={item.id}
							style={{
								width: '80%',
								borderWidth: item.appealTo?.id === user?.id ? '3px' : '1px',
								marginLeft: user?.id === item.author.id ? 'auto' : 0,
								boxShadow:
									item.appealTo?.id === user?.id ? '2px 4px 12px rgba(145,202,255,0.5)' : undefined,
								backgroundColor: item.appealTo === null ? '#f2f3f4' : undefined
							}}
							message={
								<Row align="middle" justify="space-between">
									<Col style={{ color: item.appealTo === null ? '#44a5ff' : '#000' }}>
										{item.appealTo === null ? (
											<span>Заявка в НСИ</span>
										) : (
											<span>
												<span>{item.author.name}</span>
												{item.appealTo?.name && (
													<>
														<span> к </span>
														<b>{item.appealTo?.id === user?.id ? 'Вам' : item.appealTo?.name}</b>
													</>
												)}
											</span>
										)}
									</Col>
									<Col style={{ fontSize: 12 }}>
										{dayjs(item.createdAt).isValid()
											? dayjs(item.createdAt).format('DD.MM.YYYY HH:mm')
											: '-'}
									</Col>
								</Row>
							}
							description={item.comment}
							type={user?.id === item.author.id ? 'success' : 'info'}
						/>
					))
			)}
		</Space>
	)
}
