import { useQueryClient } from '@tanstack/react-query'
import { Form, Input, Modal, message } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'
import { FC, useEffect, useState } from 'react'
import { useWbsRegistryState } from '..'

interface IProps {}

export const WbsCreateAdjustment: FC<IProps> = props => {
	const [isLoading, setIsLoading] = useState(false)
	const queryClient = useQueryClient()
	const [hasError, setHasError] = useState(false)
	const { adjustmentRecord, setAdjustmentRecord, onAdjustmentCreate } = useWbsRegistryState()
	const [wbsAdjustmentForm] = Form.useForm()

	const onCreateHandler = async (values: { name: string }) => {
		if (adjustmentRecord) {
			setIsLoading(true)
			await onAdjustmentCreate(adjustmentRecord.id, values.name)
				.then(() => {
					queryClient.invalidateQueries(['wbsRegistry'])
					setAdjustmentRecord(undefined)
					message.success('Документ "Было/Стало" успешно создан')
				})
				.catch((err: AxiosError<IErrorDetail>) => {
					Modal.error({
						zIndex: 999999,
						title: 'Ошибка создания документа',
						content: err.response?.data.detail,
						centered: true
					})
				})
				.finally(() => setIsLoading(false))
		}
	}

	useEffect(() => {
		if (adjustmentRecord)
			wbsAdjustmentForm.setFieldValue('name', `${adjustmentRecord.name}_было-стало`)
	}, [adjustmentRecord])

	const handleFormChange = () => {
		setHasError(wbsAdjustmentForm.getFieldsError().filter(({ errors }) => errors.length).length > 0)
	}

	return (
		<Modal
			destroyOnClose
			title={'Документ "Было/Стало"'}
			open={!!adjustmentRecord}
			onOk={() => wbsAdjustmentForm.submit()}
			okButtonProps={{ disabled: hasError, loading: isLoading }}
			onCancel={() => {
				setAdjustmentRecord(undefined)
			}}
			okText="Сохранить"
		>
			<Form
				onFinish={onCreateHandler}
				name="wbsAdjustmentForm"
				form={wbsAdjustmentForm}
				layout="vertical"
				onFieldsChange={handleFormChange}
			>
				<Form.Item
					name="name"
					label="Введите наименование документа:"
					rules={[
						{
							required: true,
							message: 'Введите наименование документа'
						},
						{ min: 3, message: 'Поле должно содержать минимум 3 символа' }
					]}
				>
					<Input.TextArea style={{ height: '80px' }} showCount maxLength={2000} />
				</Form.Item>
			</Form>
		</Modal>
	)
}
