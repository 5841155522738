import { LogoutOutlined } from '@ant-design/icons'
import { Button, Drawer, Tabs } from 'antd'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IAppUser } from 'interfaces/IUser'
import { FC, useState } from 'react'
import { Logout } from 'services/AuthService'
import { accessVerification } from 'shared/accessVerification'
import { PERMISSIONS } from 'shared/constants'
import { resetEnvironment } from 'store/environmentSlice'
import { NotificationController } from 'widgets/notification-controller'
import { UserMenuDetails } from './UserMenuDetails'

interface IUserMenuDrawerProps {
	open: boolean
	user: IAppUser
	onClose: () => void
}

export const UserMenuDrawer: FC<IUserMenuDrawerProps> = ({ user, open, onClose }) => {
	const dispatch = useAppDispatch()
	const { user: currentUser, permissions } = useAppSelector(state => state.environment)
	const [activeTab, setActiveTab] = useState('details')
	const onCloseDrawer = () => {
		setActiveTab('details')
		onClose()
	}
	return (
		<Drawer
			destroyOnClose
			title={`${user!?.email!}`}
			className="at-drawer-status at-drawer-status--noneliminated"
			width={'50vw'}
			open={open}
			onClose={onCloseDrawer}
			extra={
				<Button
					icon={<LogoutOutlined rev={undefined} />}
					htmlType="submit"
					onClick={() => {
						onClose()
						Logout().finally(() => dispatch(resetEnvironment()))
					}}
					ghost
					style={{ borderColor: '#fff' }}
				>
					Выйти из аккаунта
				</Button>
			}
		>
			<Tabs
				activeKey={activeTab}
				onChange={activeKey => setActiveTab(activeKey)}
				size="small"
				tabBarGutter={16}
				items={[
					{
						label: 'Детали',
						key: 'details',
						children: <UserMenuDetails user={user} />
					},
					{
						label: 'Уведомления',
						key: 'notifications',
						disabled: !accessVerification(user, permissions, [
							PERMISSIONS.WbsSdu,
							PERMISSIONS.WbsDzMtr,
							PERMISSIONS.WbsDp,
							PERMISSIONS.WbsNoteContractor,
							PERMISSIONS.WbsCreator,
							PERMISSIONS.WbsView,
							PERMISSIONS.WbsDelete,
							PERMISSIONS.WbsChangeReady,
							PERMISSIONS.WbsDzSmr
						]),
						children: (
							<NotificationController
								type={user?.isAdmin ? 'admin' : 'user'}
								notificationOptions={user?.notificationDestinationOption}
								destinationId={user?.isAdmin ? user?.destinationId : undefined}
							/>
						)
					}
				]}
			/>
		</Drawer>
	)
}
