import { Col, Row } from 'antd'
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useViolationTypesAnalytics } from 'hooks/useViolationTypesAnalytics'
import { FC } from 'react'
import { Bar } from 'react-chartjs-2'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)
interface IProps {}

export const ViolationTypesAnalyticsWidget: FC<IProps> = props => {
	const { data: analytics } = useViolationTypesAnalytics()
	const fixed = analytics?.map(i => i.issueFix) ?? []
	const noFixed = analytics?.map(i => i.issueNoFix) ?? []
	const labels =
		analytics
			?.map(i => i.lable) ?? []
	const dataPoints = [
		{
			label: 'Устранено',
			data: fixed,
			backgroundColor: '#3d85c6'
		},
		{
			label: 'Не устранено',
			data: noFixed,
			backgroundColor: '#674ea7'
		}
	]
	const datasets: any[] = dataPoints.map(dataPoint => ({
		...dataPoint
	}))

	datasets[0].data?.forEach((_: any, datasetIndex: any) => {
		const all_i = datasets
			.map((d, dataIndex) => [d.data[datasetIndex], dataIndex])
			.sort(([data1], [data2]) => data1 - data2)
		let sum = 0
		all_i.forEach(([data, datasetIdx]) => {
			datasets[datasetIdx].data[datasetIndex] = [sum, data]
			sum = sum + data
		})
	})

	const data = {
		labels: labels,
		datasets: datasets.map((d, i) => ({
			...d,
			datalabels: {
				// display: false
				color: '#fff',
				anchor: 'center' as const,
				align: 'center' as const, //i % 2 !== 0 ? ('left' as const) : ('right' as const),
				formatter: (value: any[]) => (value?.[1]! > 0 ? value![1] : '')
			}
		}))
	}

	const config = {
		type: 'bar',
		data,
		width: '100%',
		height: '60%',
		options: {
			responsive: true,
			plugins: {
				legend: {
					display: true,
					onClick: (e: any) => null
				},
				tooltip: {
					callbacks: {
						label: function (context: any) {
							let label = context.dataset.label || ''

							if (label) {
								label += ': '
							}
							if (context.parsed.y !== null) {
								label += context.parsed.y
							}
							return label
						}
					}
				}
			},
			interaction: {
				mode: 'index' as const,
				intersect: false
			},
			scales: {
				y: {
					beginAtZero: true,
					stacked: false,
					maxRotation: 0,
					ticks: {
						// stepSize: 10
						display: false,
					}
				},
				x: {
					stacked: true,
					ticks: {
						beginAtZero: true,
						color: '#444',
						fontSize: 10,
						autoSkip: false,
						maxRotation: 0,
						// maxRotation: 90,
						// minRotation: 90,
						// maxWidth: 5,
						callback: (value: any, index: any, values: any) => {
							const words = labels![value].split(/ |-/gm)
							let aux = []
							let concat = []

							for (let i = 0; i < words.length; i++) {
								concat.push(words[i])
								let join = concat.join(' ')
								if (join.length > 5) {
									aux.push(join)
									concat = []
								}
							}

							if (concat.length) {
								aux.push(concat.join(' ').trim())
							}
							return labels.length < 11 ? aux : aux[0].slice(0, 1) + '...'
						}
					}
				}
			}
		}
	}

	return (
		<Row
			wrap={false}
			gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: '#f2f2f2',
				height: '100%'
			}}
		>
			<Col span={24} style={{ padding: '1rem', background: '#fefefe' }}>
				<h2>Типы замечаний</h2>
				<Bar {...config} />
			</Col>
		</Row>
	)
}
