import { configureStore } from '@reduxjs/toolkit'
import asmSlice from './asmSlice'
import checklistsSlice from './checklistsSlice'
import environmentSlice from './environmentSlice'
import issueSlice from './issueSlice'
import lightReportSlice from './lightReportSlice'
import ordinanceSlice from './ordinanceSlice'
import reportNotificationSlice from './reportNotificationSlice'
import rootSlice from './rootSlice'
import settingsUsersSlice from './settingsUsersSlice'
import templatesSlice from './templatesSlice'
import trafficLightReport from './trafficLightSlice'
import userSlice from './userSlice'

const store = configureStore({
	reducer: {
		root: rootSlice,
		issues: issueSlice,
		environment: environmentSlice,
		templates: templatesSlice,
		checklists: checklistsSlice,
		user: userSlice,
		lightReport: lightReportSlice,
		ordinance: ordinanceSlice,
		trafficLight: trafficLightReport,
		settingsUsers: settingsUsersSlice,
		asm: asmSlice,
		reportNotification: reportNotificationSlice,
	}
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
