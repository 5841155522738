import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { Row } from '@tanstack/table-core'
import { Row as AntRow, Button, Col, Tooltip } from 'antd'
import { memo, useEffect } from 'react'
import { findNestedObj, moveRow, moveSomeRow } from 'shared/helpers'
import { IEstimatePosition, IEstimateSection, useEstimateState } from '..'

interface IProps {
	sectionId: string
	rowId: string
	isMultiply?: boolean
	row?: Row<IEstimatePosition> | Row<IEstimateSection>
}

export const EstimatePositionMove = memo(function (params: IProps) {
	const { sectionId, rowId, isMultiply = false, row } = params
	const { selectedRows, sections, container, changePosition } = useEstimateState()

	const positionEstimates: any = findNestedObj<IEstimateSection>(
		sections,
		'id',
		sectionId
	)?.children

	const rowMainIndex = positionEstimates!?.findIndex((elem: any) => elem.id === rowId)

	useEffect(() => {
		if (moveSomeRow(positionEstimates, selectedRows) === true) {
			useEstimateState.setState({ canDelete: false })
		} else useEstimateState.setState({ canDelete: true })
	}, [selectedRows])

	return (
		<AntRow align="middle" wrap={false}>
			<Col>
				<Tooltip title="Поднять">
					<Button
						size="small"
						type="text"
						disabled={
							Object.keys(selectedRows.rows).includes(row?.originalSubRows![0]?.id!) ||
							('order' in row?.original! && row?.original.order === 1) ||
							('parentId' in row?.original! && !!row?.original.parentId) ||
							(isMultiply == false
								? moveRow(positionEstimates, rowMainIndex, 'up') &&
								  container?.isLocalEstimate === false
								: moveSomeRow(positionEstimates, selectedRows, 'up') &&
								  container?.isLocalEstimate === false)
						}
						icon={<CaretUpOutlined />}
						onClick={e => {
							e.stopPropagation()
							changePosition(sectionId, isMultiply ? Object.keys(selectedRows.rows) : [rowId], 'up')
						}}
					/>
				</Tooltip>
			</Col>
			<Col>
				<Tooltip title="Опустить">
					<Button
						size="small"
						type="text"
						disabled={
							Object.keys(selectedRows.rows).includes(
								row?.originalSubRows![row?.originalSubRows?.length! - 1]?.id!
							) ||
							('order' in row?.original! &&
								row?.original.order === row?.getParentRow()?.original.children?.length) ||
							('parentId' in row?.original! && !!row?.original.parentId) ||
							(isMultiply == false
								? moveRow(positionEstimates, rowMainIndex, 'down') &&
								  container?.isLocalEstimate === false
								: moveSomeRow(positionEstimates, selectedRows, 'down') &&
								  container?.isLocalEstimate === false)
						}
						icon={<CaretDownOutlined />}
						onClick={e => {
							e.stopPropagation()
							changePosition(
								sectionId,
								isMultiply ? Object.keys(selectedRows.rows) : [rowId],
								'down'
							)
						}}
					/>
				</Tooltip>
			</Col>
		</AntRow>
	)
})
