import { DeleteOutlined, FilterOutlined, LoadingOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import StatusIndicator from 'UI/statusIndicator'
import {
	Badge,
	Button,
	Flex,
	Input,
	Popconfirm,
	Row,
	Select,
	Table,
	Tag,
	Tooltip,
	message
} from 'antd'
import Col from 'antd/es/grid/col'
import modal from 'antd/es/modal'
import { ColumnsType } from 'antd/lib/table'
import { AxiosError } from 'axios'
import ColumnConfig, { IColumns } from 'components/columnConfig'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useCheckLists } from 'hooks/useCheckLists'
import { IErrorDetail } from 'interfaces/IBase'
import { IChecklistFilters, IData } from 'interfaces/IChecklist'
import { IPermission } from 'interfaces/IPermissions'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { deleteChecklist, getChecklistForm } from 'services/ChecklistsService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import {
	setCurrentChecklist,
	setCurrentChecklistFilters,
	setCurrentChecklistId,
	setCurrentChecklistSort,
	setCurrentPage,
	setIsCreatedChecklist
} from 'store/checklistsSlice'
import { setPageTitle } from 'store/rootSlice'
import ChecklistFilters from '../ChecklistFilters'
import ChecklistsDrawer from './ChecklistDrawer'
import { ChecklistCopyButton } from './components/ChecklistCopyButton'
import CreateChecklistModal from './modals/CreateChecklistModal'
import { useSectionsState } from './sections/model/sections-state'

const { Search } = Input

interface IProps {
	checkViewPermissions: (type: 'checklist' | 'template') => 0 | 1 | 2 | null
}

const Checklists: FC<IProps> = ({ checkViewPermissions }) => {
	const checkPermissions = useCheckPermissions()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const conf = localStorage.getItem('checklistPageColumns')
	const parsedConf: IColumns[] = conf && JSON.parse(conf!)
	const checklistsChecklistState = useAppSelector(state => state.checklists.listState)
	const checklistFilters = useAppSelector(state => state.checklists.filters)
	const [modalVisible, setModalVisible] = useState(false)
	const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
	const [openFilters, setOpenFilters] = useState<boolean>(false)
	const [showColumns, setShowColumns] = useState<IColumns[]>([])
	// Легенда: null - нет прав, 0 - УКС НДК, 1 - УК КС, 2 - оба типа
	const [creatorStatus, setCreatorStatus] = useState<null | 0 | 1 | 2>(null)
	const [viewerStatus, setViewerStatus] = useState<null | 0 | 1 | 2>(null)
	const { initialStatuses, isCreatedChecklist } = useAppSelector(state => state.checklists.init)
	const { user: currentUser } = useAppSelector(state => state.environment)
	const queryClient = useQueryClient()
	const { data: checklists, isFetching } = useCheckLists()
	const { expandedRowKeys, setExpandedRowKeys } = useSectionsState()
	const isAdmin = useAppSelector(state => state.environment.permissions)?.some(
		(perm: IPermission) => perm.name === 'ProjectAdmin'
	)

	useEffect(() => {
		setExpandedRowKeys(expandedRowKeys)
	}, [checklists])

	const onRowExpand = (expanded: boolean, record: IData) => {
		let currentExpandedRowKeys = expandedRowKeys
		if (expandedRowKeys.includes(record.key!)) {
			currentExpandedRowKeys = expandedRowKeys.filter(item => item !== record.key!)
		} else {
			currentExpandedRowKeys = [...expandedRowKeys, record.key!]
		}
		setExpandedRowKeys(currentExpandedRowKeys)
	}

	const handleCopy = async (templateId?: string, checklistId?: string) => {
		if (templateId) {
			await getChecklistForm(templateId, checklistId)
				.then(response => {
					dispatch(
						setCurrentChecklist({
							...response
						})
					)
					setDrawerVisible(true)
					dispatch(setIsCreatedChecklist(true))
				})
				.catch((err: AxiosError<IErrorDetail>) => {
					modal.error({
						title: 'Ошибка копирования чек-листа',
						content: err.response?.data.detail
					})
				})
		}
	}

	const checklistsColumns: ColumnsType<IData> = [
		{
			title: 'Пакет работ / Шаблон',
			fixed: 'left',
			dataIndex: 'name',
			key: 'name',
			sorter: true,
			width: 250
		},
		{
			title: '№',
			key: 'number',
			dataIndex: 'number',
			sorter: true,
			fixed: 'left',
			width: 80,
			align: 'center'
		},
		{
			key: 'status',
			dataIndex: 'status',
			fixed: 'left',
			title: 'Статус',
			width: 120,
			render: (_, record) =>
				(record.status || record.status === 0) && (
					<Row gutter={8} wrap={false} align="middle">
						<Col>
							<StatusIndicator status={initialStatuses[record.status]!} />
						</Col>
						<Col>{initialStatuses[record.status]}</Col>
					</Row>
				)
		},
		{
			title: 'Шифр',
			key: 'cipher',
			sorter: true,
			fixed: 'left',
			align: 'center',
			width: 100,
			render: (_, record) => record!?.checkListCipher
		},
		{
			key: 'title',
			dataIndex: 'title',
			title: 'Название',
			fixed: 'left',
			width: 200,
			sorter: true,
			render: (_, record) => record.title
		},
		{
			key: 'location',
			dataIndex: 'location',
			title: 'Локация',
			fixed: 'left',
			width: 200,
			render: (_, record) => record.location
		},
		{
			key: 'owner',
			dataIndex: 'owner',
			title: 'Владелец',
			fixed: 'left',
			width: 200,
			render: (_, record) => (record.owner === null ? 'Не указан' : record.owner?.name)
		},
		{
			key: 'issues',
			title: 'Замечания',
			align: 'center',
			width: 80,
			showSorterTooltip: false,
			render: (_, record) =>
				record?.issuesOpen! + record?.issuesClosed! > 0 && (
					<Tooltip
						title={
							<Flex vertical>
								<span>Открытых замечаний: {record?.issuesOpen}</span>
								<span>Закрытых замечаний: {record?.issuesClosed}</span>
							</Flex>
						}
					>
						<Flex justify="center" gap={6}>
							{record?.issuesOpen! > 0 && <Badge count={record?.issuesOpen} color="#feb300" />}
							{record?.issuesClosed! > 0 && <Badge count={record?.issuesClosed} color="#8099A8" />}
						</Flex>
					</Tooltip>
				)
		},
		{
			key: 'createdAt',
			title: 'Дата создания',
			sorter: true,
			width: 100,
			render: (_, record) =>
				record.level === 3 &&
				(record.createdAt ? dayjs(record.createdAt).format('DD.MM.YY HH:mm') : '-')
		},
		{
			key: 'assignedTo',
			dataIndex: 'assignedTo',
			title: 'Ответственный',
			ellipsis: true,
			width: 256,
			render: (_, record) =>
				record.level === 3 &&
				(record.assignedTo === null ? (
					'Не указан'
				) : record?.assignedTo?.length! > 1 ? (
					<Select
						popupClassName="app-selector-no-flow"
						showSearch={false}
						value={record
							?.assignedTo!.sort(
								(a, b) =>
									+b.isDeleted - +a.isDeleted ||
									+b.isCheckListsProcessing - +a.isCheckListsProcessing ||
									+(b.id === currentUser?.id) - +(a.id === currentUser?.id) ||
									a?.title?.localeCompare(b?.title)
							)
							.map(x => x.id)}
						tagRender={props => (
							<Tooltip
								placement="right"
								zIndex={999999}
								title={
									(record?.assignedTo!.find(x => x.id === props.value)?.isDeleted ||
										record?.assignedTo!.find(x => x.id === props.value)?.isCheckListsProcessing ===
											false) &&
									'Пользователь был удален. Необходимо переназначить Ответственного'
								}
							>
								<Tag
									color={
										record?.assignedTo!.find(x => x.id === props.value)?.isDeleted ||
										record?.assignedTo!.find(x => x.id === props.value)?.isCheckListsProcessing ===
											false
											? 'red'
											: 'default'
									}
									style={{ fontSize: 14 }}
									bordered={false}
									title={
										record?.assignedTo!.find(x => x.id === props.value)?.isDeleted ||
										record?.assignedTo!.find(x => x.id === props.value)?.isCheckListsProcessing ===
											false
											? undefined
											: `${props.label}`
									}
								>
									{props.label &&
										props.label?.toString().slice(0, 20) +
											(props?.label?.toString()?.length > 20 ? '...' : '')}
								</Tag>
							</Tooltip>
						)}
						maxTagCount={1}
						mode="multiple"
						style={{ width: '100%' }}
						options={record?.assignedTo!.map(x => ({
							key: x.id,
							value: x.id,
							label:
								x.isDeleted || x.isCheckListsProcessing === false
									? 'Пользователь был удален'
									: `${x.title}`
						}))}
					/>
				) : (
					record?.assignedTo![0] && (
						<Tooltip
							placement="right"
							title={
								(record?.assignedTo[0].isDeleted ||
									record?.assignedTo![0].isCheckListsProcessing === false) &&
								'Пользователь был удален. Необходимо переназначить Ответственного'
							}
						>
							<Tag
								color={
									record?.assignedTo[0].isDeleted ||
									record?.assignedTo![0].isCheckListsProcessing === false
										? 'red'
										: 'default'
								}
								style={{ fontSize: 14, width: '100%' }}
								bordered={false}
								title={
									record?.assignedTo[0].isDeleted ||
									record?.assignedTo![0].isCheckListsProcessing === false
										? undefined
										: record.assignedTo[0].title
								}
							>
								{record?.assignedTo[0].isDeleted ||
								record?.assignedTo![0].isCheckListsProcessing === false
									? 'Пользователь был удален'
									: record.assignedTo[0].title.slice(0, 20) +
									  (record.assignedTo[0].title.length > 20 ? '...' : '')}{' '}
							</Tag>
						</Tooltip>
					)
				))
		},
		{
			key: 'plannedDate',
			dataIndex: 'plannedDate',
			sorter: true,
			title: 'Плановая дата проверки',
			width: 128,
			align: 'center',
			showSorterTooltip: false,
			render: (_, record) =>
				record.level === 3 && (
					<div
						style={
							dayjs(record!?.plannedDate!).isBefore(dayjs())
								? { color: '#ff0000', fontWeight: 'bold' }
								: { color: '#444' }
						}
					>
						{record?.plannedDate !== null && dayjs(record?.plannedDate).isValid()
							? record.templateType === 0
								? dayjs(record?.plannedDate).format('DD.MM.YYYY HH:mm')
								: dayjs(record?.plannedDate).format('DD.MM.YYYY')
							: '-'}
					</div>
				)
		},
		{
			key: 'factDate',
			title: 'Фактическая дата проверки',
			sorter: true,
			width: 100,
			align: 'center',
			showSorterTooltip: false,
			render: (_, record) =>
				record.level === 3 && (record.factDate ? dayjs(record.factDate).format('DD.MM.YYYY') : '-')
		},
		{
			title: 'Объем',
			fixed: 'right',
			align: 'center',
			width: 120,
			render: (_, record) => record.level === 3 && record!?.capacity
		},
		{
			key: 'type',
			dataIndex: 'type',
			title: 'Тип',
			width: 80,
			render: (_, record) =>
				record.level === 3 && (
					<Row>
						<Col>{record.templateType === 0 ? 'УКС НДК' : 'УК КС'}</Col>
					</Row>
				)
		},
		{
			title: 'М',
			fixed: 'right',
			align: 'center',
			width: 70,
			render: (_, record) =>
				record.level === 3 &&
				record!?.isMobile && (
					<Tooltip title="Создано в мобильном устройстве">
						<Tag>mobile</Tag>
					</Tooltip>
				)
		},
		{
			width: checkPermissions([PERMISSIONS.CheckListDeleted]) ? 80 : 40,
			align: 'center',
			render: (_, record) =>
				record.level === 3 && (
					<Row justify="center" gutter={8} wrap={false}>
						{(currentUser?.isAdmin || record.owner?.id === currentUser?.id) && (
							<Col>
								<ChecklistCopyButton
									id={record.id}
									onCopy={() => handleCopy(record.checkListTemplate?.id, record?.id)}
								/>
							</Col>
						)}
						{checkPermissions([PERMISSIONS.CheckListDeleted]) && (
							<Col>
								<Popconfirm
									title="Удалить?"
									placement="topRight"
									onConfirm={async () =>
										await deleteChecklist(record!?.id!)
											.then(async () => {
												message.success(`Чек-лист №${record?.number!} успешно удалён`)
												queryClient.invalidateQueries(['checkListList'])
												queryClient.invalidateQueries(['checklists'])
											})
											.catch((error: AxiosError) => {
												const data = error!?.response!?.data! as IErrorDetail
												message.error(data.detail)
											})
									}
									okButtonProps={{ danger: true }}
									okText="Да"
									cancelText="Отмена"
								>
									<Button icon={<DeleteOutlined />} danger />
								</Popconfirm>
							</Col>
						)}
					</Row>
				),
			fixed: 'right'
		}
	]

	const handleRowClick = (e: IData) => {
		dispatch(setCurrentChecklistId(e.id))
		navigate(e.id)
	}

	const pagination = (page: number, pageSize: number) => {
		dispatch(setCurrentPage(page))
	}

	const initColumns: IColumns[] = checklistsColumns.map((item, index) => ({
		key: index.toString()!,
		title: item.title?.toString()!,
		fixed: typeof item.fixed !== 'undefined'
	}))

	const onColumnsConfig = (cols: IColumns[]) => {
		setShowColumns([...initColumns.filter(col => col.fixed === true), ...cols])
	}

	const mergeColumns: any = checklistsColumns.filter(
		col => showColumns.find(i => i.title === col.title) !== undefined
	)

	const onSearch = (searchValue: string) => {
		const filters = { ...checklistFilters, search: searchValue! }
		dispatch(setCurrentChecklistFilters({ filters }))
		dispatch(setCurrentPage(1))
	}

	const onCloseChecklistsDrawer = () => {
		if (isCreatedChecklist) {
			dispatch(setIsCreatedChecklist(false))
		}
		dispatch(setCurrentChecklistId(''))
		dispatch(setCurrentChecklist(null))
		setDrawerVisible(false)
	}

	useEffect(() => {
		// Права на просмотр чек-листов
		if (isAdmin) {
			setViewerStatus(null)
		} else {
			setViewerStatus(checkViewPermissions('checklist'))
		}
	}, [])

	useEffect(() => {
		// Права на создание чек-листов
		if (isAdmin) setCreatorStatus(2)
		if (
			checkPermissions([PERMISSIONS.ChecklistsCreateUksNdc]) &&
			checkPermissions([PERMISSIONS.ChecklistsCreateUkKc])
		) {
			setCreatorStatus(2)
		} else if (
			!checkPermissions([PERMISSIONS.ChecklistsCreateUksNdc, PERMISSIONS.ChecklistsCreateUkKc])
		) {
			creatorStatus !== null && setCreatorStatus(null)
		} else if (
			checkPermissions([PERMISSIONS.ChecklistsCreateUksNdc]) &&
			!checkPermissions([PERMISSIONS.ChecklistsCreateUkKc])
		) {
			setCreatorStatus(0)
		} else setCreatorStatus(1)
	}, [])

	useEffect(() => {
		if (viewerStatus !== null && viewerStatus !== 2) {
			dispatch(
				setCurrentChecklistFilters({ filters: { ...checklistFilters, templateType: viewerStatus } })
			)
		}
	}, [viewerStatus])

	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Чек-листы' }))
		if (parsedConf === null || parsedConf!?.length === 0) {
			setShowColumns(initColumns)
			localStorage.setItem(
				'checklistPageColumns',
				JSON.stringify(
					initColumns.filter(col => !col.fixed && !col.title.toString().includes('object'))
				)
			)
		} else {
			const cols = [...initColumns.filter(col => col.fixed === true), ...JSON.parse(conf!)]
			setShowColumns(cols)
		}
	}, [])

	const onSetFilters = (filters: IChecklistFilters, show: boolean) => {
		if (filters) {
			dispatch(
				setCurrentChecklistFilters({
					filters: {
						...checklistFilters,
						assignedTo: filters?.assignedTo,
						checkIssue: filters?.checkIssue,
						createdAt: filters?.createdAt,
						factDate: filters?.factDate,
						ownerId: filters?.ownerId,
						plannedDate: filters?.plannedDate,
						status: filters?.status,
						templateType: filters?.templateType,
						isMobile: filters?.isMobile,
						workPackageId: filters?.workPackageId
					}
				})
			)
			if (show) {
				setOpenFilters(false)
			}
		}
		dispatch(setCurrentPage(1))
	}

	return (
		<div className="checklists__block">
			<ChecklistFilters
				open={openFilters}
				parentPage={'checklists'}
				onClose={() => setOpenFilters(false)}
				onFilters={onSetFilters}
				viewerStatus={viewerStatus}
			/>

			<CreateChecklistModal
				modalVisible={modalVisible}
				setDrawerVisible={(visible: boolean) => {
					dispatch(setIsCreatedChecklist(true))
					setDrawerVisible(visible)
				}}
				setModalVisible={x => setModalVisible(x)}
			/>

			<ChecklistsDrawer open={drawerVisible} onClose={onCloseChecklistsDrawer} />

			<Row justify="space-between" style={{ marginBottom: '1rem' }}>
				<Col>
					<Row gutter={8}>
						<Col>
							<ColumnConfig
								onChange={onColumnsConfig}
								columns={initColumns}
								localstore={'checklistPageColumns'}
							/>
						</Col>
						<Col>
							<Tooltip title="Фильтры">
								<Button
									type={
										Object.entries(checklistFilters).some(
											x => x[0] !== 'search' && typeof x[1] !== 'undefined'
										)
											? 'primary'
											: 'default'
									}
									size="middle"
									onClick={() => setOpenFilters(true)}
									icon={<FilterOutlined rev={undefined} />}
								/>
							</Tooltip>
						</Col>
						<Col>
							<Search
								allowClear
								placeholder="Поиск..."
								defaultValue={checklistFilters.search}
								onSearch={value => onSearch(value)}
								style={{ width: 300 }}
							/>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row gutter={8}>
						<Col>
							<Button
								type="primary"
								size="middle"
								onClick={() => setModalVisible(true)}
								disabled={creatorStatus === null}
							>
								Создать чек-лист
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
			{checkPermissions([PERMISSIONS.ChecklistsUkKc, PERMISSIONS.ChecklistsUksNdc]) && (
				<Table
					loading={{ indicator: <LoadingOutlined spin />, spinning: isFetching }}
					bordered
					size="small"
					rowClassName={row => {
						return 'level' in row && row.level === 1
							? 'app-checkList-table__row_1_level'
							: 'app-checkList-table__row'
					}}
					rowKey={row => row.key!}
					columns={mergeColumns}
					scroll={{ y: '74vh', x: '10%' }}
					dataSource={checklists}
					onChange={(_, __, FilterValue) => {
						const columnName: any = {
							createdAt: 0,
							title: 2,
							number: 14,
							name: 6,
							cipher: 8,
							plannedDate: 10,
							factDate: 12
						}

						const columnKey = (FilterValue as any).columnKey
						const order = sortVariation((FilterValue as any).order)
						const sortOrder =
							columnName.hasOwnProperty(columnKey) && order
								? columnName[columnKey] + (order === 'ASC' ? 0 : 1)
								: undefined
						dispatch(setCurrentChecklistSort(sortOrder))
					}}
					pagination={{
						current: checklistsChecklistState.currentPage,
						defaultPageSize: 50,
						total: checklistsChecklistState.totalItems,
						hideOnSinglePage: false,
						showSizeChanger: false,
						onChange: pagination
					}}
					expandable={{
						defaultExpandedRowKeys: expandedRowKeys,
						expandedRowKeys,
						onExpand: onRowExpand,
						expandRowByClick: true,
						rowExpandable: record => record?.children?.length! > 0
					}}
					onRow={record => ({
						onDoubleClick: e => {
							if (record.level === 3) {
								e.stopPropagation()
								handleRowClick(record)
							}
						}
					})}
				/>
			)}
		</div>
	)
}

const sortVariation = (order: string) => {
	switch (order) {
		case 'ascend':
			return 'ASC'
		case 'descend':
			return 'DESC'
		default:
			return undefined
	}
}
export default Checklists
