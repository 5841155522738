import { CopyOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'

export const ChecklistCopyButton = ({
	id,
	onCopy
}: {
	id: string
	onCopy: (id: string) => void
}) => {
	return (
		<Tooltip title="Копировать">
			<Button icon={<CopyOutlined />} onClick={() => onCopy(id)} />
		</Tooltip>
	)
}
