import { Row, Col, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { IAppUserProjects } from 'interfaces/IUser'

export const UserRightsColumns: ColumnsType<IAppUserProjects> = [
	{
		title: 'Проект',
		dataIndex: 'project',
		width: '30%',
		render: (_, record) => record.name
	},
	{
		title: 'Роли',
		render: (_, record) => (
			<Row gutter={[8, 8]} align="middle">
				{record!?.roles!?.map(role => (
					<Col key={role.id}>
						<Tag color="blue">{role.name}</Tag>
					</Col>
				))}
			</Row>
		)
	}
]
