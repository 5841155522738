import { useQuery } from '@tanstack/react-query'
import modal from 'antd/es/modal'
import { EstimatePositionApi } from '../api/estimate-position-api'
import { useEstimatePositionState } from './estimate-position-state'

export const useEstimatePositionTechnologies = () => {
	const { section, estimatePositionId } = useEstimatePositionState()
	return useQuery({
		queryKey: ['asmTechnologies', section?.sourceId],
		queryFn: async () => {
			if (estimatePositionId === null && section?.sourceId && section?.sourceId !== null) {
				const data = await EstimatePositionApi.getTechnologies(section.sourceId)
				return data.filter(d => !d.isDeleted)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useEstimatePositionTechnology = () => {
	const {
		section,
		technologyId,
		estimatePositionId,
		setWorkData,
		setWorkProperties,
		setEditableWorks,
		validateDto,
		unsetCard
	} = useEstimatePositionState()
	return useQuery({
		queryKey: ['asmTechnology', technologyId],
		queryFn: async () => {
			if (technologyId && section?.id) {
				await EstimatePositionApi.getSectionPropertyFilters(section.id).then(propertyFilters =>
					useEstimatePositionState.setState({ propertyFilters })
				)
				const data = await EstimatePositionApi.getTechnology(technologyId)
				useEstimatePositionState.setState({ currentTechnology: data })
				if (estimatePositionId === null) {
					setWorkData(data)
					setWorkProperties(data)
				} else {
					if (!validateDto()) setEditableWorks()
					else {
						unsetCard()
						modal.error({
							title: 'Работа была отвязана от технологии.',
							content:
								/* 'Необходимо обратиться к ответственному по НСИ, чтобы была сделана привязка' */ 'С этой позицией сметы работать уже нельзя, необходимо создать новую позицию с нужной работой'
						})
					}
				}

				return data
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useWbsHistory = () => {
	const { estimatePositionId } = useEstimatePositionState()
	return useQuery({
		queryKey: ['wbsHistory', estimatePositionId],
		queryFn: async () => {
			if (estimatePositionId) {
				return await EstimatePositionApi.getHistory(estimatePositionId).then(res => res.data)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}
