import { UploadOutlined } from '@ant-design/icons'
import { Divider, Form, Input, Modal, Upload, UploadFile, UploadProps, message } from 'antd'
import { RcFile } from 'antd/es/upload'
import { FC, useState } from 'react'
import { getBase64 } from 'services/helperService'
import { ICancelOrdinanceFine } from 'widgets/fine/model/fine-interface'
import { useFineState } from '../model/fine-card-state'

interface IProps {
	onSave: (values: any) => void
	onCancel: () => void
}

export const FineCardCancelationModal: FC<IProps> = props => {
	const show = useFineState().cancelationModalView
	const [fineCardCancelationForm] = Form.useForm<ICancelOrdinanceFine>()
	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [previewTitle, setPreviewTitle] = useState('')

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile)
		}
		setPreviewImage(file.url || (file.preview as string))
		setPreviewOpen(true)
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
	}

	const uploadProps: UploadProps = {
		multiple: true,
		onPreview: handlePreview,
		fileList: fineCardCancelationForm.getFieldValue('files'),
		listType: 'picture-card',
		beforeUpload: file => {
			const canUpload =
				!file.name.includes('jfif') &&
				(file.type === 'image/jpeg' ||
					file.type === 'image/jpg' ||
					file.type === 'image/png' ||
					file.type === 'application/pdf')
			if (!canUpload) {
				message.error({
					content: `${file.name} - тип файла не поддерживается`,
					style: { zIndex: 9999 }
				})
			}
			return canUpload ? false : Upload.LIST_IGNORE
		}
	}
	const normFile = (e: any) => {
		if (Array.isArray(e)) {
			return e
		}
		return e?.fileList
	}

	const onFinish = (values: any) => {
		props.onSave(values)
		fineCardCancelationForm.resetFields()
	}

	return (
		<Form name="fineCardCancelationForm" form={fineCardCancelationForm} onFinish={onFinish}>
			<Modal
				title="Добавить комментарий"
				open={show}
				okText="Сохранить"
				onOk={() => fineCardCancelationForm.submit()}
				onCancel={props.onCancel}
			>
				<Form.Item
					name="cancelationReason"
					rules={[
						{
							required: true,
							message: 'Укажите комментарий'
						}
					]}
				>
					<Input.TextArea
						placeholder="Ваш комментарий"
						showCount
						maxLength={250}
						style={{ height: '120px', resize: 'none' }}
					/>
				</Form.Item>
				<Divider />
				<Form.Item name="files" valuePropName="fileList" getValueFromEvent={normFile}>
					<Upload {...uploadProps}>
						<UploadButton />
					</Upload>
				</Form.Item>
				<Modal
					open={previewOpen}
					title={previewTitle}
					footer={null}
					onCancel={() => setPreviewOpen(false)}
				>
					<img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
				</Modal>
			</Modal>
		</Form>
	)
}

const UploadButton = () => {
	return (
		<div>
			<UploadOutlined rev={undefined} />
			<div style={{ marginTop: 8 }}>Добавить</div>
		</div>
	)
}
