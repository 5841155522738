import { Button, Col, Row } from 'antd'
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip
} from 'chart.js'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useOrdinanceAnalytics } from 'hooks/useOrdinanceAnalytics'
import { FC } from 'react'
import { Bar } from 'react-chartjs-2'
import { useNavigate } from 'react-router-dom'
import { resetOrdinancesState, setFilterOrdinance } from 'store/ordinanceSlice'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface IProps {}

enum filterSettings {
	FixOrdinancesLess30 = 'Устраненные с задержкой\n менее 30 дней',
	FixOrdinancesMore30 = 'Устраненные с задержкой\n более 30 дней',
	NoFixrdinancesLess30 = 'Неустраненные\n менее 30 дней',
	NoFixrdinancesMore30 = 'Неустраненные\n более 30 дней'
}

export const OrdinanceAnalyticsWidget: FC<IProps> = props => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { diagramsContractor } = useAppSelector(state => state.environment)
	const { data: analytics } = useOrdinanceAnalytics()
	const values = Object.keys(filterSettings).map((_, index) =>
		analytics ? Object.values(analytics)[index] : 0
	)
	const labels = Object.values(filterSettings).map(v => v)
	const backgroundColor = ['#80bae4', '#5783a0', '#335d79', '#1e4964']
	const config = {
		type: 'bar',
		data: {
			labels,
			datasets: [
				{
					data: values,
					backgroundColor: backgroundColor,
					datalabels: {
						color: '#fff',
						formatter: (value: any) => (value > 0 ? value : '')
					}
				}
			]
		},
		width: '100%',
		height: '30%',
		options: {
			responsive: true,
			plugins: {
				legend: {
					display: false
				}
			},
			interaction: {
				mode: 'index' as const,
				intersect: false
			},
			scales: {
				y: {
					ticks: {
						beginAtZero: true,
						color: '#444',
						fontSize: 12,
						stepSize: 10
					},
					suggestedMin: 5
				},
				x: {
					ticks: {
						beginAtZero: true,
						color: '#444',
						fontSize: 10,
						autoSkip: false,
						maxRotation: 0,
						callback: (value: any, index: any, values: any) => {
							const words = labels[value].split(' ')
							let aux = []
							let concat = []

							for (let i = 0; i < words.length; i++) {
								concat.push(words[i])
								let join = concat.join(' ')
								if (join.length > 10) {
									aux.push(join)
									concat = []
								}
							}

							if (concat.length) {
								aux.push(concat.join(' ').trim())
							}

							return aux
						}
					}
				}
			}
		}
	}

	const goTo = () => {
		dispatch(resetOrdinancesState())
		dispatch(
			setFilterOrdinance({
				filters: { contractorId: diagramsContractor !== 'all' ? diagramsContractor : undefined }
			})
		)
		navigate('/control/ordinance')
	}

	return (
		<Row
			wrap={false}
			gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: '#f2f2f2'
			}}
		>
			<Col span={24} style={{ padding: '1rem', background: '#fefefe' }}>
				{analytics?.TotalOrdinances && analytics.TotalOrdinances > 0 ? (
					<Row align="middle" gutter={8}>
						<Col>
							<h2 style={{ margin: 0 }}>Предписаний:</h2>
						</Col>
						<Col>
							<Button
								style={{ height: 'auto', fontSize: '2rem', fontWeight: 'bold', color: '#1677ff' }}
								type="link"
								onClick={goTo}
							>
								{analytics?.TotalOrdinances}
							</Button>
						</Col>
					</Row>
				) : (
					<h2>Предписания</h2>
				)}
				<Bar {...config} />
			</Col>
		</Row>
	)
}
