import { Button, Card, Col, Divider, Popover, Row, message } from 'antd'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import IssueDrawer from 'pages/issuesPage/issueDrawer'
import { FC, useEffect, useState } from 'react'
import { setPageTitle } from 'store/rootSlice'
import Legend from './components/Legend'
import MKDTable from './components/mkd/MKDTable'
import CreateLightReportModal from './modals/CreateLightReportModal'
import TrafficLightTable from './components/TrafficLightTable'
import { trafficLightService } from 'services/LightReportService'
import LoaderSpinner from 'UI/loaderSpinner'
import { DatabaseOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { TrafficLightDownload } from './components/trafficLightDownload'
import { setProjectsState, setTrafficLight } from 'store/trafficLightSlice'

const LightReport: FC = () => {
	const dispatch = useAppDispatch()
	const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
	const [issueModalVisible, setIssueModalVisible] = useState<boolean>(false)
	const [currentIssue, setCurrentIssue] = useState<string>('')
	const trafficLight = useAppSelector(state => state.trafficLight)
	const projects = useAppSelector(state => state.environment.projectsList)
	const currentProject = useAppSelector(state => state.environment.project)
	const [data, setData] = useState(true)
	const [isLoading, setIsLoading] = useState(false)

	const onOpenIssue = (issueId: string) => {
		setCurrentIssue(issueId)
		setIssueModalVisible(true)
	}

	const updateReport = async () => {
		if (!!trafficLight.report.length && !!trafficLight.selectedProjects.length) {
			setIsLoading(true)
			await trafficLightService
				.getReport(trafficLight.selectedProjects)
				.then(data => {
					dispatch(setTrafficLight(data))
					message.success('Отчет успешно обновлен')
				})
				.catch(() => {
					message.error('При обновлении данных произошла ошибка. Попробуйте обновить еще раз.')
				})
				.finally(() => {
					setIsLoading(false)
				})
		} else {
			dispatch(setProjectsState([currentProject.id]))
			await trafficLightService
				.getReport([currentProject.id])
				.then(data => {
					dispatch(setTrafficLight(data))
				})
				.finally(() => {
					setIsLoading(false)
				})
		}
	}

	useEffect(() => {
		if (data) {
			updateReport()
			setData(false)
		}
	}, [])

	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Отчёты', subTitle: 'Светофор' }))
	}, [])
	return (
		<>
			{isLoading && <LoaderSpinner />}
			<Card>
				<CreateLightReportModal open={showCreateModal} close={() => setShowCreateModal(false)} />
				<Row justify="space-between">
					<Col>
						<Row gutter={8}>
							<Col>
								<Button
									icon={<DatabaseOutlined />}
									type="primary"
									onClick={() => setShowCreateModal(true)}
								>
									Создать отчет
								</Button>
							</Col>
							<Col>
								<TrafficLightDownload />
							</Col>
						</Row>
					</Col>
					<Col>
						<Popover content={<Legend />} placement="bottom" trigger="click">
							<Button icon={<InfoCircleOutlined />}>Легенда</Button>
						</Popover>
					</Col>
				</Row>
				<IssueDrawer
					drawerOpen={issueModalVisible}
					currentIssue={currentIssue}
					onDrawerClose={() => {
						setCurrentIssue('')
						setIssueModalVisible(false)
					}}
				/>
				<>
					{trafficLight.report && (
						<div style={{ marginTop: 24 }}>
							<TrafficLightTable data={trafficLight.report} />
						</div>
					)}
					<Divider />
					{trafficLight.issues && (
						<div>
							<MKDTable issues={trafficLight.issues} onOpenIssue={onOpenIssue} />
						</div>
					)}
				</>
			</Card>
		</>
	)
}
export default LightReport
