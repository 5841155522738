import { DatabaseOutlined } from '@ant-design/icons'
import { Button, Col, Empty, Row, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useReportNotification } from 'hooks/useReportNotification'
import { IReportNotifications } from 'interfaces/INotification'
import { FC, useState } from 'react'
import { setCurrentPage } from 'store/reportNotificationSlice'
import ReportNotificationsModal from './ReportNotificationsModal'

interface IProps {
	message: (m: string, type: string) => void
	loader: (x: boolean) => void
}

const ReportNotifications: FC<IProps> = ({ message, loader }) => {
	const [showReportNotificationsFilters, setShowReportNotificationsFilters] =
		useState<boolean>(false)
	const { data: reportNotifications, isFetching } = useReportNotification()
	const filtersReportNotification = useAppSelector(state => state.reportNotification.filters)
	const dispatch = useAppDispatch()
	const reportNotificationListState = useAppSelector(state => state.reportNotification.listState)
	const reportNotificationsColumns: ColumnsType<IReportNotifications> = [
		{
			dataIndex: 'notificationType',
			key: 'notificationType',
			width: '30%',
			align: 'center',
			title: 'Тип уведомления',
			render: (_, record) => record.notificationType
		},
		{
			dataIndex: 'number',
			key: 'number',
			width: '20%',
			align: 'center',
			title: 'Номер объекта',
			render: (_, record) => record.number
		},
		{
			dataIndex: 'objectName',
			key: 'objectName',
			width: '30%',
			align: 'center',
			title: 'Объект',
			render: (_, record) => record.objectName
		},
		{
			dataIndex: 'user',
			key: 'user',
			width: '30%',
			align: 'center',
			title: 'Получатель',
			render: (_, record) => record.user
		},
		{
			dataIndex: 'email',
			key: 'email',
			width: '30%',
			align: 'center',
			title: 'E-mail',
			render: (_, record) => record.email
		},
		{
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: '30%',
			align: 'center',
			title: 'Дата уведомления',
			render: (_, record) => dayjs(record.createdAt).format('YYYY-MM-DD HH:mm')
		},
		{
			dataIndex: 'object',
			key: 'object',
			width: '30%',
			align: 'center',
			title: 'Документ',
			render: (_, record) => record.object
		},
		{
			dataIndex: 'project',
			key: 'project',
			width: '30%',
			align: 'center',
			title: 'Проект',
			render: (_, record) => record.project
		},
		{
			dataIndex: 'contractorName',
			key: 'contractorName',
			width: '30%',
			align: 'center',
			title: 'Генподрядчик',
			render: (_, record) => record.contractor
		},
		{
			dataIndex: 'contactPerson',
			key: 'contactPerson',
			width: '30%',
			align: 'center',
			title: 'Контактное лицо',
			render: (_, record) => record.contactPerson
		}
	]
	const pagination = (page: number) => {
		dispatch(setCurrentPage(page))
	}
	return (
		<>
			<ReportNotificationsModal
				show={showReportNotificationsFilters}
				onClose={() => setShowReportNotificationsFilters(false)}
			/>
			<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
				<Col>
					<Row gutter={8} justify="start" style={{ marginBottom: '1rem' }}>
						<Button
							type={'default'}
							size="middle"
							onClick={() => {
								setShowReportNotificationsFilters(true)
							}}
							icon={<DatabaseOutlined />}
						>
							Создать отчёт
						</Button>
					</Row>
				</Col>
				{Object.keys(filtersReportNotification).length !== 0 ? (
					<Table
						loading={isFetching}
						rowKey={(record, index) => index!}
						size="small"
						bordered
						dataSource={reportNotifications?.data}
						columns={reportNotificationsColumns}
						pagination={{
							current: reportNotificationListState?.currentPage,
							defaultPageSize: 20,
							total: reportNotificationListState?.totalItems,
							hideOnSinglePage: true,
							showSizeChanger: false,
							onChange: pagination
						}}
						scroll={{ y: '60vh', x: '100%' }}
					/>
				) : (
					<Empty />
				)}
			</div>
		</>
	)
}

export default ReportNotifications
