import { useQuery } from '@tanstack/react-query'
import { violationsService } from 'services/violationsService'

export const useViolations = (violationTypeId?: string) => {
	return useQuery({
		queryKey: ['violations', violationTypeId],
		queryFn: async () => {
			return await violationsService.getList(violationTypeId).then(data => data)
		},
		refetchOnWindowFocus: false
	})
}
