import { Select } from 'antd'
import { IEsimatePositionProperty } from 'interfaces/IEstimatePosition'
import { FC, useEffect, useState } from 'react'
import { mainSelectProps } from 'shared/helpers'
import {
	EstimatePositionApi,
	IAdditionalProperty
} from 'widgets/estimate-position-card/api/estimate-position-api'

interface IListPropertyProps {
	property: IEsimatePositionProperty
	callBack: (value: string) => void
	disabled: boolean
}

export const EstimatePositionPropertiesGuid: FC<IListPropertyProps> = ({
	property,
	callBack,
	disabled,
	...rest
}) => {
	const [list, setList] = useState<IAdditionalProperty[]>([])
	const getList = async (id: string) => {
		const data = await EstimatePositionApi.getAdditionalProperties(id)
		if (data) {
			setList(data)
		}
	}

	useEffect(() => {
		getList(property.property.id)
	}, [property])

	return (
		<Select
			{...rest}
			{...mainSelectProps}
			allowClear
			disabled={disabled}
			onChange={value => callBack(value)}
			options={list
				?.filter(i =>
					list.some(x => x.id === property.value) && i.id === property.value
						? i
						: i.isDeleted === false
				)
				.map(item => ({
					key: item.id,
					value: item.id,
					label: item.isDeleted ? `${item.name} (удалён)` : item.name,
					disabled: item.isDeleted
				}))}
		/>
	)
}
