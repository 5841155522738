import { message } from 'antd'
import { IEsimatePositionProperty } from 'interfaces/IEstimatePosition'
import { all, create } from 'mathjs'
import { uniqueSet } from 'services/helperService'
import { ROUND_VALUE } from 'shared/constants'
import { useEstimatePositionState } from './estimate-position-state'
const config = {}
const math = create(all, config)

export const EstimatePositionHelpers = {
	calculate(amount: number | null, formula?: string, rate: number = 1) {
		if (formula) {
			const properties = useEstimatePositionState.getState().workProperties
			let computing = formula
			let firstValue: any
			const secondValue = ROUND_VALUE
			let thirdValue: any = ''
			if (computing) {
				if (!!properties?.length) {
					properties.forEach(x => {
						const identityName = x.identityName ? x.identityName : ''
						const propertyValue = x.value ? x.value : 0
						while (computing?.includes(`[${identityName}]`)) {
							computing = computing?.replace(`[${identityName}]`, propertyValue.toString())
						}
						const newComputing = computing?.includes('Окр')
						if (newComputing) {
							firstValue = computing?.split(',')[0].slice(4)
						} else {
							firstValue = computing
						}
						// if (newComputing) {
						// 	firstValue = computing?.split(',')[0].slice(4)
						// 	secondValue = computing?.split(',')[1]
						// 	thirdValue = computing?.split(',')[2].slice(-11, -1)
						// } else {
						// 	firstValue = computing
						// }
					})
					try {
						let result
						if (thirdValue === 'Окр15как20') {
							const mathEval: number = math.evaluate(firstValue)
							result = math.round(mathEval, secondValue)
						} else if (thirdValue === 'Окр15как10') {
							const mathEval: number = math.evaluate(firstValue)
							result = math.floor(mathEval, secondValue)
						} else {
							const regex = /^[^\\.]+?(?=\.0*$)|^[^\\.]+?\..*?(?=0*$)|^[^.]*$/g
							result = math.evaluate(firstValue).toFixed(5).match(regex)
						}
						return math.round(result! * rate, ROUND_VALUE)
					} catch (error) {
						return 0
					}
				} else {
					return 0
				}
			} else {
				return 0
			}
		}
		return math.round(amount! * rate, ROUND_VALUE)
	},
	validateProperty(propValue: string | number, identityName: string, valueType: string) {
		const propertyFilters = useEstimatePositionState.getState().propertyFilters
		let comparison = {
			status: true,
			message: ''
		}

		if (
			propertyFilters?.length !== 0 &&
			propertyFilters?.some(filter => filter.key === identityName)
		) {
			const evaluate = propertyFilters.map(filter => {
				if (!isNaN(parseFloat(filter.value))) {
					return math.evaluate(
						propValue +
							(filter.comparisonType === '=' ? '==' : filter.comparisonType) +
							filter.value
					)
				} else if (Array.isArray(filter.value)) {
					return propValue.toString().includes(filter.value)
				} else {
					switch (filter.comparisonType) {
						case 'STARTWITH':
							return propValue.toString().startsWith(filter.value.trim())
						case 'CONTAINS':
							return propValue.toString().includes(filter.value)
						case 'NOT CONTAINS':
							return !propValue.toString().includes(filter.value)
						case 'LIKE':
							return propValue.toString().includes(filter.value)
						case 'NOT LIKE':
							return !propValue.toString().includes(filter.value)
						case '=':
							return propValue.toString() === filter.value
						case '!=':
							return propValue.toString() !== filter.value
						default:
							return true
					}
				}
			})

			comparison.status =
				propertyFilters.length > 1
					? evaluate.every((el: boolean) => el === evaluate[0])
					: evaluate[0]

			const messageText = (text: string) => {
				switch (text) {
					case '!=':
						return 'должен быть не равен'
					case '>=':
						return 'должен быть больше или равен'
					case '<=':
						return 'должен быть меньше или равен'
					case '>':
						return 'должен быть больше'
					case '<':
						return 'должен быть меньше'
					case 'STARTWITH':
						return 'должен начинаться с'
					case 'CONTAINS':
						return 'должен содержать'
					case 'NOT CONTAINS':
						return 'не должен содержать'
					case 'LIKE':
						return 'должен быть подобен'
					case 'NOT LIKE':
						return 'не должен быть подобен'
					default:
						return 'должен быть равен'
				}
			}
			if (!comparison.status) {
				if (evaluate.length > 1) {
					comparison.message = `Параметр "${identityName}" должен быть в диапазоне от ${
						propertyFilters.find(prop => prop.comparisonType === '>=')?.value
					} до ${propertyFilters.find(prop => prop.comparisonType === '<=')?.value}`
				} else {
					comparison.message = `Параметр "${identityName}" ${messageText(
						propertyFilters[0].comparisonType
					)} "${propertyFilters[0].value}"`
				}
			}
		}

		switch (valueType) {
			case 'System.Decimal':
				if (isNaN(parseFloat(propValue as string)))
					comparison = {
						status: false,
						message: 'Допускается только числовое значение'
					}
				break
			case 'System.String':
				if (typeof propValue !== 'string')
					comparison = {
						status: false,
						message: 'Допускается только строковое значение'
					}
				break
		}

		return comparison
	},
	getBaseQualityMeasureUnit() {
		const technology = useEstimatePositionState.getState().currentTechnology
		const unitsTechnology = technology?.works.flatMap(w => w.measureUnit)
		const dto = useEstimatePositionState.getState().dto
		const unitsDto = dto?.works.flatMap(w => w.measureUnit)
		const unique = uniqueSet(unitsDto ? unitsDto! : unitsTechnology!, 'id')
		if (unique?.length === 1) {
			return unique[0]?.name
		}
		return ''
	},
	getWorkAmountSum(workId: string, currentTechnologyWorkId: string) {
		const workData = useEstimatePositionState.getState().workData
		const source = workData ? workData.find(data => data.work.id === workId)?.sourceData : undefined
		if (
			source &&
			source
				.filter(s => !s.typeMaterial?.predefinedDataName && s.measureUnit)
				.every(item => item.measureUnit?.id === source[0].measureUnit?.id)
		) {
			const sigma = source
				.filter(s => !s.typeMaterial?.predefinedDataName)
				.map(item => +item.amount!)
				.reduce((partialSum, a) => partialSum! + a!, 0)
			const currentTechnology = useEstimatePositionState.getState().currentTechnology
			const check =
				currentTechnology?.works.find(w => w.work.id === currentTechnologyWorkId)?.measureUnit
					.isRounded === true
			if (sigma && check) {
				useEstimatePositionState.getState().setWorkAmount(workId, String(math.ceil(sigma)))
			} else if (sigma) {
				useEstimatePositionState
					.getState()
					.setWorkAmount(workId, String(math.round(sigma, ROUND_VALUE)))
			}
		} else {
			message.error('В выбранных материалах единицы измерения не совпадают')
		}
	},
	roundingValue(targetValue: string, property: IEsimatePositionProperty, check: boolean, roundValue?: number) {
		if (targetValue?.includes('.')) {
			const decimal = targetValue.split('.')
			const parseValue =
				decimal[0].length > 15
					? decimal[0].substring(0, 14).concat('.', decimal[1])
					: decimal[0].concat('.', decimal[1])
					useEstimatePositionState.getState().setWorkPropertyValue(
				property.property.id,
				String(
					math[check ? 'ceil' : 'round'](
						+parseValue,
						roundValue
					)
				)
			)
		} else {
			targetValue!?.length > 15
				? useEstimatePositionState.getState().setWorkPropertyValue(
						property.property.id,
						String(
							math[check ? 'ceil' : 'round'](
								+targetValue!?.substring(0, 14).replace(/,/, '.'),
								roundValue
							)
						)
				  )
				: useEstimatePositionState.getState().setWorkPropertyValue(
						property.property.id,
						String(
							math[check ? 'ceil' : 'round'](
								+targetValue!?.replace(/,/, '.'),
								roundValue
							)
						)
				  )
		}
	}
}
