import { Card, Descriptions, Form, Input, Select, Space, Switch } from 'antd'
import { FormInstance } from 'antd/lib'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IContractor } from 'interfaces/IEnvironment'
import { IUserDto } from 'interfaces/IUser'
import { FC, useEffect, useState } from 'react'
import { getContractorList } from 'services/EnvironmentService'
import { setUserValue } from 'store/userSlice'

const { Option } = Select

interface IUserDrawerDetailProps {
	userForm: FormInstance
}

const UserDrawerDetail: FC<IUserDrawerDetailProps> = ({ userForm }) => {
	const dispatch = useAppDispatch()
	const userDto: IUserDto = useAppSelector(state => state.user.dto)
	const [contractors, setContractors] = useState<IContractor[]>([])

	useEffect(() => {
		getContractorList(null).then(data => {
			setContractors(data)
		})
	}, [])

	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			<Card className="at-workcard">
				<Descriptions
					title="Основные параметры:"
					layout="horizontal"
					column={12}
					bordered
					className="at-descriptions"
					labelStyle={{
						fontWeight: 'bold',
						width: '20%',
						alignItems: 'center',
						padding: '2px 8px'
					}}
					contentStyle={{
						width: '80%',
						padding: '0px 0px',
						alignItems: 'center'
					}}
				>
					<Descriptions.Item
						label={
							<>
								Фамилия:<span style={{ color: 'red' }}>*</span>
							</>
						}
						span={12}
					>
						<Form.Item
							name="lastName"
							initialValue={userDto?.lastName!}
							rules={[
								{
									required: true,
									message: 'Укажите фамилию пользователя'
								}
							]}
						>
							<Input
								allowClear
								value={userDto!?.lastName!}
								onChange={el =>
									dispatch(setUserValue({ prop: 'lastName', value: el.target.value }))
								}
							/>
						</Form.Item>
					</Descriptions.Item>
					<Descriptions.Item
						label={
							<>
								Имя:<span style={{ color: 'red' }}>*</span>
							</>
						}
						span={12}
					>
						<Form.Item
							name="firstName"
							initialValue={userDto?.firstName!}
							rules={[
								{
									required: true,
									message: 'Укажите имя пользователя'
								}
							]}
						>
							<Input
								allowClear
								value={userDto!?.firstName!}
								onChange={el =>
									dispatch(setUserValue({ prop: 'firstName', value: el.target.value }))
								}
							/>
						</Form.Item>
					</Descriptions.Item>
					<Descriptions.Item
						label={
							<>
								E-mail:<span style={{ color: 'red' }}>*</span>
							</>
						}
						span={12}
					>
						<Form.Item
							name="email"
							initialValue={userDto!?.email!}
							rules={[
								{
									required: true,
									message: 'Укажите email пользователя'
								},
								{ type: 'email', message: 'Не корректный e-mail' }
							]}
						>
							<Input
								allowClear
								value={userDto!?.email!}
								onChange={el => dispatch(setUserValue({ prop: 'email', value: el.target.value }))}
							/>
						</Form.Item>
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<>
								Кодовое слово:<span style={{ color: 'red' }}>*</span>
							</>
						}
						span={12}
					>
						<Form.Item
							name="secretWord"
							initialValue={userDto!?.secretWord!}
							rules={[
								{
									required: true,
									message: 'Укажите кодовое слово пользователя'
								},
								{ min: 8, message: 'Минимальная длина 8 символов' }
							]}
						>
							<Input
								allowClear
								value={userDto!?.secretWord!}
								onChange={el =>
									dispatch(setUserValue({ prop: 'secretWord', value: el.target.value }))
								}
							/>
						</Form.Item>
					</Descriptions.Item>

					<Descriptions.Item
						label={
							<>
								Компания:<span style={{ color: 'red' }}>*</span>
							</>
						}
						span={12}
					>
						<Form.Item
							name={['contractor', 'id']}
							initialValue={userDto!?.contractor?.id!}
							rules={[
								{
									required: true,
									message: 'Укажите компанию пользователя'
								}
							]}
						>
							<Select
								onChange={el => dispatch(setUserValue({ prop: 'contractorId', value: el }))}
								style={{ width: '100%' }}
								showSearch
								allowClear
								optionFilterProp="children"
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toString()
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toString().toLowerCase())
								}
							>
								{contractors.map((item, index) => (
									<Option key={index} value={item.id}>
										{item.name}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Descriptions.Item>
					<Descriptions.Item label="Администратор" span={12}>
						<Form.Item name="isAdmin" initialValue={userDto!?.isAdmin!}>
							<Switch
								style={{ marginLeft: '5px' }}
								size="small"
								checked={userDto?.isAdmin}
								onChange={value => dispatch(setUserValue({ prop: 'isAdmin', value: value }))}
							/>
						</Form.Item>
					</Descriptions.Item>
				</Descriptions>
			</Card>
		</Space>
	)
}
export default UserDrawerDetail
