import { CopyOutlined } from '@ant-design/icons'
import { Button, ButtonProps, Tooltip, message } from 'antd'
import { FC } from 'react'

interface IProps extends ButtonProps {
	text: string | undefined
	tooltip: string
}
export const CopyToClipboard: FC<IProps> = ({ text, tooltip, ...buttonProps }) => {
	const toClipBoard = (text: string | undefined) => {
		if (text) {
			navigator.clipboard
				.writeText(text)
				.then(() => {
					message.success('Текст скопирован в буфер обмена')
				})
				.catch(err => {
					message.error('Произошла ошибка при копировании')
				})
		} else {
			message.warning('Нечего копировать')
		}
	}

	return (
		<Tooltip zIndex={99999999} title={tooltip}>
			<Button
				{...buttonProps}
				size="small"
				icon={<CopyOutlined />}
				onClick={() => toClipBoard(text)}
			/>
		</Tooltip>
	)
}
