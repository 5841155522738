import { FC } from 'react'

interface IStatusIndicatorProps {
	status: string | null
}

const StatusIndicator: FC<IStatusIndicatorProps> = ({ status }) => {		
	const setModify = (modifier: string) => {
		switch (modifier!?.toLowerCase()) {
			case 'open':
			case 'новый':
				return 'open'
			case 'not eliminated':
			case 'not approved':
			case 'in dispute':
			case 'cancel':
			case 'canceled':
			case 'аннулирован': 
				return 'danger'
			case 'eliminated':
			case 'answered':
			case 'work completed':
			case 'work_completed':
			case 'завершен':
				return 'completed'
			case 'draft':
			case 'ready_to_inspect':
			case 'ready to inspect':
				case 'удержан': 
			case 'принято под гарантийные обязательства':
				return 'inspect'
			case 'exhibited':
			case 'выставлен':
				return 'exhibited'
			default:
				return 'void'
		}
	}
	return <div className={`at-status-indicator at-status-indicator--${setModify(status!)}`}></div>
}

export default StatusIndicator
