import { QuestionCircleFilled, QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Modal, Row, Typography, message } from 'antd'
import axios, { AxiosError } from 'axios'
import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BASEURL } from 'services/ApiService'
import { AuthData, Authenticate } from 'services/AuthService'

interface IUserRecoveryForm {
	email: string
	secretWord: string
}

interface IRestoreData {
	detail: string
	status: number
	title: string
	type: string
}

const { Title } = Typography

const AuthForm: FC = () => {
	const navigate = useNavigate()
	// const [redirectURL, setRedirectURL] = useState<string>('')
	const [showModal, setShowModal] = useState(false)
	const [authForm] = Form.useForm()
	const [userRecovery] = Form.useForm<IUserRecoveryForm>()
	// const setURL = () => {
	// 	localStorage.setItem('authType', 'bim360')
	// 	document.location.href = redirectURL
	// }
	// useEffect(() => {
	// 	getRedirectUrl().then((redirectUrl) => {
	// 		setRedirectURL(redirectUrl)
	// 	})
	// }, [])

	const onUserRecoveryFinish = async (values: IUserRecoveryForm) => {
		await axios({
			baseURL: BASEURL,
			headers: {
				Accept: 'application/json'
			},
			url: `/v1/forgot-password`,
			method: 'post',
			data: values
		})
			.then(response => {
				if (response.status === 200) {
					message.success('На указанную почту отравлено письмо для подтверждения', 5)
					onModalCancel()
				} else {
					message.error('Произошла ошибка, обратитесь в службу поддержки', 5)
				}
			})
			.catch((error: AxiosError) => {
				const errorData = error.response?.data as IRestoreData
				message.error(`Произошла ошибка: ${errorData!?.detail!}`, 5)
			})
	}
	const onModalCancel = () => {
		setShowModal(false)
		userRecovery.resetFields()
	}

	const onAuthFormFinish = async (data: AuthData) => {
		const auth = await Authenticate(data)
		if (auth.success) {
			window.location.reload()
		} else {
			message.error(auth.message)
		}
	}

	const onAuthFormFailed = (errorInfo: any) => {
		console.log('Failed:', errorInfo)
	}

	const info = () => {
		Modal.info({
			icon: <QuestionCircleFilled rev={undefined} />,
			title: <Title level={5}>Нужна помощь?</Title>,
			content: (
				<>
					Если у Вас возникли сложности со входом в приложение или другой вопрос, связанный с
					работой приложения, а также предложения по улучшению работы приложения, напишите,
					пожалуйста, на <a href="mailto:digital@ndeco.ru">digital@ndeco.ru</a>
					<br />
					Спасибо!
				</>
			),
			onOk() {}
		})
	}

	return (
		<>
			<Modal
				open={showModal}
				title="Запрос на восстановление пароля"
				okText="Восстановить"
				onOk={() => userRecovery.submit()}
				onCancel={onModalCancel}
			>
				<Form name="userRecovery" form={userRecovery} onFinish={onUserRecoveryFinish}>
					<Form.Item
						name="email"
						rules={[
							{ required: true, message: 'Поле не должно быть пустым' },
							{ type: 'email', message: 'Не корректный e-mail' }
						]}
					>
						<Input className="at-input" placeholder="E-mail" />
					</Form.Item>
					<Form.Item
						name="secretWord"
						rules={[{ required: true, message: 'Поле не должно быть пустым' }]}
					>
						<Input className="at-input" placeholder="Кодовое слово" />
					</Form.Item>
				</Form>
			</Modal>
			<Form
				name="authForm"
				form={authForm}
				initialValues={{ remember: true }}
				onFinish={onAuthFormFinish}
				onFinishFailed={onAuthFormFailed}
				autoComplete="off"
			>
				<Form.Item
					name="email"
					rules={[
						{ required: true, message: 'Поле не должно быть пустым' },
						{ type: 'email', message: 'Не корректный e-mail' }
					]}
				>
					<Input className="at-input" placeholder="E-mail" />
				</Form.Item>

				<Form.Item
					name="password"
					rules={[{ required: true, message: 'Поле не должно быть пустым' }]}
				>
					<Input.Password className="at-input" placeholder="Пароль" />
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit" className="at-btn at-btn--fill">
						Войти
					</Button>
				</Form.Item>
				{/* <Button className="at-btn at-btn--fill" type="link" onClick={() => setURL()}>
					<p>
						Войти через <strong>Autodesk BIM 360</strong>
					</p>
				</Button> */}

				<Row align="middle" gutter={8}>
					<Col flex={1}>
						<Button
							className="at-btn at-btn--fill"
							type="default"
							block
							onClick={() => setShowModal(true)}
						>
							Забыли пароль?
						</Button>
					</Col>
					<Col flex={1}>
						<Button
							className="at-btn at-btn--fill"
							type="default"
							block
							onClick={() => navigate('/registration')}
						>
							Регистрация
						</Button>
					</Col>
				</Row>
				<div style={{ width: '100%', textAlign: 'center', marginTop: '1rem' }}>
					<Button type="link" icon={<QuestionCircleOutlined rev={undefined} />} onClick={info}>
						Нужна помощь?
					</Button>
				</div>
				<div style={{ width: '100%', textAlign: 'center', marginTop: '1rem' }}>
					<a
						target="_blank"
						rel="noreferrer"
						download="Политика_ПД_ИГ АБСОЛЮТ.pdf"
						href="/files/policy.pdf"
					>
						Политика конфиденциальности
					</a>
				</div>

				<div style={{ width: '100%', textAlign: 'center', marginTop: '1rem' }}>
					<p>
						<a
							download="Согласие на обработку, использование и хранение персональных данных.pdf"
							href="/files/policy_approval.pdf"
						>
							Согласие на обработку персональных данных
						</a>
					</p>
				</div>
				<div style={{ width: '100%', textAlign: 'center', marginTop: '1rem' }}>
					<p>
						<a download="Политика обработки файлов cookie.pdf" href="/files/policy_cookies.pdf">
							Политика обработки файлов cookie
						</a>
					</p>
				</div>
			</Form>
		</>
	)
}

export default AuthForm
