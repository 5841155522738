import { FilterOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { FC, useEffect, useState } from 'react'

interface IProps {
	filters?: object
	onClick: () => void
}

export const FilterButton: FC<IProps> = ({ filters, onClick }) => {
	const [state, setState] = useState(false)
	useEffect(() => {
		setState(
			filters
				? Object.entries(filters).some(f => f[0] !== 'search' && typeof f[1] !== 'undefined')
				: false
		)
	}, [filters])
	return (
		<Tooltip title="Фильтры">
			<Button icon={<FilterOutlined />} type={state ? 'primary' : 'default'} onClick={onClick} />
		</Tooltip>
	)
}
