import { AppstoreFilled, AppstoreTwoTone, BuildTwoTone } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Modal, Space, Table, Typography, message } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { uniq } from 'lodash'
import { all, create } from 'mathjs'
import { useLocalEstimateState } from 'pages/unmodelPage/localEstimate/model/localEstimateState'
import { FC } from 'react'
import { ROUND_VALUE } from 'shared/constants'
import { findNestedObj } from 'shared/helpers'
import { WbsContainerApi } from '../api/wbsContainerApi'
import {
	IWbsContainerSection,
	IWbsContainerSectionItemWork,
	IWbsContainerSectionItemWorkMaterial
} from '../model/wbsContainerInterfaces'
import { useWbsContainerState } from '../model/wbsContainerState'
const config = {}
const math = create(all, config)
const { Title } = Typography

interface IProps {
	type: 'wbs' | 'local'
}

export const WbsRemoveModal: FC<IProps> = props => {
	const { type } = props
	const queryClient = useQueryClient()

	const { showRemoveModal, toRemove, sections, removeSectionId, setSectionBlocks } =
		useWbsContainerState()

	const {
		showRemoveModal: showRemoveModalLocalEstimate,
		toRemove: toRemoveLocalEstimate,
		sections: sectionsLocalEstimate,
		removeSectionId: removeSectionIdLocalEstimate,
		setSectionBlocks: setSectionBlocksLocalEstimate
	} = useLocalEstimateState()

	const data = findNestedObj<IWbsContainerSection>(
		(type === 'wbs' ? sections : sectionsLocalEstimate)!,
		'id',
		(type === 'wbs' ? removeSectionId : removeSectionIdLocalEstimate)!
	)?.children

	const source: IWbsContainerSectionItemWork[] = data
		?.flatMap((x: any) => x.children)
		.filter(x =>
			(type === 'wbs' ? toRemove : toRemoveLocalEstimate).includes(x.id)
		) as IWbsContainerSectionItemWork[]

	const onClose = () => {
		type === 'wbs'
			? useWbsContainerState.setState({ showRemoveModal: false, toRemove: [] })
			: useLocalEstimateState.setState({
					showRemoveModal: false,
					toRemove: []
			  })
	}

	const onDelete = async () => {
		const removable = source?.map(s => s.estimatePositionId)
		await WbsContainerApi.deleteEstimatePositions(uniq(removable))
			.then(async () => {
				message.success('Позиции успешно удалены')
				type === 'wbs'
					? await queryClient.invalidateQueries({ queryKey: ['wbsSections'] }).then(() => {
							setSectionBlocks('')
							removeSectionId && setTimeout(() => setSectionBlocks(removeSectionId), 200)
					  })
					: await queryClient
							.invalidateQueries({ queryKey: ['localEstimateSections'] })
							.then(() => {
								setSectionBlocksLocalEstimate('')
								removeSectionIdLocalEstimate &&
									setTimeout(() => setSectionBlocksLocalEstimate(removeSectionIdLocalEstimate), 200)
							})
				onClose()
			})
			.catch(() => message.error('Во время удаления произошла ошибка. Обратитесь к администратору'))
	}

	const removeColumns: ColumnsType<
		IWbsContainerSectionItemWork | IWbsContainerSectionItemWorkMaterial
	> = [
		{
			title: 'Наименование',
			dataIndex: 'name',
			render: (value, record) =>
				'parentCodifier' in record && record.level === 2
					? `${record.parentCodifier}${record.order} - ${value}`
					: value
		},
		{
			dataIndex: 'measureUnit',
			title: 'Ед.изм',
			width: 80,
			align: 'center'
		},
		{
			key: 'amount',
			dataIndex: 'amount',
			title: 'Кол-во',
			width: 128,
			align: 'center'
		},
		{
			dataIndex: 'rate',
			title: 'Коэффициент нормы расхода',
			width: 120,
			align: 'center',
			render: value =>
				!Number.isNaN(Number(value!))
					? math.round(Number(value!), ROUND_VALUE).toLocaleString()
					: ''
		},
		{
			title: (
				<Space
					direction="vertical"
					size="small"
					style={{ rowGap: 0, lineHeight: '100% !important' }}
				>
					<p style={{ margin: 0 }}>Кол-во</p>
					<p style={{ margin: 0, fontSize: 11, lineHeight: 1.2 }}>с учётом коэффициента расхода</p>
				</Space>
			),
			render: (_, record) => {
				const row = record as unknown as
					| IWbsContainerSectionItemWork
					| IWbsContainerSectionItemWorkMaterial
				return row.amount && row.rate
					? math.round(Number(row.amount! * row.rate!), ROUND_VALUE).toLocaleString()
					: ''
			},
			width: 128,
			align: 'center'
		}
	]
	return (
		<Modal
			title={
				<Title level={4} type="danger">
					Внимание! Следующие позиции будут удалены:
				</Title>
			}
			centered
			destroyOnClose
			width={window.innerWidth - (window.innerWidth * 50) / 100}
			open={type === 'wbs' ? showRemoveModal : showRemoveModalLocalEstimate}
			okButtonProps={{ danger: true }}
			okText="Удалить"
			onOk={onDelete}
			onCancel={onClose}
		>
			<Table
				size="small"
				bordered
				className="app-wbs-table"
				rowKey={row => row.id}
				scroll={{ y: '50vh' }}
				pagination={false}
				dataSource={source ?? []}
				columns={removeColumns}
				onRow={record => ({
					style: record.level !== 3 ? { cursor: 'pointer' } : {}
				})}
				expandable={{
					expandRowByClick: true,
					expandIcon: ({ expanded, onExpand, record }) =>
						record.level !== 3 ? (
							'children' in record && !!record.children?.length ? (
								expanded ? (
									<AppstoreTwoTone twoToneColor="#6fa9fa" onClick={e => onExpand(record, e)} />
								) : (
									<AppstoreFilled style={{ color: '#6fa9fa' }} onClick={e => onExpand(record, e)} />
								)
							) : (
								<AppstoreTwoTone twoToneColor="#9e9e9e" />
							)
						) : (
							<BuildTwoTone twoToneColor="#5d9f6c" />
						)
				}}
			/>
		</Modal>
	)
}
