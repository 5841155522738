import dayjs from 'dayjs'
import { IBase } from 'interfaces/IBase'
import {
	IContainer,
	IContainerCopyDto,
	IContainerUpdateDto,
	ITypeSection
} from 'interfaces/IContainer'
import { IProject } from 'interfaces/IEnvironment'
import {
	IAsmNoteDto,
	IEstimatePosition,
	IEstimatePositionDto,
	IEstimatePositionOrder,
	IPropertyFilters
} from 'interfaces/IEstimatePosition'
import { IMaterials } from 'interfaces/IMaterials'
import { IPagination } from 'interfaces/IPagination'
import { ISectionEstimate } from 'interfaces/ISectionEstimate'
import { ITechnology } from 'interfaces/ITechnology'
import { IWorkTree } from 'interfaces/IWorktree'
import { IUnmodelFilters } from 'pages/unmodelPage/UnmodelFilters'
import { IRequestNci } from 'pages/unmodelPage/asmRequestNci'
import { ISort } from 'store/asmSlice'
import * as yup from 'yup'
import { API } from './ApiService'

export const getContainers = async ({
	project,
	filters,
	limit = 50,
	offset = 0,
	sortState,
	projectId
}: {
	project: IProject
	filters?: IUnmodelFilters
	limit?: number
	offset?: number
	sortState?: ISort
	projectId?: string
}) => {
	if (filters?.createdDate !== undefined && filters?.createdDate !== null) {
		filters = {
			...filters,
			createdDate: dayjs(filters?.createdDate).format('YYYY-MM-DD')
		}
	}
	return await API<IPagination<IContainer[]>>({
		url: `/v1/containers`,
		method: 'get',
		params: { limit, offset, ...filters, sortState, projectId }
	}).then(async response => response)
}

export const getAuthors = async (project: IProject) => {
	return await API({
		url: `/v1/containers/get-authors`,
		method: 'get',
		params: { projectId: project.id }
	}).then(response => response)
}

export const getTypeSection = async () => {
	return await API<ITypeSection[]>({
		url: `/v1/typeSection`,
		method: 'get'
	}).then(response => response.data)
}

export const addContainer = async (name: string, project: IProject, typeSection: string) => {
	return await API<IBase>({
		url: '/v1/containers/',
		method: 'post',
		data: {
			name,
			projectId: project.id,
			typeSectionId: typeSection
		}
	}).then(async response => response.data)
}

export const copyContainer = async (
	containerId: string,
	container: IContainerCopyDto,
	destinationProjectId: string,
	copyNoteDp: boolean = false
) => {
	return await API({
		url: `/v1/containers/${containerId}/copy`,
		method: 'post',
		params: { name: container.name, destinationProjectId, copyNoteDp }
	}).then(async response => response.data)
}

export const updContainer = async (
	containerId: string,
	dto: IContainerUpdateDto,
	type: 'wbs' | 'local' = 'wbs'
) => {
	const url = type === 'local' ? 'containerEstimates' : 'containers'
	return await API({
		url: `/v1/${url}/${containerId}`,
		method: 'patch',
		data: dto
	}).then(async response => response.data)
}

// export const updContainerName = async ({
// 	containerId,
// 	name
// }: {
// 	containerId: string
// 	name: string
// }) => {
// 	return await API({
// 		url: `/v1/containers/${containerId}/name`,
// 		method: 'patch',
// 		params: { name }
// 	}).then(async response => response.data)
// }

export const updContainerReady = async ({
	containerId,
	ready
}: {
	containerId: string
	ready: boolean
}) => {
	return await API({
		url: `/v1/containers/${containerId}/ready`,
		method: 'patch',
		params: { ready }
	}).then(async response => response.data)
}

export const deleteContainer = async (containerId: string) => {
	return await API({
		url: `/v1/containers/${containerId}`,
		method: 'delete'
	}).then(async response => response.data)
}

export const getContainerEstimateList = async (containerId: string) => {
	return await API<string[]>({
		url: `/v1/sections/${containerId}`,
		method: 'get'
	}).then(async response => response.data)
}

export const getSections = async (containerId: string) => {
	return await API<IWorkTree[]>({
		url: '/v1/sections',
		method: 'get',
		params: { containerId }
	}).then(async response => {
		const parseSections = (list: IWorkTree[], level: number): IWorkTree[] => {
			let currentLevel = level + 1
			return list.map(item => ({
				...item,
				level: currentLevel,
				children:
					typeof item.children !== 'undefined'
						? parseSections(item.children, currentLevel)
						: undefined
			}))
		}
		const sections: IWorkTree[] = response.data.map(item => ({
			...item,
			level: 1,
			children: typeof item.children !== 'undefined' ? parseSections(item.children, 1) : undefined
		}))
		return sections
	})
}

export const getSectionPropertyFilters = async (sectionId: string) => {
	return await API<IPropertyFilters[]>({
		url: `/v1/sections/${sectionId}/filters`,
		method: 'get'
	}).then(async response => response.data)
}

export const getEstimatePositions = async (sectionId: string, containerId: string) => {
	return await API<ISectionEstimate[]>({
		url: `/v1/sections/${sectionId}/estimatePositions`,
		method: 'get',
		params: {
			containerId: containerId
		}
	}).then(async response => response.data)
}

export const getEstimatePositionById = async (id: string) => {
	return await API<IEstimatePosition>({
		url: `/v1/estimatePositions/${id}`,
		method: 'get'
	}).then(async response => response.data)
}

export const getTechnologiesBySection = async (sectionId: string) => {
	return await API<ITechnology[]>({
		url: `/v1/sections/${sectionId}/technologies`,
		method: 'get'
	}).then(async response => response.data)
}

export const getTechnologyById = async (technologyId: string) => {
	return await API<ITechnology>({
		url: `/v1/technologies/${technologyId}`,
		method: 'get'
	}).then(async response => response.data)
}

export const getTechnologies = async () => {
	return await API<ITechnology[]>({
		url: `/v1/technologies`,
		method: 'get'
	}).then(async response => response.data)
}

export const getMaterials = async (typeMaterialId: string) => {
	return await API<IMaterials[]>({
		url: `/v1/materials`,
		method: 'get',
		params: {
			typeMaterialId: typeMaterialId
		}
	}).then(async response => response.data)
}

export const saveEstimatePosition = async (
	estimatePositionDto: IEstimatePositionDto,
	method: string
) => {
	return await API({
		url: '/v1/estimatePositions',
		method: method,
		data: estimatePositionDto
	}).then(async response => response.status)
}

export const deleteEstimatePositions = async (estimatePositions: React.Key[]) => {
	return await API({
		url: `/v1/estimatePositions`,
		method: 'delete',
		data: estimatePositions
	}).then(async response => response.status)
}

export const setEstiametPositionOrder = async (data: IEstimatePositionOrder[]) => {
	return await API({
		url: `/v1/estimatePositions/order`,
		method: 'patch',
		data: data
	}).then(async response => response)
}

export const copyEstimatePosition = async (estimateId: string) => {
	return await API({
		url: `/v1/estimatePositions/${estimateId}/copy`,
		method: 'post'
	}).then(async response => response.data)
}

export const validateEstimatePosition = (data: IEstimatePositionDto) => {
	let scheme = yup.object().shape({
		id: yup.string().nullable().notRequired(),
		containerId: yup.string().required(),
		technologyId: yup.string().required(),
		works: yup.array(
			yup.object().shape({
				workId: yup.string().required(),
				amount: yup.number().required(),
				materials: yup.array(
					yup.object().shape({
						typeMaterialId: yup.string().required(),
						materialId: yup.string().required(),
						amount: yup.number().nullable().required()
					})
				)
			})
		),
		properties: yup.array(
			yup.object().shape({
				identityName: yup.string().required(),
				propertyId: yup.string().required(),
				valueType: yup.string().required(),
				value: yup.mixed().when('valueType', {
					is: 'System.Decimal',
					then: yup.number().nullable().required(),
					otherwise: yup.string().nullable().required()
				})
			})
		)
	})
	return scheme.isValidSync(data)
}

export const checkContainer = async (containerId: string) => {
	return await API<boolean>({
		url: `/v1/containers/checkContainer/${containerId}`,
		method: 'get'
	}).then(async response => response.data)
}

export const getContainer = async (containerId: string) => {
	return await API<IContainer>({
		url: `/v1/containers/${containerId}`,
		method: 'get'
	}).then(async response => response.data)
}

export const setWBSNote = async (dto: IAsmNoteDto) => {
	return await API<IContainer>({
		url: '/v1/estimatePositions/note',
		method: 'patch',
		data: dto
	}).then(async response => response.data)
}

export const createRequestNci = async (dto: IRequestNci) => {
	return await API({
		url: '/v1/containers/createRequestNCI',
		method: 'post',
		data: dto
	}).then(async response => response.data)
}

export const createCorrectionContainer = async (containerId: string, name: string) => {
	return await API({
		url: `/v1/containers/${containerId}/correctionContainer`,
		method: 'post',
		data: { name }
	}).then(response => response.data)
}
