import { IssueAnalyticsWidget } from 'UI/widgets/IssueAnalyticsWidget/IssueAnalyticsWidget'
import { OrdinanceAnalyticsWidget } from 'UI/widgets/OrdinanceAnalyticsWidget/OrdinanceAnalyticsWidget'
import { ViolationTypesAnalyticsWidget } from 'UI/widgets/ViolationTypesAnalyticsWidget/ViolationTypesAnalyticsWidget'
import { MyFilesWidget } from 'UI/widgets/myFilesWidget/MyFilesWidget'
import { Card, Col, Flex, Row, Select, Space } from 'antd'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IBase } from 'interfaces/IBase'
import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setDiagramsContractor } from 'store/environmentSlice'
import { setPageTitle } from 'store/rootSlice'
const HomePage: FC = () => {
	const checkPermissions = useCheckPermissions()
	const dispatch = useAppDispatch()
	const { permissions, contractors, project, diagramsContractor, contracts, user } = useAppSelector(
		state => state.environment
	)
	const userContractorId = useAppSelector(state => state.environment.user?.contractorId)
	const showWidgets = {
		issue: checkPermissions([PERMISSIONS.IssueView]),
		ordinance: checkPermissions([PERMISSIONS.OrdinanceView])
	}
	const [currentId, setCurrentId] = useState<string | null>(null)
	const [uniqContractors, setUniqContractors] = useState<Pick<IBase, 'id' | 'name'>[]>([])
	const allCompanies = checkPermissions([PERMISSIONS.AllCompanies])

	useEffect(() => {
		const filterContractors =
			contracts
				?.filter(c =>
					contractors?.some(o => o?.id === (allCompanies ? c.contractor?.id : userContractorId))
				)
				.map(c => ({
					id:
						// c.subContractor === null
						// 	?
						allCompanies ? c.contractor?.id : userContractorId!,
					// : c.subContractor.id,
					name:
						// c.subContractor === null ?
						allCompanies ? c.contractor?.name : user?.contractorName!
					//  : c.subContractor.name
				})) ?? []

		setUniqContractors(
			_.uniqBy(
				filterContractors.sort((a, b) => +a.name - +b.name),
				'id'
			)
		)
	}, [contracts, contractors])

	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Главная' }))
	}, [])

	useEffect(() => {
		if (!!contractors?.length) {
			setCurrentId(
				userContractorId && uniqContractors?.some(c => c.id === userContractorId)
					? userContractorId
					: contractors?.[0].id
			)
		}
	}, [userContractorId, project, contractors, uniqContractors])
	useEffect(() => {
		currentId &&
			(diagramsContractor === undefined || diagramsContractor !== 'all') &&
			dispatch(setDiagramsContractor(currentId))
	}, [currentId])

	return !!uniqContractors?.length &&
		(allCompanies || userContractorId === diagramsContractor) &&
		(showWidgets.issue || showWidgets.ordinance) ? (
		<div className="dashboard__block">
			<Row
				align="stretch"
				gutter={[
					{ xs: 16, md: 20 },
					{ xs: 16, md: 10 }
				]}
			>
				<Col span={24}>
					<Select
						dropdownStyle={uniqContractors.length === 1 ? { display: 'none' } : {}}
						suffixIcon={uniqContractors.length === 1 ? null : undefined}
						value={diagramsContractor}
						onChange={value => {
							dispatch(setDiagramsContractor(value))
						}}
						showSearch
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						filterSort={(optionA, optionB) =>
							(optionA?.label ?? '')
								.toLowerCase()
								.localeCompare((optionB?.label ?? '').toLowerCase())
						}
						placeholder="Подрядчик"
						style={{ width: 300, cursor: uniqContractors.length === 1 ? 'default' : 'pointer' }}
						options={
							uniqContractors.length !== 1 && allCompanies
								? [
										{
											key: 0,
											value: 'all',
											label: 'Все компании'
										},
										...uniqContractors?.map(c => ({
											key: c.id,
											value: c.id,
											label: c.name,
											disabled: uniqContractors.length === 1
										}))
								  ]
								: uniqContractors.length !== 1
								? [
										...uniqContractors?.map(c => ({
											key: c.id,
											value: c.id,
											label: c.name,
											disabled: uniqContractors.length === 1
										}))
								  ]
								: uniqContractors?.map(c => ({
										key: c.id,
										value: c.id,
										label: c.name,
										disabled: uniqContractors.length === 1
								  }))
						}
					/>
				</Col>

				{showWidgets.issue && (
					<Col xs={{ span: 24 }} xxl={{ span: 12 }}>
						<IssueAnalyticsWidget />
					</Col>
				)}
				{showWidgets.issue && (
					<Col xs={{ span: 24 }} xxl={{ span: 12 }}>
						<ViolationTypesAnalyticsWidget />
					</Col>
				)}
				{showWidgets.ordinance && (
					<Col xs={{ span: 24 }} xxl={{ span: 12 }}>
						<OrdinanceAnalyticsWidget />
					</Col>
				)}
				<Col xs={{ span: 24 }} xxl={{ span: 12 }}>
					<MyFilesWidget />
				</Col>
			</Row>
		</div>
	) : (
		<Flex justify="center" align="center" style={{ width: '100%', height: '100%' }}>
			<div className="at-bg">
				<Card
					style={{
						backgroundColor: '#ffffff80',
						padding: '5rem',
						width: '100%',
						textAlign: 'center'
					}}
				>
					<Space direction="vertical" align="center">
						<h1 style={{ color: 'grey', textTransform: 'uppercase', margin: 0 }}>
							Добро пожаловать
						</h1>
						{!!contractors?.length === false && <h3>В выбранном проекте нет компаний</h3>}
					</Space>
				</Card>
			</div>
		</Flex>
	)
}

export default HomePage
