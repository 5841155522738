import { Form, FormInstance, Select, Tag, message } from 'antd'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import useDebounce from 'hooks/useDebounce'
import { IChecklistResponsibleUser } from 'interfaces/IChecklist'
import { uniq } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { getResponsibleUsers } from 'services/ChecklistsService'
import { setUsers } from 'store/templatesSlice'

interface IAssignedToProps {
	form: FormInstance<any>
	onUpdateChecklist: (updateData: any, keepOpen?: boolean) => void
	allDisabled: boolean
}

const AssignedTo: FC<IAssignedToProps> = ({ form, onUpdateChecklist, allDisabled }) => {
	const dispatch = useAppDispatch()
	const currentUser = useAppSelector(state => state.environment.user)
	const currentChecklist = useAppSelector(state => state.checklists.init.currentChecklist)
	const [usersData, setUsersData] = useState<IChecklistResponsibleUser[]>([])
	const [searchValue, setSearchValue] = useState<string | null>(null)
	const [isLoading, setIsLoading] = useState(false)
	const projectId = useAppSelector(state => state.environment.project?.id)
	const [assigned, setAssigned] = useState<string | string[]>('')
	const [hasErrorUser, setHasErrorUser] = useState<string[]>([])

	const fetchUsers = (value: string) => {
		setIsLoading(true)
		if (currentChecklist && projectId) {
			getResponsibleUsers({
				templateType: currentChecklist?.templateType,
				name: value || undefined,
				projectId: currentChecklist.project?.id === null ? projectId : currentChecklist.project?.id
			})
				.then(res => {
					setUsersData(res)
					setIsLoading(false)
				})
				.catch(error => message.error(error.message))
		}
	}
	const debounceFetch = (value: string) => {
		if (value.length >= 3) {
			setSearchValue(value)
			fetchUsers(value)
		} else {
			setSearchValue(null)
			fetchUsers(value)
		}
	}
	const debouncer = useDebounce((deb: string) => debounceFetch(deb), 1000)

	const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
		const target = event.currentTarget

		if (!isLoading && target.scrollHeight === Math.ceil(target.scrollTop) + target.offsetHeight) {
			setIsLoading(true)
			if (currentChecklist && projectId) {
				getResponsibleUsers({
					templateType: currentChecklist?.templateType,
					name: searchValue || undefined,
					projectId:
						currentChecklist.project?.id === null ? projectId : currentChecklist.project?.id
				})
					.then(res => {
						setUsersData(res)
						dispatch(setUsers(res))
					})
					.catch(error => message.error(error.message))
					.finally(() => setIsLoading(false))
			}
		}
	}

	useEffect(() => {
		if (currentChecklist && projectId) {
			getResponsibleUsers({
				templateType: currentChecklist?.templateType,
				name: searchValue || undefined,
				projectId: currentChecklist.project?.id === null ? projectId : currentChecklist.project?.id
			}).then(res => {
				setUsersData(res)
			})
		}
	}, [currentChecklist])

	const onAssignedSelect = (user: string) => {
		// if (currentChecklist?.templateType === 1) {
		if (!(assigned as string[]).some(a => a === user)) {
			setAssigned(prev => [...prev, user])
		} else {
			setAssigned(user as string)
		}
	}

	const onAssignedDeselect = (user: string) => {
		if (Array.isArray(assigned) && (assigned.some(a => a === user) || user === 'correct')) {
			const users =
				user === 'correct'
					? assigned.filter(u => !hasErrorUser.includes(u))
					: user === currentUser?.id
					? uniq(assigned)
					: assigned.filter(u => u !== user)
			if (!currentUser?.isAdmin && user === currentUser?.id) {
				message.error(
					'Вас назначили Ответственным по данному чек-листу. Чтобы Вы перестали быть ответственным, обратитесь к Автору чек-листа или Администратору'
				)
				if (user === currentUser?.id) {
					setAssigned(uniq([...users, user]))
				}
			} else {
				if (users.length >= 1) {
					setAssigned(users)
				} else {
					if (user === currentUser?.id) {
						setAssigned(uniq([...users, user]))
					}
					form.setFieldValue('assignedTo', [user as string])
					form.validateFields()
					message.error('Поле "Ответственный" не может быть пустым')
				}
			}
		}
	}

	useEffect(() => {
		if (!!usersData.length) {
			const valid: string[] = Array.isArray(assigned)
				? assigned.filter(item => !usersData.flatMap(({ id }) => id).includes(item))
				: usersData.some(({ id }) => id === assigned)
				? []
				: [assigned]

			const list = !!valid.length
				? Array.isArray(assigned)
					? assigned.map(a => {
							return valid.some(v => v === a) ? 'Пользователь был удален' : a
					  })
					: valid.some(v => v === assigned)
					? ['Пользователь был удален']
					: [assigned]
				: Array.isArray(assigned)
				? assigned
				: [assigned]

			form.setFieldValue('assignedTo', list)
			setHasErrorUser(valid)
		}
	}, [assigned, usersData])

	useEffect(() => {
		const sorted = [...currentChecklist?.assignedTo!]?.sort(
			(a, b) =>
				+(b.id === currentUser?.id) - +(a.id === currentUser?.id) ||
				a?.title?.localeCompare(b?.title)
		)
		setAssigned(sorted.flatMap(user => user.id)!)
	}, [currentChecklist])

	useEffect(() => {
		if (Array.isArray(assigned)) {
			form.setFieldValue(
				'assignedTo',
				assigned
					.sort((a, b) => +hasErrorUser.includes(b) - +hasErrorUser.includes(a))
					.map(a => {
						return hasErrorUser.some(v => v === a) ? 'Пользователь был удален' : a
					})
			)
		}
	}, [hasErrorUser])

	return (
		<Form.Item
			name="assignedTo"
			rules={[
				{ required: assigned.length === 0, message: 'Укажите ответственного' },
				() => ({
					validator(_, value) {
						if (!!hasErrorUser.length) {
							return Promise.reject(
								new Error('Пользователь был удален. Необходимо переназначить Ответственного')
							)
						}
						return Promise.resolve()
					}
				})
			]}
		>
			<Select
				status={hasErrorUser ? 'error' : undefined}
				bordered={!!hasErrorUser.length}
				mode={/* currentChecklist?.templateType === 1 ? */ 'multiple' /* : undefined */}
				maxTagCount={!!hasErrorUser.length ? 1 : 'responsive'}
				showSearch
				onSearch={debouncer}
				optionFilterProp="children"
				filterOption={(input, option) =>
					(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
				}
				filterSort={(optionA, optionB) =>
					(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
				}
				options={usersData.map((user: IChecklistResponsibleUser) => ({
					key: user.id,
					value: user.id,
					label: user.fullName
				}))}
				tagRender={props => (
					<Tag
						onClose={e => onAssignedDeselect('correct')}
						closable={props.value === 'Пользователь был удален'}
						color={props.value === 'Пользователь был удален' ? 'red' : 'default'}
						style={{ fontSize: 14 }}
						bordered={false}
					>
						{props.label}
					</Tag>
				)}
				onPopupScroll={onScroll}
				onSelect={onAssignedSelect}
				onDeselect={onAssignedDeselect}
				value={assigned}
				disabled={allDisabled}
				style={{ width: '100%', textOverflow: 'ellipsis' }}
			/>
		</Form.Item>
	)
}

export default AssignedTo
