import { DatabaseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FC, useState } from 'react'
import { WbsStatusReportCreateModal } from './WbsStatusReportCreateModal'

interface IProps {}

export const WbsStatusReportCreate: FC<IProps> = () => {
	const [show, setShow] = useState(false)
	return (
		<>
			<WbsStatusReportCreateModal show={show} onClose={() => setShow(false)} />
			<Button type={'primary'} icon={<DatabaseOutlined />} onClick={() => setShow(true)}>
				Создать отчёт
			</Button>
		</>
	)
}
