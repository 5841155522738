import { create } from 'zustand'

interface IState {
	show: boolean
}
interface IStateActions {
	toggleShow: (state?: boolean) => void
}

export const useRegulatoryRequestState = create<IState & IStateActions>((set, get) => ({
	show: false,
	toggleShow: state => set(() => ({ show: state ? state : !get().show }))
}))
