import { API } from './ApiService'

type TDiagramType = 'issues' | 'issueSubTypes' | 'ordinances' | 'myFiles'

export const getDiagram = async (type: TDiagramType = 'issues', contractorId?: string) => {
	return await API({
		url: `/v1/diagrams/${type}`,
		method: 'get',
		params: { contractorId: contractorId === 'all' ? undefined : contractorId }
	}).then(async response => response.data)
}
