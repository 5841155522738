import { useQuery } from '@tanstack/react-query'
import { AdjustmentPositionCommentsApi } from '../api/adjustmentComments.api'
import { useAdjustmentPositionCommentsState } from './adjustment-position-comments-state'

export const useAdjustmentPositionComments = () => {
	const { currentId } = useAdjustmentPositionCommentsState()
	return useQuery({
		queryKey: ['adjustmentPositionComments', currentId],
		queryFn: async () => {
			if (currentId !== null) {
				return await AdjustmentPositionCommentsApi.getEPComments(currentId).catch(() => {
					return null
				})
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useAdjustmentPositionResponsibleUsers = () => {
	const { currentId } = useAdjustmentPositionCommentsState()
	return useQuery({
		queryKey: ['adjustmentPositionResponsibleUsers', currentId],
		queryFn: async () => {
			if (currentId !== null) {
				return await AdjustmentPositionCommentsApi.getEPCommentsUsers(currentId).catch(() => {
					return null
				})
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}
