import { all, create } from 'mathjs'
import { ROUND_VALUE } from 'shared/constants'
const config = {}
const math = create(all, config)

export const RoundedNumber = (value: any) => {
	return !Number.isNaN(Number(value!))
		? math.round(Number(value!), ROUND_VALUE).toLocaleString()
		: ''
}
