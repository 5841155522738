import { FC, useEffect, useState } from 'react'
import { Table, Checkbox, Button, Space, Modal, Input, Row, Col, Descriptions, Form, FormInstance, Tag } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import { IAppRigths, IRoleDTO } from 'interfaces/IAppRights'
import { useAppDispatch } from 'hooks/appReduxHook'
import { setPageTitle } from 'store/rootSlice'
import { getPermissions, getRoles, getRolesPermissions } from 'services/AdminService'
import { IPermission, IRole } from 'interfaces/IPermissions'
import RoleDrawer from './RoleDrawer'

interface TabRolesProps {
	message: (m: string, type: string) => void
	loader: (x: boolean) => void
}

const TabRoles = ({ ...props }: TabRolesProps) => {
	const dispatch = useAppDispatch()

	const [appRigths, setAppRights] = useState<IAppRigths[]>([])
	const [isChanged, setIsChanged] = useState<boolean>(false)
	const [permissions, setPermissions] = useState<IPermission[]>([])
	const [roles, setRoles] = useState<IRole[]>([])
	const [showRoleDrawer, setShowRoleDrawer] = useState(false)
	const [selectedRole, setSelectedRole] = useState<string | null>(null)

	const getAppRights = () => {
		props.loader(true)
		getRolesPermissions()
			.then(response => {
				if (response.status === 200) {
					setAppRights(response.data)
				}
			})
			.catch(e => {
				setTimeout(() => {
					props.loader(false)
					props.message(e.message, 'error')
				}, 200)
			})
			.finally(() => {
				setTimeout(() => {
					props.loader(false)
				}, 200)
			})
	}

	useEffect(() => {
		dispatch(setPageTitle({ subTitle: 'Роли' }))
		getAppRights()
		getPermissions().then(data => setPermissions(data))
		getRoles().then(data => setRoles(data))
	}, [])

	const roleColumns: ColumnsType<IRole> = [
		{
			key: 'id',
			dataIndex: 'name',
			title: 'Наименование роли',
			width: '20%',
			sorter: (a, b) => a.name!.length - b.name!.length,
			defaultSortOrder: 'ascend',
			filters: roles.map(r => ({ text: r.name!, value: r.name! })),
			onFilter: (value: any, record) => record.name!?.includes(value! as string),
			filterSearch: true
		},
		{
			dataIndex: 'description',
			width: '30%',
			title: 'Описание'
		},
		{
			title: 'Права',
			render: (_, record) =>
				record.permissions.map(permission => (
					<Tag
						color={'#1677ff'}
						style={{
							fontSize: 14,
							padding: 4,
							margin: 3,
							color: '#f0f0f0'
						}}
					>
						{permission.synonym}
					</Tag>
				))
		}
	]

	const onRoleDrawerClose = (mustUpdate: boolean) => {
		if (mustUpdate) getRoles().then(data => setRoles(data))
		setShowRoleDrawer(false)
		setSelectedRole(null)
	}

	return (
		<>
			<RoleDrawer open={showRoleDrawer} roleId={selectedRole} onClose={mustUpdate => onRoleDrawerClose(mustUpdate)} />
			<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
				<Table
					style={{ boxShadow: '0 0 1rem rgba(0,0,0,.1)' }}
					scroll={{ y: '64vh', x: '100%' }}
					rowKey={row => row.id!}
					columns={roleColumns}
					dataSource={roles}
					pagination={false}
					onRow={record => ({
						onDoubleClick: () => {
							if (record.id !== null) {
								setSelectedRole(record.id)
								setShowRoleDrawer(true)
							}
						}
					})}
					locale={{
						triggerAsc: 'Сортировать по возврастанию',
						triggerDesc: 'Сортировать по убыванию',
						cancelSort: 'Отменить сортировку',
						emptyText: (
							<Space direction="vertical">
								<div>Данных нет</div>
								<Button type="primary" onClick={() => getAppRights()}>
									Обновить
								</Button>
							</Space>
						)
					}}
				/>

				<Space className="at-jce" style={{ width: '100%', marginTop: '1rem' }}>
					<Button onClick={() => setShowRoleDrawer(true)}>Добавить роль</Button>
					<Button onClick={() => getAppRights()}>Обновить</Button>
				</Space>
			</div>
		</>
	)
}

export default TabRoles

// interface ICreateRoleModalProps {
// 	open: boolean
// 	onConfirm: (form: IRoleDTO) => void
// 	onClose: () => void
// }

// const CreateRoleModal: FC<ICreateRoleModalProps> = ({ open, onConfirm, onClose }) => {
// 	const [createRoleForm] = Form.useForm()
// 	const [isDisabeld, setIsDisabeld] = useState(true)
// 	const handleFormChange = (form: FormInstance) => {
// 		const hasErrors = form.getFieldsError().filter(({ errors }) => errors.length).length > 0
// 		setIsDisabeld(hasErrors)
// 	}

// 	return (
// 		<Modal
// 			okButtonProps={{
// 				htmlType: 'submit',
// 				disabled: isDisabeld,
// 				onClick: () => createRoleForm.submit()
// 			}}
// 			title="Создать роль"
// 			destroyOnClose={true}
// 			open={open}
// 			onCancel={() => {
// 				onClose()
// 				setIsDisabeld(true)
// 				createRoleForm.resetFields()
// 			}}
// 			okText="Сохранить"
// 			cancelText="Отмена"
// 		>
// 			<Form
// 				name="createRoleForm"
// 				form={createRoleForm}
// 				onFinish={() => {
// 					onConfirm(createRoleForm.getFieldsValue())
// 				}}
// 				onFieldsChange={() => handleFormChange(createRoleForm)}
// 			>
// 				<Descriptions
// 					layout="horizontal"
// 					column={12}
// 					bordered
// 					className="at-descriptions"
// 					labelStyle={{
// 						fontWeight: 'bold',
// 						width: '20%',
// 						padding: '2px 8px'
// 					}}
// 					contentStyle={{
// 						width: '80%',
// 						padding: '4px 4px'
// 					}}
// 				>
// 					<Descriptions.Item label="Название" span={12}>
// 						<Form.Item shouldUpdate name="name" rules={[{ required: true, message: 'Введите название роли' }]}>
// 							<Input />
// 						</Form.Item>
// 					</Descriptions.Item>
// 					<Descriptions.Item label="Права" span={12}>
// 						<Row>
// 							<Col span={24}>
// 								<Form.Item shouldUpdate name="isCanViewAdmin" initialValue={false}>
// 									<Checkbox
// 										onChange={e =>
// 											createRoleForm.setFieldValue(e.target?.id!.replace('createRoleForm_', ''), e.target.checked)
// 										}
// 									>
// 										Администратор
// 									</Checkbox>
// 								</Form.Item>
// 							</Col>
// 							<Col span={24}>
// 								<Form.Item shouldUpdate name="isCanFormationOfPrescriptions" initialValue={false}>
// 									<Checkbox
// 										onChange={e =>
// 											createRoleForm.setFieldValue(e.target?.id!.replace('createRoleForm_', ''), e.target.checked)
// 										}
// 									>
// 										Формирование Предписаний
// 									</Checkbox>
// 								</Form.Item>
// 							</Col>
// 							<Col span={24}>
// 								<Form.Item shouldUpdate name="isCanFormationOfActsOnEliminationOfViolations" initialValue={false}>
// 									<Checkbox
// 										onChange={e =>
// 											createRoleForm.setFieldValue(e.target?.id!.replace('createRoleForm_', ''), e.target.checked)
// 										}
// 									>
// 										Формирование Актов об устранении нарушений
// 									</Checkbox>
// 								</Form.Item>
// 							</Col>
// 							<Col span={24}>
// 								<Form.Item shouldUpdate name="isCanIntroductionOfUnmodeledVolumes" initialValue={false}>
// 									<Checkbox
// 										onChange={e =>
// 											createRoleForm.setFieldValue(e.target?.id!.replace('createRoleForm_', ''), e.target.checked)
// 										}
// 									>
// 										Внесение немоделируемых объемов
// 									</Checkbox>
// 								</Form.Item>
// 							</Col>
// 							<Col span={24}>
// 								<Form.Item shouldUpdate name="isCanTheConstructionControlSection" initialValue={false}>
// 									<Checkbox
// 										onChange={e =>
// 											createRoleForm.setFieldValue(e.target?.id!.replace('createRoleForm_', ''), e.target.checked)
// 										}
// 									>
// 										Доступ к разделу строительному контролю
// 									</Checkbox>
// 								</Form.Item>
// 							</Col>
// 						</Row>
// 					</Descriptions.Item>
// 				</Descriptions>
// 			</Form>
// 		</Modal>
// 	)
// }
