import { Card, Divider, Form, FormInstance, Input, InputNumber, message } from 'antd'
import { all, create } from 'mathjs'
import { useLocalEstimateState } from 'pages/unmodelPage/localEstimate/model/localEstimateState'
import { FC, useEffect } from 'react'
import { ROUND_VALUE } from 'shared/constants'
import { AsmCardHelpers } from '../../model/asm-card-helpers'
import { IAsmPermissions, useAsmCardState } from '../../model/asm-card-state'
import { AsmCardPropertiesGuid } from './asm-card-properties-guid'

interface IProps {
	asmCardForm: FormInstance
	permissions: IAsmPermissions
}

const config = {}
const math = create(all, config)

export const AsmCardProperties: FC<IProps> = ({ asmCardForm, permissions }) => {
	const { currentTechnology, workProperties, setWorkPropertyValue, setPropertyValue } =
		useAsmCardState()
	const { container } = useLocalEstimateState()
	useEffect(() => {
		workProperties?.map(prop =>
			asmCardForm.setFieldValue(['properties', prop.identityName], prop.value)
		)
	}, [workProperties])
	return workProperties &&
		(!currentTechnology?.expandable ||
			!(
				currentTechnology?.expandable &&
				workProperties!.length === 1 &&
				workProperties[0].baseQuantity === true
			)) ? (
		<>
			<Divider children="Параметры" orientation="left" />
			<Card className="at-workcard">
				{workProperties!
					.filter(p => (currentTechnology?.expandable ? p.baseQuantity !== true : p))
					.sort((a, b) => {
						if (a.order === null) return 1
						if (b.order === null) return -1
						return a.order - b.order
					})
					.sort((a, b) => +b.baseQuantity - +a.baseQuantity)
					.map(property => {
						const required = property?.required ?? false
						return (
							<Form.Item
								extra={
									property.commentAdditionalProperty !== null && (
										<div style={{ fontSize: 12, color: 'gray' }}>
											{property.commentAdditionalProperty}
										</div>
									)
								}
								required={false}
								labelCol={{ style: { width: 336 } }}
								labelAlign="left"
								key={property.property.id}
								name={['properties', property.identityName]}
								label={
									<span>
										{property.baseQuantity === true ? (
											'Количество работ'
										) : (
											<span style={{ color: property?.isDeleted ? 'red' : 'black' }}>
												{property.name}
											</span>
										)}
										{required ? <b style={{ color: 'red' }}> *</b> : ''}
									</span>
								}
								rules={[
									() => ({
										validator(_, value) {
											if (required) {
												if (value === null || value === undefined || value.length === 0) {
													return Promise.reject(new Error('Поле не должно быть пустым'))
												}
												const validate = AsmCardHelpers.validateProperty(
													value,
													property.identityName,
													property.valueType
												)
												if (
													// (property.baseQuantity === true &&
													property.isDeleted === false &&
													(Number(value) === 0 ||
														// )
														!validate.status)
												) {
													const zeroError =
														// property.baseQuantity === true &&
														Number(value) === 0 ? 'Не может быть равно 0' : validate.message
													message.error(zeroError)
													return Promise.reject(new Error(zeroError))
												}
												if (value < 0 || !validate.status) {
													const minusError = value < 0 ? 'Не может быть меньше 0' : validate.message
													message.error(minusError)
													return Promise.reject(new Error(minusError))
												}
												if (
													typeof value === 'string' &&
													value &&
													value.length < 3 &&
													!property.valueType.includes('System.Decimal')
												) {
													return Promise.reject(new Error('Минимум 3 символа'))
												}
											}
											if (!required && value && value.length > 0) {
												const validate = AsmCardHelpers.validateProperty(
													value,
													property.identityName,
													property.valueType
												)
												if (!validate.status) return Promise.reject(new Error(validate.message))
											}
											return Promise.resolve()
										}
									})
								]}
							>
								{property.valueType === 'System.Decimal' ? (
									<InputNumber
										decimalSeparator=","
										// formatter={value => {
										// 	const targetValue = value?.toString()?.replace(/\./g, ',')
										// 	if (targetValue?.includes(',')) {
										// 		const decimal = targetValue.split(',')
										// 		const parseValue =
										// 			decimal[0].length > 15
										// 				? decimal[0].substring(0, 14).concat(',', decimal[1])
										// 				: decimal[0].concat(',', decimal[1])
										// 		return parseValue
										// 	} else {
										// 		return targetValue!?.length > 15
										// 			? targetValue!?.substring(0, 14).replace(/,/, '.')
										// 			: targetValue!?.replace(/,/, '.')
										// 	}
										// }}
										// precision={ROUND_VALUE}
										disabled={!permissions.edit || property.isDeleted || container?.isLocalEstimate}
										addonAfter={
											property.baseQuantity === true
												? AsmCardHelpers.getBaseQualityMeasureUnit()
												: property.measureUnitName
										}
										controls={false}
										keyboard={false}
										onBlur={e => {
											const targetValue = e.currentTarget.value.replace(/,/, '.')
											setPropertyValue(targetValue)
											if (
												(currentTechnology?.properties.find(
													p => p.property.id === property.property.id
												)?.property.measureUnit
													? currentTechnology?.properties.find(
															p => p.property.id === property.property.id
													  )?.property.measureUnit?.isRounded === true
													: property.property.isRounded === true) &&
												!Number.isNaN(targetValue)
											) {
												if (targetValue?.includes('.')) {
													const decimal = targetValue.split('.')
													const parseValue =
														decimal[0].length > 15
															? decimal[0].substring(0, 14).concat('.', decimal[1])
															: decimal[0].concat('.', decimal[1])
													setWorkPropertyValue(property.property.id, String(math.ceil(+parseValue)))
												} else {
													targetValue!?.length > 15
														? setWorkPropertyValue(
																property.property.id,
																String(math.ceil(+targetValue!?.substring(0, 14).replace(/,/, '.')))
														  )
														: setWorkPropertyValue(
																property.property.id,
																String(math.ceil(+targetValue!?.replace(/,/, '.')))
														  )
												}
											} else if (!Number.isNaN(targetValue))
												if (targetValue?.includes('.')) {
													const decimal = targetValue.split('.')
													const parseValue =
														decimal[0].length > 15
															? decimal[0].substring(0, 14).concat('.', decimal[1])
															: decimal[0].concat('.', decimal[1])
													setWorkPropertyValue(
														property.property.id,
														String(math.ceil(+parseValue, ROUND_VALUE))
													)
												} else {
													targetValue!?.length > 15
														? setWorkPropertyValue(
																property.property.id,
																String(
																	math.ceil(
																		+targetValue!?.substring(0, 14).replace(/,/, '.'),
																		ROUND_VALUE
																	)
																)
														  )
														: setWorkPropertyValue(
																property.property.id,
																String(math.ceil(+targetValue!?.replace(/,/, '.'), ROUND_VALUE))
														  )
												}
										}}
										changeOnWheel={false}
										onKeyDown={event => {
											if (
												!/[0-9]|Backspace|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|\.|\,/.test(
													event.key
												)
											)
												event.preventDefault()
										}}
										style={{ width: '100%' }}
									/>
								) : property.valueType === 'System.Guid' ? (
									<AsmCardPropertiesGuid
										property={property}
										callBack={value => setWorkPropertyValue(property.property.id, value)}
									/>
								) : (
									<Input
										disabled={!permissions.edit || property.isDeleted || container?.isLocalEstimate}
										onBlur={e => setWorkPropertyValue(property.property.id, e.currentTarget.value)}
										addonAfter={
											property.baseQuantity === true
												? AsmCardHelpers.getBaseQualityMeasureUnit()
												: property.measureUnitName
										}
										min={0}
										maxLength={1024}
									/>
								)}
							</Form.Item>
						)
					})}
			</Card>
		</>
	) : null
}
