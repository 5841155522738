import LoaderSpinner from 'UI/loaderSpinner'
import { Descriptions, Divider, Modal, message } from 'antd'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { IOrdinance, IOrdinanceIssue } from 'interfaces/IOrdinance'
import { FC, useState } from 'react'
import { addEliminationAct } from 'services/OrdinanceService'
interface IEliminationActModalProps {
	open: boolean
	ordinance: IOrdinance
	selectedIssueRows: string[]
	ordinanceIssues: IOrdinanceIssue[]
	onClose: (mustUpdate?: boolean) => void
}

const EliminationActModal: FC<IEliminationActModalProps> = ({
	open,
	ordinance,
	selectedIssueRows,
	ordinanceIssues,
	onClose
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const onSendEliminationAct = async () => {
		setIsLoading(true)
		await addEliminationAct(ordinance.id, { issueIds: selectedIssueRows })
			.then(response => {
				message.success('Акт об устранении нарушений успешно создан')
				onClose(true)
			})
			.catch((error: AxiosError) =>
				message.error(`Произошла ошибка во время сохранения: ${error.message}`)
			)
			.finally(() => setIsLoading(false))
	}

	return (
		<Modal
			open={open}
			okText="Сформировать"
			onOk={onSendEliminationAct}
			onCancel={() => onClose()}
			width="50vw"
			centered
			zIndex={9999999999}
			title="Предпросмотр акта об устранении нарушений"
		>
			{isLoading && <LoaderSpinner />}
			<Descriptions
				title="Основные параметры:"
				layout="horizontal"
				column={12}
				bordered
				className="at-descriptions"
				labelStyle={{
					fontWeight: 'bold',
					width: '20%',
					alignItems: 'center',
					padding: '4px 8px'
				}}
				contentStyle={{
					width: '80%',
					padding: '4px 8px'
				}}
			>
				<Descriptions.Item label="Генподрядчик:" span={12}>
					{ordinance?.contractor!?.name!}
				</Descriptions.Item>
				{/* <Descriptions.Item label="Объект строительства:" span={12}>
					{ordinance?.constructionObject!?.name!}
				</Descriptions.Item> */}
			</Descriptions>

			<Descriptions
				style={{ marginTop: '1rem' }}
				title="Выбранные замечания:"
				layout="horizontal"
				column={12}
				bordered
				className="at-descriptions"
				labelStyle={{
					fontWeight: 'bold',
					width: '20%',
					alignItems: 'center',
					padding: '4px 8px'
				}}
				contentStyle={{
					width: '80%',
					padding: '4px 8px'
				}}
			>
				{selectedIssueRows.length > 0 &&
					selectedIssueRows.map(issue => (
						<>
							<Descriptions.Item label="Описание нарушения:" span={12}>
								{ordinanceIssues!?.find(elem => elem.id === issue)?.description!}
							</Descriptions.Item>
							<Descriptions.Item label="Принятые действия по устранению:" span={12}>
								{ordinanceIssues!?.find(elem => elem.id === issue)?.response!?.answer!}
							</Descriptions.Item>
							<Descriptions.Item label="Дата устранения:" span={12}>
								{ordinanceIssues!?.find(elem => elem.id === issue)!?.response!?.date! !== null
									? dayjs(
											ordinanceIssues!?.find(elem => elem.id === issue)!?.response!?.date!
									  ).format('DD.MM.YYYY')
									: ''}
							</Descriptions.Item>
							{selectedIssueRows.length > 1 && (
								<Descriptions.Item span={12}>
									<Divider />
								</Descriptions.Item>
							)}
						</>
					))}
			</Descriptions>
		</Modal>
	)
}
export default EliminationActModal
