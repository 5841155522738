import { useQuery } from "@tanstack/react-query"
import { NotificationsApi } from "../api/wbs-notification-api"
import { useNotificationsState } from "./wbs-notification-state"

export const useNotificationsQuery = () => {
	const { limit, currentPage, setTotalItems, setTotalPages, filters } = useNotificationsState()

	return useQuery({
		queryKey: ['wbsNotifications', currentPage, filters],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			const notifications = await NotificationsApi.get({
				filters,
                limit: limit,
				offset: currentPage === 1 ? 0 : currentPage * limit - limit
			})
			setTotalItems(notifications!.totalItems)
			setTotalPages(notifications!.totalPages)
			return notifications.data
		}
	})
}

export const useNotificationTypesListQuery = () => {
	return useQuery({
		queryKey: ['wbsNotificationTypesList'],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await NotificationsApi.getNotificationTypesList()
		}
	})
}

export const useWbsListQuery = () => {
	return useQuery({
		queryKey: ['wbsList'],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await NotificationsApi.getWbsList()
		}
	})
}