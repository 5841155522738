import dayjs from 'dayjs'
import { IChecklistFilters, IChecklistSort } from 'interfaces/IChecklist'
import { ICheckListTemplatesActiveUKKS, ICheckedOrders, IChecklistTemplateItem, ITemplateDTO } from 'interfaces/ITemplate'
import { API } from './ApiService'
export interface IGetData {
	createdAt?: Date
	owner?: string
	templateType?: number
}

export const getCheckListTemplates = async ({
	filters,
	limit = 50,
	offset = 0,
	title,
	templateType,
	sort
}: {
	filters?: IChecklistFilters | undefined
	limit?: number
	offset?: number
	title?: string | null
	templateType?: number | null
	sort?: IChecklistSort
}) => {
	if (filters?.isActive !== undefined) {
		filters = {
			...filters,
			isActive: Boolean(filters.isActive)
		}
	}
	if (filters?.createdAt !== undefined) {
		filters = {
			...filters,
			createdAt: dayjs(filters?.createdAt).format('YYYY-MM-DD')
		}
	}
	if (filters?.plannedDate !== undefined) {
		filters = {
			...filters,
			plannedDate: dayjs(filters?.plannedDate).format('YYYY-MM-DD')
		}
	}
	if (filters?.factDate !== undefined) {
		filters = {
			...filters,
			factDate: dayjs(filters?.factDate).format('YYYY-MM-DD')
		}
	}
	return await API({
		url:
			templateType === 1 || templateType === 0
				? `/v1/checkListTemplates?templateType=${templateType}`
				: `/v1/checkListTemplates`,
		method: 'get',
		params: { limit, offset, title, ...sort, ...filters, desc: false }
	}).then(async response => response.data)
}

export const addCheckListTemplate = async ({
	template,
	templateType
}: {
	template: ITemplateDTO[]
	templateType?: null | 0 | 1 | 2 | undefined
}) => {
	return await API({
		url:
			templateType === 1 || templateType === 0
				? `/v1/checkListTemplates?templateType=${templateType}`
				: `/v1/checkListTemplates`,
		method: 'post',
		data: template
	}).then(async response => response.data)
}

export const removeTemplateItem = async (id: string | null | undefined) => {
	return await API({
		url: `/v1/checkListTemplateItems/${id}`,
		method: 'delete'
	}).then(async response => response.data)
}

export const getChecklistTemplatesOwners = async () => {
	return await API({
		url: `/v1/checkListTemplates/owners`,
		method: 'get'
	}).then(async response => response)
}

export const getTemplatesOrders = async () => {
	return await API<ICheckedOrders>({
		url: `/v1/checkListTemplatesCheckedOrders`,
		method: 'get'
	}).then(async response => response.data)
}

export const deleteTemplate = async (id: string) => {
	return await API<ICheckedOrders>({
		url: `/v1/checkListTemplates/${id}`,
		method: 'delete'
	}).then(async response => response.data)
}

export const getTemplates = async () => {
	return await API<IChecklistTemplateItem[]>({
		url: `/v1/checkListTemplatesWithSection`,
		method: 'get'
	}).then(async response => response.data)
}

export const getCheckListTemplatesActiveUKKS = async () => {
	return await API<ICheckListTemplatesActiveUKKS[]>({
		url: '/v1/checklistTemplatesActiveUkKS',
		method: 'get'
	}).then(async response => response.data)
}
