import { Button, Card, Descriptions, Tag, message } from 'antd'
import dayjs from 'dayjs'
import { FC } from 'react'
import { DATE_FORMAT } from 'shared/constants'
import { IFine, TFineCardType } from '../model/fine-card-interface'
import { useFineState } from '../model/fine-card-state'
import { downloadFile } from 'services/AdminService'
interface IProps {
	type: TFineCardType
	data: IFine | string | undefined
	onShowOrdinance: () => void
}
export const FineCardDetails: FC<IProps> = props => {
	const data = props.data as IFine 
	const { setId, attachment} = useFineState()
	return (
		<Card className="at-workcard">
			{data!?.isAutoCreated && (
				<Tag style={{ fontSize: 14, marginBottom: '1rem' }} color="#feb300">
					Авто
				</Tag>
			)}
			{data?.isRepeated && (
				<Tag color="red" style={{ marginBottom: '1rem', fontSize: 14 }}>
					Повторный
				</Tag>
			)}
			<Descriptions
				layout="horizontal"
				column={1}
				bordered
				className="at-descriptions"
				labelStyle={{
					fontWeight: 'bold',
					width: '20%',
					alignItems: 'center',
					padding: '4px 8px'
				}}
				contentStyle={{
					width: '80%',
					padding: '4px 8px'
				}}
			>
				<Descriptions.Item label="Дата создания:">
					{dayjs(data?.createdAt).isValid() ? dayjs(data?.createdAt).format(DATE_FORMAT.view) : '-'}
				</Descriptions.Item>

				<Descriptions.Item label="Автор предписания:">{data?.createdBy?.name}</Descriptions.Item>

				<Descriptions.Item label="Проект:">{data?.project?.name}</Descriptions.Item>

				<Descriptions.Item label="Предписание:">
					{props.type === 'create' ? (
						data?.ordinance?.name
					) : (
						<Button style={{ padding: 0 }} type="link" onClick={() => props.onShowOrdinance()}>
							{data?.ordinance?.name}
						</Button>
					)}
				</Descriptions.Item>

				<Descriptions.Item label="Срок устранения нарушения после выдачи штрафа:">
					{dayjs(data?.dateTo, props.type === 'create' ? DATE_FORMAT.view : undefined).isValid()
						? dayjs(data?.dateTo, props.type === 'create' ? DATE_FORMAT.view : undefined).format(
								DATE_FORMAT.view
						  )
						: '-'}
				</Descriptions.Item>

				<Descriptions.Item label="Генподрядчик:">{data?.contractor?.name}</Descriptions.Item>

				<Descriptions.Item label="Субподрядчик:">{data?.subContractor?.name}</Descriptions.Item>
						  
				<Descriptions.Item label="Договор:">{data?.contract?.name}</Descriptions.Item>

				<Descriptions.Item label="Фаза:">{data?.phase}</Descriptions.Item>

				<Descriptions.Item label="Корпус:">{data?.building}</Descriptions.Item>

				<Descriptions.Item label="Руководитель строительства:">
					{data?.responsibleManager?.name}
				</Descriptions.Item>

				<Descriptions.Item label="Сумма штрафа:">{data?.sum?.toLocaleString()}</Descriptions.Item>
				{data?.isRepeated && data?.mainFine !== null && (
					<Descriptions.Item label="Первичный штраф:">
						<Button
							style={{ textAlign: 'left', padding: 0 }}
							block
							type="link"
							onClick={() => setId('inner', data?.mainFine?.id!)}
						>
							{`№${data?.mainFine?.name ?? data?.mainFine?.number} от ${dayjs(data?.mainFine?.createdAt).format(
								DATE_FORMAT.view
							)}`}
						</Button>
					</Descriptions.Item>
				)}
				{data?.ordinanceFineStatus?.identityName === 'Canceled' && (
					<Descriptions.Item label="Причина снятия:">{data?.cancelationReason}</Descriptions.Item>
				)}
				{attachment && (
								<Descriptions.Item label="Файл штрафа:">
									<Button
										type="link"
										block
										style={{ textAlign: 'left', padding: 0 }}
										onClick={() =>
											downloadFile(attachment.id)
												.then(response => {
													const href = URL.createObjectURL(response.data)
													const link = document.createElement('a')
													link.href = href
													link.setAttribute('download', attachment.name + attachment.extension)
													document.body.appendChild(link)
													link.click()
													document.body.removeChild(link)
													URL.revokeObjectURL(href)
												})
												.catch(() => message.error('Во время загрузки файла произошла ошибка'))
										}
									>				
									{attachment?.name?.length>=50 && attachment?.name?.slice(0,50)+"..."}
								</Button>
						  		</Descriptions.Item>
						  )
						  }
			</Descriptions>
		</Card>
	)
}
