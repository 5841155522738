import { Row } from '@tanstack/react-table'
import { all, create } from 'mathjs'
import { FC } from 'react'
import { IAdjustmentPosition, IAdjustmentPositionMaterial } from '..'
const config = {}
const math = create(all, config)

interface IProps {
	type: 'include' | 'exclude'
	subType: 'origin' | 'second'
	row: Row<IAdjustmentPosition | IAdjustmentPositionMaterial>
	cellType: 'amountWithRate' | 'priceMaterial' | 'priceService' | 'totalSum'
}

export const AdjustmentIndentCell: FC<IProps> = ({ type, subType, row, cellType }) => {
	const original = row.original
	const parent = row.getParentRow()?.original

	const variants = {
		original:
			subType === 'origin'
				? {
						amountWithRate: [original.amountWithRate, original.amountWithRateSecond],
						priceMaterial: [original.priceMaterial, original.priceMaterialSecond],
						priceService: [
							'workName' in original ? original.priceService : null,
							'workName' in original ? original.priceServiceSecond : null
						],
						totalSum: [
							'workName' in original ? original.totalSum : 0,
							'workName' in original ? original.totalSumSecond : 0
						]
				  }
				: {
						amountWithRate: [original.amountWithRateSecond, original.amountWithRateSdu],
						priceMaterial: [original.priceMaterialSecond, original.priceMaterialSdu],
						priceService: [
							'workName' in original ? original.priceServiceSecond : null,
							'workName' in original ? original.priceServiceSdu : null
						],
						totalSum: [
							'workName' in original ? original.totalSumSecond : 0,
							'workName' in original ? original.totalSumSdu : 0
						]
				  },
		parent:
			subType === 'origin'
				? {
						amountWithRate: [parent?.amountWithRate, parent?.amountWithRateSecond],
						priceMaterial: [parent?.priceMaterial, parent?.priceMaterialSecond],
						priceService: [
							parent && 'workName' in parent ? parent.priceService : null,
							parent && 'workName' in parent ? parent.priceServiceSecond : null
						],
						totalSum: [
							parent && 'workName' in parent ? parent.totalSum : 0,
							parent && 'workName' in parent ? parent.totalSumSecond : 0
						]
				  }
				: {
						amountWithRate: [parent?.amountWithRateSecond, parent?.amountWithRateSdu],
						priceMaterial: [parent?.priceMaterialSecond, parent?.priceMaterialSdu],
						priceService: [
							parent && 'workName' in parent ? parent.priceServiceSecond : null,
							parent && 'workName' in parent ? parent.priceServiceSdu : null
						],
						totalSum: [
							parent && 'workName' in parent ? parent.totalSumSecond : 0,
							parent && 'workName' in parent ? parent.totalSumSdu : 0
						]
				  }
	}

	const difference = {
		original:
			subType === 'origin'
				? {
						amountWithRate: original.amountWithRate - original.amountWithRateSecond,
						priceMaterial: original.priceMaterial - original.priceMaterialSecond,
						priceService:
							('workName' in original ? original.priceService : 0) -
							('workName' in original ? original.priceServiceSecond : 0),
						totalSum:
							('workName' in original ? original.totalSum : 0) -
							('workName' in original ? original.totalSumSecond : 0)
				  }
				: {
						amountWithRate: original.amountWithRateSecond - original.amountWithRateSdu,
						priceMaterial: original.priceMaterialSecond - original.priceMaterialSdu,
						priceService:
							('workName' in original ? original.priceServiceSecond : 0) -
							('workName' in original ? original.priceServiceSdu : 0),
						totalSum:
							('workName' in original ? original.totalSumSecond : 0) -
							('workName' in original ? original.totalSumSdu : 0)
				  },
		parent:
			subType === 'origin'
				? {
						amountWithRate: parent ? parent?.amountWithRate - parent?.amountWithRateSecond : 0,
						priceMaterial: parent ? parent?.priceMaterial - parent?.priceMaterialSecond : 0,
						priceService:
							(parent && 'workName' in parent ? parent.priceService : 0) -
							(parent && 'workName' in parent ? parent.priceServiceSecond : 0),
						totalSum:
							(parent && 'workName' in parent ? parent.totalSum : 0) -
							(parent && 'workName' in parent ? parent.totalSumSecond : 0)
				  }
				: {
						amountWithRate: parent ? parent.amountWithRateSecond - parent.amountWithRateSdu : 0,
						priceMaterial: parent ? parent.priceMaterialSecond - parent.priceMaterialSdu : 0,
						priceService:
							(parent && 'workName' in parent ? parent.priceServiceSecond : 0) -
							(parent && 'workName' in parent ? parent.priceServiceSdu : 0),
						totalSum:
							(parent && 'workName' in parent ? parent.totalSumSecond : 0) -
							(parent && 'workName' in parent ? parent.totalSumSdu : 0)
				  }
	}

	const getTotalSum = () => {
		const { amountWithRate, priceMaterial } = variants.original
		const a = amountWithRate[type === 'exclude' ? 0 : 1]
		const b = priceMaterial[type === 'exclude' ? 0 : 1]
		return a * b
	}

	const getValue = () => {
		const { amountWithRate, priceMaterial, priceService, totalSum } = variants.original
		const { priceMaterial: priceMaterialParent, priceService: priceServiceParent } = variants.parent

		const amountDifference =
			(typeof parent !== 'undefined' && difference.parent.amountWithRate) ||
			difference.original.amountWithRate

		const isPriceDifference =
			(typeof parent !== 'undefined' &&
				(priceMaterialParent[0] !== priceMaterialParent[1] ||
					priceServiceParent[0] !== priceServiceParent[1])) ||
			priceMaterial[0] !== priceMaterial[1] ||
			priceService[0] !== priceService[1]

		switch (true) {
			case cellType === 'totalSum' && isPriceDifference:
				return 'materialName' in original ? getTotalSum() : totalSum[type === 'exclude' ? 0 : 1]

			case cellType === 'totalSum' && type === 'include' && amountDifference < 0:
				return 'materialName' in original
					? difference.original.amountWithRate * priceMaterial[0] * -1
					: (totalSum[0] - totalSum[1]) * -1

			case cellType === 'totalSum' && type === 'exclude' && amountDifference > 0:
				return 'materialName' in original
					? difference.original.amountWithRate * priceMaterial[0]
					: totalSum[0] - totalSum[1]

			case cellType === 'amountWithRate' && type === 'exclude' && isPriceDifference:
				return amountWithRate[0]

			case cellType === 'amountWithRate' && type === 'exclude' && amountDifference > 0:
				return difference.original.amountWithRate

			case cellType === 'amountWithRate' && type === 'include' && isPriceDifference:
				return amountWithRate[1]

			case cellType === 'amountWithRate' && type === 'include' && amountDifference < 0:
				return difference.original.amountWithRate * -1

			case cellType === 'priceMaterial' && type === 'exclude' && amountDifference > 0:
				return priceMaterial[0]

			case cellType === 'priceService' && type === 'exclude' && amountDifference > 0:
				return priceService[0]

			case cellType === 'priceMaterial' && type === 'include' && amountDifference < 0:
				return priceMaterial[1]

			case cellType === 'priceService' && type === 'include' && amountDifference < 0:
				return priceService[1]

			case (cellType === 'priceMaterial' || cellType === 'priceService') && isPriceDifference:
				return variants.original[cellType][type === 'exclude' ? 0 : 1]

			default:
				return null
		}
	}

	return (
		<div
			className={`adjustment-indent-${
				cellType === 'totalSum' && 'materialName' in original ? 'danger' : type
			} ${
				type === 'exclude' &&
				getValue() !== null &&
				(cellType === 'amountWithRate' || cellType === 'totalSum')
					? type === 'exclude' && subType === 'origin' && original.isUnrelated
						? ''
						: 'adjustment-indent-exclude--minus'
					: ''
			}`}
		>
			{type === 'exclude' && subType === 'origin' && original.isUnrelated
				? null
				: getValue() !== null &&
				  math.round(Number(getValue()), cellType === 'amountWithRate' ? 3 : 2)}
		</div>
	)
}
