import { useQueryClient } from '@tanstack/react-query'
import { Form, Input, Modal, Select, message } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC } from 'react'
import { IViolationDto, violationsService } from 'services/violationsService'

interface IProps {
	show: boolean
	onCloseModal: () => void
}

export const AddViolationModal: FC<IProps> = props => {
	const queryClient = useQueryClient()
	const { show, onCloseModal } = props
	const [addViolationForm] = Form.useForm<IViolationDto>()
	const { violationTypes } = useAppSelector(state => state.environment)

	const onAddViolation = async () => {
		const formData = addViolationForm.getFieldsValue()
		await violationsService
			.add(formData)
			.then(() => {
				queryClient.invalidateQueries({ queryKey: ['violations'] })
				onClose()
			})
			.catch(() => message.error('Во время сохранения произошла ошибка'))
	}

	const onClose = () => {
		addViolationForm.resetFields()
		onCloseModal()
	}

	return (
		<Modal
			centered
			open={show}
			onCancel={onClose}
			title="Новое нарушение"
			onOk={() => addViolationForm.submit()}
			okText="Создать"
		>
			<Form
				form={addViolationForm}
				name="addViolationForm"
				onFinish={onAddViolation}
				layout="vertical"
			>
				<Form.Item
					name="name"
					label="Наименование"
					rules={[{ required: true, message: 'Укажите наименование' }]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					name="violationTypeId"
					label="Связанные типы нарушений"
					rules={[{ required: true, message: 'Укажите типы нарушений' }]}
				>
					<Select
						mode="multiple"
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						filterSort={(a, b) =>
							(a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
						}
						options={violationTypes?.map(o => ({
							key: o.id,
							value: o.id,
							label: o.name
						}))}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
}
