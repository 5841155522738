import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'hooks/appReduxHook'
import { getPlannedDates } from 'services/PlannedDatesService'
import { usePlannedDatesState } from './planned-dates-state'

export const usePlanedDates = () => {
	const { project } = useAppSelector(state => state.environment)
	const { pagination } = usePlannedDatesState()
	return useQuery({
		queryKey: ['templatesPlanedDate', project, pagination.currentPage],
		queryFn: async () => {
			if (project) {
				return await getPlannedDates(pagination.currentPage).then(res => {
					usePlannedDatesState.setState({
						pagination: {
							...pagination,
							totalItems: res.totalItems
						}
					})
					return res.data
				})
			}
			return null
		}
	})
}
