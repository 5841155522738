import { Input } from 'antd'
import { memo } from 'react'
import { IEstimatePosition, IEstimatePositionMaterial, useEstimateState } from '..'

interface IProps {
	row: IEstimatePosition | IEstimatePositionMaterial
	type: 'noteSDU' | 'noteDZ' | 'noteDP'
}
export const EstimateNoteCell = memo(function (params: IProps) {
	const { type, row } = params
	const { setNote } = useEstimateState()
	return type in row && row[type] && (row[type]! as string).length > 0 ? (
		<Input.TextArea
			onFocus={e => e.currentTarget.blur()}
			data-attr={`${row.id}_input${type}${'workName' in row ? 'Work' : 'Material'}`}
			autoSize={false}
			style={{
				borderRadius: 0,
				maxHeight: 32,
				height: '100%',
				background: 'transparent',
				color: '#444',
				cursor: 'pointer',
				resize: 'none'
			}}
			value={row[type] ?? ''}
			onClick={() => setNote(row, type)}
		/>
	) : (
		<p
			style={{
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				margin: 0,
				cursor: 'pointer'
			}}
			onClick={() => setNote(row, type)}
		>
			{'-'}
		</p>
	)
})
