import { IAppUser, IUserPermission } from 'interfaces/IUser'

export const accessVerification = (
	user: IAppUser | null,
	permissions: IUserPermission[] | null,
	checking: string[],
	checkType: 'every' | 'some' = 'some'
): boolean => {
	const isAdmin = user?.isAdmin ?? false
	if (isAdmin) return true
	else {
		const isAvailable = permissions
			? checkType === 'every'
				? checking.every(check => typeof permissions.find(p => p.name === check) !== 'undefined')
				: checking.some(check => typeof permissions.find(p => p.name === check) !== 'undefined')
			: false
		// if (!isAvailable) {
		// 	message.warning('Доступ запрещён')
		// }
		return isAvailable
	}
}
