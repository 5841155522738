import { DownloadOutlined, FileExcelOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuProps, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { IContainer } from 'interfaces/IContainer'
import { useLocalEstimateState } from 'pages/unmodelPage/localEstimate/model/localEstimateState'
import { FC, useRef } from 'react'
import { exportService } from 'services/exportService'
import { EstimateImportData } from 'widgets/estimate-import-data'
import { useWbsPermits } from '../model/useWbsPermits'

interface IProps {
	row: IContainer
	registry?: 'wbsRegistry' | 'localEstimateRegistry'
}

export const WbsToExcel: FC<IProps> = ({ row, registry }) => {
	const wbs = { id: row.id, name: row.name, createdAt: row.createdDate }
	const title = 'Импорт / экспорт Excel'
	const wbsPermissions = useWbsPermits()
	const uploadRef = useRef<any>(null)
	const { user: currentUser } = useAppSelector(state => state.environment)
	const { container } = useLocalEstimateState()
	const isRedactor = row?.redactors.some(r => r.id === currentUser?.id)
	const items: MenuProps['items'] = [
		{
			key: 'withPrices',
			label: <div data-attr="buttonDownloadWithPrice">Выгрузка с ценами</div>,
			icon: <DownloadOutlined />,
			disabled:
				registry === 'localEstimateRegistry'
					? !wbsPermissions.canLocalExport
					: !wbsPermissions.canExport,
			onClick: () =>
				exportService.exportWbsToExcel(
					wbs,
					registry === 'localEstimateRegistry' ? 'local' : 'wbs',
					true
				)
		},
		{
			key: 'withoutPrices',
			label: <div data-attr="buttonDownloadWithoutPrice">Выгрузка без цен</div>,
			icon: <DownloadOutlined />,
			disabled:
				registry === 'localEstimateRegistry'
					? !wbsPermissions.canLocalExport
					: !wbsPermissions.canExport,
			onClick: () =>
				exportService.exportWbsToExcel(wbs, registry === 'localEstimateRegistry' ? 'local' : 'wbs')
		},
		{
			key: 'import',
			label: 'Загрузить',
			icon: <UploadOutlined />,
			disabled:
				registry === 'localEstimateRegistry'
					? row.ready ||
					  !wbsPermissions.canLocalImport ||
					  !(
							wbsPermissions.canLocalImport &&
							wbsPermissions.canLocalExternal &&
							(isRedactor || currentUser?.isAdmin)
					  )
					: row.ready || !wbsPermissions.canImport,
			onClick: () => uploadRef.current!.click()
		}
	]

	return (
		<>
			<Dropdown menu={{ items }} trigger={['click']} placement="bottom">
				<Tooltip title={title}>
					<Button
						data-attr="buttonExcel"
						style={{ borderColor: 'green', color: 'green' }}
						size="middle"
						icon={<FileExcelOutlined />}
					></Button>
				</Tooltip>
			</Dropdown>
			<EstimateImportData
				containerId={row?.id}
				disabled={row?.ready}
				trigger={uploadRef}
				registry={registry}
				container={row}
			/>
		</>
	)
}
