import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Divider } from 'antd'
const rowStyle: React.CSSProperties = {
	display: 'flex',
	flexDirection: 'row',
	border: '1px black solid',
	boxSizing: 'border-box'
}
const statusStyle: React.CSSProperties = {
	minWidth: 67,
	height: 67,
	borderRight: '1px black solid',
	boxSizing: 'border-box',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}
const dataStyle: React.CSSProperties = {
	minWidth: 130,
	height: 67,
	borderRight: '1px black solid',
	boxSizing: 'border-box',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}
const titleStyle: React.CSSProperties = {
	paddingLeft: 16
}

const Legend = () => (
	<div
		className="light-report-legend"
		style={{
			width: 650
		}}
	>
		{/* <Divider style={{ margin: 0 }}>Статус инженерных систем</Divider>
		<div style={{ boxSizing: 'border-box' }}>
			<div style={{ ...rowStyle, borderBottom: 'none' }}>
				<div style={statusStyle}>
					<span
						style={{
							width: 16,
							height: 16,
							background: 'radial-gradient(farthest-corner at 6px 6px, #ff0000, #990003',
							borderRadius: 8
						}}
					></span>
				</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>Не предъявлялось для приемки</div>
			</div>
			<div style={{ ...rowStyle, borderBottom: 'none' }}>
				<div style={statusStyle}>
					<span
						style={{
							width: 16,
							height: 16,
							background: 'radial-gradient(farthest-corner at 6px 6px, #faad14, #b96b05',
							borderRadius: 8
						}}
					></span>
				</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>Не принято, замечания</div>
			</div>
			<div style={{ ...rowStyle, borderBottom: 'none' }}>
				<div style={statusStyle}>
					<span
						style={{
							width: 16,
							height: 16,
							background: 'radial-gradient(farthest-corner at 6px 6px, #52c41a, #076751',
							borderRadius: 8
						}}
					></span>
				</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>Принято</div>
			</div>
			<div style={rowStyle}>
				<div style={statusStyle}>
					<span
						style={{
							width: 16,
							height: 16,
							background: 'radial-gradient(farthest-corner at 6px 6px, #1677ff, #093a7e',
							borderRadius: 8
						}}
					></span>
				</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>
					Принято под гарантийное обязательство застройщика
				</div>
			</div>
		</div> */}

		<Divider style={{ margin: 0 }}>Статус инженерных систем</Divider>
		<div style={{ boxSizing: 'border-box' }}>
			<div style={{ ...rowStyle, borderBottom: 'none' }}>
				<div style={statusStyle}>
					<span
						style={{
							width: 20,
							height: 20,
							background: 'radial-gradient(farthest-corner at 6px 6px, #ff0000, #990003',
							borderRadius: 10
						}}
					></span>
				</div>
				<div style={dataStyle}>25.05.2024</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>
					Система не предъявлялась УК. Дата передачи системы по ABS Plan не просрочена
				</div>
			</div>

			<div style={{ ...rowStyle, borderBottom: 'none' }}>
				<div style={statusStyle}>
					<span
						style={{
							width: 20,
							height: 20,
							background: 'radial-gradient(farthest-corner at 6px 6px,  #ff0000, #990003',
							borderRadius: 10
						}}
					></span>
				</div>
				<div style={{ ...dataStyle, color: 'red' }}>25.05.2023</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>
					Система не предъявлялась УК. Дата передачи системы по ABS Plan просрочена
				</div>
			</div>

			<div style={{ ...rowStyle, borderBottom: 'none' }}>
				<div style={statusStyle}>
					<span
						style={{
							width: 20,
							height: 20,
							background: 'radial-gradient(farthest-corner at 6px 6px, #faad14, #b96b05',
							borderRadius: 10
						}}
					></span>
				</div>
				<div style={dataStyle}>05.02.2024</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>
					Система предъявлялась УК, есть замечания. Указана дата проверки. Дата передачи системы по ABS Plan не
					просрочена
				</div>
			</div>

			<div style={{ ...rowStyle, borderBottom: 'none' }}>
				<div style={statusStyle}>
					<span
						style={{
							width: 20,
							height: 20,
							background: 'radial-gradient(farthest-corner at 6px 6px, #faad14, #b96b05',
							borderRadius: 10
						}}
					></span>
				</div>
				<div style={{ ...dataStyle, color: 'red' }}>05.02.2024</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>
					Система предъявлялась УК, есть замечания. Указана дата проверки. Дата передачи системы по ABS Plan просрочена
				</div>
			</div>

			<div style={{ ...rowStyle, borderBottom: 'none' }}>
				<div style={statusStyle}>
					<span
						style={{
							width: 20,
							height: 20,
							background: 'radial-gradient(farthest-corner at 6px 6px, #faad14, #b96b05',
							borderRadius: 10
						}}
					></span>
				</div>
				<div style={dataStyle}>
					<div style={{ display: 'block' }}>
						<div style={{ marginLeft: '5px' }}>06.02.2024</div>
						<div style={{ marginLeft: '5px' }}>13.02.2024</div>
					</div>
				</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>
					Система предъявлялась УК, есть замечания. Указаны: дата первой проверки, дата самой крайний проверки. Дата
					передачи системы по ABS Plan не просрочена
				</div>
			</div>

			<div style={{ ...rowStyle, borderBottom: 'none' }}>
				<div style={statusStyle}>
					<span
						style={{
							width: 20,
							height: 20,
							background: 'radial-gradient(farthest-corner at 6px 6px, #faad14, #b96b05',
							borderRadius: 10
						}}
					></span>
				</div>
				<div style={dataStyle}>
					<div style={{ display: 'block' }}>
						<div style={{ marginLeft: '5px', color: 'red' }}>06.02.2024</div>
						<div style={{ marginLeft: '5px', color: 'red' }}>13.02.2024</div>
					</div>
				</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>
					Система предъявлялась УК, есть замечания. Указаны: дата первой проверки, дата самой крайний проверки. Дата
					передачи системы по ABS Plan просрочена
				</div>
			</div>

			<div style={{ ...rowStyle, borderBottom: 'none' }}>
				<div style={statusStyle}>
					<span
						style={{
							width: 20,
							height: 20,
							background: 'radial-gradient(farthest-corner at 6px 6px, #faad14, #b96b05',
							borderRadius: 10
						}}
					></span>
				</div>
				<div style={dataStyle}>
					<div style={{ display: 'block' }}>
						<div style={{ marginLeft: '5px' }}>06.02.2024</div>
						<div style={{ marginLeft: '5px', color: 'red' }}>13.02.2024</div>
					</div>
				</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>
					Система предъявлялась УК, есть замечания. Указаны: дата первой проверки, дата самой крайний проверки. Дата
					передачи системы по ABS Plan просрочена
				</div>
			</div>

			<div style={{ ...rowStyle, borderBottom: 'none' }}>
				<div style={statusStyle}>
					<span
						style={{
							width: 20,
							height: 20,
							background: 'radial-gradient(farthest-corner at 6px 6px, #1677ff, #093a7e',
							borderRadius: 10
						}}
					></span>
				</div>
				<div style={dataStyle}>05.02.2024</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>
					Система принята УК под гарантийное обязательство застройщика
				</div>
			</div>

			<div style={rowStyle}>
				<div style={statusStyle}>
					<span
						style={{
							width: 20,
							height: 20,
							background: 'radial-gradient(farthest-corner at 6px 6px, #52c41a, #076751',
							borderRadius: 10
						}}
					></span>
				</div>
				<div style={dataStyle}>05.02.2024</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>Система принята УК</div>
			</div>
		</div>

		<Divider style={{ marginBottom: 0 }}>Степень критичности для эксплуатации</Divider>
		<div style={{ boxSizing: 'border-box' }}>
			<div style={{ ...rowStyle }}>
				<div style={{ ...statusStyle }}>
					<ExclamationCircleOutlined
						style={{
							fontSize: '36px',
							color: '#ff4d4f',
							padding: '.3rem'
						}}
					/>
				</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>Высокая</div>
			</div>
		</div>
		{/* <div style={rowStyle}>
				<div style={{ ...statusStyle }}>
					<div
						style={{
							width: '10px',
							height: '10px',
							background: '#1677ff',
							color: '#fff',
							padding: '.3rem',
							border: '1px solid #183e75',
							borderRadius: '2px',
							cursor: 'help'
						}}
					/>
				</div>
				<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>Низкая</div>
			</div> */}
		{/* </div> */}
		{/* <div style={{ boxSizing: 'border-box', marginTop: 24 }}>
			<div style={rowStyle}>
				<div style={titleStyle}>Изменения по статусу состояния, прошедшие за отчетный период</div>
			</div>
		</div> */}

		{/* <Divider style={{ marginBottom: 0 }}>Изменение по срокам</Divider>
		<div style={{ boxSizing: 'border-box' }}>
			<div style={{ ...rowStyle, borderBottom: 'none' }}>
				<div style={{ ...statusStyle, width: 100 }}>
					<div>01.12.2022</div>
				</div>
				<div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%' }}>
					<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>Планируемая дата устранения</div>
				</div>
			</div>
			<div style={{ boxSizing: 'border-box' }}>
				<div style={rowStyle}>
					<div style={{ ...statusStyle, width: 100, color: 'red' }}>
						<div>01.12.2022</div>
					</div>
					<div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%' }}>
						<div style={{ ...titleStyle, display: 'flex', alignItems: 'center' }}>
							Дата проверки просрочена, необходимо задать новую дату
						</div>
					</div>
				</div>
				<div style={{ boxSizing: 'border-box' }}>
					<div style={{ ...rowStyle, borderTop: 'none' }}>
						<div style={{ ...statusStyle, width: 100, display: 'block' }}>
							<div style={{ marginLeft: '5px' }}>01.12.2022</div>
							<div style={{ marginLeft: '5px' }}>01.12.2022</div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%' }}>
							<div style={{ ...titleStyle, display: 'block', alignItems: 'center' }}>
								<div>Первая плановая дата проверки</div>
								<div>Крайняя плановая дата проверки</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div> */}

		{/* <Divider style={{ marginBottom: 0 }}>Степень критичности для эксплуатации</Divider>
		<div style={{ boxSizing: 'border-box' }}>
			<div style={rowStyle}>
				<div style={{ ...statusStyle, border: '3px #ff4d4f solid' }}></div>
				<div style={titleStyle}>Проблемы критичные для ремонта в квартирах жителями</div>
			</div>
			<div style={rowStyle}>
				<div style={{ ...statusStyle, border: '3px #6100e9 solid' }}></div>
				<div style={titleStyle}>Проблемы критичные для работы инженерных систем</div>
			</div>
		</div> */}
	</div>
)

export default Legend
