import { CloseOutlined } from '@ant-design/icons'
import { Card, Descriptions, Form, FormInstance, Input, Switch } from 'antd'
import { FC } from 'react'

interface IProps {
	index: number
	field: {
		fieldKey?: number | undefined
		isListField?: boolean
		key: number
		name: number
	}
	form: FormInstance
	draggable: boolean
	remove: (index: number | number[]) => void
	isEdited: boolean
	removeNow: (name: number) => void
	idx: number
	currentTemplateType: string
	allDisabled: boolean
}

const Item: FC<IProps> = ({
	index,
	field,
	form,
	draggable,
	remove,
	isEdited,
	removeNow,
	idx,
	currentTemplateType,
	allDisabled
}) => {
	const currentSection = form.getFieldValue([`sections`, idx])

	return (
		<div draggable={draggable}>
			<Form.Item style={{ width: '100%', margin: 0 }} name={[field.name, 'item']}>
				<Card className="at-workcard">
					<CloseOutlined
						onClick={() => {
							if (!allDisabled) {
								if (isEdited) {
									removeNow(field.key)
								}
								remove(field.name)
							}
						}}
						style={{
							fontSize: '16px',
							padding: '.5rem',
							fontWeight: 'bold',
							position: 'absolute',
							top: '-18px',
							right: '18px',
							cursor: 'pointer',
							zIndex: 1,
							background: '#f2f2f2',
							border: '1px solid #ddd',
							borderRadius: '50px'
						}}
						rev={undefined}
					/>
					<Descriptions
						column={12}
						bordered
						labelStyle={{
							fontWeight: 'bold',
							width: '30%',
							alignItems: 'center',
							padding: '2px 8px'
						}}
						contentStyle={{
							width: '70%',
							padding: '0px 8px'
						}}
						className="at-descriptions"
					>
						<Descriptions.Item
							label={
								<label>
									{'Пункт № ' + (index + 1)}
									<label style={{ color: 'red' }}>*</label>
								</label>
							}
							span={12}
						>
							{allDisabled ? (
								<p style={{ padding: '4px', margin: 0 }}>{currentSection.items[index].name}</p>
							) : (
								<>
									<Form.Item
										{...field}
										name={[field.name, 'name']}
										rules={[{ required: true, message: 'Укажите название' }]}
									>
										<Input disabled={allDisabled} />
									</Form.Item>
								</>
							)}
						</Descriptions.Item>
						<Descriptions.Item label="Описание" span={12}>
							{allDisabled ? (
								<p style={{ padding: '4px', margin: 0 }}>
									{currentSection.items[index].description}
								</p>
							) : (
								<Form.Item {...field} name={[field.name, 'description']}>
									<Input.TextArea disabled={allDisabled} />
								</Form.Item>
							)}
						</Descriptions.Item>
					</Descriptions>

					<Descriptions layout="horizontal" column={12} className="at-checklist-descriptions">
						<Descriptions.Item span={6} style={{ padding: '8px 0' }}>
							<Form.Item
								className="remove-after"
								labelAlign="left"
								labelCol={{ span: 16 }}
								label="Создать замечание"
								{...field}
								name={[field.name, 'checkboxIssue']}
							>
								<Switch
									size="small"
									title="Создавать замечание при отрицательном ответе"
									defaultChecked={!!currentSection?.items[index]?.checkboxIssue}
									disabled={allDisabled}
								/>
							</Form.Item>
						</Descriptions.Item>

						{currentTemplateType === '1' && (
							<Descriptions.Item span={6} style={{ padding: '8px 0' }}>
								<Form.Item
									className="remove-after"
									labelCol={{ span: 16 }}
									labelAlign="left"
									style={{ color: 'red' }}
									label="Критичен для управляющей компании"
									{...field}
									name={[field.name, 'checkboxUk']}
								>
									<Switch
										size="small"
										title="Критичен для управляющей компании"
										defaultChecked={!!currentSection?.items[index]?.checkboxUk}
										disabled={allDisabled}
									/>
								</Form.Item>
							</Descriptions.Item>
						)}

						<Descriptions.Item span={6} style={{ padding: '8px 0' }}>
							<Form.Item
								className="remove-after"
								labelAlign="left"
								labelCol={{ span: 16 }}
								label="Прикрепить файл"
								{...field}
								name={[field.name, 'checkboxFile']}
							>
								<Switch
									size="small"
									title="Прикреплять файл"
									defaultChecked={!!currentSection?.items[index]?.checkboxFile}
									disabled={allDisabled}
								/>
							</Form.Item>
						</Descriptions.Item>

						{currentTemplateType === '1' && (
							<Descriptions.Item span={6} style={{ padding: '8px 0' }}>
								<Form.Item
									className="remove-after"
									labelAlign="left"
									labelCol={{ span: 16 }}
									label="Критичен для передачи собственнику"
									{...field}
									name={[field.name, 'checkboxCritical']}
								>
									<Switch
										size="small"
										title="Критичен для передачи собственнику"
										defaultChecked={!!currentSection?.items[index]?.checkboxCritical}
										disabled={allDisabled}
									/>
								</Form.Item>
							</Descriptions.Item>
						)}
					</Descriptions>
				</Card>
			</Form.Item>
		</div>
	)
}

export default Item
