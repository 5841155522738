import { create } from 'zustand'

interface IAdjustmentPositionCommentsState {
	show: boolean
	currentId: string | null
	sectionId: string | null
}

const initCommentsState = {
	show: false,
	currentId: null,
	sectionId: null
}

export const useAdjustmentPositionCommentsState = create<IAdjustmentPositionCommentsState>(set => ({
	...initCommentsState
}))
