import { Button } from 'antd'
import { FC, useState } from 'react'
import { AddViolationModal } from './add-violation-modal'
import { PlusSquareOutlined } from '@ant-design/icons'

interface IProps {}

export const AddViolation: FC<IProps> = () => {
	const [showModal, setShowModal] = useState(false)

	return (
		<>
			<AddViolationModal show={showModal} onCloseModal={() => setShowModal(false)} />
			<Button icon={<PlusSquareOutlined />} type="primary" onClick={() => setShowModal(true)}>
				Добавить
			</Button>
		</>
	)
}
