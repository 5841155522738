import { IPagination } from 'interfaces/IPagination';
import { API } from 'services/ApiService';
import { IEstimateComment } from '..';

export const EstimateCommentsApi = {
	async getAll(containerId: string, isCommentsClosed?: boolean, myComments?: boolean, type?: 'wbs' | 'local') {
		return await API<IPagination<IEstimateComment[]>>({
			url: `/v1/${type === 'wbs' ? 'containers' : 'containerEstimates'}/${containerId}/estimatePositionsComments`,
			method: 'get',
			params: { isCommentsClosed, myComments }
		}).then(async response => response.data)
	},

	async getAuthors(id: string) {
		return await API<boolean>({
			url: `/v1/estimatePositions/${id}/comments/authorCommentsStatus`,
			method: 'get'
		}).then(async response => response.data)
	}
}
