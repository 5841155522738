import { Input } from 'antd'
import { FC } from 'react'

interface IProps {
	currentValue?: string
	onSearch: (value: string) => void
}

export const SearchLine: FC<IProps> = ({ currentValue, onSearch }) => {
	return (
		<Input.Search
			placeholder="Поиск..."
			defaultValue={currentValue}
			onSearch={onSearch}
			allowClear
			style={{ width: 444 }}
		/>
	)
}
