import { Card, Col, Row, Space } from 'antd'
import { FC, Fragment, ReactNode } from 'react'
import './DashboardLayout.sass'

interface IProps {
	headerLeft?: ReactNode[]
	headerRight?: ReactNode[]
	body?: ReactNode
	overlays?: ReactNode[]
}

export const DashboardLayout: FC<IProps> = props => {
	const { headerLeft, headerRight, body, overlays } = props
	return (
		<Card size="small" className="dashboard-layout" classNames={{ body: 'dashboard-layout__body' }}>
			{overlays?.map((drawer, index) => (
				<Fragment key={`dashboard-overlays-${index}`}>{drawer}</Fragment>
			))}
			<Space direction="vertical" style={{ width: '100%' }}>
				<Card size="small">
					<Row align="middle" justify="space-between">
						<Col>
							<Row align="middle" gutter={8}>
								{headerLeft?.map((item, index) => (
									<Col key={`dashboard-header-left-${index}`}>{item}</Col>
								))}
							</Row>
						</Col>
						<Col>
							<Row align="middle" gutter={8}>
								{headerRight?.map((item, index) => (
									<Col key={`dashboard-header-right-${index}`}>{item}</Col>
								))}
							</Row>
						</Col>
					</Row>
				</Card>
				<Card size="small">
					<div id="wrapper">{body}</div>
				</Card>
			</Space>
		</Card>
	)
}
