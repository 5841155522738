import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from './appReduxHook'
import { getAllContactPersons } from 'services/EnvironmentService'

export const useContactPersons = () => {
	const { project } = useAppSelector(state => state.environment)
	return useQuery({
		queryKey: ['contactPersons', project],
		queryFn: async () => {
			return await getAllContactPersons()
		}
	})
}
