import { useQuery } from '@tanstack/react-query'
import { IData } from 'interfaces/IChecklist'
import { getChecklists } from 'services/ChecklistsService'
import { setTotalItems } from 'store/checklistsSlice'
import { useAppDispatch, useAppSelector } from './appReduxHook'

export const useCheckLists = () => {
	const projectId = useAppSelector(state => state.environment.project?.id)
	const filters = useAppSelector(state => state.checklists.filters)
	const currentPage = useAppSelector(state => state.checklists.listState.currentPage)
	const sortState = useAppSelector(state => state.checklists.sortState)
	const dispatch = useAppDispatch()
	return useQuery<IData[]>({
		queryKey: ['checkListList', projectId, filters, currentPage, sortState],
		queryFn: async () => {
			const checkList = await getChecklists({
				sortState,
				filters: filters,
				offset: currentPage! > 1 ? currentPage! * 50 - 50 : 0
			}).then(response => response)
			dispatch(setTotalItems(checkList.totalItems))
			return [...checkList.data.checkListLevel, ...checkList.data.checkListWorkPackageLevel]
		},
		refetchOnWindowFocus: false
	})
}
