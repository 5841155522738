import { LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Tooltip } from 'antd'
import { memo } from 'react'
import { useEstimateState } from '..'

interface IProps {
	id: string
	sectionId: string
	parentId?: string
	isClosed?: boolean
}

export const EstimatePositionClosed = memo(function (params: IProps) {
	const { id, sectionId, parentId, isClosed } = params
	const { closedEstimatePosition } = useEstimateState()
	return (
		<Popconfirm
			title={isClosed ? 'Отменить закрытие' : 'Закрыть?'}
			description={`Вы уверены, что хотите ${
				isClosed ? 'отменить закрытие по данной позиции сметы' : 'закрыть эту позицию сметы'
			}?`}
			onConfirm={() => closedEstimatePosition(id, sectionId, isClosed!)}
		>
			<Tooltip title={isClosed ? 'Отменить закрытие по ПО?' : 'Закрыть по ПО?'}>
				<Button
					size="small"
					type="text"
					danger
					icon={isClosed ? <LockOutlined /> : <UnlockOutlined />}
				/>
			</Tooltip>
		</Popconfirm>
	)
})
