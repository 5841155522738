import { create } from 'zustand'

interface IState {
	showModal: boolean
	idExpand?: string[]
}
interface IStateActions {
	toggleShowModal: (state?: boolean) => void
}

export const useEstimateRelatedDocumentsState = create<IState & IStateActions>((set, get) => ({
	idExpand: [],
	showModal: false,
	toggleShowModal: state => set(() => ({ showModal: state ? state : !get().showModal }))
}))