import { useQuery } from '@tanstack/react-query'
import { getDiagram } from 'services/DiagramService'

export interface IMyFilesAnalytics {
	open: number[]
	progress: number[]
	done: number[]
}

export const useMyFilesAnalytics = () => {
	return useQuery({
		queryKey: ['useMyFilesAnalytics'],
		queryFn: async () => {
			return await getDiagram('myFiles').then(data => data as IMyFilesAnalytics)
		},
		refetchOnWindowFocus: false
	})
}
