import { useQuery } from '@tanstack/react-query'
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Form,
	Modal,
	Row,
	Select,
	Space,
	Typography,
	message
} from 'antd'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect, useState } from 'react'
import { reportService } from 'services/ReportService'
import { IHOCFineReportFilters } from '../model/hoc-fine-report-interface'
import { useHOCFineReport, useHOCFineReportExcel } from '../model/hoc-fine-report-queries'
import { downloadDocument } from 'shared/helpers'

interface IProps {
	show: boolean
	download?: boolean
	onClose: () => void
}

interface IHOCFineReportForm {
	projects: string[]
	contractor: string
	range: [Dayjs, Dayjs] | undefined
	allPeriod: boolean | undefined
}

const { Title, Text } = Typography

export const HOCFineReportCreateModal: FC<IProps> = ({ show, download, onClose }) => {

	const [isBlocked, setIsBlocked] = useState(false)

	const [HOCFineReportForm] = Form.useForm<IHOCFineReportForm>()
	const allPeriod = Form.useWatch('allPeriod', HOCFineReportForm)
	const selectedProjects = Form.useWatch('projects', HOCFineReportForm)

	const projects = useAppSelector(state => state.environment.user?.projects)
	const project = useAppSelector(state => state.environment.project)

	const { data: contractors } = useQuery({
		queryKey: ['reportContractors', selectedProjects],
		queryFn: async () => {
			return selectedProjects && selectedProjects?.length > 0
				? await reportService.getContractors(selectedProjects)
				: []
		}
	})

	const { mutate: getHOCFineReport } = useHOCFineReport()
	const { mutate: getHOCFineReportExcel } = useHOCFineReportExcel()

	const onModalClose = () => {
		HOCFineReportForm.resetFields()
		HOCFineReportForm.setFieldsValue({ allPeriod: true, range: undefined })
		onClose()
	}

	useEffect(() => {
		HOCFineReportForm.validateFields()
	}, [allPeriod])

	useEffect(() => {
		HOCFineReportForm.setFieldsValue({ allPeriod: true, range: undefined, projects: [project.id] })
	}, [show])


	const onFinish = async () => {
		setIsBlocked(true)
		const data = HOCFineReportForm.getFieldsValue()
		const filters: IHOCFineReportFilters = {
			projectIds: data.projects,
			contractorId: data.contractor,
			from: data.allPeriod ? null : dayjs(data.range?.[0]).format('YYYY-MM-DD'),
			to: data.allPeriod ? null : dayjs(data.range?.[1]).format('YYYY-MM-DD')
		}

		if (download) {
			await getHOCFineReportExcel(filters, {
				onSuccess: (data) => {
					downloadDocument(data, 'Отчет РСО Штрафы')
					onModalClose()
				},
				onError: () => message.error('Во время загрузки файла произошла ошибка'),
				onSettled: () => setIsBlocked(false)
			})
		}
		else {
			await getHOCFineReport(filters, {
				onSuccess: () => onModalClose(),
				onError: () => message.error('Во время получения данных произошла ошибка'),
				onSettled: () => setIsBlocked(false)
			})
		}
	}

	const setAllProjects = () => {
		HOCFineReportForm.setFieldValue(
			'projects',
			projects?.map(p => p.id)
		)
		HOCFineReportForm.validateFields()
	}

	return (
		<Form form={HOCFineReportForm} name="HOCFineReportForm" onFinish={onFinish} disabled={isBlocked}>
			<Modal
				destroyOnClose
				onCancel={onModalClose}
				centered
				open={show}
				title={download ? "Скачать отчет" : "Создать отчет"}
				okButtonProps={{ disabled: isBlocked }}
				footer={
					<Row justify="space-between">
						<Col>
							<Button danger disabled={isBlocked} onClick={() => HOCFineReportForm.resetFields()}>
								Сбросить
							</Button>
						</Col>
						<Col>
							<Space>
								<Button disabled={isBlocked} onClick={onModalClose}>
									Отмена
								</Button>

								<Button disabled={isBlocked} type="primary" onClick={() => HOCFineReportForm.submit()}>
									{download ? 'Скачать' : 'Сформировать'}
								</Button>
							</Space>
						</Col>
					</Row>
				}
			>
				<Row justify="center">
					<Col
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '.5rem',
							padding: '1rem',
							width: '100%'
						}}
					>
						<>
							<Col>
								<Row align="middle" justify="space-between">
									<Col>
										<Title level={5}>Проекты:</Title>
									</Col>
									<Col>
										<Button type="link" size="small" onClick={setAllProjects}>
											Выбрать все
										</Button>
									</Col>
								</Row>
								<Form.Item
									name="projects"
									rules={[
										{
											required: true,
											message: 'Выберете хотя бы один проект'
										}
									]}
								>
									<Select
										mode="multiple"
										maxTagCount="responsive"
										allowClear
										style={{ width: '100%' }}
										onClear={() => HOCFineReportForm.setFieldValue('contractor', undefined)}
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										options={projects?.map(p => ({
											key: p.id,
											label: p.name,
											value: p.id
										}))}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Title level={5}>Контрагент:</Title>
								<Form.Item name="contractor">
									<Select
										disabled={contractors?.length === 0 || selectedProjects?.length === 0}
										allowClear
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										style={{ width: '100%' }}
										options={contractors?.map(p => ({
											key: p.id,
											label: p.name,
											value: p.id
										}))}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Title level={5}>Период:</Title>
								<Form.Item
									name="range"
									rules={[
										{
											required: !allPeriod,
											message: 'Укажите период'
										}
									]}
								>
									<DatePicker.RangePicker
										disabled={allPeriod}
										format="DD.MM.YYYY"
										style={{ width: '100%' }}
									/>
								</Form.Item>
							</Col>
							<Row justify="end">
								<Text style={{ marginRight: '1rem' }}>За всё время</Text>
								<Form.Item name="allPeriod">
									<Checkbox
										checked={allPeriod}
										onChange={value => HOCFineReportForm.setFieldValue('allPeriod', value.target.checked)}
									/>
								</Form.Item>
							</Row>
						</>
					</Col>
				</Row>
			</Modal>
		</Form>
	)
}
