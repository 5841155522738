export enum IssueSource {
	directCreation,
	fromChecklistItem
}

export enum ISSUES_REGISTRY_COLUMNS {
	Number = 'Номер',
	Type = 'Тип',
	ViolationCode = 'Код нарушения',
	SubType = 'Под тип',
	ViolationType = 'Тип нарушения',
	Title = 'Наименование',
	Description = 'Описание',
	ActPoints = 'Номер пункта нормативного акта',
	LocationDescription = 'Описание локации',
	Status = 'Статус',
	DueDate = 'Дата устранения',
	Project = 'Проект',
	Contractor = 'Генподрядчик',
	SubContractor = 'Субподрядчик',
	Owner = 'Владелец',
	HaveOrdinance = 'Наличие предписания',
	OrdinanceNumber = 'Номер предписания',
	ReasonDescription = 'Основание'
}

export enum ISSUE_STATUSES {
	closed = '2d9df9da-5340-4f2a-ad30-77a24bb1bfea',
	ready_to_inspect = '5758e9f3-b35b-4d97-8616-aa3bbb96131f',
	open = '4ae3200c-d2df-4c70-922a-780ea1575a35',
	cancel = '958da9b4-0ac3-43bc-8f0c-3b61341083b7'
}