import { create } from "zustand";
import { IOrdinanceFineFilters, IUpdateOrdinanceFine } from "./fine-interface";

interface IFilters {
    ordinanceFineStatusId: string
    contractorId: string
    contractId: string
    createdById: string
    isRepeated: boolean
    isAutoCreated: boolean
    number: number
    createdAtFrom: Date
    createdAtTo: Date
    dateToFrom: Date
    dateToTo: Date
}

interface IOrdinanceFine {
    show: boolean
    filters: IOrdinanceFineFilters
	currentPage: number
	totalItems: number
    showOrdinance: boolean
    selectedOrdinance: string | null
    selectedOrdinanceFine: IOrdinanceFineFilters
    showOrdinanceFineDetail: boolean
	totalPages: number | undefined
    ordinanceFineDto: IUpdateOrdinanceFine | undefined
    ordinanceFineStatus: string | undefined
    day: string
    showDrawer: boolean
    setShowDrawer: (showDrawer: boolean) => void
    setDay: (day: string) => void
    updateOrdinanceFineStatus: (ordinanceFineStatus: string | undefined) => void
    updateOrdinanceFine: (dtoForm: IUpdateOrdinanceFine) => void
    setSelectedOrdinanceFine: (selectedOrdinanceFine: IOrdinanceFineFilters) => void
    setShowOrdinanceFineDetail: (showOrdinanceFineDetail: boolean) => void
    setShow: (show: boolean) => void
    openOrdinance: (showOrdinance: boolean, selectedOrdinance: string | null ) => void
	onPagination: (page: number) => void
	setTotalItems: (items: number) => void
	setTotalPages: (pages: number) => void
    setOrdinanceFineFilters: (filters: IOrdinanceFineFilters) => void
    unsetFilters: () => void
}

const initStateFilters = {}

export const useOrdinanceFineState = create<IOrdinanceFine>(set => ({
    show: false,
    filters: {},
    selectedOrdinanceFine: {},
    showOrdinance: false,
    selectedOrdinance: null,
	currentPage: 1,
	totalItems: 1,
	totalPages: undefined,
    showOrdinanceFineDetail: false,
    ordinanceFineDto: undefined,
    ordinanceFineStatus: '',
    day: '',
    showDrawer: false,
    setShowDrawer: showDrawer => 
        set(() => ({showDrawer})),
    setDay: day => set(() => ({day})),
    updateOrdinanceFineStatus: (ordinanceFineStatus) => set({ordinanceFineStatus}),
    updateOrdinanceFine: (dtoForm) => {
        const ordinanceFineUpdate = ({
            sum: dtoForm.sum,
            dateTo: dtoForm.dateTo,
            ordinanceFineStatusId: dtoForm.ordinanceFineStatusId
        })
    set(() => ({
        ordinanceFineDto: ordinanceFineUpdate!
    }))},
    setShowOrdinanceFineDetail: showOrdinanceFineDetail => set({showOrdinanceFineDetail}),
    setSelectedOrdinanceFine: selectedOrdinanceFine => set(() => ({selectedOrdinanceFine})),
    setShow: show => set(() => ({ show})),
    openOrdinance: (showOrdinance, selectedOrdinance) => set(() => ({showOrdinance: showOrdinance, selectedOrdinance: selectedOrdinance })) ,
	onPagination: page => set(() => ({ currentPage: page })),
	setTotalItems: items => set(() => ({ totalItems: items })),
	setTotalPages: pages => set(() => ({ totalPages: pages })),
    setOrdinanceFineFilters: filters =>
		set(() => ({filters: filters})),
    unsetFilters: () => set(() => ({ ...initStateFilters }))
}))