import { Button, Input, message, Modal } from 'antd'
import Upload, { RcFile, UploadFile, UploadProps } from 'antd/lib/upload'
import { FC, useEffect, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { getChecklist, setAcceptedUnderWarranty } from 'services/ChecklistsService'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { setCurrentChecklist } from 'store/checklistsSlice'
import LoaderSpinner from 'UI/loaderSpinner'
import dayjs from 'dayjs'
import { AxiosError } from 'axios'
import { useQueryClient } from '@tanstack/react-query'

interface IProps {
	warrantyModalVisible: boolean
	setWarrantyModalVisible: (value: boolean) => void
}

interface IErrorData {
	detail: string
	type?: string
	title?: string
	status?: number
}

const WarrantyModal: FC<IProps> = ({ warrantyModalVisible, setWarrantyModalVisible }) => {
	const queryClient = useQueryClient()
	const dispatch = useAppDispatch()
	const [uploading, setUploading] = useState(false)
	const [itemFile, setItemFile] = useState<UploadFile | null>(null)
	const [description, setDescription] = useState('')
	const [descriptionIsEmpty, setDescriptionIsEmpty] = useState(false)
	const currentChecklist = useAppSelector(state => state.checklists.init.currentChecklist)
	const currentChecklistId = useAppSelector(state => state.checklists.init.currentChecklist?.id)

	const showMessage = (alert: string, type: string) => {
		switch (type) {
			case 'success':
				return message.success(alert)
			case 'info':
				return message.info(alert)
			case 'error':
				return message.error(alert)
		}
	}

	const onReloadChecklistItem = () => {
		if (currentChecklistId) {
			getChecklist(currentChecklistId).then(res => dispatch(setCurrentChecklist({ ...res, period: dayjs(res.period) })))
		}
	}

	const onUpload = async () => {
		if (![0, 1].some(s => s !== currentChecklist!?.status!)) {
			message.error('Чек-лист должен быть в статусе "Завершён"')
		} else {
			if (itemFile) {
				const config = {
					headers: { 'content-type': 'multipart/form-data' }
				}
				const fmData = new FormData()
				fmData.append('file', itemFile as RcFile)
				fmData.append('description', description)
				if (fmData) {
					if (description) {
						setUploading(true)
						setAcceptedUnderWarranty(currentChecklistId!, description, fmData, config)
							.then(() => {
								onReloadChecklistItem()
								setWarrantyModalVisible(false)
								queryClient.invalidateQueries(['checkListList'])
							})
							.catch((err: AxiosError) => {
								const errorMessage = (err!?.response!?.data! as IErrorData).detail
								message.error(errorMessage!)
							})
							.finally(() => setUploading(false))
					} else {
						setDescriptionIsEmpty(true)
						message.error('Необходимо заполнить описание')
					}
				}
			} else {
				message.error('Необходимо выбрать файл')
			}
		}
	}

	const props: UploadProps = {
		accept: 'application/pdf',
		maxCount: 1,
		onRemove: () => {
			setItemFile(null)
		},
		beforeUpload: file => {
			if (file.type !== 'application/pdf') {
				showMessage(`${file.name} - не поддерживаемый формат файла`, 'error')
				setItemFile(null)
			} else {
				setItemFile(file)
			}
			return false
		}
	}

	useEffect(() => {
		if (!warrantyModalVisible && itemFile) {
			setItemFile(null)
		}
	}, [warrantyModalVisible])

	return (
		<Modal
			title="Загрузите копию обязательства и описание"
			open={warrantyModalVisible}
			destroyOnClose={true}
			onOk={onUpload}
			okText="Загрузить"
			onCancel={() => setWarrantyModalVisible(false)}
			cancelText="Закрыть"
		>
			{uploading && <LoaderSpinner />}
			<div style={{ margin: '16px 0' }}>
				<Upload {...props}>
					<Button icon={<UploadOutlined rev={undefined} />}>Прикрепите PDF файл</Button>
				</Upload>
			</div>
			<Input.TextArea
				placeholder={descriptionIsEmpty ? 'Необходимо заполнить описание' : 'Введите описание'}
				onChange={e => {
					setDescriptionIsEmpty(false)
					setDescription(e.currentTarget.value)
				}}
				status={descriptionIsEmpty ? 'error' : ''}
			/>
		</Modal>
	)
}

export default WarrantyModal
