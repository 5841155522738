import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IPageTitle {
  mainTitle?: string
  subTitle?: string | undefined
}

const initPageTitle: IPageTitle = {
  mainTitle: '',
  subTitle: '',
}

const initialState = {
  pageTitle: initPageTitle,
}

const rootSlice = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setPageTitle(state, action: PayloadAction<IPageTitle>) {
      if (action.payload.mainTitle && !action.payload.subTitle) {
        state.pageTitle.mainTitle = action.payload.mainTitle
        state.pageTitle.subTitle = ''
      }
      if (!action.payload.mainTitle && action.payload.subTitle) {
        state.pageTitle.subTitle = action.payload.subTitle
      }
      if (action.payload.mainTitle && action.payload.subTitle) {
        state.pageTitle.mainTitle = action.payload.mainTitle
        state.pageTitle.subTitle = action.payload.subTitle
      }
    },
  },
})

export const { setPageTitle } = rootSlice.actions

export default rootSlice.reducer
