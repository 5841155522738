import { API } from 'services/ApiService'
import {
	IAdjustmentPositionCommentDto,
	IAdjustmentPositionComments,
	IAdjustmentPositionCommentsUser
} from '../model/adjustment-position-comments-schema'

export const AdjustmentPositionCommentsApi = {
	async getEPComments(epId: string) {
		return await API<IAdjustmentPositionComments>({
			url: `v1/adjustment/estimatePosition/${epId}/comments`,
			method: 'get'
		}).then(response => response.data)
	},
	async addEPComments(epId: string, dto: IAdjustmentPositionCommentDto) {
		return await API<IAdjustmentPositionComments>({
			url: `v1/adjustment/estimatePosition/${epId}/comments`,
			method: 'post',
			data: { ...dto }
		}).then(response => response.data)
	},
	async getEPCommentsUsers(epId: string) {
		return await API<IAdjustmentPositionCommentsUser[]>({
			url: `v1/adjustment/estimatePosition/${epId}/responsibleUsersComments`,
			method: 'get'
		}).then(response => response.data)
	},
	async toggleEPChatState(epId: string) {
		return await API<IAdjustmentPositionCommentsUser[]>({
			url: `v1/adjustment/estimatePosition/${epId}/comments/state`,
			method: 'patch'
		}).then(response => response.data)
	}
}
