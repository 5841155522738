import { useQueryClient } from '@tanstack/react-query'
import { Switch, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useNotifications } from 'hooks/useNotifications'
import { FC } from 'react'
import { notificationService } from 'services/NotificationService'
import { INotificationType } from 'widgets/notification-controller'
interface INotificationsProps {
	message: (m: string, type: string) => void
	loader: (x: boolean) => void
}
const Notifications: FC<INotificationsProps> = ({ message, loader }) => {
	const queryClient = useQueryClient()
	const { data: notifications, isFetching } = useNotifications()

	const handleChangeStatus = async (record: INotificationType) => {
		const dto = {
			...record,
			isSending: !record.isSending
		}
		await notificationService
			.updateAdminNotification(dto)
			.then(() => queryClient.invalidateQueries({ queryKey: ['notifications'] }))
	}

	const notificationsColumns: ColumnsType<INotificationType> = [
		{
			dataIndex: 'nameRu',
			width: '30%',
			title: 'Тип уведомления',
			sorter: (a, b) => a.nameRu?.length - b.nameRu?.length,
			defaultSortOrder: 'descend'
		},
		{
			dataIndex: 'objectNameRu',
			width: '30%',
			title: 'Объект',
			sorter: (a, b) => a.objectNameRu?.length - b.objectNameRu?.length,
			defaultSortOrder: 'descend'
		},
		{
			dataIndex: 'isSending',
			width: '30%',
			title: 'Включение / Отключение',
			render: (status: boolean, record) => (
				<Switch
					style={{ marginLeft: '5px', marginTop: '25px' }}
					size="small"
					checked={status}
					onChange={() => handleChangeStatus(record)}
				/>
			)
		}
	]
	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<Table
				loading={isFetching}
				rowKey={record => record.id}
				size="small"
				bordered
				dataSource={notifications}
				columns={notificationsColumns}
				pagination={false}
				scroll={{ y: '60vh', x: '100%' }}
			/>
		</div>
	)
}
export default Notifications
