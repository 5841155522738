import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface IWbsCols {
	origin: boolean
	second: boolean
	sdu: boolean
	comments: boolean
}
interface ILocalEstimateCols extends IWbsCols {}

interface IState {
	wbs: IWbsCols
	local: ILocalEstimateCols
	setVisibleColumns: (key: string, value: boolean, type: 'wbs' | 'local') => void
}
const columns = {
	wbs: {
		origin: true,
		second: true,
		sdu: true,
		comments: true
	},
	local: {
		origin: true,
		second: true,
		sdu: true,
		comments: true
	}
}

export const useAdjustmentColumnConfig = create(
	persist<IState>(
		(set, get) => ({
			...columns,
			setVisibleColumns: (key, value, type) => {
				set(() => ({ [type]: { ...get()[type], [key]: value } }))
			}
		}),
		{
			name: 'adjustment-column-config',
			storage: createJSONStorage(() => localStorage)
		}
	)
)
