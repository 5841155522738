import { Card, Descriptions, Button, Row, Col } from 'antd'
import { IReleaseNote } from 'interfaces/release-note.interface'
import { FC, useState } from 'react'
import * as DOMPurify from 'dompurify'
import dayjs from 'dayjs'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { ReleaseNotesEditor } from './releaseNotes.editor'
import { ReleaseNoteService } from 'services/release-note.service'
import { useQueryClient } from '@tanstack/react-query'
interface IReleaseNotesItemProps {
	isAdmin: boolean
	item: IReleaseNote
}

export const ReleaseNotesItem: FC<IReleaseNotesItemProps> = ({ item, isAdmin = false }) => {
	const queryClient = useQueryClient()
	const [controls, setControls] = useState(false)
	const [isEdit, setIsEdit] = useState(false)

	const onDelete = async (id: string) => {
		await ReleaseNoteService.delete(id).then(() => queryClient.invalidateQueries(['releaseNotes']))
	}

	return (
		<>
			{!isEdit ? (
				<Card
					style={{ marginBottom: '1rem' }}
					onMouseOver={() => isAdmin && setControls(true)}
					onMouseLeave={() => setControls(false)}
				>
					<Row
						hidden={!controls}
						gutter={8}
						style={{
							position: 'absolute',
							top: 8,
							right: 32,
							cursor: 'pointer'
						}}
					>
						<Col>
							<Button type="primary" icon={<EditOutlined />} shape="circle" onClick={() => setIsEdit(true)} />
						</Col>
						{/* <Col>
							<Button danger icon={<DeleteOutlined />} shape="circle" onClick={() => onDelete(item.id)} />
						</Col> */}
					</Row>
					<Descriptions
						column={12}
						bordered
						labelStyle={{
							fontWeight: 'bold',
							width: '20%',
							alignItems: 'center',
							padding: '8px'
						}}
						contentStyle={{
							width: '80%',
							padding: '8px'
						}}
					>
						<Descriptions.Item label="Номер версии" span={12}>
							{item?.number}
						</Descriptions.Item>
						<Descriptions.Item label="Дата" span={12}>
							{dayjs(item?.create).isValid() && dayjs(item.create).format('DD.MM.YYYY')}
						</Descriptions.Item>
						<Descriptions.Item label="Описание" span={12}>
							<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.description) }} />
						</Descriptions.Item>
					</Descriptions>
				</Card>
			) : (
				<ReleaseNotesEditor onCancel={() => setIsEdit(false)} toEdit={item} />
			)}
		</>
	)
}
