import { useQuery } from '@tanstack/react-query'
import { useAppDispatch } from './appReduxHook'
import { setEnvironment } from 'store/environmentSlice'
import {
	getContractors,
	getContractsByProject1CId,
	getIssueStatusList,
	getIssueSubTypes,
	getIssueTypes,
	getViolationTypes
} from 'services/IssuesService'
import { IProject } from 'interfaces/IEnvironment'
import { getBuildingPermitsList, getOrdinanceStatuses } from 'services/OrdinanceService'
import { getProjectList } from 'services/EnvironmentService'
import { workPackageService } from 'services/workPackageService'
import { violationsService } from 'services/violationsService'

export const useShared = (project: IProject) => {
	const dispatch = useAppDispatch()
	return useQuery({
		queryKey: ['appShared', project],
		queryFn: async () => {
			const issueStatusList = await getIssueStatusList().then(data => {
				dispatch(setEnvironment({ state: 'issueStatuses', data }))
				return data
			})

			await getContractsByProject1CId(project?.project1C?.id!).then(data => {
				dispatch(setEnvironment({ state: 'contracts', data }))
				return data
			})

			await getContractors().then(data => {
				dispatch(setEnvironment({ state: 'contractors', data }))
				return data
			})

			await getIssueTypes().then(data => {
				dispatch(setEnvironment({ state: 'issueTypes', data }))
				return data
			})

			await getIssueSubTypes().then(data => {
				dispatch(setEnvironment({ state: 'issueSubTypes', data }))
				return data
			})

			await getViolationTypes().then(data => {
				dispatch(setEnvironment({ state: 'violationTypes', data }))
				return data
			})

			await getOrdinanceStatuses().then(data => {
				dispatch(setEnvironment({ state: 'ordinanceStatuses', data }))
				return data
			})

			await getBuildingPermitsList(project?.project1C?.id).then(data => {
				dispatch(setEnvironment({ state: 'buildingPermits', data }))
				return data
			})

			await getProjectList(project.id).then(data => {
				dispatch(setEnvironment({ state: 'allProjects', data }))
				return data
			})

			await workPackageService.getList().then(data => {
				dispatch(setEnvironment({ state: 'workPackages', data }))
				return data
			})

			await violationsService.getList().then(data => {
				dispatch(setEnvironment({ state: 'violations', data }))
				return data
			})

			return issueStatusList
		},
		retryDelay: (attemptIndex: number) => Math.min(1000 * 2 ** attemptIndex, 30000),
		retry: 2,
		refetchOnWindowFocus: false,
		refetchInterval: 900000
	})
}
