import { EllipsisOutlined, SaveOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import {
	Button,
	Col,
	Dropdown,
	Form,
	Input,
	MenuProps,
	Modal,
	Radio,
	Row,
	Select,
	Space,
	message
} from 'antd'
import { uniq } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { mainSelectProps } from 'shared/helpers'
import { useWbsRoles, useWbsVisas } from './useWbsStatuses'
import { WbsStatusApi } from './wbs-status-api'
import { WbsStatusState } from './wbsStatusState'

interface IProps {
	isLocal?: boolean
	isAdjustment?: boolean
}

interface IWbsAddForm {
	name?: string
	roles?: string[]
	visas?: string[]
}

export const WbsVisaAdd: FC<IProps> = ({ isLocal, isAdjustment }) => {
	const key = isLocal ? 'estimateStatusGuide' : 'wbsStatusGuide'
	const adjustmentKey = isLocal ? 'adjustmentEstimateStatusGuide' : 'adjustmentWbsStatusGuide'
	const { data: visas } = useWbsVisas()
	const { data: roles } = useWbsRoles()
	const { showAddVisaModal, addVisaContainer, unsetVisaAdd } = WbsStatusState()
	const [wbsVisaAddForm] = Form.useForm<IWbsAddForm>()
	const queryClient = useQueryClient()
	const [addType, setAddType] = useState<'choose' | 'create'>('create')

	useEffect(() => {
		if (showAddVisaModal) {
			setAddType(!!visas?.length ? 'choose' : 'create')
		}
	}, [showAddVisaModal, visas])

	const onFormClose = (update: boolean = false) => {
		wbsVisaAddForm.resetFields()
		unsetVisaAdd()
		if (update) {
			queryClient.invalidateQueries({ queryKey: ['wbsStatusGuide'] })
		}
		setAddType('choose')
	}

	const onWbsVisaAdd = async (close: boolean = false) => {
		const values = wbsVisaAddForm.getFieldsValue()
		const validate = await wbsVisaAddForm
			.validateFields()
			.then(() => true)
			.catch(() => false)
		if (validate) {
			if (addType === 'create') {
				const dto = {
					name: values.name!,
					rolesId: values.roles!
				}
				await WbsStatusApi.addVisa(dto).then(async visa => {
					const currentVisas = addVisaContainer?.children.flatMap(v => v.id)
					const dto = {
						name: addVisaContainer?.name!,
						description: addVisaContainer?.description!,
						color: addVisaContainer?.color!,
						order: addVisaContainer?.order!,
						visasId: [...currentVisas!, visa]
					}
					await WbsStatusApi.updateStatus(addVisaContainer?.id!, dto, isLocal, isAdjustment).then(
						() => {
							queryClient.invalidateQueries({
								queryKey: [isAdjustment ? adjustmentKey : key]
							})
							queryClient.invalidateQueries({ queryKey: ['wbsVisas'] })
							wbsVisaAddForm.resetFields()
							message.success('Виза создана и добавлена')
							if (close) onFormClose()
						}
					)
				})
			} else if (addType === 'choose') {
				const currentVisas = addVisaContainer?.children.flatMap(v => v.id)
				const dto = {
					name: addVisaContainer?.name!,
					description: addVisaContainer?.description!,
					color: addVisaContainer?.color!,
					order: addVisaContainer?.order!,
					visasId: uniq([...currentVisas!, ...values.visas!])
				}
				await WbsStatusApi.updateStatus(addVisaContainer?.id!, dto, isLocal, isAdjustment).then(
					() => {
						queryClient.invalidateQueries({
							queryKey: [isAdjustment ? adjustmentKey : key]
						})
						queryClient.invalidateQueries({ queryKey: ['wbsVisas'] })
						wbsVisaAddForm.resetFields()
						message.success('Список виз успешно обновлён')
						if (close) onFormClose()
					}
				)
			}
		}
	}

	const items: MenuProps['items'] = [
		{
			label: 'Сохранить',
			key: 'save',
			icon: <SaveOutlined />,
			onClick: () => onWbsVisaAdd()
		},
		{
			label: 'Сохранить и закрыть',
			key: 'saveAndClose',
			icon: <SaveOutlined />,
			onClick: () => onWbsVisaAdd(true)
		}
	]

	return (
		<Modal
			centered={true}
			destroyOnClose={true}
			title="Добавить визу"
			open={showAddVisaModal}
			footer={
				<Row justify="end" gutter={8} wrap={false}>
					<Col>
						<Button onClick={() => onFormClose()}>Отмена</Button>
					</Col>
					<Col>
						<Dropdown.Button
							trigger={['click']}
							type="primary"
							icon={<EllipsisOutlined />}
							menu={{ items }}
							onClick={() => onWbsVisaAdd()}
						>
							Сохранить
						</Dropdown.Button>
					</Col>
				</Row>
			}
			onCancel={() => onFormClose()}
		>
			<Space direction="vertical" size="large" style={{ width: '100%' }}>
				<Radio.Group
					value={addType}
					onChange={e => setAddType(e.target.value)}
					options={[
						{ label: 'Выбрать', value: 'choose', disabled: visas?.length === 0 },
						{ label: 'Создать новую', value: 'create' }
					]}
					optionType="button"
					buttonStyle="solid"
				/>
				<Form name="wbsVisaAddForm" form={wbsVisaAddForm} labelCol={{ span: 6 }} labelAlign="left">
					{addType === 'create' ? (
						<>
							<Form.Item
								name="name"
								label="Наименование"
								rules={[
									{
										required: true,
										message: 'Укажите название'
									}
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="roles"
								label="Роли"
								rules={[
									{
										required: true,
										message: 'Укажите хотя бы одну роль'
									}
								]}
							>
								<Select
									{...mainSelectProps}
									mode="multiple"
									options={roles?.map(r => ({
										key: r.id,
										value: r.id,
										label: r.name
									}))}
								/>
							</Form.Item>
						</>
					) : (
						<Form.Item
							name="visas"
							label="Визы"
							rules={[
								{
									required: true,
									message: 'Укажите хотя бы одну визу'
								}
							]}
						>
							<Select
								{...mainSelectProps}
								mode="multiple"
								options={visas?.map(v => ({
									key: v.id,
									value: v.id,
									label: v.name
								}))}
							/>
						</Form.Item>
					)}
				</Form>
			</Space>
		</Modal>
	)
}
