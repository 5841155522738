import { RollbackOutlined } from '@ant-design/icons'
import { Button, Card, Col, Row } from 'antd'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { EstimateColumnsConfig } from '..'
import { EstimateContainerActions } from './EstimateContainerActions'
import { EstimateStatusLine } from './EstimateStatusLine'

export const EstimateContainerHeader = memo(function ({ type }: { type: 'wbs' | 'local' }) {
	const navigate = useNavigate()
	return (
		<Card id="app-estimateHeader" size="small">
			<Row align="middle" justify="space-between" gutter={16} wrap={false}>
				<Col>
					<Row align="middle" justify="space-between" gutter={16} wrap={false}>
						<Col>
							<EstimateColumnsConfig type={type} />
						</Col>
						<Col>
							<Button
								icon={<RollbackOutlined />}
								onClick={() => navigate(type === 'local' ? '/local' : '/unmodel')}
							>
								{`Назад к списку ${type === 'local' ? 'локальных смет' : 'ВОР'}`}
							</Button>
						</Col>
					</Row>
				</Col>
				<Col flex={1}>
					<EstimateStatusLine type={type} />
				</Col>
				{/* <Col>
					<EstimateRelatedDocumentsButton type={type} />
				</Col> */}
				<Col>
					<EstimateContainerActions />
				</Col>
			</Row>
		</Card>
	)
})
