import { Button, Col, Drawer, Form, List, Row, Select, Tooltip, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import useDebounce from 'hooks/useDebounce'
import { useContractors, usePermissions, useProjects } from 'hooks/usePermissions'
import { IContractor, IProject } from 'interfaces/IEnvironment'
import { IRole } from 'interfaces/IPermissions'
import { IPermissionFilters, IUser } from 'interfaces/IUser'
import { FC, useEffect, useState } from 'react'
import { getRoles, getUsers } from 'services/AdminService'
import {
	resetSettingsUsersFilter,
	setCurrentPage,
	setPermissionFilters
} from 'store/settingsUsersSlice'

const { Text } = Typography
const { Option } = Select

interface IFilterItem {
	title: string | React.ReactNode
	formItemName: string
	list: IUser[] | IProject[] | IContractor[] | any[] | null
	date: boolean
	disabled: boolean
}

interface IFiltersProps {
	open: boolean
	onClose: () => void
}
const PermissionsFilters: FC<IFiltersProps> = ({ open, onClose }) => {
	const dispatch = useAppDispatch()
	const project = useAppSelector(state => state.environment.project?.id!)
	const { data: allProjects, isSuccess: isSuccessProject } = useProjects()
	const { data: contractors, isSuccess: isSuccessContractor } = useContractors()
	const { data: permissions, isSuccess: isSuccessPermission } = usePermissions()
	const [filtersForm] = Form.useForm()
	const [isLoading, setIsLoading] = useState(false)
	const [users, setUsers] = useState<IUser[] | null>(null)
	const [roles, setRoles] = useState<IRole[] | null>(null)

	useEffect(() => {
		getRoles().then(data => setRoles(data))
	}, [])

	const filterList: IFilterItem[] = [
		{
			title: 'Пользователь',
			formItemName: 'ids',
			list: users ? users : permissions!,
			date: false,
			disabled: false
		},
		{
			title: 'Проект',
			formItemName: 'projectFilterId',
			list: isSuccessProject ? allProjects! : null,
			date: false,
			disabled: false
		},
		{
			title: 'Компания',
			formItemName: 'contractorId',
			list: isSuccessContractor ? contractors! : null,
			date: false,
			disabled: false
		},
		{
			title: 'Роль',
			formItemName: 'roleId',
			list: roles,
			date: false,
			disabled: false
		}
	]

	const [filterItems, setFilterItems] = useState<IFilterItem[]>(filterList)

	useEffect(() => {
		setFilterItems(filterList)
	}, [
		project,
		permissions,
		isSuccessProject,
		isSuccessContractor,
		isSuccessPermission,
		users,
		roles
	])

	const onResetFilters = () => {
		filtersForm.resetFields()
		dispatch(resetSettingsUsersFilter())
		setUsers(null)
		onClose()
		dispatch(setCurrentPage(1))
	}

	const debounceFetch = (value: string) => {
		if (value.length >= 3) {
			setIsLoading(true)
			getUsers({ search: value })
				.then(data => {
					setUsers(data.data)
				})
				.finally(() => setIsLoading(false))
		} else {
			setUsers(permissions!)
			setIsLoading(false)
		}
	}
	const debouncer = useDebounce((deb: string) => debounceFetch(deb), 1000)

	const onConfirmFilters = async (filters: IPermissionFilters, show: boolean) => {
		if (filters) {
			dispatch(
				setPermissionFilters({
					filters: {
						ids: filters.ids,
						projectFilterId: filters.projectFilterId,
						contractorId: filters.contractorId,
						roleId: filters.roleId
					}
				})
			)
			if (show) {
				onClose()
			}
		}
		setUsers(null)
		dispatch(setCurrentPage(1))
	}

	return (
		<Drawer
			open={open}
			onClose={onClose}
			placement="left"
			title="Фильтры"
			className="at-drawer-left"
			footer={
				<Row gutter={8} justify="end">
					<Col>
						<Button ghost danger onClick={onResetFilters}>
							Сбросить
						</Button>
					</Col>
					<Col>
						<Button type="primary" htmlType="submit" onClick={() => filtersForm.submit()}>
							Применить
						</Button>
					</Col>
				</Row>
			}
		>
			<Form
				form={filtersForm}
				name="filtersForm"
				onFinish={() => onConfirmFilters(filtersForm.getFieldsValue(), true)}
			>
				<List
					loading={isLoading}
					dataSource={filterItems}
					renderItem={(item, index) => (
						<Row align="middle" gutter={8}>
							<Col style={{ flex: '0 0 100%' }}>
								<Text strong>{item.title}:</Text>
							</Col>
							<Col style={{ flex: '1 0 100%' }}>
								<Tooltip
									title={
										item.formItemName === 'ids'
											? 'Для получения списка пользователей начните вводить имя или e-mail'
											: null
									}
								>
									<Form.Item key={index} name={item.formItemName}>
										{
											<Select
												loading={isLoading}
												mode={item.formItemName === 'ids' ? 'multiple' : undefined}
												showSearch
												onSearch={debouncer}
												allowClear
												optionFilterProp="children"
												// disabled={item.disabled || isLoading || item.list!?.length === 0}
												// filterSort={(optionA, optionB) =>
												// 	(optionA?.label ?? '')
												// 		.toString()
												// 		.toLowerCase()
												// 		.localeCompare((optionB?.label ?? '').toString().toLowerCase())
												// }
												style={{ width: '100%' }}
												placeholder="Не выбран"
											>
												{item.list!?.map((option, index) => (
													<Option
														key={option!?.id ? option!?.id : index}
														value={option!?.id ? option!?.id : index}
													>
														{option!?.name
															? option!?.name
															: `${option!?.firstName} ${option!?.lastName} / ${option.email}`}
													</Option>
												))}
											</Select>
										}
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
					)}
				/>
			</Form>
		</Drawer>
	)
}

export default PermissionsFilters
