import { FC, useState, useEffect } from 'react'
import { IIssueAttachments, IIssueDTOExtended } from 'interfaces/IIssue'
import { Card, Table, Image, Row, Col } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { IIssueFileList } from '../IssueTabs'
import { useAppSelector } from 'hooks/appReduxHook'
import { getIssueAttachment } from 'services/IssuesService'
interface ICommentsListProps {
	issue: IIssueDTOExtended
}

// issue: IIssueComment[]

interface ICommentsData {
	author: string
	comment: string
	created: Date
	attachments: IIssueAttachments[]
}

const commentsCols: ColumnsType<ICommentsData> = [
	{
		key: 'author',
		title: 'Автор',
		width: 160,
		dataIndex: 'author'
	},
	{
		key: 'comment',
		title: 'Комментарий',
		dataIndex: 'comment'
	},
	{
		key: 'created',
		title: 'Дата',
		width: 140,
		dataIndex: 'created',
		render: (_, record) => dayjs(record.created).format('HH:mm DD.MM.YYYY')
	}
]

export interface ICommentFile {
	id: string
	objectId: string
	name: string
	extension: string
	fileSize: number
	contentType: string
	description: string | null
	uploadDate: Date
	url: string
	blob: string | undefined
	createdBy: {
		id: string
		name: string
		email: string
	}
}

export const CommentsList: FC<ICommentsListProps> = ({ issue }) => {
	const { dto: issueDto } = useAppSelector(state => state.issues)
	const [commentsAttachments, setCommentsAttachments] = useState<ICommentFile[]>([])
	const commentsData: ICommentsData[] = issue!?.comments!?.map(comment => ({
		author: comment.author.name,
		comment: comment.comment,
		created: comment!.created,
		attachments: issue!?.attachments!?.filter(att => comment.attacmentId.some(f => f === att.id)) || []
	}))

	const blobToBase64 = async (id: string) => {
		const blob: Blob = await getIssueAttachment(id).then(data => data)

		if (blob) {
			return await new Promise<string>((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(blob)
				reader.onload = () => resolve(reader.result!.toString())
				reader.onerror = error => reject(error)
			})
		}
	}

	useEffect(() => {
		const getBlobs = async () => {
			const blobList: IIssueFileList[] = await Promise.all(
				(issueDto!?.attachments as any[])!?.map(async (item): Promise<ICommentFile> => {
					const blob = await blobToBase64(item.id).then(data => {
						return data!
					})
					return { ...item, blob: blob! }
				})
			)
			setCommentsAttachments(blobList)
		}
		if (issueDto!?.attachments!?.length! > 0) getBlobs()
	}, [issueDto])

	return (
		<Card title="Комментарии">
			<Table
				rowKey={(record, index) => index!}
				pagination={false}
				columns={commentsCols}
				expandable={{
					expandedRowRender: record => (
						<Row gutter={[8, 8]}>
							{record.attachments!?.map(att => (
								<Col>
									<Image width={100} src={commentsAttachments.find(file => file.id === att.id)?.blob} />
								</Col>
							))}
						</Row>
					),
					rowExpandable: record => !!record.attachments.length,
					expandedRowClassName: record => 'at-expandable'
				}}
				dataSource={commentsData}
			/>
		</Card>
	)
}
