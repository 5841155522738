import { useQuery } from "@tanstack/react-query"
import { IContractor, IProject } from "interfaces/IEnvironment"
import { IUser } from "interfaces/IUser"
import { getUsers } from "services/AdminService"
import { getContractorList, getProjectList } from "services/EnvironmentService"
import { setTotalItems } from "store/settingsUsersSlice"
import { useAppDispatch, useAppSelector } from "./appReduxHook"

export const usePermissions = () => {
	const projectId = useAppSelector(state => state.environment.project?.id)
	const {filters } = useAppSelector(state => state.settingsUsers)
	const currentPage = useAppSelector(state => state.settingsUsers.listState!.currentPage)
	const dispatch = useAppDispatch()
	return useQuery<IUser[]>({
		queryKey: ['permissionsList', projectId, filters, currentPage],
		queryFn: async () => {
			const permissionsList = await getUsers({
				filters: filters!,
				offset: currentPage! > 1 ? currentPage! * 50 - 50 : 0
			}).then(response => response)
			dispatch(setTotalItems(permissionsList.totalItems))
			return permissionsList.data
		},
		refetchOnWindowFocus: false
	})
}

export const useProjects = () => {
	return useQuery<IProject[]>({
		queryKey: ['projectList'],
		queryFn: async () => {
			const projectList = await getProjectList(null).then(response => response)
			return projectList
		}
	})
}

export const useContractors = () => {
	return useQuery<IContractor[]>({
		queryKey: ['contractorList'],
		queryFn: async () => {
			const contractorList = await getContractorList(null).then(response => response)
			return contractorList
		}
	})
}

