import StatusIndicator from 'UI/statusIndicator'
import { Col, Row, Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { IFine } from '../model/fine-interface'
import { ButtonForOrdinance } from './button-for-ordinance'

export const FinePage = () => {
	const fineColumns: ColumnsType<IFine> = [
		{
			dataIndex: 'ordinanceFineStatus',
			title: 'Статус',
			fixed: 'left',
			width: 55,
			render: (_, record) => (
				<Row gutter={8} wrap={false} align="middle">
					<Col>
						<StatusIndicator status={record.ordinanceFineStatus!.name} />
					</Col>
					<Col>{record.ordinanceFineStatus.name}</Col>
				</Row>
			)
		},
		{
			title: '№',
			render: (_, record) => (record.name !== null ? record.name : record.number),
			width: 80,
			fixed: 'left',
			align: 'center'
		},
		{
			key: 'createdAt',
			title: 'Дата создания',
			width: 80,
			fixed: 'left',
			sorter: false,
			render: (_, record) =>
				dayjs(record.createdAt).isValid() ? dayjs(record.createdAt).format('DD.MM.YYYY HH:mm') : '-'
		},
		{
			dataIndex: ['contractor', 'name'],
			title: 'Генподрядчик',
			fixed: 'left',
			ellipsis: {
				showTitle: true
			},
			width: 100
		},
		{
			dataIndex: ['subContractor', 'name'],
			title: 'Субподрядчик',
			fixed: 'left',
			ellipsis: {
				showTitle: true
			},
			width: 100
		},
		{
			title: 'Повторный',
			dataIndex: 'isRepeated',
			align: 'center',
			ellipsis: {
				showTitle: true
			},
			width: 25,
			render: (_, record) =>
				record.isRepeated && (
					<Tooltip title="Повторный">
						<b>П</b>
					</Tooltip>
				)
		},
		{
			key: 'ordinance',
			title: 'Предписание',
			align: 'center',
			width: 65,
			render: (_, record) => record.ordinance !== null && <ButtonForOrdinance record={record} />
		},
		{
			title: 'Наименование',
			dataIndex: 'name',
			ellipsis: {
				showTitle: true
			},
			width: 150,
			render: (_, record) =>
				dayjs(record.createdAt).isValid()
					? `Штраф №${record!?.name !== null ? record!?.name : record!?.number} от ${dayjs(
							record.createdAt
					  ).format('DD.MM.YYYY')}г.`
					: '-'
		},
		{
			dataIndex: ['contract', 'name'],
			title: 'Договор',
			width: 150
		},
		{
			key: 'dateTo',
			title: 'Срок устранения нарушения после выдачи штрафа',
			width: 70,
			ellipsis: {
				showTitle: true
			},
			render: (_, record) =>
				dayjs(record.dateTo).isValid() ? dayjs(record.dateTo).format('DD.MM.YYYY') : '-'
		},
	]

	return fineColumns
}
