import { CopyOutlined } from '@ant-design/icons'
import { Button, Popconfirm } from 'antd'
import { memo } from 'react'
import { useAdjustmentState } from '../model/adjustment-state'

interface IProps {
	id: string
	sectionId: string
}

export const AdjustmentPositionCopy = memo(function (params: IProps) {
	const { id, sectionId } = params
	const { copyEstimatePosition } = useAdjustmentState()
	return (
		<Popconfirm
			title="Скопировать?"
			description="Вы уверены, что хотите скопировать эту позицию сметы?"
			onConfirm={() => copyEstimatePosition([id], sectionId)}
		>
			<Button size="small" type="text" icon={<CopyOutlined style={{ color: '#1677ff' }} />} />
		</Popconfirm>
	)
})
