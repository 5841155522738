import { useQueryClient } from '@tanstack/react-query'
import { Col, Collapse, Descriptions, Divider, Row, Space, Spin, Switch, message } from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import { INotificationDestinationOptions } from 'interfaces/IUser'
import { FC, useEffect, useState } from 'react'
import { notificationControllerApi, useNotificationTypes } from '..'
import {
	INotificationType,
	type TNotificationControllerType
} from '../model/notification-controller-interface'
import { useNotificationUserOptions } from '../model/notification-controller-queries'
interface IProps {
	type: TNotificationControllerType
	destinationId?: string
	notificationOptions?: INotificationDestinationOptions[]
}
interface IGroupedNotification {
	group: string
	notificationTypes?: Omit<INotificationType, 'group'>[]
}

interface IChangeOption {
	notificationTypeId: string[]
	optionSending: number
	destinationId?: string
	action: string
	group?: string
}

const options = [
	{
		label: 'E-mail',
		value: 1
	},
	{
		label: 'Push-уведомление',
		value: 2
	},
	{
		label: 'Колокольчик',
		value: 3
	}
]

export const NotificationController: FC<IProps> = props => {
	const { type, destinationId } = props
	const queryClient = useQueryClient()
	const { data, isFetching } = useNotificationTypes(type, destinationId!)
	const { data: userOptions, isFetching: userOptionsFetching } = useNotificationUserOptions(
		type,
		destinationId
	)
	const [notificationTypeRegistry, setNotificationTypeRegistry] = useState<IGroupedNotification[]>(
		[]
	)
	const [activeKeys, setActiveKeys] = useState<string[]>([])
	useEffect(() => {
		const grouped = groupNotifications(data)
		setNotificationTypeRegistry(grouped)
	}, [data, destinationId])

	const groupNotifications = (data?: INotificationType[]) => {
		const grouped: IGroupedNotification[] = []
		data?.forEach(item => {
			if (grouped.find(ar => ar.group === item.objectNameRu)) {
				grouped?.find(ar => ar.group === item.objectNameRu)?.notificationTypes?.push(item)
			} else {
				grouped.push({
					group: item.objectNameRu,
					notificationTypes: [item]
				})
			}
		})
		return grouped
	}

	const onChangeOption = async ({ data }: { data: IChangeOption }) => {
		const { action, ...params } = data
		switch (action) {
			case 'add':
				const notificationTypeIds = notificationTypeRegistry
					.find(reg => reg.group === params.group)
					?.notificationTypes?.filter(
						item =>
							params.notificationTypeId.includes(item.id) &&
							!userOptions?.some(
								option =>
									option.notificationTypeId === item.id &&
									option.userOptionSending === params.optionSending
							)
					)
					.map(item => item.id)

				return await notificationControllerApi
					.addNotification(type, notificationTypeIds!, params.optionSending, params.destinationId)
					.then(() => onUpdateUser())
			case 'remove':
				const optionsId = userOptions
					?.filter(
						op =>
							params.notificationTypeId.includes(op.notificationTypeId) &&
							op.userOptionSending === params.optionSending
					)
					?.map(op => op.notificationOptionId)
				return await notificationControllerApi
					.removeNotification(type, optionsId, params.destinationId)
					.then(() => onUpdateUser())
		}
	}

	const onUpdateUser = async () => {
		queryClient.invalidateQueries({ queryKey: ['notificationUserOptions'] })
		message.success(type === 'admin' ? 'Пользователь обновлён' : 'Настройки уведомлений сохранены')
	}
	const handleCollapseChange = (key: string | string[]) => {
		setActiveKeys(Array.isArray(key) ? key : [key])
	}

	const renderPanelHeader = (group: string, notificationTypes?: INotificationType[]) => (
		<Row align="middle" wrap={false} gutter={16}>
			<Col style={{ width: '35%' }}>{group ? group : 'Прочее'}</Col>
			<Col>
				<Row align="middle" gutter={16} wrap={false}>
					{options.map((el, i) => (
						<Row align="middle" gutter={16} key={i} style={{ marginRight: '9px' }} wrap={false}>
							<Col style={{ whiteSpace: 'nowrap' }}>{`Все ${el.label}`}</Col>
							<Col>
								<Switch
									disabled={userOptionsFetching}
									size="small"
									checked={notificationTypes?.every(item =>
										userOptions?.some(
											option =>
												option.notificationTypeId === item.id &&
												option.userOptionSending === el.value
										)
									)}
									onChange={(checked, e) => {
										e.stopPropagation()
										onChangeOption({
											data: {
												notificationTypeId: notificationTypes?.map(item => item.id) || [],
												optionSending: el.value,
												destinationId,
												action: checked ? 'add' : 'remove',
												group
											}
										})
									}}
								/>
							</Col>
							{i !== options.length - 1 && (
								<Col>
									<Divider type="vertical" />
								</Col>
							)}
						</Row>
					))}
				</Row>
			</Col>
		</Row>
	)

	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			{isFetching ? (
				<Spin />
			) : (
				<Collapse activeKey={activeKeys} onChange={handleCollapseChange}>
					{notificationTypeRegistry
						?.filter(t => (type === 'user' ? t.group === 'ВОР' : t))
						?.sort((a, b) => a.group.localeCompare(b.group))
						.map((reg, i) => (
							<CollapsePanel
								header={renderPanelHeader(reg.group, reg.notificationTypes)}
								key={reg.group + i}
							>
								<Descriptions
									layout="horizontal"
									column={12}
									bordered
									className="at-descriptions"
									labelStyle={{
										fontWeight: 'bold',
										width: '35%',
										alignItems: 'center',
										padding: '8px'
									}}
									contentStyle={{
										width: '65%',
										padding: '8px',
										alignItems: 'center',
										whiteSpace: 'nowrap'
									}}
								>
									{reg.notificationTypes

										?.sort((a, b) => a.objectNameRu?.localeCompare(b.objectNameRu))
										.map(item => (
											<Descriptions.Item
												key={item.id}
												label={item.nameRu ? item.nameRu : 'Прочее'}
												span={12}
											>
												<Row gutter={16} align="middle">
													{options.map((el, i) => (
														<Col key={item.id + i}>
															<Row align="middle" gutter={16}>
																<Col> {el.label}</Col>
																<Col>
																	<Switch
																		disabled={userOptionsFetching}
																		size="small"
																		checked={
																			typeof userOptions?.find(
																				option =>
																					option.notificationTypeId === item.id &&
																					option.userOptionSending === el.value
																			) !== 'undefined'
																		}
																		onChange={checked =>
																			onChangeOption({
																				data: {
																					notificationTypeId: [item.id],
																					optionSending: el.value,
																					destinationId,
																					action: checked ? 'add' : 'remove',
																					group: reg.group
																				}
																			})
																		}
																	/>
																</Col>
																{i !== options.length - 1 && (
																	<Col>
																		<Divider type="vertical" />
																	</Col>
																)}
															</Row>
														</Col>
													))}
												</Row>
											</Descriptions.Item>
										))}
								</Descriptions>
							</CollapsePanel>
						))}
				</Collapse>
			)}
		</Space>
	)
}
