import { Button, Form, Input } from "antd"
import { DatePicker, Space, message} from 'antd';
import { FC } from "react"
import { maintanceService } from 'services/MaintanceService'

interface IProps {
	message: (m: string, type: string) => void
	loader: (x: boolean) => void
}



const MaintanceNotificationForm: FC<IProps> = ({ loader })=> {
    
    const { RangePicker } = DatePicker;
    const [form] = Form.useForm();
    

    const onHandleMaintenanceSet = async (values:any): Promise<void> => {
      const { description, dateRange } = values;
      const date = dateRange[0]?.format('DD/MM/YYYY');
      const startTime = dateRange[0]?.format('HH:mm');
      const endTime = dateRange[1]?.format('HH:mm');
      if (description && date && startTime && endTime) {
        await maintanceService.setMaintanceNotification({description, date, startTime,endTime}).then(()=> {
        message.success("Запись была создана")
      form.resetFields()
    }).catch(error => message.error(error.response.data.detail))
    }}
    
    return (
        <Form
      form={form}
      onFinish={onHandleMaintenanceSet}
      initialValues={{ description: "Время проведения технических работ:" }}
    >
      <Space direction="vertical" size={12}>
        <Form.Item
          name="description"
          rules={[{ required: true, message: 'Введите текст уведомления' }]}
        >
          <Input placeholder="Введите текст уведомления" />
        </Form.Item>
        <Form.Item
          name="dateRange"
          rules={[{ required: true, message: 'Выберите диапазон времени' }]}
        >
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
      </Space>
      <Button
        type="primary"
        htmlType="submit"
        style={{ marginLeft: "2vh" }}
      >
        Назначить
      </Button>
    </Form>
)
}
export default MaintanceNotificationForm;
