import {
	AppstoreAddOutlined,
	CheckSquareOutlined,
	CopyOutlined,
	DeleteOutlined,
	ExportOutlined,
	PlusSquareOutlined
} from '@ant-design/icons'
import { Row } from '@tanstack/react-table'
import { Button, Dropdown, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useEstimatePositionState } from 'widgets/estimate-position-card'
import { useShallow } from 'zustand/react/shallow'
import { IAdjustmentSection } from '..'
import { useAdjustmentState } from '../model/adjustment-state'

interface IProps {
	row: Row<IAdjustmentSection>
}

export const AdjustmentPositionMenu: FC<IProps> = params => {
	const { row } = params
	// const wbsPermissions = useWbsPermits()
	const checkPermissions = useCheckPermissions()
	const { user: currentUser } = useAppSelector(state => state.environment)
	const {
		container,
		selectedRows,
		removeSectionId,
		toRemove,
		showRemoveModal,
		expandAllChildren,
		selectAllChildren,
		copyEstimatePosition,
		updateSections
	} = useAdjustmentState(
		useShallow(state => ({
			container: state.container,
			selectedRows: state.selectedRows,
			removeSectionId: state.removeSectionId,
			toRemove: state.toRemove,
			showRemoveModal: state.showRemoveModal,
			expandAllChildren: state.expandAllChildren,
			selectAllChildren: state.selectAllChildren,
			copyEstimatePosition: state.copyEstimatePosition,
			updateSections: state.updateSections
		}))
	)

	const canEdit = checkPermissions([PERMISSIONS.AdjustmentCreate])

	return (
		<Dropdown
			disabled={!row.original.hasTechnologies}
			overlayStyle={{ width: '256px' }}
			trigger={['click']}
			menu={{
				items: [
					{
						label: 'Добавить',
						key: 'add',
						icon: <AppstoreAddOutlined />,
						disabled: container?.ready || container?.isLocalEstimate || !canEdit,
						onClick: e => {
							e.domEvent.stopPropagation()
							e.domEvent.preventDefault()
							if (container) {
								useEstimatePositionState.setState({
									show: true,
									containerId: container.id,
									section: row.original
								})
							}
						}
					},
					{
						label: row.subRows.some(r => r.getIsSelected()) ? 'Снять выбор' : 'Выбрать всё',
						key: 'selectAll',
						icon: <CheckSquareOutlined />,
						disabled:
							container?.ready ||
							container?.isLocalEstimate ||
							!canEdit ||
							!row.original.children?.length,
						onClick: e => {
							e.domEvent.stopPropagation()
							e.domEvent.preventDefault()
							selectAllChildren(row)
						}
					},
					{
						label: row.subRows.some(r => r.getIsExpanded()) ? 'Свернуть всё' : 'Развернуть всё',
						key: 'expand',
						icon: <ExportOutlined />,
						disabled: !row.original.children?.length,
						onClick: e => {
							e.domEvent.stopPropagation()
							e.domEvent.preventDefault()
							expandAllChildren(row)
						}
					},
					{
						label: (
							<Tooltip
								zIndex={999999}
								placement="right"
								title={
									Object.keys(selectedRows).length === 0 && 'Выберете позиции сметы для удаления'
								}
							>
								Удалить
							</Tooltip>
						),
						key: 'delete',
						danger: true,
						disabled:
							container?.ready ||
							container?.isLocalEstimate ||
							!canEdit ||
							!row.getIsSomeSelected(),
						icon: <DeleteOutlined />,
						onClick: e => {
							e.domEvent.stopPropagation()
							useAdjustmentState.setState({
								showRemoveModal: true,
								toRemove: selectedRows.rows,
								removeSectionId: row.original.id
							})
						}
					},
					{
						label: (
							<Tooltip
								zIndex={999999}
								placement="right"
								title={
									Object.keys(selectedRows).length === 0 && 'Выберете позиции сметы для копирования'
								}
							>
								Копировать
							</Tooltip>
						),
						key: 'copy',
						disabled:
							container?.ready ||
							container?.isLocalEstimate ||
							!canEdit ||
							!row.getIsSomeSelected(),
						icon: <CopyOutlined />,
						onClick: e => {
							e.domEvent.stopPropagation()
							copyEstimatePosition(
								Object.keys(selectedRows.rows).map(s => s),
								row.original.id
							)
						}
					}
				]
			}}
		>
			<Tooltip title={!row.original.hasTechnologies && 'В разделе нет технологий'}>
				<Button
					data-attr="sectionActions"
					type="text"
					size="small"
					icon={
						<PlusSquareOutlined
							style={{ color: row.original.hasTechnologies ? '#0f8bfd' : 'lightgray' }}
						/>
					}
					onClick={e => {
						e.stopPropagation()
					}}
				/>
			</Tooltip>
		</Dropdown>
	)
}
