import { DollarCircleOutlined } from '@ant-design/icons'
import { Button, Card, Col, Row } from 'antd'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect, useState } from 'react'
import { reportService } from 'services/ReportService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setHocReport } from 'store/lightReportSlice'
import { HOCFineReportCreateModal } from 'widgets/reports/HOCFineReport/ui/HOCFineReportCreateModal'
import { HOCReportCreate } from './hoc-report-create'
import { HOCReportTable } from './hoc-report-table'

interface IProps {}

export const HOCReport: FC<IProps> = () => {
	const checkPermissions = useCheckPermissions()
	const dispatch = useAppDispatch()
	const data = useAppSelector(state => state.lightReport.hoc)
	const { project, permissions } = useAppSelector(state => state.environment)
	const dto = {
		contractorId: undefined,
		from: null,
		projectIds: [project.id],
		to: null
	}

	const [showHOCFineReportModal, setShowHOCFineReportModal] = useState(false)

	const tableReport = async () => {
		await reportService.getHocReport(dto).then(response => {
			dispatch(setHocReport(response))
		})
	}

	useEffect(() => {
		tableReport()
	}, [])

	return (
		<>
			<HOCFineReportCreateModal
				show={showHOCFineReportModal}
				onClose={() => setShowHOCFineReportModal(false)}
				download
			/>
			<Card>
				<Row justify="space-between">
					<Col>
						<Row gutter={8}>
							<Col>
								<HOCReportCreate />
							</Col>
							<Col>
								<HOCReportCreate download />
							</Col>
							<Col>
								<Button
									icon={<DollarCircleOutlined />}
									disabled={!checkPermissions([PERMISSIONS.ReportHOCFine])}
									size="middle"
									onClick={() => setShowHOCFineReportModal(true)}
								>
									Отчет РСО/Штрафы
								</Button>
							</Col>
						</Row>
					</Col>
					{/* {!!data.length && (
						<Col>
							<Button danger onClick={() => dispatch(resetHocReport())}>
								Сбросить
							</Button>
						</Col>
					)} */}
				</Row>
				<HOCReportTable />
			</Card>
		</>
	)
}
