import { FC, useState } from 'react'
import { List, Button, Space } from 'antd'
import { ReleaseNotesEditor } from './releaseNotes.editor'
import { useReleaseNotes } from 'hooks/useReleaseNotes'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { setPageTitle } from 'store/rootSlice'
import { ReleaseNotesItem } from './releaseNotes.item'
interface IReleaseNotesPageProps {}

export const ReleaseNotesPage: FC<IReleaseNotesPageProps> = () => {
	const dispatch = useAppDispatch()
	const [editorVisible, setEditorVisible] = useState(false)
	const isAdmin = useAppSelector(state => state.environment.user)?.isAdmin
	const { data, isFetching } = useReleaseNotes()
	dispatch(setPageTitle({ mainTitle: 'История обновлений' }))

	return (
		<Space direction="vertical" className="dashboard__block">
			{editorVisible && <ReleaseNotesEditor onCancel={() => setEditorVisible(false)} />}
			<List
				header={
					isAdmin && (
						<Button type="primary" onClick={() => setEditorVisible(true)}>
							Добавить
						</Button>
					)
				}
				itemLayout="vertical"
				loading={isFetching}
				dataSource={data?.data?.filter(i => !i.isDeleted)}
				style={{ width: '100%' }}
				renderItem={item => <ReleaseNotesItem item={item} isAdmin={isAdmin!} />}
			/>
		</Space>
	)
}
