import { EditOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, DatePicker, Form, Row, Table, Tooltip, message } from 'antd'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { IErrorDetail } from 'interfaces/IBase'
import { IPlannedDate } from 'interfaces/IPlannedDate'
import { FC, useState } from 'react'
import { changePlannedDate } from 'services/PlannedDatesService'
import { usePlanedDates } from './planned-dates-query'
import { usePlannedDatesState } from './planned-dates-state'
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean
	dataIndex: string
	title: any
	record: IPlannedDate
	index: number
	children: React.ReactNode
}

const EditableCell: FC<EditableCellProps> = ({
	editing,
	dataIndex,
	title,
	record,
	index,
	children,
	...restProps
}) => {
	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					rules={[
						{
							required: dataIndex !== 'order',
							message: `Укажите ${title.toLowerCase()}`
						}
					]}
				>
					<DatePicker format={'DD.MM.YYYY'} style={{ width: '100%', boxSizing: 'border-box' }} />
				</Form.Item>
			) : (
				children
			)}
		</td>
	)
}

export const PlannedDates: FC = () => {
	const queryClient = useQueryClient()
	const { data } = usePlanedDates()
	const { pagination, onPagination } = usePlannedDatesState()
	const [planedDateForm] = Form.useForm()
	const [editingKey, setEditingKey] = useState('')
	const isEditing = (record: IPlannedDate) => record.id === editingKey

	const edit = (record: Partial<IPlannedDate>) => {
		planedDateForm.setFieldValue('plannedDate', record.plannedDate && dayjs(record.plannedDate))
		setEditingKey(record.id!)
	}

	const onSetPlannedDate = async (record: IPlannedDate) => {
		const validate = planedDateForm
			.validateFields()
			.then(() => true)
			.catch(() => false)
		if (await validate) {
			const newPlannedDate = dayjs(planedDateForm.getFieldValue('plannedDate')).format('YYYY-MM-DD')
			await changePlannedDate(record.id, newPlannedDate)
				.then(() => {
					queryClient.invalidateQueries({ queryKey: ['templatesPlanedDate'] })
					message.success('Плановая дата успешно установлена')
					onCancel()
				})
				.catch((err: AxiosError<IErrorDetail>) => message.error(err.response?.data.detail))
		}
	}

	const onCancel = () => {
		setEditingKey('')
	}

	const planedDateColumns = [
		{
			dataIndex: 'templateType',
			title: 'Тип',
			width: 120,
			render: (value: any) => value && 'УК КС'
		},
		{
			dataIndex: 'title',
			title: 'Название',
			render: (value: string) => value
		},
		{
			dataIndex: 'plannedDate',
			title: 'Плановая дата передачи системы',
			width: 256,
			render: (value: any) => (value ? dayjs(value).format('DD.MM.YYYY') : '-'),
			editable: () => true
		},
		{
			width: 200,
			render: (_: any, record: IPlannedDate) => {
				const editable = isEditing(record)
				return editable ? (
					<Row gutter={2} wrap={false}>
						<Col>
							<Tooltip title="Сохранить">
								<Button
									icon={<SaveOutlined />}
									type="primary"
									onClick={() => onSetPlannedDate(record)}
									style={{ marginRight: 8 }}
								/>
							</Tooltip>
						</Col>
						<Col>
							<Tooltip title="Отмена">
								<Button
									icon={<StopOutlined />}
									onClick={() => onCancel()}
									style={{ marginRight: 8 }}
								/>
							</Tooltip>
						</Col>
					</Row>
				) : (
					<Tooltip title="Редактировать">
						<Button
							icon={<EditOutlined />}
							disabled={editingKey !== ''}
							onClick={() => edit(record)}
						/>
					</Tooltip>
				)
			}
		}
	]

	const mergedColumns = planedDateColumns.map(col => {
		if (!col.editable) {
			return col
		}
		return {
			...col,
			onCell: (record: IPlannedDate) => ({
				record,
				dataIndex: col.dataIndex,
				title: col.title,
				editing: isEditing(record)
			})
		}
	})

	return (
		<div className="checklists__block">
			<Form form={planedDateForm} component={false}>
				<Table
					className="app-table-types"
					components={{
						body: {
							cell: EditableCell
						}
					}}
					rowKey={record => record.id}
					size="small"
					bordered
					dataSource={data ?? []}
					columns={mergedColumns as any}
					pagination={{
						current: pagination.currentPage,
						defaultPageSize: 30,
						total: pagination.totalItems,
						hideOnSinglePage: true,
						showSizeChanger: false,
						onChange: onPagination
					}}
				/>
			</Form>
		</div>
	)
}
