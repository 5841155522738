import { Form, Select } from 'antd'
import { FC, useEffect, useState } from 'react'
import { WbsContainerApi } from '../api/wbsContainerApi'
import { INonActualPosition, ISectionToMove } from '../model/wbsContainerInterfaces'
import { useWbsContainerState } from '../model/wbsContainerState'

interface IProps {
	record: INonActualPosition
	selectedItems: string[]
	containerId?: string
}

export const WbsSelectSectionToMove: FC<IProps> = ({ record, selectedItems, containerId }) => {
	const [positions, setPositions] = useState<ISectionToMove[]>([])
	const { container } = useWbsContainerState()
	const getPositions = async (workId: string) => {
		if (containerId && workId) {
			const data = await WbsContainerApi.getSectionsToMove(containerId, workId)
			setPositions(data)
		}
	}
	useEffect(() => {
		getPositions(record.works?.[0]?.workId)
	}, [record])

	return (
		<Form.Item
			style={{ margin: 0 }}
			name={record.id}
			rules={[
				{
					required: !selectedItems.includes(record.id),
					message: 'Укажите секцию или пометьте на удаление'
				}
			]}
		>
			<Select
				allowClear
				disabled={selectedItems.includes(record.id) || positions.length === 0}
				style={{ width: '100%' }}
				options={positions.map(item => ({
					key: item.id,
					value: item.id,
					label: `${item.codifier} - ${item.name}`
				}))}
			/>
		</Form.Item>
	)
}
