import { useEffect } from "react";
import useMaintanceState from "store/maintanceState";


export const useMaintance = (start: string | null, end: string | null) => {
    const {setMaintanceOnProgress, setMaintanceFinished} = useMaintanceState()
    
    useEffect(() => {
        if (start !== null && end !== null) {
            const calculateTime = (time: string) => {
                const [hours, minutes] = time.split(':').map(Number);
                const curTime = new Date();
                const targetTime = new Date();
                targetTime.setHours(hours, minutes, 0, 0);
                return targetTime.getTime() - curTime.getTime();
            }

            const startTime = calculateTime(start);
            const endTime = calculateTime(end);
            if (endTime > 0) {
                const startTimeTimer = setTimeout(() => {
                    setMaintanceOnProgress();
                }, startTime);
            
            const endTimeTimer = setTimeout(() => {
                setMaintanceOnProgress()
                setMaintanceFinished()
        }, endTime);
    
    return () => {
        clearTimeout(startTimeTimer);
        clearTimeout(endTimeTimer);
    }
            };
        }
    }, [start, end]);
}; 

