import { INonActualSection } from 'pages/unmodelPage/wbsContainer'
import { API } from 'services/ApiService'
import {
	IEstimatePosition,
	IEstimatePositionNoteDto,
	IEstimatePositionOrder,
	IEstimateSection
} from '..'

export const EstimateApi = {
	async getSections(containerId: string, type: 'wbs' | 'local') {
		const url = type === 'local' ? 'containerEstimates' : 'containers'
		return await API<IEstimateSection[]>({
			url: `/v1/${url}/${containerId}/sections`,
			method: 'get'
		}).then(response => response.data)
	},

	async toggleReady(containerId: string, type: 'wbs' | 'local', ready: boolean) {
		const url = type === 'local' ? 'containerEstimates' : 'containers'
		return await API({
			url: `/v1/${url}/${containerId}/ready`,
			method: 'patch',
			params: { ready }
		}).then(response => response.status)
	},

	async getEstimatePositions(containerId: string, sectionId: string, type: 'wbs' | 'local') {
		const url = type === 'local' ? 'containerEstimates' : 'containers'
		return await API<IEstimatePosition[]>({
			url: `/v2/${url}/${containerId}/sections/${sectionId}/estimatePositions`,
			method: 'get'
		}).then(response => response.data)
	},

	async changeOrder(data: IEstimatePositionOrder[]) {
		return await API({
			url: `/v1/estimatePositions/order`,
			method: 'patch',
			data: data
		}).then(response => response)
	},

	async copyEstimatePosition(ids: string[]) {
		return await API<string[]>({
			url: `/v1/estimatePositions/copy`,
			method: 'post',
			data: ids
		}).then(response => response.data)
	},

	async clearIsCopied(containerId: string, type: 'wbs' | 'local') {
		const url = type === 'local' ? 'containerEstimates' : 'containers'
		return await API({
			url: `/v1/${url}/${containerId}/clearIsCopied`,
			method: 'patch'
		}).then(response => response.data)
	},

	async deleteEstimatePosition(ids: string[]) {
		return await API({
			url: `/v1/estimatePositions`,
			method: 'delete',
			data: ids
		}).then(response => response.data)
	},

	async setEstimatePositionNote(dto: IEstimatePositionNoteDto) {
		return await API({
			url: '/v1/estimatePositions/note',
			method: 'patch',
			data: dto
		}).then(response => response.data)
	},

	async actualize(containerId: string) {
		return await API<boolean>({
			url: `/v1/containers/${containerId}/sections/actualize`,
			method: 'post'
		}).then(response => response.data)
	},

	async getNonActualPositions(containerId: string) {
		return await API<INonActualSection[]>({
			url: `/v1/containers/${containerId}/sections/nonActualPositions`,
			method: 'get'
		}).then(response => response.data)
	},
	async closedEstimatePosition(id: string) {
		return await API({
			url: `/v1/estimatePositions/${id}/closed`,
			method: 'patch'
		}).then(response => response.data)
	}
}
