import { Row } from '@tanstack/react-table'
import { Row as AntRow, Col } from 'antd'
import { memo } from 'react'
import { IAdjustmentSection } from '../model/adjustment-schema'
import { useAdjustmentState } from '../model/adjustment-state'
import { AdjustmentPositionMenu } from './AdjustmentPositionMenu'

interface IProps {
	row: Row<IAdjustmentSection>
	callBack?: () => void
}

export const AdjustmentSectionTitle = memo(function (params: IProps) {
	const { row, callBack } = params
	const { selectedRows } = useAdjustmentState()
	return (
		<AntRow
			wrap={false}
			gutter={16}
			align="middle"
			justify="space-between"
			style={{
				width: '100%',
				height: '100%',
				color: row.original.hasPositions ? '#0F8BFD' : '#444'
			}}
		>
			<Col onClick={() => (callBack ? callBack() : null)} style={{ width: '100%' }}>
				{row.original.codifier} - {row.original.name}
			</Col>
			{/* {row.getIsSomeSelected() && selectedRows.parentId === row.id && (
				<Col>
					<EstimatePositionMove sectionId={row.id} rowId={''} isMultiply={true} row={row} />
				</Col>
			)} */}
			<Col>
				<AntRow>
					<Col>
						<AdjustmentPositionMenu row={row} />
					</Col>
				</AntRow>
			</Col>
		</AntRow>
	)
})
