import { Row } from '@tanstack/react-table'
import { Row as AntRow, Col } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { memo } from 'react'
import { useWbsPermits } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'
import {
	EstimatePositionMenu,
	EstimatePositionMove,
	IEstimateSection /* useEstimateState */,
	useEstimateState
} from '..'

interface IProps {
	row: Row<IEstimateSection>
	callBack?: () => void
}

export const EstimateSectionTitle = memo(function (params: IProps) {
	const { row, callBack } = params
	const { selectedRows } = useEstimateState()
	const { user } = useAppSelector(state => state.environment)
	const permits = useWbsPermits()
	const { container } = useEstimateState(
		useShallow(state => ({
			container: state.container
		}))
	)
	const isAuthor =
		(user?.isAdmin ||
			container?.author.id === user?.id ||
			container?.redactors.some(r => r.id === user?.id)) ??
		false

	const isLocalEstimate = typeof container?.isLocalEstimate !== 'undefined'
	const canEditCorrectLocalEstimate = container?.isLocalEstimate === false && permits.canLocalEdit
	return (
		<AntRow
			wrap={false}
			gutter={16}
			align="middle"
			justify="space-between"
			style={{
				width: '100%',
				height: '100%',
				color: row.original.hasPositions ? '#0F8BFD' : '#444'
			}}
		>
			<Col onClick={() => (callBack ? callBack() : null)} style={{ width: '100%' }}>
				{row.original.codifier} - {row.original.name}
			</Col>
			{row.getIsSomeSelected() &&
				selectedRows.parentId === row.id &&
				((!isLocalEstimate && permits.canEdit && isAuthor) ||
					(canEditCorrectLocalEstimate && isAuthor)) && (
					<Col>
						<EstimatePositionMove sectionId={row.id} rowId={''} isMultiply={true} row={row} />
					</Col>
				)}
			<Col>
				<AntRow>
					<Col>
						<EstimatePositionMenu row={row} />
					</Col>
				</AntRow>
			</Col>
		</AntRow>
	)
})
