import { API } from "services/ApiService"
import { IHOCFineReport, IHOCFineReportFilters } from "../model/hoc-fine-report-interface"

export const HOCFineReportApi = {
    async getHOCFineReport(settings: IHOCFineReportFilters) {
		return await API<IHOCFineReport[]>({
			url: `/v1/reports/HOCFineReport`,
			method: 'get',
			params: { ...settings }
		}).then(response => response.data)
	},
	async getHOCFineReportExcel(settings: IHOCFineReportFilters) {
		return await API<Blob>({
			url: `/v1/export/exportHOCFineToExcel`,
			method: 'get',
			params: { ...settings },
			responseType: 'blob'
		}).then(response => response.data)
	},
}
