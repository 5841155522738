import { API } from 'services/ApiService'
import { IUpdateOrdinanceFine } from 'widgets/fine/model/fine-interface'
import { IFine } from '../model/fine-card-interface'

export const FineCardApi = {
	async get(id: string) {
		return await API<IFine>({
			url: `/v1/ordinanceFine/${id}`,
			method: 'get'
		}).then(response => response.data)
	},
	async getAttachments(ordinanceFineId: string) {
		return await API<any[]>({
			url: `/v1/ordinanceFine/${ordinanceFineId}/attachment`,
			method: 'get'
		}).then(async response => response.data)
	},
	async update(ordinanceFineId: string, dto: IUpdateOrdinanceFine) {
		return await API({
			url: `/v1/ordinanceFine/${ordinanceFineId}/update`,
			method: 'patch',
			data: dto
		}).then(response => response.data)
	},
	async cancel(ordinanceFineId: string, dto: FormData) {
		return await API({
			url: `/v1/ordinanceFine/${ordinanceFineId}/cancel`,
			method: 'patch',
			data: dto
		}).then(async response => response.data)
	},
	async attachment(ordinanceFineId: string) {
		return await API({
			url: `/v1/ordinanceFine/${ordinanceFineId}/attachment`,
			method: 'get'
		}).then(async response => response.data)
	},
	async getFineAttachment(ordinanceFineId: string) {
		return await API({
			url: `/v1/ordinanceFine/${ordinanceFineId}`,
			method: 'get'
		}).then(async response => response.data)
}
}
