import { INotificationType } from 'widgets/notification-controller'
import { API } from './ApiService'

export const notificationService = {
	async getAdminNotification() {
		return await API<INotificationType[]>({
			// url: `get-admin-options`,
			url: `/v1/notification/notificationTypesForAdmin`,
			method: 'get'
		}).then(response => response.data)
	},

	async getNotification() {
		return await API({
			url: `/v1/notification`,
			method: 'get'
		}).then(response => response.data)
	},

	async updateAdminNotification(dto: INotificationType) {
		const { id, ...data } = dto
		return await API<INotificationType>({
			url: `/v1/notification/updateNotificationTypes/${id}`,
			method: 'patch',
			data: data
		}).then(response => response.data)
	}
}
