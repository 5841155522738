import { useQuery } from '@tanstack/react-query'
import { notificationService } from 'services/NotificationService'

export const useNotifications = () => {
	return useQuery({
		queryKey: ['notifications'],
		queryFn: async () => {
			return await notificationService.getAdminNotification()
		}
	})
}
