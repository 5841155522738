import { EditOutlined, SaveOutlined, StopOutlined } from '@ant-design/icons'
import { Button, Checkbox, Col, Form, InputNumber, Row, Table, Tag, Tooltip } from 'antd'
import { FormInstance } from 'antd/lib'
import type { ColumnsType } from 'antd/lib/table'
import { useAppSelector } from 'hooks/appReduxHook'
import { IContract } from 'interfaces/IContract'
import { FC, useEffect, useState } from 'react'
import { getAllContracts, updateOrdinanceFineSum } from 'services/ContractsService'

interface IContractsWithChildren extends IContract {
	children?: IContract[]
}
interface IContractsGuideProps {}

export interface IContractDto {
	ordinanceFineFirstSum: number
	ordinanceFineRepeatSum: number
	isNotOrdinanceFine: boolean
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean
	dataIndex: string
	title: any
	record: IContractsWithChildren
	index: number
	children: React.ReactNode
	contractsForm: FormInstance
}

const EditableCell: FC<EditableCellProps> = ({
	editing,
	dataIndex,
	title,
	record,
	index,
	children,
	contractsForm,
	...restProps
}) => {
	const inputNode =
		dataIndex === 'ordinanceFineFirstSum' ||
		dataIndex === 'ordinanceFineRepeatSum' ||
		dataIndex === 'isNotOrdinanceFine' ? (
			dataIndex === 'isNotOrdinanceFine' ? (
				<Checkbox
					defaultChecked={record.isNotOrdinanceFine}
					onChange={value =>
						contractsForm.setFieldValue('isNotOrdinanceFine', value.target.checked)
					}
				/>
			) : (
				<InputNumber
					keyboard={false}
					controls={false}
					style={{ width: '100%' }}
					parser={value => value!.replace(',', '.')}
				/>
			)
		) : (
			''
		)

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					// style={{ margin: 0 }}
					rules={[
						{
							required: dataIndex !== 'isNotOrdinanceFine',
							message: `Укажите ${title.toLowerCase()}`
						}
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	)
}

export const ContractsGuide: FC<IContractsGuideProps> = () => {
	const [contracts, setContracts] = useState<IContract[]>([])
	const { projectsList, user } = useAppSelector(state => state.environment)
	const [contractsData, setContractsData] = useState<IContractsWithChildren[]>([])
	const [contractsForm] = Form.useForm()
	const [editingKey, setEditingKey] = useState('')
	const isEditing = (record: IContractsWithChildren) => record.id === editingKey
	const getData = () => {
		const parsedData: IContractsWithChildren[] =
			contracts! &&
			[...contracts!]
				.sort((a, b) => a!?.org!?.name!.localeCompare(b!?.org!?.name!))
				.filter(contract => contract.mainContract === null)
				.map(contract => {
					const child = contracts!?.filter(sub => sub.mainContract!?.id === contract.id)
					if (child.length > 0) {
						return { ...contract, children: child }
					}
					return contract
				})
		setContractsData(parsedData)
	}

	useEffect(() => {
		const init = async () => {
			await getAllContracts().then(data => setContracts(data))
		}
		init()
		// getData()
	}, [])

	useEffect(() => {
		getData()
	}, [contracts])

	const edit = (record: Partial<IContractsWithChildren>) => {
		contractsForm.setFieldsValue({
			ordinanceFineFirstSum: record.ordinanceFineFirstSum,
			ordinanceFineRepeatSum: record.ordinanceFineRepeatSum,
			isNotOrdinanceFine: record.isNotOrdinanceFine
		})
		setEditingKey(record.id!)
	}

	const cancel = () => {
		setEditingKey('')
	}

	const save = async (data: IContractsWithChildren) => {
		const dto: IContractDto = {
			ordinanceFineFirstSum: contractsForm.getFieldValue('ordinanceFineFirstSum'),
			ordinanceFineRepeatSum: contractsForm.getFieldValue('ordinanceFineRepeatSum'),
			isNotOrdinanceFine: contractsForm.getFieldValue('isNotOrdinanceFine')
		}
		await updateOrdinanceFineSum(data.id, dto).then(async () => {
			await getAllContracts().then(data => setContracts(data))
		})
		cancel()
	}

	const contractsGuideColumns: ColumnsType<IContractsWithChildren> = [
		{
			title: 'Наименование',
			dataIndex: 'name',
			width: 200
		},
		{
			title: 'Организация',
			render: (_, record) => record!?.org!?.name!,
			width: 100
		},
		{
			title: 'Подрядчик',
			render: (_, record) => record!?.contractor!?.name!,
			width: 100
		},
		{
			title: 'Субподрядчик',
			render: (_, record) => record!?.subContractor!?.name!,
			width: 100
		},
		{
			title: 'Проект',
			render: (_, record) =>
				// projectsList.find(prj => prj!?.project1C!?.id! === record!?.project1C!?.id)?.name!,
				record.project?.map(p => <div>{p.name}</div>),
			width: 100
		},
		{
			title: 'Проект 1С',
			render: (_, record) => record!?.project1C!?.name!,
			width: 100
		},
		{
			title: 'Объекты строительства',
			width: 200,
			render: (_, record) =>
				record.constructionObjects.map(item => (
					<Tooltip title={item.name}>
						<Tag
							style={{
								fontSize: 14,
								padding: 4,
								margin: 4
							}}
						>
							{item.name}
						</Tag>
					</Tooltip>
				))
		},
		{
			title: 'Сумма первого штрафа',
			dataIndex: 'ordinanceFineFirstSum',
			width: 100,
			fixed: 'right',
			onCell: record => ({
				record,
				dataIndex: 'ordinanceFineFirstSum',
				title: 'Сумму первого штрафа',
				editing: isEditing(record),
				contractsForm: contractsForm
			})
		},
		{
			title: 'Сумма повторного штрафа',
			dataIndex: 'ordinanceFineRepeatSum',
			width: 100,
			fixed: 'right',
			onCell: record => ({
				record,
				dataIndex: 'ordinanceFineRepeatSum',
				title: 'Сумму повторного штрафа',
				editing: isEditing(record),
				contractsForm: contractsForm
			})
		},
		{
			title: 'Не выставлять штраф',
			dataIndex: 'isNotOrdinanceFine',
			width: 80,
			fixed: 'right',
			onCell: record => ({
				record,
				dataIndex: 'isNotOrdinanceFine',
				title: 'Не выставлять штраф',
				editing: isEditing(record),
				contractsForm: contractsForm
			}),
			render: (_, record) => <Checkbox checked={record.isNotOrdinanceFine} />
		},
		{
			width: 90,
			align: 'center',
			fixed: 'right',
			render: (_, record) => {
				const editable = isEditing(record)
				return (
					user?.isAdmin &&
					(editable ? (
						<Row gutter={2} wrap={false}>
							<Col>
								<Tooltip title="Сохранить">
									<Button
										icon={<SaveOutlined />}
										type="primary"
										onClick={() => save(record)}
										style={{ marginRight: 8 }}
									/>
								</Tooltip>
							</Col>
							<Col>
								<Tooltip title="Отмена">
									<Button
										icon={<StopOutlined />}
										onClick={() => cancel()}
										style={{ marginRight: 8 }}
									/>
								</Tooltip>
							</Col>
						</Row>
					) : (
						record.mainContract === null && (
							<Tooltip title="Редактировать">
								<Button
									icon={<EditOutlined />}
									disabled={editingKey !== ''}
									onClick={() => edit(record)}
								/>
							</Tooltip>
						)
					))
				)
			}
		}
	]

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<Form form={contractsForm} component={false}>
				<Table
					size="small"
					rowKey={record => record.id!}
					dataSource={contractsData}
					sticky={{ offsetHeader: -16 }}
					components={{
						body: {
							cell: EditableCell
						}
					}}
					className="app-table-types"
					columns={contractsGuideColumns}
					pagination={false}
					bordered
					scroll={{ x: '100%' }}
					expandable={{
						rowExpandable: record => record!?.children!?.length > 0,
						expandedRowRender: () => <></>,
						columnWidth: 40
					}}
				/>
			</Form>
		</div>
	)
}
