import { useQuery } from '@tanstack/react-query'
import { getCheckListTemplates, getCheckListTemplatesActiveUKKS, getTemplates } from 'services/TemplatesService'
import { setTotalItems } from 'store/templatesSlice'
import { useAppDispatch, useAppSelector } from './appReduxHook'

export const useChecklistTemplateList = () => {
	const projectId = useAppSelector(state => state.environment.project?.id)
	const totalItems = useAppSelector(state => state.templates.listState.totalItems)
	return useQuery({
		queryKey: ['checklists', projectId, totalItems],
		queryFn: async () => {
			return await getTemplates().then(data => data)
		}
	})
}

export const useChecklistTemplateLists = () => {
	const projectId = useAppSelector(state => state.environment.project?.id)
	const filters = useAppSelector(state => state.templates.filters)
	const currentPage = useAppSelector(state => state.templates.listState.currentPage)
	const sort = useAppSelector(state => state.templates.sort)
	const dispatch = useAppDispatch()
	return useQuery({
		queryKey: ['templates', projectId, filters, currentPage
		, sort
	],
		queryFn: async () => {
			const checkListTemplate = await getCheckListTemplates({
				sort,
				filters: filters,
				offset: currentPage! > 1 ? currentPage! * 50 - 50 : 0
			}).then(response => response)
			dispatch(setTotalItems(checkListTemplate.totalItems))
			return checkListTemplate.data
		},
		refetchOnWindowFocus: false
	})
}

export const useCheckListTemplatesActiveUKKS = () => {
	const projectId = useAppSelector(state => state.environment.project?.id)
	return useQuery({
		queryKey: ['checkListTemplatesActiveUKKS', projectId],
		queryFn: async () => {
			return await getCheckListTemplatesActiveUKKS().then(data => data)
		}
	})
}