import { Button } from 'antd'
import { FC } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useEstimateRelatedDocumentsState } from '../model/estimate-related-documents-state'

interface IProp {
	type: 'wbs' | 'local'
}

export const EstimateRelatedDocumentsButton: FC<IProp> = ({ type }) => {
	const { showModal, toggleShowModal } = useEstimateRelatedDocumentsState(
		useShallow(state => ({
			showModal: state.showModal,
			toggleShowModal: state.toggleShowModal
		}))
	)
	return (
		<>
			<Button type="primary" onClick={() => toggleShowModal(true)}>
				Связанные документы
			</Button>
		</>
	)
}
