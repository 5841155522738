import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'hooks/appReduxHook'
import { WbsRegistryApi, useWbsRegistryState } from '..'

export const useWbsRegistry = () => {
	const { project } = useAppSelector(state => state.environment)
	const { currentPage, sortState, filters, setTotalItems } = useWbsRegistryState()
	return useQuery({
		queryKey: ['wbsRegistry', currentPage, filters, sortState, project],
		queryFn: async () => {
			if (project) {
				const registry = await WbsRegistryApi.getRegistry(currentPage, filters, sortState).then(data => data)
				setTotalItems(registry.totalItems)
				return registry.data
			}
			return null
		},
		retryOnMount: true,
		staleTime: 0,
		cacheTime: 0,
		refetchOnWindowFocus: false
	})
}
