import { IPagination } from 'interfaces/IPagination'
import { IPlannedDate } from 'interfaces/IPlannedDate'
import { API } from './ApiService'

export interface IGetData {
	createdAt?: Date
	owner?: string
	templateType?: number
}

export const getPlannedDates = async (page: number) => {
	const LIMIT = 30
	return await API<IPagination<IPlannedDate[]>>({
		url: `/v1/checkListTemplatesWithPlannedDate`,
		method: 'get',
		params: { limit: LIMIT, offset: page === 1 ? 0 : page * LIMIT - LIMIT }
	}).then(response => response.data)
}

export const changePlannedDate = async (id: string, plannedDate: string) => {
	const data = {
		plannedDate
	}
	return await API({
		method: 'patch',
		url: `/v1/checkListTemplatesProjects/${id}`,
		data: data
	}).then(response => response)
}
