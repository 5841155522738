import { Button, Drawer, Form, Tabs, message } from 'antd'
import { AxiosError } from 'axios'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { IUser, IUserDto } from 'interfaces/IUser'
import { FC, useEffect, useState } from 'react'
import { deleteUser, getUser, updateUser } from 'services/AdminService'
import { resetUserState, setUser } from 'store/userSlice'
import { NotificationController } from 'widgets/notification-controller'
import UserDrawerDetail from './UserDrawerDetail'
import UserDrawerProjects from './UserDrawerProjects'

interface IUserDrawerProps {
	user: IUser | null
	open: boolean
	onClose: (mustUpdate: boolean) => void
}

const UserDrawer: FC<IUserDrawerProps> = ({ user, open, onClose }) => {
	const dispatch = useAppDispatch()
	const [userForm] = Form.useForm()
	const [activeKey, setActiveKey] = useState('details')
	const userDto: IUserDto = useAppSelector(state => state.user.dto)

	useEffect(() => {
		dispatch(resetUserState())
		userForm.resetFields()
		const initUser = async (id: string) => {
			const dto: IUserDto = await getUser(id)
			if (dto) {
				dispatch(setUser({ ...dto, contractorId: dto.contractor?.id ?? null }))
				userForm.setFieldsValue(dto)
			}
		}
		if (user !== null) initUser(user!?.id!)
	}, [user])

	const onTabChange = (key: string) => {
		setActiveKey(key)
	}

	const onUserSave = async () => {
		await updateUser(userDto)
			.then(() => {
				onCancel(true)
				message.success('Пользователь обновлён')
			})
			.catch((error: AxiosError) => message.error(error.message))
	}

	const onCancel = (mustUpdate: boolean = false) => {
		onClose(mustUpdate)
		userForm.resetFields()
		dispatch(resetUserState())
		setActiveKey('details')
	}

	const onUserFormFailed = () => {
		setActiveKey('details')
	}

	const onDeleteUser = async (userId: string) => {
		return await deleteUser(userId)
			.then(data => {
				onCancel(true)
				message.success('Пользователь удалён')
			})
			.catch((error: AxiosError<IErrorDetail>) => {
				onCancel(true)
				message.success('Произошла ошибка во время удаления пользователя')
				console.log(error)
			})
	}

	return (
		<Drawer
			open={open}
			onClose={() => onCancel()}
			width={window.outerWidth < 1300 ? '75vw' : '50vw'}
			destroyOnClose={true}
			title={userDto.id !== null ? `Пользователь: ${userDto.email}` : 'Новый пользователь'}
			extra={
				<Button
					htmlType="submit"
					onClick={() => userForm.submit()}
					ghost
					style={{ borderColor: '#fff' }}
				>
					Сохранить
				</Button>
			}
			footer={
				<Button onClick={() => onDeleteUser(userDto?.id!)} danger>
					Удалить пользователя
				</Button>
			}
			className="at-drawer-status at-drawer-status--noneliminated"
			bodyStyle={{ backgroundColor: '#f2f2f2', paddingTop: 0 }}
		>
			<Form name="userForm" form={userForm} onFinish={onUserSave} onFinishFailed={onUserFormFailed}>
				<Tabs
					style={{ width: '100%', minHeight: '100%' }}
					activeKey={activeKey}
					size="large"
					tabBarGutter={16}
					onChange={onTabChange}
					items={[
						{
							label: 'Детали',
							key: 'details',
							children: <UserDrawerDetail userForm={userForm} />
						},
						{
							label: 'Проекты',
							key: 'issues',
							children: <UserDrawerProjects />
						},
						{
							label: 'Уведомления',
							key: 'notifications',
							children: (
								<NotificationController
									type="admin"
									destinationId={userDto.destinationId}
									notificationOptions={userDto?.notificationDestinationOption}
								/>
							)
						}
					]}
				/>
			</Form>
		</Drawer>
	)
}
export default UserDrawer
