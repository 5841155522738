import { Card, Popconfirm } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { FC } from 'react'
import * as DOMPurify from 'dompurify'
import { ITextItem } from 'interfaces/IAnalytics'
import { useAppSelector } from 'hooks/appReduxHook'

interface IProps {
	item: ITextItem | null
	setTextItem: (item: ITextItem) => void
	editIconVisible: boolean
	setEditIconVisible: (visibility: boolean) => void
	handleDeleteItem: (id: string) => void
}

const InformationUnit: FC<IProps> = ({ item, setTextItem, editIconVisible, setEditIconVisible, handleDeleteItem }) => {
	const { user: currentUser } = useAppSelector(state => state.environment)

	return (
		<Card
			key={item?.id}
			style={{
				margin: '16px 0',
				backgroundColor: 'whitesmoke',
				position: 'relative'
			}}
			onMouseOver={() => currentUser?.isAdmin && setEditIconVisible(true)}
			onMouseLeave={() => setEditIconVisible(false)}
		>
			<EditOutlined
				style={{
					position: 'absolute',
					top: 8,
					right: 32,
					cursor: 'pointer'
				}}
				hidden={!editIconVisible}
				onClick={() => {
					item && setTextItem(item)
				}}
				rev={undefined}
			/>
			<Popconfirm
				title="Удалить запись?"
				placement="left"
				icon={<DeleteOutlined style={{ color: 'red' }} rev={undefined} />}
				onConfirm={() => item && handleDeleteItem(item.id)}
				okText="Да"
				okButtonProps={{ danger: true }}
				cancelText="Нет"
			>
				<DeleteOutlined
					style={{
						position: 'absolute',
						top: 8,
						right: 8,
						cursor: 'pointer'
					}}
					hidden={!editIconVisible}
					rev={undefined} // onClick={() => item && handleDeleteItem(item.id)}
				/>
			</Popconfirm>

			<div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item!.content) }} />
		</Card>
	)
}

export default InformationUnit
