import { IContainer } from 'interfaces/IContainer'
import { IWbsContainer } from 'pages/unmodelPage/wbsContainer'
import { create } from 'zustand'

interface IWbsStatusModalState {
	show: boolean
	container?: IWbsContainer | IContainer
	setContainer: (container: IWbsContainer | IContainer) => void
	unset: () => void
}

const initial = {
	show: false,
	container: undefined
}

export const useWbsStatusModalState = create<IWbsStatusModalState>((set, get) => ({
	...initial,
	setContainer: container => {
		console.log('useWbsStatusModalState', container)
		set(() => ({ container, show: true }))
	},
	unset: () => set(() => ({ ...initial }))
}))
