import { useIsFetching, useQuery } from '@tanstack/react-query'
import {
	Button,
	Card,
	Checkbox,
	Col,
	DatePicker,
	Divider,
	Form,
	Row,
	Select,
	Typography,
	message
} from 'antd'
import { AxiosError } from 'axios'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { IReport } from 'interfaces/IReports'
import { FC, useEffect, useState } from 'react'
import { getContracts } from 'services/ContractsService'
import { reportService } from 'services/ReportService'
import { exportService } from 'services/exportService'

const { Title, Text } = Typography
const reportTypes = [
	{ key: 1, title: 'Реестр замечаний', value: 'issues' },
	{ key: 2, title: 'Отчёт по автору', value: 'author' },
	{ key: 3, title: 'Оценка подрядчика', value: 'contractorRating' }
	// { key: 4, title: 'Отчет РСО', value: 'reportHOC' }
]

interface IReportForm {
	type?: 'issues' | 'author' | 'contractorRating' | 'reportHOC'
	projects?: string[]
	contractor?: string
	contract?: string
	owner?: string
	range?: [Dayjs, Dayjs]
}

const Reports1C: FC = () => {
	const isLoading = Boolean(useIsFetching())
	const [blocked, setBlocked] = useState(false)
	const [allPeriod, setAllPeriod] = useState(false)
	const [reportForm] = Form.useForm<IReportForm>()
	const reportType = Form.useWatch('type', reportForm)
	const [reportDto, setReportDto] = useState<IReportForm>({})
	const projects = useAppSelector(state => state.environment.user?.projects)
	const project = useAppSelector(state => state.environment.project)

	const { data: contractors } = useQuery({
		queryKey: ['reportContractors', reportDto.projects],
		queryFn: async () => {
			return reportDto.projects && reportDto.projects?.length > 0
				? await reportService.getContractors(reportDto?.projects)
				: []
		}
	})

	const { data: authors } = useQuery({
		queryKey: ['reportAuthors', reportDto.projects],
		queryFn: async () => {
			return reportDto.projects && reportDto.projects?.length > 0
				? await reportService.getAuthors(reportDto?.projects)
				: []
		}
	})

	const { data: contracts } = useQuery({
		queryKey: ['reportContracts', reportDto.projects, reportDto.contractor],
		queryFn: async () => {
			if (reportDto.projects && reportDto.projects?.length > 0 && reportDto?.contractor) {
				const contracts = await getContracts({
					contractorId: reportDto?.contractor,
					projectId: reportDto?.projects![0]
				})
				return (
					contracts.filter(contract => contract?.contractor?.id! === reportDto?.contractor) || []
				)
			}
			return []
		}
	})

	const generateReport = async () => {
		setBlocked(true)
		const dto: IReport = {
			from: !allPeriod ? dayjs(reportDto?.range![0]!).format('YYYY-MM-DD') : null,
			to: !allPeriod ? dayjs(reportDto?.range![1]!).format('YYYY-MM-DD') : null,
			contractorId: reportDto.contractor,
			contractId: reportDto.contract,
			projectId:
				reportDto.type !== 'contractorRating' ? reportDto.projects : reportDto.projects![0],
			ownerId: reportDto.owner
		}

		if (reportType === 'reportHOC') {
			await exportService
				.exportHOCToExcel(dto)
				.then(response => {
					const href = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = href
					link.setAttribute('download', 'Отчёт РСО')
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
					URL.revokeObjectURL(href)
				})
				.catch(() => message.error('Во время загрузки файла произошла ошибка'))
				.finally(() => setBlocked(false))
		} else {
			await reportService
				.getReport(reportDto.type === 'author' ? 'issues' : reportDto.type!, dto)
				.then(response => {
					if ('message' in response) {
						return message.error(response.message)
					} else {
						base64ToBlob(
							'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
								response.base64File,
							response.extension
						)
					}
				})
				.catch((error: AxiosError) => {
					message.error('Произошла ошибка при получении отчёта. Обратитесь в тех. поддержку')
				})
				.finally(() => setBlocked(false))
		}
	}

	const onFormChange = (changedValues: IReportForm, values: IReportForm) => {
		switch (true) {
			case 'type' in changedValues:
				reportForm.resetFields()
				reportForm.setFieldValue('type', changedValues.type)
				setReportDto({ type: changedValues.type })
				setAllPeriod(true)
				break
			case 'projects' in changedValues:
				const prj = changedValues.projects
					? Array.isArray(changedValues.projects)
						? changedValues.projects
						: [changedValues.projects]
					: []
				setReportDto(prev => ({
					...prev,
					projects: prj,
					contractor: undefined,
					contract: undefined,
					owner: undefined
				}))
				reportForm.setFieldValue('contractor', undefined)
				reportForm.setFieldValue('contract', undefined)
				reportForm.setFieldValue('owner', undefined)
				break
			case 'contractor' in changedValues:
				setReportDto(prev => ({
					...prev,
					contractor: changedValues.contractor,
					contract: undefined
				}))
				reportForm.setFieldValue('contract', undefined)
				break
			case 'contract' in changedValues:
				setReportDto(prev => ({ ...prev, contract: changedValues.contract }))
				break
			case 'owner' in changedValues:
				setReportDto(prev => ({ ...prev, owner: changedValues.owner }))
				break
			case 'range' in changedValues:
				setReportDto(prev => ({ ...prev, range: changedValues.range }))
				break
		}
	}

	const base64ToBlob = async (base64: string, extension: string) => {
		const reportTitle = reportTypes!?.find(type => type!?.value! === reportDto.type!)!?.title! || ''

		const blob = await fetch(base64)
			.then(res => res.blob())
			.then(data => {
				const href = URL.createObjectURL(data as Blob)
				const link = document.createElement('a')
				link.href = href
				link.setAttribute('download', reportTitle! + extension)
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				URL.revokeObjectURL(href)
			})
			.catch(er => console.log(er))
		return blob
	}
	useEffect(() => {
		reportForm.setFieldValue('projects', [project.id])
		setReportDto({ ...reportDto, projects: [...reportForm.getFieldValue('projects')] })
	}, [reportType])
	return (
		<Card>
			<Form
				form={reportForm}
				name="reportForm"
				onValuesChange={onFormChange}
				onFinish={generateReport}
				disabled={blocked}
			>
				<Row justify="center">
					<Col
						span={6}
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '.5rem',
							border: '1px solid #ddd',
							borderRadius: '1rem',
							padding: '1rem'
						}}
					>
						<Col>
							<Title level={5}>Тип отчёта:</Title>
							<Form.Item
								name="type"
								rules={[
									{
										required: true,
										message: 'Выберете тип отчёта'
									}
								]}
							>
								<Select
									allowClear
									style={{ width: '100%' }}
									options={reportTypes.map(t => ({
										key: t.key,
										label: t.title,
										value: t.value
									}))}
								/>
							</Form.Item>
						</Col>
						{reportDto.type !== undefined && reportDto.type !== null && (
							<>
								<Col>
									<Title level={5}>Проекты:</Title>
									<Form.Item
										name="projects"
										rules={[
											{
												required: true,
												message: 'Выберете хотя бы один проект'
											}
										]}
									>
										<Select
											mode={reportDto?.type !== 'contractorRating' ? 'multiple' : undefined}
											showSearch
											maxTagCount="responsive"
											allowClear
											style={{ width: '100%' }}
											onClear={() => reportForm.setFieldValue('contractor', undefined)}
											optionFilterProp="children"
											filterOption={(input, option) =>
												(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
											}
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? '')
													.toLowerCase()
													.localeCompare((optionB?.label ?? '').toLowerCase())
											}
											options={projects?.map(p => ({
												key: p.id,
												label: p.name,
												value: p.id
											}))}
										/>
									</Form.Item>
								</Col>
								{reportDto?.type !== 'author' && (
									<Col>
										<Title level={5}>Контрагент:</Title>
										<Form.Item
											name="contractor"
											rules={[
												{
													required: true,
													message: 'Выберете контрагента'
												}
											]}
										>
											<Select
												disabled={contractors?.length === 0 || reportDto.projects?.length === 0}
												allowClear
												showSearch
												optionFilterProp="children"
												filterOption={(input, option) =>
													(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
												}
												filterSort={(optionA, optionB) =>
													(optionA?.label ?? '')
														.toLowerCase()
														.localeCompare((optionB?.label ?? '').toLowerCase())
												}
												style={{ width: '100%' }}
												options={contractors?.map(p => ({
													key: p.id,
													label: p.name,
													value: p.id
												}))}
											/>
										</Form.Item>
									</Col>
								)}

								{reportDto?.type === 'author' && (
									<Col>
										<Title level={5}>Автор:</Title>
										<Form.Item name="owner">
											<Select
												disabled={authors?.length === 0 || reportDto.projects?.length === 0}
												allowClear
												showSearch
												optionFilterProp="children"
												filterOption={(input, option) =>
													(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
												}
												filterSort={(optionA, optionB) =>
													(optionA?.label ?? '')
														.toLowerCase()
														.localeCompare((optionB?.label ?? '').toLowerCase())
												}
												style={{ width: '100%' }}
												options={authors?.map(p => ({
													key: p.ownerId,
													label: p.name,
													value: p.ownerId
												}))}
											/>
										</Form.Item>
									</Col>
								)}

								{reportDto?.type === 'contractorRating' && (
									<Col>
										<Title level={5}>Договор контрагента:</Title>
										<Form.Item name="contract">
											<Select
												disabled={contracts?.length === 0 || reportDto.projects?.length === 0}
												allowClear
												showSearch
												optionFilterProp="children"
												filterOption={(input, option) =>
													(option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())
												}
												style={{ width: '100%' }}
												options={contracts?.map(p => ({
													key: p.id,
													label: p.name,
													value: p.id
												}))}
											/>
										</Form.Item>
									</Col>
								)}
								<Col>
									<Title level={5}>Период:</Title>
									<Form.Item
										name="range"
										rules={[
											{
												required: !allPeriod,
												message: 'Укажите период'
											}
										]}
									>
										<DatePicker.RangePicker
											disabled={reportDto?.type === null || allPeriod}
											format="DD.MM.YYYY"
											style={{ width: '100%' }}
										/>
									</Form.Item>
								</Col>
								<Row justify="end">
									<Text style={{ marginRight: '1rem' }}>За всё время</Text>
									<Checkbox
										checked={allPeriod}
										onChange={e => setAllPeriod(e.target.checked)}
										disabled={reportDto?.type === null}
									></Checkbox>
								</Row>

								<Divider />
								<Row justify="space-between">
									<Button type="primary" block loading={isLoading || blocked} htmlType="submit">
										Сформировать
									</Button>
								</Row>
								<Row justify="space-between">
									<Button
										danger
										type="default"
										block
										className="item"
										onClick={() => {
											setReportDto({})
											setAllPeriod(false)
											reportForm.resetFields()
										}}
									>
										Сброс
									</Button>
								</Row>
							</>
						)}
					</Col>
				</Row>
			</Form>
		</Card>
	)
}

export default Reports1C
