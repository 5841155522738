import { create } from "zustand";
import { IHOCFineReport } from "./hoc-fine-report-interface";

interface IHOCFineReportState {
    HOCFineReportData: IHOCFineReport[],
    setData: (data: IHOCFineReport[]) => void
    resetData: () => void
}


export const useHOCFineReportState = create<IHOCFineReportState>(set => ({
    HOCFineReportData: [],
    setData: (data) => set(() => ({ HOCFineReportData: data })),
    resetData: () => set(() => ({ HOCFineReportData: [] }))
}))