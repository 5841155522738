import { useQuery } from '@tanstack/react-query'
import { notificationControllerApi } from '..'
import { TNotificationControllerType } from './notification-controller-interface'

export const useNotificationTypes = (type: TNotificationControllerType, destinationId: string) => {
	return useQuery({
		queryKey: ['notificationTypes', type, destinationId],
		queryFn: async () => {
			return await notificationControllerApi.getList(type).then(data => data)
		},
		refetchOnWindowFocus: false
	})
}
export const useNotificationUserOptions = (
	type: TNotificationControllerType,
	destinationId?: string
) => {
	return useQuery({
		queryKey: ['notificationUserOptions', type],
		queryFn: async () => {
			return await notificationControllerApi
				.getOptions(type, destinationId)
				.then(data => data)
				.catch(() => [])
		},
		refetchOnWindowFocus: false
	})
}
