import { useQuery, useQueryClient } from '@tanstack/react-query'
import { IErrorData } from 'UI/dashboard/EnvironmentConfig'
import { message } from 'antd'
import { AxiosError } from 'axios'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { WbsContainerApi } from 'pages/unmodelPage/wbsContainer'
import { useNavigate } from 'react-router-dom'
import { getAppUserPermissions } from 'services/AuthService'
import {
	getContractors,
	getContractsByProject1CId,
	getIssueSubTypes,
	getIssueTypes
} from 'services/IssuesService'
import { getBuildingPermitsList } from 'services/OrdinanceService'
import { resetAsm } from 'store/asmSlice'
import { resetCheckListsState } from 'store/checklistsSlice'
import {
	setEnvironment,
	setEnvironmentPermissions,
	setEnvironmentProject
} from 'store/environmentSlice'
import { resetIssuesState } from 'store/issueSlice'
import { resetOrdinancesState } from 'store/ordinanceSlice'
import { setPageTitle } from 'store/rootSlice'
import { resetTemplatesState } from 'store/templatesSlice'
import { resetTrafficLight } from 'store/trafficLightSlice'
import { adjustmentApi } from 'widgets/adjustment'
import { LocalEstimateContainerApi, getLocalEstimates } from '../api/localEstimateApi'
import { useLocalEstimateState } from './localEstimateState'

export const useLocalEstimates = (limit?: number) => {
	const project = useAppSelector(state => state.environment.project)
	const { localEstimateFilters, currentPage, setTotalItems, setTotalPages } =
		useLocalEstimateState()
	return useQuery({
		queryKey: ['localEstimates', project?.id, localEstimateFilters, currentPage, limit],
		queryFn: async () => {
			const localEstimateList = await getLocalEstimates({
				project: project!,
				filters: localEstimateFilters,
				offset: currentPage! > 1 ? currentPage! * 50 - 50 : 0,
				limit
			}).then(data => data.data)
			setTotalItems(localEstimateList!.totalItems)
			setTotalPages(localEstimateList!.totalPages)
			return localEstimateList.data
		},
		refetchOnWindowFocus: false
	})
}

export const useLocalEstimate = (containerId?: string) => {
	const queryClient = useQueryClient()
	const dispatch = useAppDispatch()
	const project = useAppSelector(state => state.environment.project)
	const { setContainer, setHasError, currentContainerId } = useLocalEstimateState()

	const { user } = useAppSelector(state => state.environment)
	const navigate = useNavigate()
	return useQuery({
		queryKey: ['localEstimate', currentContainerId],
		queryFn: async () => {
			// setCurrentContainerId(undefined)
			if (currentContainerId) {
				const container = await LocalEstimateContainerApi.getContainer(currentContainerId!).then(
					data => data
				)
				useLocalEstimateState.setState({ container })
				const userProject = user?.projects?.find(p => p.id === container.projectId)
				if (container.isDeleted) {
					setHasError('delete')
					navigate('/local')
				}
				if (project.id === container.projectId) {
					dispatch(
						setPageTitle({
							mainTitle: 'Список ВОР',
							subTitle: `Локальная смета: ${container?.name}, Вид раздела: ${container?.typeSections?.name}`
						})
					)
					setContainer(container)
					return container
				}
				if (project.id !== container.projectId && userProject && !container.isDeleted) {
					const onProjectSelect = async () => {
						dispatch(resetIssuesState())
						dispatch(resetOrdinancesState())
						dispatch(resetCheckListsState())
						dispatch(resetTemplatesState())
						dispatch(resetAsm())
						dispatch(resetTrafficLight())

						// dispatch(setFilterOrdinance({ currentPage: 1, filters: {} }))

						await getAppUserPermissions(userProject?.id!).then(data => {
							dispatch(setEnvironmentPermissions(data))
						})
						dispatch(
							setEnvironmentProject({
								option: 'project',
								data: userProject!
							})
						)
						await getContractors().then(data => {
							dispatch(setEnvironment({ state: 'contractors', data }))
						})
						await getIssueTypes()
							.then(data => {
								dispatch(setEnvironment({ state: 'issueTypes', data }))
							})
							.catch((error: AxiosError) => {
								const { detail } = error!?.response!?.data! as IErrorData
								const { url } = error!?.config!
								message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
								return Promise.reject
							})
						await getIssueSubTypes()
							.then(data => {
								dispatch(setEnvironment({ state: 'issueSubTypes', data }))
							})
							.catch((error: AxiosError) => {
								const { detail } = error!?.response!?.data! as IErrorData
								const { url } = error!?.config!
								message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
								return Promise.reject
							})
						await getContractsByProject1CId(userProject!?.project1C?.id!).then(data => {
							dispatch(setEnvironment({ state: 'contracts', data }))
						})
						await getBuildingPermitsList(userProject!?.project1C?.id!).then(data => {
							dispatch(setEnvironment({ state: 'buildingPermits', data }))
						})
						queryClient.invalidateQueries({ queryKey: ['issueAnalytics'] })
						queryClient.invalidateQueries({ queryKey: ['ordinanceAnalytics'] })
						queryClient.invalidateQueries({ queryKey: ['violationTypesAnalytics'] })
						queryClient.invalidateQueries({ queryKey: ['useMyFilesAnalytics'] })
					}
					onProjectSelect()
				} else {
					setHasError('project')
					navigate('/local')
				}
				dispatch(
					setPageTitle({
						mainTitle: 'Список Локальных смет',
						subTitle: `Локальная смета: ${container?.name}, Вид раздела: ${container?.typeSections?.name}`
					})
				)
				setContainer(container)
				return container
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useLocalEstimateAuthors = () => {
	const project = useAppSelector(state => state.environment.project)
	return useQuery({
		queryKey: ['localEstimateAuthors', project?.id],
		queryFn: async () => {
			return await LocalEstimateContainerApi.getAuthors(project).then(data => data.data)
		},
		refetchOnWindowFocus: false
	})
}

export const useLocalEstimateSections = () => {
	const queryClient = useQueryClient()
	const { container, setSections } = useLocalEstimateState()
	return useQuery({
		queryKey: ['localEstimateSections', container],
		queryFn: async () => {
			if (container) {
				const data = await LocalEstimateContainerApi.getSections(container.id)
				setSections(data)
				if (container.isCopied) queryClient.invalidateQueries(['localEstimate'])
				return data
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useLocalEstimateSectionItems = () => {
	const { container, sectionBlock, setTechnologies, setSectionItems } = useLocalEstimateState()
	return useQuery({
		queryKey: [`localEstimateSectionItems-${sectionBlock}`, container, sectionBlock],
		queryFn: async () => {
			if (container && sectionBlock) {
				const data = await LocalEstimateContainerApi.getSectionItems(sectionBlock, container.id)
				await WbsContainerApi.getSectionTechnologies(sectionBlock).then(data =>
					setTechnologies(sectionBlock, data)
				)
				setSectionItems(sectionBlock, data)
				return null
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useLocalEstimatePotentialRedactors = () => {
	const { project } = useAppSelector(state => state.environment)
	return useQuery({
		queryKey: ['localEstimatePotentialRedactors', project],
		queryFn: async () => {
			if (project) {
				return await LocalEstimateContainerApi.getPotentialRedactors()
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useCurrentConstructionObjects = () => {
	const { project } = useAppSelector(state => state.environment)
	return useQuery({
		queryKey: ['localEstimateCurrentConstructionObjects', project],
		queryFn: async () => {
			if (project) {
				return await LocalEstimateContainerApi.getContainersConstructionObjects()
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useContainerLocalEstimateVisas = (containerId?: string, isAdjustment?: boolean) => {
	return useQuery({
		queryKey: [isAdjustment ? 'adjustmentLocalEstimateVisas' : 'localEstimateVisas', containerId],
		queryFn: async () => {
			if (containerId) {
				return isAdjustment
					? await adjustmentApi.getContainerVisas(containerId, 'local').then(data => data)
					: await LocalEstimateContainerApi.getContainerVisas(containerId).then(data => data)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useCanVisaApproveLocalEstimate = (id?: string, isAdjustment?: boolean) => {
	// const { container } = useLocalEstimateState()
	// const containerId = id ? id : container?.id
	const api = isAdjustment ? adjustmentApi : WbsContainerApi
	return useQuery({
		queryKey: ['localEstimateCanVisaApprove', id],
		queryFn: async () => {
			if (id) {
				return await api.getCanVisaApprove(id, 'local')
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}
