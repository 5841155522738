import Maintance from 'components/Maintance'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect, useState } from 'react'
import type { ColumnsType } from 'antd/lib/table'
import { IContractor } from 'interfaces/IEnvironment'
import { Table } from 'antd'
import { getContractorList } from 'services/EnvironmentService'

interface IContractorsGuideProps {}

export const ContractorsGuide: FC<IContractorsGuideProps> = () => {
	// const contractors = useAppSelector(state => state.environment.contractors)
	const [contractors, setContractors] = useState<IContractor[]>([])
	useEffect(() => {
		const init = async () => {
			await getContractorList(null).then(data => setContractors(data.filter(c => !!c.name.length)))
		}
		init()
	}, [])

	const columns: ColumnsType<IContractor> = [
		{
			title: 'Наименование',
			dataIndex: 'name'
		}
	]

	return (
		<div className="dashboard__block" style={{ display: 'flex', alignItems: 'center' }}>
			<Table
				dataSource={contractors!.sort((a, b) => a.name.localeCompare(b.name))}
				columns={columns}
				style={{ width: '100%' }}
				pagination={false}
				size="small"
			/>
			{/* <Maintance /> */}
		</div>
	)
}
