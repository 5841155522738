import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IIssuesReportData, ILightReportData } from 'interfaces/ILightReport'
import { IHOCReport } from 'interfaces/IReports'

interface IState {
	lightReportData: IIssuesReportData | null
}

const state: IState = {
	lightReportData: null
}

const lightReportSlice = createSlice({
	name: 'lightReport',
	initialState: {
		init: state,
		hoc: [] as IHOCReport[]
	},
	reducers: {
		setLightReport(state, action: PayloadAction<IIssuesReportData>) {
			state.init.lightReportData = action.payload
		},
		setHocReport(state, action: PayloadAction<IHOCReport[]>) {
			state.hoc = action.payload
		},
		resetHocReport(state) {
			state.hoc = []
		}
	}
})

export const { setLightReport, setHocReport, resetHocReport } = lightReportSlice.actions

export default lightReportSlice.reducer
