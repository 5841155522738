import { Spin, Tabs, message } from 'antd'
import { useEffect, useState } from 'react'

import { LoadingOutlined } from '@ant-design/icons'

import { useAppDispatch } from 'hooks/appReduxHook'
import { IProject } from 'interfaces/IEnvironment'
import { useParams } from 'react-router-dom'
import { getProjectList } from 'services/EnvironmentService'
import { setPageTitle } from 'store/rootSlice'
import Notifications from './Notifications'
import ReportNotifications from './ReportNotifications'
import TabPermissions from './TabPermissions'
import TabRoles from './TabRoles'
import MaintanceNotificationForm from './MaintanceNotificationForm'

const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin rev={undefined} />

const AdminPage = () => {
	const params = useParams()
	const dispatch = useAppDispatch()

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [projects, setProjects] = useState<IProject[]>([])

	const showMessage = (alert: string, type: string) => {
		switch (type) {
			case 'success':
				return message.success(alert)
			case 'info':
				return message.info(alert)
			case 'error':
				return message.error(alert)
		}
	}

	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Настройки приложения', subTitle: 'Роли' }))
		getProjectList().then(data => setProjects(data))
	}, [])

	return (
		<div className="dashboard__block">
			{isLoading && (
				<div className="at-loader">
					<Spin indicator={antIcon}></Spin>
				</div>
			)}
			<Tabs
				style={{ width: '100%' }}
				defaultActiveKey={params['*'] ? params['*'] : 'roles'}
				size="large"
				type="line"
				tabBarGutter={16}
				items={[
					{
						label: 'Роли',
						key: 'roles',
						children: <TabRoles message={showMessage} loader={setIsLoading} />
					},
					{
						label: 'Пользователи',
						key: 'users',
						children: (
							<TabPermissions message={showMessage} loader={setIsLoading} projects={projects} />
						)
					},
					// {
					// 	label: 'Forge',
					// 	key: 'forge',
					// 	children: <PluginUpload message={showMessage} loader={setIsLoading} />
					// },
					{
						label: 'Уведомления',
						key: 'notifications',
						children: <Notifications message={showMessage} loader={setIsLoading} />
					},
					{
						label: 'Отчет по уведомлениям',
						key: 'reportNotifications',
						children: <ReportNotifications message={showMessage} loader={setIsLoading} />
					},
					{
						label: 'Технические работы',
						key: 'Maintanance',
						children: <MaintanceNotificationForm message={showMessage} loader={setIsLoading} />
					}
				]}
			/>
		</div>
	)
}

export default AdminPage
