import {
	DeleteOutlined,
	EditOutlined,
	PlusSquareOutlined,
	SaveOutlined,
	StopOutlined
} from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import {
	Button,
	Col,
	Form,
	Input,
	InputNumber,
	Modal,
	Popconfirm,
	Row,
	Space,
	Table,
	Tooltip,
	message
} from 'antd'
import { AxiosError } from 'axios'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect, useState } from 'react'
import { issueTypeService, issuesElementTranslate } from 'services/IssuesService'
import { AddDictionaryModal } from './addDictionary.modal'

interface IIssueDictionary {
	id: string
	name: string
	type: 'issueTypes' | 'issueSubTypes' | 'violationTypes'
	isDeleted: boolean
	parentId?: string
	order?: number | null
	code?: number
	violationWeight?: number
}

export interface IDictionaryForm {
	type: string
	name: string
	issueTypeId?: string
	issueSubTypeId?: string
	order: number
}

interface IViolationGuideProps {}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean
	dataIndex: string
	title: any
	record: IIssueDictionary
	inputType: 'issueTypes' | 'issueSubTypes' | 'violationTypes'
	index: number
	children: React.ReactNode
}

const { confirm } = Modal

const EditableCell: FC<EditableCellProps> = ({
	editing,
	dataIndex,
	title,
	record,
	inputType,
	index,
	children,
	...restProps
}) => {
	const inputNode = !(
		(inputType === 'issueTypes' &&
			(dataIndex === 'code' || dataIndex === 'violationWeight' || dataIndex === 'order')) ||
		(inputType === 'issueSubTypes' && dataIndex === 'violationWeight') ||
		(inputType === 'violationTypes' && dataIndex === 'order')
	) ? (
		dataIndex === 'name' ? (
			<Input />
		) : dataIndex === 'order' ? (
			<InputNumber
				keyboard={false}
				controls={false}
				style={{ width: '100%' }}
				onKeyDown={event => {
					if (!/[0-9]|Backspace|Delete|ArrowLeft|ArrowDown|ArrowRight|ArrowUp/.test(event.key)) {
						event.preventDefault()
					}
				}}
			/>
		) : (
			<InputNumber
				keyboard={false}
				controls={false}
				style={{ width: '100%' }}
				parser={value => value!.replace(',', '.')}
			/>
		)
	) : (
		''
	)

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item
					name={dataIndex}
					// style={{ margin: 0 }}
					rules={[
						{
							required: true,
							message: `Укажите ${title.toLowerCase()}`
						}
					]}
				>
					{inputNode}
				</Form.Item>
			) : (
				children
			)}
		</td>
	)
}

export const ViolationTypesGuide: FC<IViolationGuideProps> = () => {
	const queryClient = useQueryClient()
	const { issueTypes, issueSubTypes, violationTypes } = useAppSelector(state => state.environment)
	const [typesForm] = Form.useForm()
	const orderWatch = Form.useWatch('order', typesForm)
	const codeWatch = Form.useWatch('code', typesForm)
	const violationWeightWatch = Form.useWatch('violationWeight', typesForm)
	const nameWatch = Form.useWatch('name', typesForm)
	const [showAddModal, setShowAddModal] = useState(false)
	const [editingKey, setEditingKey] = useState('')
	const [popOpen, setPopOpen] = useState(false)
	const isEditing = (record: IIssueDictionary) => record.id === editingKey

	const [issueDictionary, setIssueDictionary] = useState<IIssueDictionary[]>([])

	useEffect(() => {
		setIssueDictionary(
			issueTypes
				?.filter(x => !x.isDeleted)!
				?.map(type => ({
					id: type.id,
					name: issuesElementTranslate(type.name),
					type: 'issueTypes',
					isDeleted: type.isDeleted,
					children: issueSubTypes
						?.filter(subType => subType.issueTypeId === type.id && subType.isDeleted !== true)!
						.map(sub => ({
							id: sub.id,
							parentId: sub.issueTypeId,
							name: sub.name,
							type: 'issueSubTypes',
							isDeleted: sub.isDeleted,
							order: sub.order,
							code: sub.code,
							children: violationTypes!
								?.filter(v => v.issueSubTypeId === sub.id && v.isDeleted !== true)!
								?.sort((a, b) => a.name.localeCompare(b.name))
								.map(vio => ({
									id: vio.id,
									parentId: vio.issueSubTypeId,
									name: vio.name,
									type: 'violationTypes',
									isDeleted: vio.isDeleted,
									code: vio.code,
									violationWeight: vio.violationWeight
								}))
						}))
				}))
		)
	}, [issueTypes, issueSubTypes, violationTypes])

	const edit = (record: Partial<IIssueDictionary>) => {
		typesForm.setFieldsValue({
			name: record.name,
			order: record.order,
			code: record.code,
			violationWeight: record.violationWeight
		})
		setEditingKey(record.id!)
	}

	const saveType = async (record: IIssueDictionary) => {
		switch (record.type) {
			case 'issueSubTypes':
				await issueTypeService
					.updateSubType({
						id: record.id,
						name: typesForm.getFieldValue('name'),
						issueTypeId: record!?.parentId!,
						order: typesForm.getFieldValue('order'),
						code: typesForm.getFieldValue('code'),
						isDeleted: record!?.isDeleted!
					})
					.then(() => {
						queryClient.invalidateQueries({ queryKey: ['appShared'] })
						message.success('Подтип успешно сохранён')
						cancel()
					})
				break
			case 'violationTypes':
				await issueTypeService
					.updateViolationType({
						id: record.id,
						name: typesForm.getFieldValue('name'),
						issueSubTypeId: record!?.parentId!,
						code: typesForm.getFieldValue('code'),
						violationWeight: typesForm.getFieldValue('violationWeight'),
						isDeleted: record!?.isDeleted!
					})
					.then(() => {
						queryClient.invalidateQueries({ queryKey: ['appShared'] })
						message.success('Тип нарушения успешно сохранён')
						cancel()
					})
				break
			default:
				await issueTypeService
					.updateType({
						id: record.id,
						name: typesForm.getFieldValue('name'),
						isDeleted: record.isDeleted
					})
					.then(() => {
						queryClient.invalidateQueries({ queryKey: ['appShared'] })
						message.success('Тип успешно сохранён')
						cancel()
					})
				break
		}
	}

	const deleteType = async (record: IIssueDictionary) => {
		await issueTypeService
			.deleteType(record.type, record.id)
			.then(() => {
				queryClient.invalidateQueries({ queryKey: ['appShared'] })
				cancel()
			})
			.catch((err: AxiosError) =>
				err.response!?.status! === 409
					? message.error(err.response!?.data! as String)
					: message.error('Произошла непредвиденная ошибка на сервере!')
			)
			.finally(() => setPopOpen(false))
	}

	const cancel = () => {
		setEditingKey('')
	}

	const saveHandler = (record: IIssueDictionary) => {
		confirm({
			title: 'Внимание!',
			content: (
				<>
					<p>Если уже были сформированы файлы Предписаний, то изменения внесены НЕ БУДУТ!</p>
					<p>Изменения отобразятся только в новых карточках Замечаний и Предписаний.</p>
					<p>Вы действительно хотите внести изменения ?</p>
				</>
			),
			onOk() {
				saveType(record)
			}
		})
	}

	const issueDictionaryColumns = [
		{
			dataIndex: 'name',
			title: 'Наименование',
			render: (_: any, record: IIssueDictionary) => record.name,
			editable: (_: any, record: IIssueDictionary) => true
		},
		{
			dataIndex: 'code',
			title: 'Код',
			width: 200,
			render: (_: any, record: IIssueDictionary) => record!?.code!,
			editable: (_: any, record: IIssueDictionary) => record.type !== 'issueTypes'
		},
		{
			dataIndex: 'order',
			title: 'Порядковый номер',
			width: 200,
			defaultSortOrder: 'descend',
			sorter: (a: IIssueDictionary, b: IIssueDictionary) => {
				if (a.order === null) return 0
				if (b.order === null) return 1
				return a.order! < b.order!
			},
			render: (_: any, record: IIssueDictionary) => record!?.order!,
			editable: (_: any, record: IIssueDictionary) => record.type === 'issueSubTypes'
		},
		{
			dataIndex: 'violationWeight',
			title: 'Вес типа нарушения',
			width: 200,
			render: (_: any, record: IIssueDictionary) => record!?.violationWeight,
			editable: (_: any, record: IIssueDictionary) => record.type === 'violationTypes'
		},
		{
			width: 200,
			render: (_: any, record: IIssueDictionary) => {
				const editable = isEditing(record)

				return editable ? (
					<Row gutter={2} wrap={false}>
						<Col>
							<Tooltip title="Сохранить">
								<Button
									icon={<SaveOutlined />}
									disabled={
										orderWatch === null ||
										codeWatch === null ||
										violationWeightWatch === null ||
										nameWatch?.length === 0
									}
									type="primary"
									onClick={() => saveHandler(record)}
									style={{ marginRight: 8 }}
								/>
							</Tooltip>
						</Col>
						<Col>
							<Tooltip title="Отмена">
								<Button
									icon={<StopOutlined />}
									onClick={() => cancel()}
									style={{ marginRight: 8 }}
								/>
							</Tooltip>
						</Col>
						<Col>
							<Popconfirm
								open={popOpen}
								title="Удалить?"
								placement="topRight"
								onConfirm={() => deleteType(record)}
								onCancel={() => {
									setPopOpen(false)
									cancel()
								}}
								okButtonProps={{ danger: true }}
								okText="Да"
								cancelText="Отмена"
							>
								<Button
									icon={<DeleteOutlined />}
									danger
									style={{ marginRight: 8 }}
									onClick={() => setPopOpen(true)}
								/>
							</Popconfirm>
						</Col>
					</Row>
				) : (
					<Tooltip title="Редактировать">
						<Button
							icon={<EditOutlined />}
							disabled={editingKey !== '' || record.isDeleted}
							onClick={() => edit(record)}
						/>
					</Tooltip>
				)
			}
		}
	]

	const mergedColumns = issueDictionaryColumns.map(col => {
		if (!col.editable) {
			return col
		}
		return {
			...col,
			onCell: (record: IIssueDictionary) => ({
				record,
				dataIndex: col.dataIndex,
				inputType: record.type,
				title: col.title,
				editing: isEditing(record)
			})
		}
	})

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<AddDictionaryModal show={showAddModal} onClose={() => setShowAddModal(false)} />
			<Space style={{ width: '100%', marginBottom: '1rem' }}>
				<Button icon={<PlusSquareOutlined />} type="primary" onClick={() => setShowAddModal(true)}>
					Добавить
				</Button>
			</Space>
			<Form form={typesForm} component={false}>
				<Table
					className="app-table-types"
					components={{
						body: {
							cell: EditableCell
						}
					}}
					rowKey={record => record.id}
					size="small"
					pagination={false}
					bordered
					dataSource={issueDictionary}
					columns={mergedColumns as any}
				/>
			</Form>
		</div>
	)
}
