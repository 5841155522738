import { Form, Input, Select, Tag } from 'antd'
import { IBase } from 'interfaces/IBase'
import { IAppUser, IUserPermission } from 'interfaces/IUser'
import { FC } from 'react'
import { CONTRACTORS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { ILocalEstimate } from '..'

interface IProps extends React.HTMLAttributes<HTMLElement> {
	editing: boolean
	dataIndex: string
	title: any
	record: ILocalEstimate
	index: number
	potentialRedactors: IBase[]
	constructionObjects: IBase[]
	user: IAppUser | null
	children: React.ReactNode
	permissions: IUserPermission[]
	currentUser: IAppUser | null
}

export const LocalEstimateRegistryCell: FC<IProps> = ({
	editing,
	dataIndex,
	title,
	record,
	children,
	potentialRedactors,
	constructionObjects,
	user,
	index,
	permissions,
	currentUser,
	...restProps
}) => {
	const checkPermissions = useCheckPermissions()
	const canEdit = (type: 'redactors' | 'construction') => {
		if (user && record.author) {
			if (user.isAdmin) return true
			if (
				type === 'construction'
					? record.author.id === user.id &&
					  ((checkPermissions([PERMISSIONS.AllCompanies]) ||
							user.contractorId === CONTRACTORS.NDC) ??
							false)
					: record.author.id === user.id
			)
				return true
		}
		return false
	}

	const setNode = () => {
		switch (dataIndex) {
			case 'name':
				return (
					<Form.Item
						style={{ margin: 0 }}
						name={dataIndex}
						rules={[
							{
								required: true,
								message: 'Укажите название контейнера'
							},
							{
								min: 3,
								message: 'Не менее 3х символов'
							}
						]}
					>
						<Input />
					</Form.Item>
				)
			case 'redactors':
				return (
					<Form.Item name={dataIndex} style={{ margin: 0 }}>
						<Select
							maxTagCount={1}
							showSearch={true}
							optionFilterProp="children"
							filterOption={(input, option) =>
								(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare((optionB?.label ?? '').toLowerCase())
							}
							disabled={!canEdit('redactors')}
							mode="multiple"
							style={{ width: '100%' }}
							allowClear
							value={potentialRedactors?.sort(
								(a, b) =>
									+b.isDeleted - +a.isDeleted ||
									+(b.id === currentUser?.id) - +(a.id === currentUser?.id) ||
									a?.name?.localeCompare(b?.name)
							)}
							tagRender={props => (
								<Tag closable={false} bordered={false}>
									{potentialRedactors?.find(p => p.name === props.label)
										? props.label
										: 'Пользователь был удален'}
								</Tag>
							)}
							options={potentialRedactors?.map(r => ({
								key: r.id,
								value: r.id,
								label: r.name
							}))}
						/>
					</Form.Item>
				)
			case 'constructionObjectId':
				return (
					<Form.Item name={dataIndex} style={{ margin: 0 }}>
						<Select
							showSearch={true}
							optionFilterProp="children"
							filterOption={(input, option) =>
								(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
							}
							filterSort={(optionA, optionB) =>
								(optionA?.label ?? '')
									.toLowerCase()
									.localeCompare((optionB?.label ?? '').toLowerCase())
							}
							disabled={!canEdit('construction')}
							style={{ width: '100%' }}
							allowClear
							options={constructionObjects?.map(r => ({
								key: r.id,
								value: r.id,
								label: r.name
							}))}
						/>
					</Form.Item>
				)
		}
	}

	const editableNode = setNode()

	return (
		<td {...restProps}>
			<span className={'app-registry-col-' + dataIndex}>{editing ? editableNode : children}</span>
		</td>
	)
}
