import { useQuery } from '@tanstack/react-query'
import { FineCardApi } from '../api/fine-card-api'
import { TFineCardType } from './fine-card-interface'
import { useFineState } from './fine-card-state'
import { IFileAttachment } from 'interfaces/IPluginFiles'

export const useFineCard = (type: TFineCardType) => {
	const { edit, inner, setData } = useFineState()
	return useQuery({
		queryKey: ['fineCard' + type, edit, inner, type],
		queryFn: async () => {
			if (type === 'edit' && typeof edit === 'string') {
				const data = await FineCardApi.get(edit).then(data => data)
				setData(type, data)
			}
			if (type === 'inner' && typeof inner === 'string') {
				const data = await FineCardApi.get(inner).then(data => data)
				setData(type, data)
			}

			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useFineCardAttachment = (id?: string) => {
	return useQuery({
		queryKey: ['fineCardAttachment', id],
		queryFn: async () => {
			return id ? await FineCardApi.getAttachments(id).then(data => data) : []
		},
		refetchOnWindowFocus: false
	})

	}
	export const useGetFineAttachment = (id?: string) => {
		const {setAttachment} = useFineState()
		return useQuery({
			queryKey: ['fineCardAttachmentToDownload', id],
			queryFn: async () => {
				if (id) {const attachment = await FineCardApi.getFineAttachment(id).then(data => data.attachments[0])
				setAttachment(attachment)}
				return null
			},
			refetchOnWindowFocus: false
		})
	}
	
