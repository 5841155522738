import { Card, Spin, Table, Tag } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { useHistory } from 'hooks/useHistory'
import { IHistory, IHistoryInfo } from 'interfaces/IHistory'
import { FC, useEffect } from 'react'
import { issuesElementTranslate } from 'services/IssuesService'
import { useHistoryState } from './model/history-state'

interface IHistoryProps {
	target: 'issue' | 'ordinance' | 'checklist' | 'template' | 'ordinanceFine' | 'wbs' | 'local'
	id?: string
}

const History: FC<IHistoryProps> = props => {
	const { data: history, isLoading } = useHistory({ target: props.target, id: props.id })
	const { currentPage, totalItems, onPagination } = useHistoryState()
	const actionFormat = (action: string) => {
		switch (action) {
			case 'Added':
				return 'Создание'
			case 'Modified':
				return 'Редактирование'
			default:
				return action
		}
	}

	useEffect(() => {
		onPagination(1)
	}, [])
	const historyColumns: ColumnsType<IHistory> = [
		{
			title: 'Описание',
			width: 170,
			render: (_, record) => record.user?.name ?? 'Myabs System'
		},
		{
			title: 'Дата',
			width: 110,
			render: (_, record) => dayjs(record.dateCreate).format('HH:mm:ss DD.MM.YYYY')
		},
		{
			title: 'Активность',
			width: 150,
			render: (_, record) =>
				record.info?.find(el => el.title === 'Согласовано')?.value
					? 'Согласование'
					: actionFormat(record.action)
		}
	]
	const innerHistoryColumns: ColumnsType<IHistory> = [
		{
			title: 'Описание',
			width: 170,
			render: (_, record) => record?.name ?? 'Myabs System'
		},
		{
			title: 'Дата',
			width: 110,
			render: (_, record) =>
				dayjs(record.editDate ?? record.dateCreate).format('HH:mm:ss DD.MM.YYYY')
		},
		{
			title: 'Активность',
			width: 150,
			render: (_, record) =>
				record.info?.find(el => el.title === 'Согласовано')?.value
					? 'Согласование'
					: actionFormat(record.action)
		}
	]

	const historyAdapter = (info: IHistoryInfo) => {
		switch (true) {
			case info.title.toLowerCase().includes('дата') || info.title.toLowerCase().includes('срок'):
				return dayjs(info.value as string).format('DD.MM.YYYY')
			case info.title === 'Тип нарушения':
				return issuesElementTranslate(info.value!?.toString().toLowerCase())
			case typeof info.value === 'boolean':
				return (info.value as boolean) === true ? 'Да' : 'Нет'
			case info.title === 'Ответственный':
			case info.title === 'Секции':
			case info.title === 'Редакторы':
				return (info.value as string[]).map(x => <Tag style={{ fontSize: 14 }}>{x}</Tag>)
			case info.title === 'Позиции сметы':
			case info.title === 'Материалы позиции сметы':
				return (
					<Table
						size="small"
						sticky={{ offsetHeader: 0 }}
						className="at-expandable"
						dataSource={info.value as unknown as IHistory[]}
						columns={innerHistoryColumns}
						pagination={false}
						scroll={{ y: 500 }}
						rowKey={record => record.id}
						expandable={{
							rowExpandable: (record: IHistory) =>
								info.title !== 'Позиции сметы' && !!record.info?.length,
							expandedRowRender: record =>
								record.info
									.filter(f => f.title !== 'Материалы позиции сметы')
									.filter(f =>
										Array.isArray(f.value!) ? (f.value! as any[])?.length !== 0 : f.value !== null
									)
									.map(i => (
										<>
											<div
												className="at-umd-inner"
												style={{
													display: 'flex',
													flexDirection: 'column',
													flexWrap: 'wrap',
													padding: ' .5rem .5rem .5rem 4rem',
													borderBottom: '1px dashed #ddd'
												}}
											>
												<b>{i.title}:</b>
												<span style={{ wordBreak: 'break-word' }}>{historyAdapter(i)}</span>
											</div>
										</>
									))
						}}
					/>
				)
			default:
				return info.value as string
		}
	}

	return (
		<Card className="at-workcard">
			{isLoading ? (
				<Spin />
			) : (
				<Table
					style={{ maxWidth: '100%', wordBreak: 'break-all' }}
					size="small"
					className="at-expandable"
					dataSource={history ?? []}
					columns={historyColumns}
					pagination={{
						current: currentPage,
						pageSize: 20,
						hideOnSinglePage: true,
						showSizeChanger: false,
						total: totalItems,
						onChange: onPagination
					}}
					rowKey={record => record.id}
					expandable={{
						columnWidth: 32,
						rowExpandable: (record: IHistory) => !!record.info?.length,
						expandedRowRender: record =>
							record.info
								.filter(f =>
									Array.isArray(f.value!) ? (f.value! as any[])?.length !== 0 : f.value !== null
								)
								.map(i => (
									<>
										<div
											className="at-umd-inner"
											style={{
												display: 'flex',
												flexDirection: 'column',
												flexWrap: 'wrap',
												padding: ' .5rem .5rem .5rem 4rem',
												borderBottom: '1px dashed #ddd'
											}}
										>
											<b>{i.title}:</b>
											<span style={{ wordBreak: 'break-word' }}>{historyAdapter(i)}</span>
										</div>
									</>
								))
					}}
				/>
			)}
		</Card>
	)
}
export default History
