import {
	BranchesOutlined,
	DeleteOutlined,
	EditOutlined,
	PartitionOutlined,
	SaveOutlined,
	StopOutlined
} from '@ant-design/icons'
import { Button, Col, Row, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { WbsToExcel, useWbsPermits } from 'widgets/wbs'
import { ILocalEstimate, useLocalEstimateRegistryState } from '..'

interface IProps {
	record: ILocalEstimate
	editable: boolean
	isLoading: boolean
	onSave: (record: ILocalEstimate) => void
}

export const LocalEstimateRegistryActions: FC<IProps> = params => {
	const checkPermissions = useCheckPermissions()
	const { record, editable, isLoading, onSave } = params
	const {
		editableRow,
		setEditableRow,
		setCorrectionRecord,
		setAdjustmentRecord,
		setContainerToRemove
	} = useLocalEstimateRegistryState()
	const { user, permissions } = useAppSelector(state => state.environment)
	const wbsPermissions = useWbsPermits()

	const isAuthor =
		(user?.isAdmin ||
			record?.author?.id === user?.id ||
			record?.redactors.some(r => r.id === user?.id)) ??
		false

	const canCreateAdjustment: boolean =
		record.status?.name === 'Approved' &&
		(user?.isAdmin || checkPermissions([PERMISSIONS.AdjustmentCreate]))

	return editable ? (
		<Row gutter={2} wrap={false}>
			<Col>
				<Tooltip title="Сохранить">
					<Button
						icon={<SaveOutlined />}
						type="primary"
						loading={isLoading}
						onClick={() => onSave(record)}
						style={{ marginRight: 8 }}
					/>
				</Tooltip>
			</Col>
			<Col>
				<Tooltip title="Отмена">
					<Button
						icon={<StopOutlined />}
						onClick={() => setEditableRow(undefined)}
						style={{ marginRight: 8 }}
					/>
				</Tooltip>
			</Col>
		</Row>
	) : (
		<Row gutter={6} wrap={false}>
			<Col>
				<Tooltip title="Создать Корректировку Локальной сметы">
					<Button
						data-attr="buttonCreateLocalEstimateCorrect"
						icon={<BranchesOutlined />}
						loading={isLoading}
						disabled={
							(record.status?.name !== 'Approved' && record.mainParent !== null) ||
							(!user?.isAdmin && !wbsPermissions.canLocalEdit) ||
							record.children?.length >= 1
						}
						onClick={() => setCorrectionRecord(record)}
					/>
				</Tooltip>
			</Col>
			<Col>
				<Tooltip title='Создать документ "Было/Стало"'>
					<Button
						data-attr="buttonCreateLocalEstimateAdjustment"
						icon={<PartitionOutlined />}
						loading={isLoading}
						disabled={!canCreateAdjustment}
						onClick={() => setAdjustmentRecord(record)}
					/>
				</Tooltip>
			</Col>
			{record.mainParent !== null && (
				<>
					<Col>
						<Tooltip title="Редактировать">
							<Button
								data-attr="buttonEditVOR"
								icon={<EditOutlined />}
								disabled={
									record.ready ||
									!wbsPermissions.canLocalEdit ||
									(wbsPermissions.canLocalEdit && !isAuthor) ||
									editableRow !== undefined
								}
								onClick={() => setEditableRow(record)}
							/>
						</Tooltip>
					</Col>
					<Col>
						<WbsToExcel row={record} registry="localEstimateRegistry" />
					</Col>
					<Col>
						<Tooltip title="Удалить Корректировку Локальной сметы">
							<Button
								data-attr="buttonDeleteVOR"
								type="primary"
								loading={isLoading}
								disabled={record.ready || !user?.isAdmin}
								danger
								onClick={() => setContainerToRemove(record)}
								icon={<DeleteOutlined />}
							/>
						</Tooltip>
					</Col>
				</>
			)}
		</Row>
	)
}
