import { FilterButton, SearchLine } from 'features'
import { useAppDispatch } from 'hooks/appReduxHook'
import { FC, useEffect } from 'react'
import { setPageTitle } from 'store/rootSlice'
import { DashboardLayout } from 'widgets/dashboards'
import {
	WbsCopy,
	WbsCreate,
	WbsCreateAdjustment,
	WbsRegistry,
	WbsRegistryFilters,
	WbsRemove,
	useWbsRegistryState
} from 'widgets/wbs'
import { WbsCreateCorrection } from 'widgets/wbs/ui/WbsCreateCorrection'
import { IState } from './model/estimate-page-schema'

interface IProps {
	state: IState
}

export const WbsPage: FC<IProps> = props => {
	const { filters, setShowFilters, setSearch } = useWbsRegistryState()

	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Список ВОР' }))
	}, [])

	return (
		<DashboardLayout
			body={<WbsRegistry />}
			overlays={[
				<WbsRegistryFilters />,
				<WbsCreateCorrection />,
				<WbsCreateAdjustment />,
				<WbsRemove />,
				<WbsCopy />
			]}
			headerLeft={[
				<FilterButton filters={filters} onClick={() => setShowFilters(true)} />,
				<SearchLine currentValue={filters.search} onSearch={setSearch} />,
				<WbsCreate />
			]}
		/>
	)
}
