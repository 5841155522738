import { AppstoreOutlined, BuildOutlined, UserOutlined } from '@ant-design/icons'
import { Col, Row, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { FC, ReactNode } from 'react'
import { useWbsHistory } from '../../model/estimate-position-queries'
interface IProps {}

interface IHistoryData {
	id: string
	item: IHistoryDataItem
	date?: string
	action?: string
	beforeState?: string | number
	currentState?: string | number
	children?: IHistoryData[]
}

interface IHistoryDataItem {
	name: string
	type: 'redactor' | 'work' | 'workProperty' | 'material' | 'materialProperty'
}

export const EstimatePositionHistory: FC<IProps> = props => {
	const { data: historyData, isFetching } = useWbsHistory()

	const rowIcon: Record<string, ReactNode> = {
		redactor: <UserOutlined style={{ color: '#1677ff' }} />,
		work: <AppstoreOutlined style={{ color: '#6fa9fa' }} />,
		material: <BuildOutlined style={{ color: '#5d9f6c' }} />
	}

	const columns: ColumnsType<IHistoryData> = [
		{
			dataIndex: ['item', 'name'],
			title: 'Редактор',
			width: 320,
			render: (value, record) => (
				<Row
					wrap={false}
					gutter={8}
					justify={record.item.type.includes('Property') ? 'end' : 'start'}
				>
					<Col>{rowIcon[record.item.type]}</Col>
					<Col style={{ wordBreak: 'keep-all' }}>
						{record.item.type === 'redactor' ? (
							<b>{value}</b>
						) : record.item.type.includes('Property') ? (
							`${value}:`
						) : (
							value
						)}
					</Col>
				</Row>
			)
		},
		{
			dataIndex: 'date',
			title: 'Дата',
			width: 128,
			render: (_, record) =>
				record.date && dayjs(record.date).isValid() && dayjs(record.date).format('HH:mm DD.MM.YYYY')
		},
		{
			dataIndex: 'beforeState',
			title: 'Было',
			width: 256,
			render: value => (typeof value === 'boolean' ? (value ? 'Да' : 'Нет') : value)
		},
		{
			dataIndex: 'currentState',
			title: 'Стало',
			width: 256,
			render: value => (typeof value === 'boolean' ? (value ? 'Да' : 'Нет') : value)
		},
		{
			dataIndex: 'action',
			title: 'Активность',
			width: 128,
			render: value => value ?? 'Редактирование'
		}
	]
	return (
		<Table
			size="small"
			bordered
			className="app-table"
			rowClassName="app-table__row"
			rowKey={row => row.id}
			loading={isFetching}
			dataSource={historyData ?? []}
			columns={columns}
			onRow={() => ({
				style: { cursor: 'pointer' }
			})}
			expandable={{
				expandRowByClick: true
			}}
			pagination={{
				hideOnSinglePage: true
			}}
		/>
	)
}
