import { FC, useEffect, useState } from 'react'
import { Button, Col, Row, Select, SelectProps, Table, Tag, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/lib/table'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import { LoadingOutlined, DeleteOutlined } from '@ant-design/icons'
import { IProject } from 'interfaces/IEnvironment'
import { getProjectList } from 'services/EnvironmentService'
import { IUserDto, IUserDtoProject, IUserRole } from 'interfaces/IUser'
import { getRoles } from 'services/AdminService'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { setUserValue } from 'store/userSlice'

interface IUserDrawerProjectsProps {}

const { Option } = Select

const UserDrawerProjects: FC<IUserDrawerProjectsProps> = () => {
	const dispatch = useAppDispatch()
	const userDto: IUserDto = useAppSelector(state => state.user.dto)
	const [isLoading, setIsLoading] = useState(true)
	const [projects, setProjects] = useState<IProject[]>([])
	const [selectedProject, setSelectedProject] = useState<string[]>()
	const [userProjects, setUserProjects] = useState<IUserDtoProject[]>(userDto.projects!)
	const [roles, setRoles] = useState<IUserRole[]>([])
	const [options, setOptions] = useState<SelectProps['options']>([])
	const userRightsColumns: ColumnsType<IUserDtoProject> = [
		{
			title: 'Проект',
			dataIndex: 'project',
			width: '20%',
			render: (_, record) => <b>{record.name}</b>
		},
		{
			title: 'Роли',
			render: (_, record) => (
				<Select
					bordered={false}
					showSearch
					mode="multiple"
					style={{ width: '100%' }}
					placeholder="Выберете роль/роли пользователя"
					value={userDto
						.projects!?.find(project => project.id === record.id)
						?.roles?.map(role => role)}
					options={options?.sort((a, b) => a.label!.toString().localeCompare(b.label!.toString()))}
					optionFilterProp="label"
					tagRender={tagRender}
					onChange={value => onRulesChange(record, value)}
				/>
			)
		},
		{
			width: 45,
			render: (_, record) => (
				<Row>
					<Col>
						<Tooltip placement="topLeft" title="Удалить проект">
							<Button
								style={{
									padding: '.3rem',
									width: '32px',
									height: '32px',
									border: 'none'
								}}
								size="middle"
								shape="circle"
								danger
								onClick={() => {
									onRemoveProject(record.id!)
								}}
								icon={<DeleteOutlined rev={undefined} />}
							/>
						</Tooltip>
					</Col>
				</Row>
			)
		}
	]

	useEffect(() => {
		getProjectList(null).then(data => setProjects(data))
		getRoles().then(data => setRoles(data))
	}, [])

	useEffect(() => {
		setOptions(
			roles.map(role => ({
				label: role.name,
				value: role.id,
				disabled: false
			}))
		)
		setIsLoading(false)
	}, [roles])

	useEffect(() => {
		dispatch(setUserValue({ prop: 'projects', value: userProjects }))
	}, [userProjects])

	const tagRender = (props: CustomTagProps) => {
		const { value, onClose } = props
		const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
			event.preventDefault()
			event.stopPropagation()
		}
		return (
			<Tag
				color={'#1677ff'}
				onMouseDown={onPreventMouseDown}
				closable={true}
				onClose={onClose}
				style={{
					fontSize: 14,
					padding: 4,
					margin: 3,
					color: '#f0f0f0'
				}}
			>
				{roles.find(role => role.id === value)?.name}
			</Tag>
		)
	}

	const onAddProject = (projectIds: string[] = []) => {
		const projectsList: IProject[] = projects.filter(p => projectIds.includes(p.id))
		if (!!projectsList.length) {
			const newItems = projectsList.map(p => ({
				id: p.id,
				name: p.name,
				roles: null
			}))
			setUserProjects(prev => [...prev, ...newItems])
			setSelectedProject([])
		}
	}

	const onRemoveProject = (projectId: string) => {
		setUserProjects(prev => prev.filter(prj => prj.id !== projectId))
	}

	const onRulesChange = (record: any, value: any) => {
		const dto = userDto.projects!?.map(prj => ({
			id: prj.id,
			name: prj.name,
			roles: prj.id === record.id ? value : prj.roles
		}))
		setUserProjects(dto)
	}

	return (
		<>
			<Row align="middle" gutter={8} style={{ marginBottom: '1rem' }}>
				<Col flex={1}>
					<Select
						mode="multiple"
						style={{ width: '100%' }}
						value={selectedProject}
						onChange={el => setSelectedProject(el)}
						showSearch
						optionFilterProp="children"
					>
						{projects
							.filter(p => !userDto.projects!?.some(prj => prj.id === p.id))
							.map(project => (
								<Option
									value={project.id}
									disabled={userDto.projects!?.some(prj => prj.id === project.id)}
								>
									{project.name}
								</Option>
							))}
					</Select>
				</Col>
				<Col>
					<Button type="primary" onClick={() => onAddProject(selectedProject!)}>
						Добавить проект
					</Button>
				</Col>
			</Row>

			<Table
				loading={{ indicator: <LoadingOutlined spin rev={undefined} />, spinning: isLoading }}
				dataSource={userDto.projects!}
				rowKey={record => record.id!}
				size="small"
				bordered
				columns={userRightsColumns}
				pagination={false}
			></Table>
		</>
	)
}
export default UserDrawerProjects
