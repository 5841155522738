import { IMaintance } from 'interfaces/IMaintance'
import { API } from './ApiService'


export const maintanceService = {
	async getMaintanceNotification() {
		const DateTime =  new Date().toISOString()
		return await API<IMaintance[]>({
			url: `/v1/technicalWorks`,
			method: 'get',
			params: {DateTime: DateTime}
		}).then(response => response.data)
	},

	async setMaintanceNotification(maintance: IMaintance) {
		const { ...data } = maintance
		return await API<IMaintance>({
			url: `/v1/technicalWorks`,
			method: 'post',
			data: data
		}).then(response => response.data)
	}
}