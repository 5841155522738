import {
	FileImageOutlined,
	FilePdfOutlined,
	FileZipOutlined,
	LoadingOutlined
} from '@ant-design/icons'
import { Button, Empty, Row, Spin, message } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Table } from 'antd/lib'
import dayjs from 'dayjs'
import { IFileAttachment } from 'interfaces/IPluginFiles'
import { FC } from 'react'
import { downloadFile } from 'services/AdminService'
import { useFineCardAttachment } from '../model/fine-card-query'

interface IProps {
	fineId?: string
}

export const FineCardAttachments: FC<IProps> = ({ fineId }) => {
	const { data: fileList, isFetching } = useFineCardAttachment(fineId)
	const fileListColumns: ColumnsType<IFileAttachment> = [
		{
			title: 'Тип',
			dataIndex: 'extension',
			width: '50px',
			align: 'center',
			render: (_, record) => {
				if (record.extension.replace('.', '') === 'pdf') {
					return <FilePdfOutlined style={{ color: 'red' }} />
				} else if (
					record.extension.replace('.', '') === 'png' ||
					record.extension.replace('.', '') === 'jpeg' ||
					record.extension.replace('.', '') === 'jpg'
				) {
					return <FileImageOutlined style={{ color: '#feb300' }} />
				} else {
					return <FileZipOutlined style={{ color: 'blue' }} />
				}
			}
		},
		{
			title: 'Имя файла',
			render: (_, record) => (
				<Button
					size="middle"
					type="text"
					block
					style={{ textAlign: 'left' }}
					onClick={() =>
						downloadFile(record.id)
							.then(response => {
								const href = URL.createObjectURL(response.data)
								const link = document.createElement('a')
								link.href = href
								link.setAttribute('download', record.name + record.extension!)
								document.body.appendChild(link)
								link.click()
								document.body.removeChild(link)
								URL.revokeObjectURL(href)
							})
							.catch(() => message.error('Во время загрузки файла произошла ошибка'))
					}
				>
					{record.name}
				</Button>
			)
		},
		{
			title: 'Дата загрузки',
			width: '15%',
			render: (_, record) => dayjs(record.uploadDate).format('HH:mm DD.MM.YYYY')
		}
	]
	return (
		<>
			{!isFetching ? (
				fileList?.length ? (
					<Table
						size="small"
						pagination={false}
						dataSource={fileList}
						columns={fileListColumns}
						rowKey={record => record.id}
					/>
				) : (
					<Empty />
				)
			) : (
				<Row justify="center">
					<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
				</Row>
			)}
		</>
	)
}
