import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import { Row } from '@tanstack/table-core'
import { Row as AntRow, Button, Col, Tooltip } from 'antd'
import { memo } from 'react'
import { IAdjustmentPosition, IAdjustmentSection } from '../model/adjustment-schema'
import { useAdjustmentState } from '../model/adjustment-state'

interface IProps {
	sectionId: string
	rowId: string
	isMultiply?: boolean
	row?: Row<IAdjustmentPosition> | Row<IAdjustmentSection>
}

export const AdjustmentPositionMove = memo(function (params: IProps) {
	const { sectionId, rowId, isMultiply = false, row } = params
	const { selectedRows, changePosition } = useAdjustmentState()
	return (
		<AntRow align="middle" wrap={false}>
			<Col>
				<Tooltip title="Поднять">
					<Button
						size="small"
						type="text"
						disabled={
							Object.keys(selectedRows.rows).includes(row?.originalSubRows![0]?.id!) ||
							('order' in row?.original! && row?.original.order === 1)
						}
						icon={<CaretUpOutlined />}
						onClick={e => {
							e.stopPropagation()
							changePosition(sectionId, isMultiply ? Object.keys(selectedRows.rows) : [rowId], 'up')
						}}
					/>
				</Tooltip>
			</Col>
			<Col>
				<Tooltip title="Опустить">
					<Button
						size="small"
						type="text"
						disabled={
							Object.keys(selectedRows.rows).includes(
								row?.originalSubRows![row?.originalSubRows?.length! - 1]?.id!
							) ||
							('order' in row?.original! &&
								row?.original.order === row?.getParentRow()?.original.children?.length)
						}
						icon={<CaretDownOutlined />}
						onClick={e => {
							e.stopPropagation()
							changePosition(
								sectionId,
								isMultiply ? Object.keys(selectedRows.rows) : [rowId],
								'down'
							)
						}}
					/>
				</Tooltip>
			</Col>
		</AntRow>
	)
})
