import { MenuOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { DndContext } from '@dnd-kit/core'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import {
	Button,
	Checkbox,
	Col,
	Form,
	FormInstance,
	InputNumber,
	Row,
	Select,
	Table,
	Tooltip
} from 'antd'
import { ColumnsType } from 'antd/es/table'
import { all, create } from 'mathjs'
import { Children, FC, ReactElement, cloneElement, useEffect } from 'react'
import { PERMISSIONS, ROUND_VALUE } from 'shared/constants'

import { useAppSelector } from 'hooks/appReduxHook'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useEstimateState } from 'widgets/estimate'
import { EstimatePositionHelpers } from 'widgets/estimate-position-card/model/estimate-position-helpers'
import {
	IDataSource,
	IDataType,
	useEstimatePositionState
} from 'widgets/estimate-position-card/model/estimate-position-state'
import { useWbsPermits } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'
import { MaterialComment } from '../material-comment/material-comment'

interface IProps {
	disabled: boolean
	estimatePositionForm: FormInstance
	expandable: boolean
	canEdit: boolean
	sourceData: IDataSource
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
	'data-row-key': string
}

const config = {}
const math = create(all, config)

const RowLine = ({ children, ...props }: RowProps, disabled: boolean) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
		isDragging
	} = useSortable({
		id: props['data-row-key']
	})

	const style: React.CSSProperties = {
		...props.style,
		transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
		transition,
		...(isDragging ? { position: 'relative', zIndex: 9 } : {})
	}

	return (
		<tr {...props} ref={setNodeRef} style={style} {...attributes}>
			{Children.map(children, child => {
				if ((child as ReactElement).key === 'sort') {
					return cloneElement(child as React.ReactElement, {
						children: (
							<>
								<MenuOutlined
									ref={setActivatorNodeRef}
									style={{
										touchAction: 'none',
										cursor: disabled ? 'not-allowed' : 'move',
										color: disabled ? '#f2f2f2' : '#9e9e9e'
									}}
									{...listeners}
								/>
							</>
						)
					})
				}
				return child
			})}
		</tr>
	)
}

export const EstimatePositionWork: FC<IProps> = props => {
	const { sourceData: data, expandable, estimatePositionForm, disabled, canEdit } = props
	const checkPermissions = useCheckPermissions()
	const wbsPermissions = useWbsPermits()
	const {
		currentTechnology,
		dto,
		propertyValue,
		workData,
		onAddRow,
		onDragEnd,
		onRemoveRow,
		setWorkDataValue
	} = useEstimatePositionState(
		useShallow(state => ({
			currentTechnology: state.currentTechnology,
			dto: state.dto,
			propertyValue: state.propertyValue,
			workData: state.workData,
			onAddRow: state.onAddRow,
			onDragEnd: state.onDragEnd,
			onRemoveRow: state.onRemoveRow,
			setWorkDataValue: state.setWorkDataValue
		}))
	)
	const { user: currentUser } = useAppSelector(state => state.environment)
	const container = useEstimateState(useShallow(state => state.container))

	const isRedactor =
		currentUser?.isAdmin ||
		container?.author.id === currentUser?.id ||
		container?.redactors.some(r => r.id === currentUser?.id)

	const isLocalEstimate = typeof container?.isLocalEstimate !== 'undefined'
	const canEditCorrectLocalEstimate =
		container?.isLocalEstimate === false && wbsPermissions.canLocalEdit

	useEffect(() => {
		data.sourceData.forEach(d => {
			estimatePositionForm.setFieldValue([data.work.id, d.key, 'material'], d.materialId)
			estimatePositionForm.setFieldValue([data.work.id, d.key, 'amount'], d.amount)
			estimatePositionForm.setFieldValue([data.work.id, d.key, 'comment'], d.comment)
			estimatePositionForm.setFieldValue([data.work.id, 'workAmount'], data.work.amount)
			estimatePositionForm.setFieldValue([data.work.id, d.key, 'notesMaterial', 'noteDP'], d.noteDP)
			estimatePositionForm.setFieldValue([data.work.id, d.key, 'notesMaterial', 'noteDZ'], d.noteDZ)
			estimatePositionForm.setFieldValue(
				[data.work.id, d.key, 'notesMaterial', 'noteSDU'],
				d.noteSDU
			)
		})
	}, [workData, data])
	const canRemove = (record: IDataType): boolean => {
		const check = data.sourceData.filter(d => d.typeMaterial?.id === record.typeMaterial?.id).length
		return check > 1 ? false : true
	}

	const columns: ColumnsType<IDataType> = [
		{
			key: 'sort',
			width: 48,
			align: 'center',
			hidden: disabled || !canEdit
		},
		{
			key: 'number',
			title: 'П.п.',
			width: 48,
			align: 'center',
			render: (_, __, index) => index + 1
		},
		{
			key: 'typeMaterial',
			width: 256,
			title: 'Тип материала',
			render: (_, record) => {
				return record.typeMaterial !== null
					? record.typeMaterial?.name
					: dto?.works
							.find(w => w.id === record.workId)
							?.materials.find(m => m.material.id === record.materialId)?.typeMaterial.name
			}
		},
		{
			key: 'material',
			title: 'Материал',
			render: (_, record) => (
				<>
					{record.typeMaterial === null ||
					(currentTechnology?.works
						.find(w => w.work.id === record.technologyWorkId)
						?.typeMaterials.find(tm => tm.isOptional === false) &&
						record.typeMaterial.materials.find(m => !m.isDeleted)?.id ===
							dto?.works
								.find(w => w.id === record.workId)
								?.materials.find(m => m.material.id === record.materialId)?.material.id &&
						record.typeMaterial!?.materials?.filter(m => !m.isDeleted).length === 1) ? (
						<p style={{ marginBottom: 5 }}>
							{
								dto?.works
									.find(w => w.id === record.workId)
									?.materials.find(m => m.material.id === record.materialId)?.material.name
							}
						</p>
					) : record.typeMaterial === null ||
					  (currentTechnology?.works
							.find(w => w.id === record.workId)
							?.typeMaterials.find(tm => tm.isOptional === false) &&
							record.typeMaterial!?.materials?.filter(m => !m.isDeleted).length === 1 &&
							record.typeMaterial.isOptional === false) ? (
						<p style={{ marginBottom: 5 }}>
							{
								record?.typeMaterial?.materials
									?.filter(x => x.typeMaterialId === record.typeMaterial?.id)
									.filter(m => !m.isDeleted)[0].name
							}
						</p>
					) : (
						<Form.Item
							name={[record.workId, record.key, 'material']}
							rules={[
								() => ({
									validator(_, value) {
										if (
											record.typeMaterial?.materials.find(material => material.id === value)
												?.isDeleted
										) {
											return Promise.reject(new Error('Выберете корректный материал'))
										}
										if (typeof value === 'string' && value.length > 0) {
											return Promise.resolve()
										}
										if (typeof dto === 'undefined' && record.typeMaterial?.isOptional === false) {
											return Promise.reject(new Error('Выберете материал'))
										}
										if (typeof dto === 'undefined' && record.typeMaterial?.isOptional === true) {
											return Promise.resolve()
										}
										return Promise.reject(new Error('Выберете материал'))
									}
								})
							]}
						>
							<Select
								disabled={
									disabled ||
									!canEdit ||
									(container?.isLocalEstimate === false && typeof record.parentId === 'string')
								}
								popupClassName="at-select-no-deleted"
								showSearch
								optionFilterProp="children"
								filterOption={(inputValue, option) =>
									inputValue
										.toLowerCase()
										.split(/[ ,]+/)
										.every(el => option?.label?.toLowerCase().includes(el))
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase()) ||
									+optionA.disabled - +optionB.disabled
								}
								allowClear
								style={{ width: '100%' }}
								onChange={value =>
									setWorkDataValue(record.id, 'materialId', value, data.work.id, record)
								}
								options={record.typeMaterial?.materials
									?.filter(
										x =>
											x.typeMaterialId === record.typeMaterial?.id &&
											(!x.isDeleted || x.id === record.materialId)
									)
									?.map(item => ({
										key: item.id,
										value: item.id,
										label: `${item.name}${item.isDeleted ? ' (удалённый)' : ''}`,
										disabled: item.isDeleted
									}))}
							/>
						</Form.Item>
					)}
					{(record.availableComment || !!record.comment?.length) && (
						<MaterialComment
							record={record}
							disabled={
								disabled ||
								!canEdit ||
								record.isClosed === true ||
								(record.isClosed === false && record.isEditable === false)
							}
						/>
					)}
				</>
			)
		},

		{
			key: 'amount',
			align: 'center',
			width: 128,
			title: <Tooltip title="Количество без нормы расхода">Кол-во</Tooltip>,
			render: (_, record) =>
				expandable ? (
					!disabled && canEdit ? (
						<Form.Item
							name={[record.workId, record.key, 'amount']}
							rules={[
								() => ({
									validator(_, value) {
										if (value === 0) {
											return Promise.reject(new Error('Не может быть равным 0'))
										} else if (value < 0) {
											return Promise.reject(new Error('Не может быть меньше 0'))
										} else if (value === null) {
											if (typeof dto === 'undefined' && record.typeMaterial?.isOptional === false) {
												return Promise.reject(new Error('Поле не может быть пустым'))
											}
											if (
												typeof dto === 'undefined' &&
												record.typeMaterial?.isOptional === true &&
												!record.materialId
											) {
												return Promise.resolve()
											}
											return Promise.reject(new Error('Поле не может быть пустым'))
										}
										return Promise.resolve()
									}
								})
							]}
						>
							<InputNumber
								// formatter={value => `${value}`.replace(/\./g, ',')}
								parser={value => value!?.replace(/,/, '.')}
								disabled={
									!record.materialId ||
									record.isClosed === true ||
									(record.isClosed === false && record.isEditable === false) ||
									(!currentUser?.isAdmin &&
										!canEditCorrectLocalEstimate &&
										!isRedactor &&
										container?.isLocalEstimate === false &&
										record.isClosed === false &&
										record.isEditable === true &&
										dto?.technology.expandable === false)
								}
								// precision={ROUND_VALUE}
								controls={false}
								keyboard={false}
								changeOnWheel={false}
								style={{ width: '100%' }}
								onBlur={e => {
									const currentTarget = +e.currentTarget.value.replace(/,/, '.')
									const check = currentTechnology?.works
										.find(w => w.id === record.workId)
										?.typeMaterials?.find(tm => tm.id === record.typeMaterial?.id)
										?.typeMaterial.materials?.find(m => m.id === record.materialId)
									if (
										!Number.isNaN(currentTarget) && check
											? check?.measureUnit?.isRounded === true
											: record?.measureUnit?.isRounded === true ||
											  record.typeMaterial?.materials.find(m => m.id === record.materialId)
													?.measureUnit?.isRounded === true
									) {
										setWorkDataValue(record.id, 'amount', math.ceil(currentTarget), data.work.id)
									} else if (!Number.isNaN(currentTarget))
										setWorkDataValue(
											record.id,
											'amount',
											math.round(currentTarget, ROUND_VALUE),
											data.work.id
										)
								}}
							/>
						</Form.Item>
					) : !Number.isNaN(record.amount) && record?.measureUnit?.isRounded === true ? (
						math.ceil(+record.amount!)
					) : (
						record.amount
					)
				) : (
					<Tooltip
						title={
							(
								currentTechnology?.works
									.find(w => w.work.id === record.technologyWorkId)
									?.typeMaterials.find(tm => tm.typeMaterial.id === record.typeMaterial?.id)
									?.formula ?? record.formula
							)?.match(/\[([^,]+)/g) ?? ''
						}
					>
						{!Number.isNaN(record.amount) &&
						(currentTechnology?.works
							.find(w => w.id === record.workId)
							?.typeMaterials?.find(tm => tm.typeMaterial.id === record.typeMaterial?.id)
							?.measureUnit
							? currentTechnology?.works
									?.find(w => w.id === record.workId)
									?.typeMaterials?.find(tm => tm.typeMaterial.id === record.typeMaterial?.id)
									?.measureUnit?.isRounded === true
							: currentTechnology?.works
									.find(w => w.id === record.workId)
									?.typeMaterials?.find(tm => tm.typeMaterial.id === record.typeMaterial?.id)
									?.typeMaterial.materials.find(m => m.id === record.materialId)?.measureUnit
							? currentTechnology?.works
									.find(w => w.id === record.workId)
									?.typeMaterials?.find(tm => tm.typeMaterial.id === record.typeMaterial?.id)
									?.typeMaterial.materials.find(m => m.id === record.materialId)?.measureUnit
									?.isRounded === true
							: record?.measureUnit?.isRounded === true)
							? math.ceil(
									EstimatePositionHelpers.calculate(
										math.ceil(+record.amount!),
										currentTechnology?.works
											.find(w => w.work.id === record.technologyWorkId)
											?.typeMaterials.find(tm => tm.typeMaterial.id === record.typeMaterial?.id)
											?.formula ?? record.formula
									)
							  )
							: EstimatePositionHelpers.calculate(
									record.amount,
									currentTechnology?.works
										.find(w => w.work.id === record.technologyWorkId)
										?.typeMaterials.find(tm => tm.typeMaterial.id === record.typeMaterial?.id)
										?.formula ?? record.formula
							  )}
					</Tooltip>
				)
		},
		{
			key: 'rate',
			width: 72,
			align: 'center',
			title: <Tooltip title="Норма расхода">НР</Tooltip>,
			dataIndex: 'rate',
			render: (_, record) =>
				data.work.typeMaterials.find(t => t.typeMaterial.id === record.typeMaterial?.id)
					? data.work.typeMaterials.find(t => t.typeMaterial.id === record.typeMaterial?.id)?.rate
					: dto?.works
							.find(w => w.id === record.workId)
							?.materials.find(m => m.material.id === record.materialId)?.norma
		},
		{
			key: 'amountRate',
			align: 'center',
			width: 100,
			title: <Tooltip title="Количество с нормой расхода">Кол-во с НР</Tooltip>,
			render: (_, record) => {
				const checkMaterial = currentTechnology?.works
					.find(w => w.id === record.workId)
					?.typeMaterials?.find(tm => tm.typeMaterial?.id === record.typeMaterial?.id)
					?.typeMaterial.materials?.find(m => m.id === record.materialId)?.measureUnit
				const checkTypeMaterial = currentTechnology?.works
					.find(w => w.id === record.workId)
					?.typeMaterials?.find(tm => tm.typeMaterial?.id === record.typeMaterial?.id)?.measureUnit
				return (
					!Number.isNaN(record.amount) && checkTypeMaterial
						? checkTypeMaterial.isRounded === true
						: !Number.isNaN(record.amount) && checkMaterial
						? checkMaterial?.isRounded === true
						: record?.measureUnit?.isRounded === true
				)
					? math.ceil(
							math.ceil(
								expandable && record.amount
									? record.amount
									: EstimatePositionHelpers.calculate(
											+propertyValue!,
											currentTechnology?.works
												.find(w => w.work.id === record.technologyWorkId)
												?.typeMaterials.find(tm => tm.typeMaterial.id === record.typeMaterial?.id)
												?.formula ?? record.formula
									  )
							) *
								(data.work.typeMaterials.find(t => t.typeMaterial.id === record.typeMaterial?.id)
									? data.work.typeMaterials.find(t => t.typeMaterial.id === record.typeMaterial?.id)
											?.rate
									: dto?.works
											.find(w => w.id === record.workId)
											?.materials.find(m => m.material.id === record.materialId)?.norma)!
					  )
					: EstimatePositionHelpers.calculate(
							record.amount,
							expandable
								? undefined
								: currentTechnology?.works
										.find(w => w.work.id === record.technologyWorkId)
										?.typeMaterials.find(tm => tm.typeMaterial.id === record.typeMaterial?.id)
										?.formula ?? record.formula,
							record.rate!
					  )
			}
		},
		{
			key: 'measureUnit',
			width: 72,
			align: 'center',
			title: <Tooltip title="Единицы измерения">Ед.изм</Tooltip>,
			render: (_, record) => record.measureUnit?.name ?? '-'
		},
		{
			key: 'isNominated',
			width: 72,
			align: 'center',
			title: <Tooltip title="Признак номинации">НМТР</Tooltip>,
			render: (value, record) => (
				<Form.Item name={`isNominated_${record.id}`} valuePropName="checked">
					<Tooltip title={container?.ready === true ? 'ВОР уже подготовлен' : null}>
						<Checkbox
							disabled={
								disabled ||
								(container?.status.name !== 'StructureApproval' && !currentUser?.isAdmin) ||
								(isLocalEstimate === false && !currentUser?.isAdmin && !wbsPermissions.canSetDZ) ||
								(isLocalEstimate === true &&
									!currentUser?.isAdmin &&
									// !canEditCorrectLocalEstimate &&
									!(wbsPermissions.canLocalDzMTR && record.isEditableNominated === true)) ||
								record.isClosed === true ||
								(record.isClosed === false && record.isEditable === false && !currentUser?.isAdmin)
								// (record.isClosed === false &&
								// 	record.isEditable === false &&
								// 	!currentUser?.isAdmin) ||
								// !(
								// 	(record.isEditable === false &&
								// 		record.isEditableNominated === false &&
								// 		currentUser?.isAdmin) ||
								// 	((record.isEditable === true || record.isEditable === false) &&
								// 		record.isEditableNominated === true)
								// )
							}
							defaultChecked={record.isNominated}
							onChange={value => {
								setWorkDataValue(
									record.id,
									'isNominated',
									value.target.checked,
									data.work.id,
									record
								)
							}}
						/>
					</Tooltip>
				</Form.Item>
			)
		},
		{
			key: 'control',
			hidden:
				!(
					(checkPermissions([PERMISSIONS.WbsCreator]) && isRedactor && isLocalEstimate === false) ||
					(container?.isLocalEstimate === false &&
						isRedactor &&
						checkPermissions([PERMISSIONS.LocalEstimateCreate]))
				) ||
				!expandable ||
				disabled,
			width: 72,
			align: 'center',
			render: (_, record) => (
				<Row gutter={4}>
					<Col>
						<Button
							size="small"
							icon={<MinusOutlined />}
							type="primary"
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								padding: '.1rem'
							}}
							danger
							onClick={() => onRemoveRow(record, data.work.id)}
							disabled={
								canRemove(record) ||
								(container?.isLocalEstimate === false && typeof record.parentId === 'string')
							}
						/>
					</Col>
					<Col>
						<Button
							size="small"
							icon={<PlusOutlined />}
							type="primary"
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								padding: '.1rem'
							}}
							data-attr="addMaterial"
							onClick={() => onAddRow(record, data.work.id)}
							disabled={container?.isLocalEstimate === false && typeof record.parentId === 'string'}
						/>
					</Col>
				</Row>
			)
		}
	]

	return (
		<DndContext onDragEnd={event => onDragEnd(event, data.work.id)}>
			<SortableContext
				items={data.sourceData.map(d => d.key)}
				strategy={verticalListSortingStrategy}
				disabled={disabled}
			>
				<Table
					components={{
						body: {
							row: (record: RowProps) => RowLine(record, disabled)
						}
					}}
					rowKey={record => record.key}
					className="at-expandable-table app-table-odd"
					size="small"
					bordered
					pagination={false}
					dataSource={data.sourceData}
					columns={columns}
				/>
			</SortableContext>
		</DndContext>
	)
}
