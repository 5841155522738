import ReactDOM from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import 'antd/dist/reset.css'
import './style.sass'
import store from './store'
import ruRU from 'antd/lib/locale/ru_RU'
import { ConfigProvider } from 'antd'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient()

root.render(
	<Provider store={store}>
		<ConfigProvider locale={ruRU}>
			<QueryClientProvider client={queryClient}>
				<App />
			</QueryClientProvider>
		</ConfigProvider>
	</Provider>
)
