import { create } from 'zustand'
import { IState } from './estimate-page-schema'

const initialState = {
	currentPage: 1,
	totalPages: 0,
	showFilters: false
}

export const useLocalEstimatePageState = create<IState>((set, get) => ({
	...initialState,
	setShowFilters: () => {},
	setFilters: () => {},
	onPagination: () => {}
}))

export const useWbsPageState = create<IState>((set, get) => ({
	...initialState,
	setShowFilters: () => {},
	setFilters: () => {},
	onPagination: () => {}
}))
