import { Col, Row } from 'antd'
import {
	BarElement,
	CategoryScale,
	Chart,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip
} from 'chart.js'
import { getRelativePosition } from 'chart.js/helpers'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useMyFilesAnalytics } from 'hooks/useMyFilesAnalytics'
import { FC } from 'react'
import { Bar } from 'react-chartjs-2'
import { useNavigate } from 'react-router-dom'
import { setCurrentChecklistFilters } from 'store/checklistsSlice'
import { setIssueFilters } from 'store/issueSlice'
import { setFilterOrdinance } from 'store/ordinanceSlice'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)
interface IProps {}

export const MyFilesWidget: FC<IProps> = props => {
	const currentUser = useAppSelector(state => state.environment.user)
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { data, isSuccess } = useMyFilesAnalytics()

	const flat = isSuccess
		? Math.max.apply(
				null,
				Object.values(data).flatMap(x => x)
		  )
		: 0

	const labels = ['Замечания', 'Предписания', 'Чек-листы']
	const myFiles = {
		open: isSuccess ? labels.map((a, i) => [0, data?.open?.[i], data?.open[i]]) : 0,
		progress: isSuccess
			? labels.map((a, i) => [
					data?.open?.[i],
					data?.open?.[i] + data?.progress?.[i],
					data?.progress[i]
			  ])
			: 0,
		done: isSuccess
			? labels.map((a, i) => [
					data?.open?.[i] + data?.progress?.[i],
					data?.open?.[i] + data?.progress?.[i] + data?.done?.[i],
					data?.done[i]
			  ])
			: 0
	}
	const config = {
		type: 'bar',
		data: {
			labels,
			datasets: [
				{
					label: 'Открыто / Новый',
					data: myFiles.open,
					backgroundColor: '#f9b058',
					datalabels: {
						color: '#fff',
						anchor: 'center' as const,
						align: 'center' as const,
						formatter: (value: any[]) => (value[2] > 0 ? value[2] : '')
					}
				},
				{
					label: 'Готово к проверке / В работе',
					data: myFiles.progress,
					backgroundColor: '#674ea7',
					datalabels: {
						color: '#fff',
						anchor: 'center' as const,
						align: 'center' as const,
						formatter: (value: any[]) => (value[2] > 0 ? value[2] : '')
					}
				},
				{
					label: 'Устранено / Завершено',
					data: myFiles.done,
					backgroundColor: '#57a0bb',
					datalabels: {
						color: '#fff',
						anchor: 'center' as const,
						align: 'center' as const,
						formatter: (value: any[]) => (value[2] > 0 ? value[2] : '')
					}
				}
			]
		},
		width: '100%',
		height: '30%',
		options: {
			onHover: (event: any, elements: any) => {
				const canvasPosition: any = getRelativePosition(event, elements)
				const chart: Chart = canvasPosition.chart
				const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true)
				event.native.target.style.cursor = points[0] ? 'pointer' : 'default'
			},
			onClick: (event: any, elements: any) => {
				const canvasPosition: any = getRelativePosition(event, elements)
				const chart: Chart = canvasPosition.chart
				const points = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true)
				if (points[0]) {
					const point = points[0]
					switch (true) {
						case point.index === 0 && point.datasetIndex === 0:
							dispatch(
								setIssueFilters({
									filters: {
										ownerId: currentUser?.id ?? undefined,
										status: ['4ae3200c-d2df-4c70-922a-780ea1575a35']
									}
								})
							)
							navigate('/control/issues')
							break
						case point.index === 0 && point.datasetIndex === 1:
							dispatch(
								setIssueFilters({
									filters: {
										ownerId: currentUser?.id ?? undefined,
										status: ['5758e9f3-b35b-4d97-8616-aa3bbb96131f']
									}
								})
							)
							navigate('/control/issues')
							break
						case point.index === 0 && point.datasetIndex === 2:
							dispatch(
								setIssueFilters({
									filters: {
										ownerId: currentUser?.id ?? undefined,
										status: ['2d9df9da-5340-4f2a-ad30-77a24bb1bfea']
									}
								})
							)
							navigate('/control/issues')
							break
						case point.index === 1 && point.datasetIndex === 0:
							dispatch(
								setFilterOrdinance({
									filters: {
										createdById: currentUser?.id ?? undefined,
										ordinanceStatusId: '9d793d38-c719-41b0-8a7d-d61e641f5da4'
									}
								})
							)
							navigate('/control/ordinance')
							break
						// case point.index === 1 && point.datasetIndex === 1:
						// 	navigate('/control/ordinance')
						// 	break
						case point.index === 1 && point.datasetIndex === 2:
							dispatch(
								setFilterOrdinance({
									filters: {
										createdById: currentUser?.id ?? undefined,
										ordinanceStatusId: 'd6c945cf-d9e1-47f0-b3ba-c8ee583638a5'
									}
								})
							)
							navigate('/control/ordinance')
							break
						case point.index === 2 && point.datasetIndex === 0:
							dispatch(
								setCurrentChecklistFilters({
									filters: {
										ownerId: currentUser?.id ?? undefined,
										status: [0]
									}
								})
							)
							navigate('/checklist')
							break
						case point.index === 2 && point.datasetIndex === 1:
							dispatch(
								setCurrentChecklistFilters({
									filters: {
										ownerId: currentUser?.id ?? undefined,
										status: [1]
									}
								})
							)
							navigate('/checklist')
							break
						case point.index === 2 && point.datasetIndex === 2:
							dispatch(
								setCurrentChecklistFilters({
									filters: {
										ownerId: currentUser?.id ?? undefined,
										status: [2, 4]
									}
								})
							)
							navigate('/checklist')
							break
					}
				}
			},
			indexAxis: 'y' as const,
			responsive: true,
			plugins: {
				legend: {
					display: true,
					onClick: (e: any) => null,
					onHover: (event: any, elements: any) => {
						event.native.target.style.cursor = 'default'
					}
				},
				tooltip: {
					callbacks: {
						label: (context: any) => {
							let label = context.dataset.label || ''

							if (label) {
								label += ': '
							}
							if (context.raw) {
								label += context.raw[2]
							}
							return label
						}
					}
				}
			},
			interaction: {
				mode: 'index' as const,
				axis: 'y' as const,
				intersect: false
			},
			scales: {
				x: {
					beginAtZero: true,
					stacked: false,
					maxRotation: 0,
					ticks: {
						stepSize: flat > 50 ? 20 : 10
					}
				},
				y: {
					beginAtZero: true,
					stacked: true,
					ticks: {
						color: '#444',
						fontSize: 10,
						autoSkip: false
					}
				}
			}
		}
	}

	return isSuccess ? (
		<Row
			wrap={false}
			gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: '#f2f2f2',
				height: '100%'
			}}
		>
			<Col span={24} style={{ padding: '1rem', background: '#fefefe' }}>
				<h2>Мои файлы</h2>
				<Bar {...config} />
			</Col>
		</Row>
	) : (
		<></>
	)
}
