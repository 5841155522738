import { FilterButton, SearchLine } from 'features'
import { useAppDispatch } from 'hooks/appReduxHook'
import { FC, useEffect } from 'react'
import { setPageTitle } from 'store/rootSlice'
import { DashboardLayout } from 'widgets/dashboards'
import {
	LocalEstimateRegistry,
	LocalEstimateRegistryFilters,
	useLocalEstimateRegistryState
} from 'widgets/localEstimate'
import { LocalEstimateCreateCorrection } from 'widgets/localEstimate/ui/LocalEstimateCreatCorrection'
import { LocalEstimateCreateAdjustment } from 'widgets/localEstimate/ui/LocalEstimateCreateAdjustment'
import { LocalEstimateRemove } from 'widgets/localEstimate/ui/LocalEstimateRemove'
import { IState } from './model/estimate-page-schema'

interface IProps {
	state: IState
}

export const LocalEstimatePage: FC<IProps> = props => {
	const { filters, setShowFilters, setSearch } = useLocalEstimateRegistryState()
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Список Локальных смет' }))
	}, [])
	return (
		<DashboardLayout
			body={<LocalEstimateRegistry />}
			overlays={[
				<LocalEstimateRegistryFilters />,
				<LocalEstimateCreateCorrection />,
				<LocalEstimateCreateAdjustment />,
				<LocalEstimateRemove />
			]}
			headerLeft={[
				<FilterButton filters={filters} onClick={() => setShowFilters(true)} />,
				<SearchLine currentValue={filters.search} onSearch={setSearch} />
			]}
		/>
	)
}
