import { Row } from '@tanstack/table-core'
import { Row as AntRow, Col } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { memo, useCallback } from 'react'
import { findNestedObj } from 'shared/helpers'
import { useEstimatePositionState } from 'widgets/estimate-position-card'
import { useWbsPermits } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'
import {
	EstimatePositionChat,
	EstimatePositionCopy,
	EstimatePositionDelete,
	EstimatePositionMove,
	IEstimatePosition,
	IEstimateSection,
	useEstimateState
} from '..'
import { EstimatePositionClosed } from './EstimatePositionClosed'

interface IProps {
	row: IEstimatePosition
	callBack?: () => void
	mainRow?: Row<IEstimatePosition>
}

const ellipsisCell = {
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap'
}
export const EstimatePositionTitle = memo(function (params: IProps) {
	const { row, callBack, mainRow } = params
	const { user } = useAppSelector(state => state.environment)
	const permits = useWbsPermits()
	const { setToEdit } = useEstimatePositionState()
	const { container, sections } = useEstimateState(
		useShallow(state => ({
			container: state.container,
			sections: state.sections
		}))
	)
	const isAuthor =
		(user?.isAdmin ||
			container?.author.id === user?.id ||
			container?.redactors.some(r => r.id === user?.id)) ??
		false

	const isLocalEstimate = typeof container?.isLocalEstimate !== 'undefined'
	const canEditCorrectLocalEstimate = container?.isLocalEstimate === false && permits.canLocalEdit

	const currentSection = useCallback(() => {
		return (
			(sections &&
				findNestedObj<IEstimateSection>(sections, 'id', row.containerSectionId)?.children
					?.length) ??
			1
		)
	}, [row])

	return (
		<AntRow style={{ width: '100%' }} align="middle" justify="space-between" wrap={false}>
			<Col
				flex={1}
				onClick={() => (callBack ? callBack() : null)}
				onDoubleClick={() =>
					container &&
					setToEdit(row.id, { id: row.containerSectionId, name: row.workName }, container?.id)
				}
			>
				<div style={ellipsisCell}>{`${row.order} - ${row.workName}`}</div>
			</Col>
			<Col>
				<AntRow gutter={8} align="middle" wrap={false}>
					{!container?.ready && (
						<Col>
							<AntRow gutter={8} align="middle" wrap={false} className="app-wbs-move-controls">
								{currentSection() > 1 &&
									((!isLocalEstimate && permits.canEdit && isAuthor) ||
										(canEditCorrectLocalEstimate && isAuthor)) && (
										<Col>
											<EstimatePositionMove
												sectionId={row.containerSectionId}
												rowId={row.id}
												row={mainRow}
											/>
										</Col>
									)}
								{((!isLocalEstimate && permits.canEdit && isAuthor) ||
									(canEditCorrectLocalEstimate && isAuthor)) && (
									<Col>
										<EstimatePositionCopy id={row.id} sectionId={row.containerSectionId} />
									</Col>
								)}
								{row.parentId
									? canEditCorrectLocalEstimate &&
									  isAuthor && (
											<Col>
												<EstimatePositionClosed
													id={row.id}
													sectionId={row.containerSectionId}
													parentId={row?.parentId!}
													isClosed={row?.isClosed}
												/>
											</Col>
									  )
									: ((!isLocalEstimate && permits.canEdit && isAuthor) ||
											(canEditCorrectLocalEstimate && isAuthor)) && (
											<Col>
												<EstimatePositionDelete
													id={row.id}
													sectionId={row.containerSectionId}
													parentId={row?.parentId!}
												/>
											</Col>
									  )}
							</AntRow>
						</Col>
					)}

					<Col>
						<EstimatePositionChat row={row} />
					</Col>
				</AntRow>
			</Col>
		</AntRow>
	)
})
