import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { IChecklistFilters, IChecklistSort } from 'interfaces/IChecklist'
import { IProject } from 'interfaces/IEnvironment'
import { ITemplate, ITemplateItem } from 'interfaces/ITemplate'
dayjs.extend(utc)
interface IStateData {
	users: null | any[]
	templateFilters?: IChecklistFilters | undefined
	templateTypes: string[]
	viewerStatus: null | 0 | 1 | 2
}

interface IDTOAction {
	prop: string
	value: string | Date | number | boolean | any[] | null
}

const localProject = localStorage.getItem('project')
const currentProject: IProject = localProject && JSON.parse(localProject)

const initialDTO: ITemplate = {
	projectId: currentProject!?.id!,
	id: '',
	createAt: dayjs.utc().format(),
	title: '',
	description: '',
	assignedTo: {
		id: null,
		title: '',
		assignedToType: ''
	},
	owner: {
		id: null,
		name: ''
	},
	isActive: false,
	period: '',
	plannedDate: '',
	order: null,
	templateType: 0,
	location: '',
	statusId: null,
	checkboxUk: true,
	checkListTemplateSections: [
		{
			id: '',
			title: '',
			description: '',
			items: [],
			checkListTemplateId: '',
			checkListTemplateSectionId: ''
		}
	],
	bySection: false,
	isCommon: false,
	capacity: ''
}

const stateData: IStateData = {
	users: null,
	templateFilters: undefined,
	templateTypes: ['УКС НДК', 'УК КС'],
	viewerStatus: null
}

const templateFilters: IChecklistFilters = {}

const templatesSort: IChecklistSort = {
	titleSort: undefined,
	ownerSort: undefined
}

interface ITemplateFilters {
	filters: IChecklistFilters
}

const listStateTemplate = {
	currentPage: 1,
	totalItems: 0
}

const templateDtoKeys = Object.keys(initialDTO).map(key => key)

const templateSlice = createSlice({
	name: 'template',
	initialState: {
		init: initialDTO,
		dto: initialDTO,
		data: stateData,
		filters: templateFilters,
		listState: listStateTemplate,
		sort: templatesSort
	},
	reducers: {
		setTemplatesFilters(state, action: PayloadAction<ITemplateFilters>) {
			state.filters = action.payload.filters
		},
		setTemplatesSort(state, action: PayloadAction<IChecklistSort>) {
			state.sort = action.payload
		},
		resetTemplatesState(state) {
			state.filters = templateFilters
			state.listState = listStateTemplate
			state.sort = templatesSort
		},
		setCurrentPage(state, action: PayloadAction<number>) {
			state.listState.currentPage = action.payload
		},
		setTotalItems(state, action: PayloadAction<number>) {
			state.listState.totalItems = action.payload
		},
		setTemplates(state, action: PayloadAction<ITemplate>) {
			state.init = action.payload
		},
		setDto(state, action: PayloadAction<ITemplate>) {
			state.dto = action.payload
		},
		setDtoItems(state, action: PayloadAction<ITemplateItem[]>) {
			const tempData = state.init
			const items = action.payload
			items.forEach(item => {
				tempData.checkListTemplateItems.push(item)
			})
			state.dto.checkListTemplateItems = items
		},
		editTemplate(state, action: PayloadAction<ITemplate>) {
			// const tempData = state.init
		},
		setUsers(state, action: PayloadAction<any[]>) {
			const tempData = state.data
			tempData.users = action.payload
		},
		setTemplateFilters(state, action: PayloadAction<IChecklistFilters | undefined>) {
			const tempData = state.data
			tempData.templateFilters = action.payload
		},
		setTemplateTypes(state, action: PayloadAction<string[]>) {
			state.data.templateTypes = action.payload
		},
		setViewerStatus(state, action: PayloadAction<null | 0 | 1 | 2>) {
			state.data.viewerStatus = action.payload
		},
		setDtoValue(state, action: PayloadAction<IDTOAction>) {
			if (templateDtoKeys.some(key => key === action.payload.prop)) {
				state.dto = {
					...state.dto,
					[action.payload.prop]: action.payload.value
				}
			}
		},
		resetDto(state) {
			state.dto = {
				...state.init,
				bySection: false,
				isCommon: false
			}
		}
	}
})

export const {
	setTemplatesSort,
	setTemplatesFilters,
	resetTemplatesState,
	setCurrentPage,
	setTotalItems,
	setTemplates,
	setDto,
	setDtoItems,
	editTemplate,
	setUsers,
	setTemplateFilters,
	setTemplateTypes,
	setViewerStatus,
	setDtoValue,
	resetDto
} = templateSlice.actions

export default templateSlice.reducer
