import React, { useEffect,  
} from 'react';
import {
    notification } from 'antd';
import useMaintanceState from 'store/maintanceState';


export const MaintanceNotification: React.FC = () => {
const [api, contextHolder] = notification.useNotification();

const {maintance} = useMaintanceState()
const openNotification = () => {
  const key = `open${Date.now()}`;
  api.open({
    message: <span style={{color:'red', whiteSpace: "nowrap", display:"inline-block", maxWidth:'100%'}}>
    {`Сегодня ${maintance.date} в период: ${maintance.startTime} - ${maintance.endTime} будут вестись технические работы`}
    </span>,
    key,
    duration: 30*60,
    onClose: ()=> api.destroy(),
    placement:'top',
    style:{
    width:"fit-content",
    display:'flex',
      justifyContent:'center',
      alignItems:'center',
    }

  });
};

useEffect(()=>
  openNotification()
,[maintance])
return (
  <>
    {contextHolder}
  </>
);
};


