import { Descriptions, Form, Modal, Select } from 'antd'
import { useAppDispatch } from 'hooks/appReduxHook'
import { FC, useEffect, useState } from 'react'
import { getProjectList } from 'services/EnvironmentService'
import { trafficLightService } from 'services/LightReportService'
import { setProjectsState, setTrafficLight } from 'store/trafficLightSlice'

interface IProject {
	id: string
	name: string
	project1C: {
		id: string
		name: string
	}
}
interface IProps {
	open: boolean
	close: () => void
}

const CreateLightReportModal: FC<IProps> = ({ open, close }) => {
	const dispatch = useAppDispatch()
	const [lightReportForm] = Form.useForm()
	const [projectsData, setProjectsData] = useState<IProject[]>([])
	const [projects, setProjects] = useState<string[]>([])

	const handleSelectChange = (value: string[]) => {
		setProjects(value)
	}
	const createReport = async () => {
		await trafficLightService
			.getReport(projects)
			.then(data => {
				dispatch(setTrafficLight(data))
			})
			.then(() => close())
	}

	useEffect(() => {
		const init = async () => {
			await getProjectList().then(data => setProjectsData(data! as any))
		}
		init()
	}, [])

	useEffect(() => {
		dispatch(setProjectsState(projects))
	}, [projects])

	return (
		<Modal
			open={open}
			title="Создание отчета"
			destroyOnClose={true}
			onOk={() => {
				lightReportForm.submit()
			}}
			okText="Создать"
			onCancel={close}
			cancelText="Закрыть"
		>
			<Form name="createLightReportForm" form={lightReportForm} onFinish={createReport}>
				<Descriptions>
					<Descriptions.Item label="Проекты" labelStyle={{ fontWeight: 'bold' }} span={12}>
						<Form.Item
							name="projects"
							style={{ width: '100%' }}
							rules={[{ required: true, message: 'Выберите проекты' }]}
						>
							<Select
								mode="multiple"
								allowClear
								style={{ width: '100%' }}
								placeholder="Выберите проекты"
								onChange={handleSelectChange}
								optionFilterProp="children"
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
								}
								options={projectsData?.map((project: any) => ({
									value: project.id,
									label: project.name
								}))}
							/>
						</Form.Item>
					</Descriptions.Item>
				</Descriptions>
			</Form>
		</Modal>
	)
}
export default CreateLightReportModal
