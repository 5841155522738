import { Button, Modal } from 'antd'
import { FC, useState } from 'react'

const CookiesModal: FC = () => {
	const [isModalOpen, setIsModalOpen] = useState(!localStorage.getItem('acceptCookies'))

	const handleSubmit = () => {
		localStorage.setItem('acceptCookies', 'true')
		setIsModalOpen(false)
	}

	return (
		<Modal
			title="Мы используем файлы cookie"
			open={isModalOpen}
			onOk={handleSubmit}
			closeIcon={null}
			footer={[
				<Button key="submit" type="primary" onClick={handleSubmit}>
					Принять и закрыть
				</Button>
			]}
		>
			<p>
				Продолжая использовать сайт, вы соглашаетесь с{' '}
				<a download="Политика обработки файлов cookie.pdf" href="/files/policy_cookies.pdf">
					условиями использования файлов cookie
				</a>
			</p>
		</Modal>
	)
}

export default CookiesModal
