import { IHistoryData } from 'interfaces/IHistory'
import { API } from './ApiService'

export const historyService = {
	async issue({ id, limit = 20, offset = 0 }: { id: string; limit?: number; offset?: number }) {
		return await API<IHistoryData>({
			url: `/v1/issues/logIssue/${id}`,
			method: 'get',
			params: { offset, limit }
		}).then(async response => response.data)
	},
	async ordinance({ id, limit = 20, offset = 0 }: { id: string; limit?: number; offset: number }) {
		return await API<IHistoryData>({
			url: `/v1/ordinances/logOrdinance/${id}`,
			method: 'get',
			params: { offset, limit }
		}).then(async response => response.data)
	},
	async checklist({ id, limit = 20, offset = 0 }: { id: string; limit?: number; offset: number }) {
		return await API<IHistoryData>({
			url: `/v1/logCheckList/${id}`,
			method: 'get',
			params: { offset, limit }
		}).then(async response => response.data)
	},
	async template({ id, limit = 20, offset = 0 }: { id: string; limit?: number; offset: number }) {
		return await API<IHistoryData>({
			url: `/v1/logCheckListTemplates/${id}`,
			method: 'get',
			params: { offset, limit }
		}).then(async response => response.data)
	},
	async ordinanceFine({
		id,
		limit = 20,
		offset = 0
	}: {
		id: string
		limit?: number
		offset: number
	}) {
		return await API<IHistoryData>({
			url: `/v1/ordinanceFine/logOrdinanceFine/${id}`,
			method: 'get',
			params: { offset, limit }
		}).then(async response => response.data)
	},

	async wbs({ id, limit = 20, offset = 0 }: { id: string; limit?: number; offset: number }) {
		return await API<IHistoryData>({
			url: `v1/containers/${id}/logs`,
			method: 'get',
			params: { offset, limit }
		}).then(async response => response.data)
	},
	async local({ id, limit = 20, offset = 0 }: { id: string; limit?: number; offset: number }) {
		return await API<IHistoryData>({
			url: `v1/containerEstimates/${id}/logs`,
			method: 'get',
			params: { offset, limit }
		}).then(async response => response.data)
	}
}
