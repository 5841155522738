import { RcFile } from 'antd/es/upload'
import React, { ReactNode } from 'react'

export const getHeight = (elements?: string[]) => {
	if (document.querySelector('.dashboard')!) {
		const dbheader: number = parseFloat(
			getComputedStyle(document.querySelector('.dashboard__header')!).getPropertyValue('height').replace('px', '')
		)

		const elementsHeight: number = elements
			? elements
					.map(el => {
						const item = window.getComputedStyle(document.querySelector(el)!)
						const offset =
							parseFloat(item['marginTop']) +
							parseFloat(item['marginBottom']) +
							parseFloat(item['paddingTop']) +
							parseFloat(item['paddingBottom'])
						const result = Math.ceil(parseFloat(item['height']) + offset)
						return result
					})
					.reduce((partialSum, a) => partialSum + a, 0)
			: 0

		const pagination = document.querySelector('.ant-pagination')!
			? Math.ceil(parseFloat(window.getComputedStyle(document.querySelector('.ant-pagination')!)['height']))
			: 0

		const gutter = elements ? elements.length * 40 : 0

		const height = Math.floor(window.outerHeight - dbheader - elementsHeight - gutter - 150 - pagination)

		return height ? height + 'px' : '0px'
	}
}

export const setMeasureUnit = (unit: string | undefined): ReactNode | string => {
	if (typeof unit !== 'undefined') {
		switch (unit.replace(RegExp(/\s\^\s+|\s/g), '')) {
			case 'м2':
				return React.createElement('samp', null, ['м', React.createElement('sup', null, '2')])
			case 'м3':
				return React.createElement('samp', null, ['м', React.createElement('sup', null, '3')])
			default:
				return unit
		}
	}
}

export const uniqueSet = (arr: any[], key: string) => {
	if (arr?.every(item => item !==null && key in item)) {
		const keys = new Set()
		return arr.filter((el: any) => !keys.has(el[key]) && keys.add(el[key]))
	} else return arr
}

export const formatBytes = (bytes: number, decimals = 2) => {
	if (!+bytes) return '0 Bytes'

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getBase64 = (file: RcFile): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = error => reject(error)
	})
