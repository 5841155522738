import { IBase } from 'interfaces/IBase'
import { create } from 'zustand'

interface IAsmCommentsState {
	show: boolean
	currentId: string | null
	sectionId: string | null
}

export interface IAsmComments {
	estimatePositionTitle: string
	estimatePositionsComments: IAsmComment[]
	mainAuthor?: IBase
	isCommentsClosed: boolean
}

export interface IAsmComment {
	id: string
	author: { id: string; name: string }
	appealTo: { id: string; name: string }
	createdAt: Date
	comment: string
}
export interface IAsmCommentsUser {
	id: string
	firstName: string
	lastName: string
	fullName: string
	closeComment: boolean
	roles: IRolesCommentsUser[]
}

export interface IRolesCommentsUser {
	id: string
	name: string
	prefix?: string
	color?: string
}
export interface IWbsCommentDto {
	comment: string
	appealTo: string
}

const initCommentsState = {
	show: false,
	currentId: null,
	sectionId: null
}

export const useAsmCommentsState = create<IAsmCommentsState>(set => ({
	...initCommentsState
}))
