import { useQuery } from '@tanstack/react-query'
import { ReleaseNoteService } from 'services/release-note.service'

export const useReleaseNotes = () => {
	return useQuery({
		queryKey: ['releaseNotes'],
		queryFn: async () => {
			return await ReleaseNoteService.getAll()
		}
	})
}
