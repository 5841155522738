import { AppstoreFilled } from '@ant-design/icons'
import { Card, Divider, Flex, Form, FormInstance, Select } from 'antd'
import { FC } from 'react'
import {
	useEstimatePositionTechnologies,
	useEstimatePositionTechnology
} from 'widgets/estimate-position-card/model/estimate-position-queries'
import { useEstimatePositionState } from 'widgets/estimate-position-card/model/estimate-position-state'

interface IProps {
	estimatePositionForm: FormInstance
}

export const EstimatePositionTechnologies: FC<IProps> = ({ estimatePositionForm }) => {
	const { estimatePositionId, currentTechnology } = useEstimatePositionState()
	const { data: technologies } = useEstimatePositionTechnologies()
	useEstimatePositionTechnology()

	const onChangeTechnology = (value: string) => {
		estimatePositionForm.resetFields()
		useEstimatePositionState.setState({ technologyId: value })
	}
	return (
		<>
			<Divider children="Технология" orientation="left" />
			{estimatePositionId ? (
				<Card className="at-workcard">
					<Flex gap={8}>
						<AppstoreFilled />
						<h3 style={{ margin: 0 }}>{currentTechnology?.name}</h3>
					</Flex>
				</Card>
			) : (
				<Form.Item initialValue="Выберите технологию">
					<Select
						showSearch={true}
						optionFilterProp="children"
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						placeholder="Выберите технологию"
						filterSort={(optionA, optionB) =>
							(optionA?.label ?? '')
								.toString()
								.toLowerCase()
								.localeCompare((optionB?.label ?? '').toString().toLowerCase())
						}
						style={{ width: '100%' }}
						options={technologies?.map(item => ({
							key: item.id,
							value: item.id,
							label: item.name
						}))}
						onChange={onChangeTechnology}
					/>
				</Form.Item>
			)}
		</>
	)
}
