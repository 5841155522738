import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { message, Modal } from 'antd'
import Upload, { RcFile, UploadFile, UploadProps } from 'antd/lib/upload'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect, useState } from 'react'
import { getChecklist, uploadFile } from 'services/ChecklistsService'
import { setCurrentChecklist } from 'store/checklistsSlice'
import LoaderSpinner from 'UI/loaderSpinner'

interface IProps {
	fileModalVisible: boolean
	setFileModalVisible: (value: boolean) => void
	currentItemId: string
}

const getBase64 = (file: RcFile): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result as string)
		reader.onerror = error => reject(error)
	})

const AddFileModal: FC<IProps> = ({ fileModalVisible, setFileModalVisible, currentItemId }) => {
	const dispatch = useAppDispatch()
	const [uploading, setUploading] = useState(false)
	const [itemFiles, setItemFiles] = useState<UploadFile[]>([])
	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [previewTitle, setPreviewTitle] = useState('')
	const currentChecklistId = useAppSelector(state => state.checklists.init.currentChecklist?.id)
	const projectId = useAppSelector(state => state.environment.project?.id)

	const showMessage = (alert: string, type: string) => {
		switch (type) {
			case 'success':
				return message.success(alert)
			case 'info':
				return message.info(alert)
			case 'error':
				return message.error(alert)
		}
	}

	const onReloadChecklistItem = () => {
		if (currentChecklistId) {
			getChecklist(currentChecklistId).then(res =>
				dispatch(setCurrentChecklist({ ...res, period: dayjs(res.period) }))
			)
		}
	}

	const onUpload = async () => {
		if (itemFiles.length !== 0) {
			const config = {
				headers: { 'content-type': 'multipart/form-data' }
			}
			// itemFiles.forEach(itemFile => {
			const fmData = new FormData()
			itemFiles!?.map(file => fmData.append('files', file as RcFile))

			if (fmData) {
				setUploading(true)
				uploadFile(currentItemId, fmData, config, projectId)
					.then(() => {
						onReloadChecklistItem()
						setFileModalVisible(false)
					})
					.catch(err => console.log('Uploading error: ', err))
					.finally(() => setUploading(false))
			}
			// })
		} else {
			message.error('Необходимо выбрать хотя бы один файл')
		}
	}

	const props: UploadProps = {
		multiple: true,
		listType: 'picture-card',
		onPreview: file => handlePreview(file),
		onRemove: () => {
			setItemFiles([])
		},
		beforeUpload: file => {
			const isPicture =
				file.type === 'image/jpeg' ||
				file.type === 'image/png' ||
				file.type === 'image/gif' ||
				file.type === 'image/jpg' ||
				file.type === 'application/pdf'
			if (!isPicture) {
				showMessage(`${file.name} - не поддерживаемый формат файла`, 'error')
			} else {
				setItemFiles(prev => [...prev!, file])
			}
			return false
		}
	}

	useEffect(() => {
		if (!fileModalVisible && itemFiles) {
			setItemFiles([])
		}
	}, [fileModalVisible])

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile)
		}

		setPreviewImage(file.url || (file.preview as string))
		setPreviewOpen(true)
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
	}

	return (
		<Modal
			centered
			title="Загрузка файла (Форматы: JPEG, JPG, PNG, GIF, PDF)"
			open={fileModalVisible}
			destroyOnClose={true}
			onOk={onUpload}
			okText="Загрузить"
			onCancel={() => setFileModalVisible(false)}
			cancelText="Закрыть"
			width="50vw"
		>
			{uploading && <LoaderSpinner />}
			<div style={{ maxHeight: '60vh', overflowY: 'auto', borderRadius: '8px !important' }}>
				<Upload {...props}>
					<div>
						{uploading ? <LoadingOutlined /> : <PlusOutlined />}
						<div style={{ marginTop: 8 }}>Добавить</div>
					</div>
				</Upload>
			</div>
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={() => setPreviewOpen(false)}
			>
				<img alt="example" style={{ width: '100%' }} src={previewImage} />
			</Modal>
		</Modal>
	)
}

export default AddFileModal
