import { Table } from 'antd'
import { FC } from 'react'
import type { ColumnsType } from 'antd/lib/table'
import { useContactPersons } from 'hooks/useContactPersons'
import { IContactPersons } from 'interfaces/IEnvironment'
interface IContactPersonsProps {}

export const ContactPersons: FC<IContactPersonsProps> = () => {
	const { data, isSuccess, isLoading } = useContactPersons()

	const contactListColumns: ColumnsType<IContactPersons> = [
		{
			dataIndex: ['contractor', 'name'],
			title: 'Компания',
			width: 420,
			sorter: (a, b) => a!?.contractor!?.name!.localeCompare(b!?.contractor!?.name!)
		},
		{
			dataIndex: 'name',
			title: 'Имя',
			width: 420,
			sorter: (a, b) => a!?.name!.localeCompare(b!?.name!)
		},
		{
			dataIndex: 'email',
			title: 'email'
		}
	]

	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<Table
				loading={isLoading}
				size="small"
				rowKey={record => record.id!}
				dataSource={isSuccess ? data : []}
				columns={contactListColumns}
				pagination={false}
				bordered
			/>
		</div>
	)
}
