import { API } from 'services/ApiService'
import { ImportFromExcel } from '..'

export const EstimateImportDataApi = {
	async getPrepData(file: FormData, containerId: string, type: 'wbs' | 'local') {
		return await API({
			url: `/v1/${type === 'wbs' ? 'containers/previewImportFile' : 'containerEstimates/previewImportFile'}`,
			method: 'post',
			params: { containerId },
			data: file
		}).then(async response => response.data)
	},
	async confirm(data: ImportFromExcel[], type: 'wbs' | 'local') {
		return await API({
			url: `/v1/estimatePositions/${type === 'wbs' ? 'workFromContainer' : 'workFromEstimate'}`,
			method: 'patch',
			data: data
		})
	}
}
