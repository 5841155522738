import { DeleteOutlined } from '@ant-design/icons'
import { Button, Popconfirm } from 'antd'
import { memo } from 'react'
import { useEstimateState } from '..'

interface IProps {
	id: string
	sectionId: string
	parentId?: string
}

export const EstimatePositionDelete = memo(function (params: IProps) {
	const { id, sectionId, parentId } = params
	const { deleteEstimatePosition } = useEstimateState()
	return (
		<Popconfirm
			title="Удалить?"
			description="Вы уверены, что хотите удалить эту позицию сметы?"
			onConfirm={() => deleteEstimatePosition([id], sectionId)}
		>
			<Button size="small" type="text" disabled={!!parentId} danger icon={<DeleteOutlined />} />
		</Popconfirm>
	)
})
