import { create } from 'zustand'

interface IState {
	showComments: boolean
}
interface IStateActions {
	toggleShowComments: (state?: boolean) => void
}

export const useEstimateCommentsState = create<IState & IStateActions>((set, get) => ({
	showComments: false,
	toggleShowComments: state => set(() => ({ showComments: state ? state : !get().showComments }))
}))
