import dayjs from 'dayjs'
import {
	IChecklistData,
	IChecklistFilters,
	IChecklistUpdateData,
	ICurrentChecklist,
	IResponsibleManager
} from './../interfaces/IChecklist'
import { API } from './ApiService'

export const getChecklistForm = async (templateId: string, checklistId?: string) => {
	return await API({
		url: `/v1/checkLists/${templateId}/template`,
		method: 'get',
		params: { checklistId }
	}).then(async response => response.data)
}

export const createChecklist = async (data: any) => {
	return await API({
		url: `/v1/checkLists/`,
		method: 'post',
		data: [data]
	}).then(async response => response.data)
}

export const updateChecklist = async (data: IChecklistUpdateData, checklistId: string) => {
	return await API({
		url: `/v1/checkLists/${checklistId}`,
		method: 'patch',
		data
	}).then(async response => response.data)
}

export const updateChecklistItem = async (
	id: string,
	answer: number | null,
	issueId: string | null
) => {
	return await API({
		url: `/v1/checkListItems/${id}`,
		method: 'patch',
		data: { answer }
	}).then(async response => response.data)
}

export const getChecklist = async (id: string) => {
	return await API<ICurrentChecklist>({
		url: `/v1/checkLists/${id}`,
		method: 'get'
	}).then(async response => response.data)
}

export const getChecklists = async ({
	filters,
	limit = 50,
	offset = 0,
	title,
	templateType,
	sortState
}: {
	filters?: IChecklistFilters | undefined
	limit?: number
	offset?: number
	title?: string | null
	templateType?: number | null
	sortState?: number
}) => {
	if (filters?.createdAt !== undefined) {
		filters = {
			...filters,
			createdAt: dayjs(filters?.createdAt).format('YYYY-MM-DD')
		}
	}
	if (filters?.plannedDate !== undefined) {
		filters = {
			...filters,
			plannedDate: dayjs(filters?.plannedDate).format('YYYY-MM-DD')
		}
	}
	if (filters?.factDate !== undefined) {
		filters = {
			...filters,
			factDate: dayjs(filters?.factDate).format('YYYY-MM-DD')
		}
	}

	return await API<IChecklistData>({
		url:
			templateType === 1 || templateType === 0
				? `/v1/checkLists?templateType=${templateType}`
				: `/v1/checkLists`,
		method: 'get',
		params: { limit, offset, title, sortState, ...filters }
	}).then(async response => response.data)
}
export const uploadFile = async (
	itemId: string,
	body: FormData,
	conf: any,
	projectId: string | undefined
) => {
	return await API({
		url: `/v1/checkListItems/${itemId}/attachments?projectId=${projectId}`,
		method: 'post',
		data: body,
		...conf
	}).then(async response => response.data)
}
export const getChecklistItemAttachments = async (itemId: string) => {
	return await API({
		url: `/v1/checkListItems/${itemId}/attachments`,
		method: 'get'
	}).then(async response => response.data)
}
export const getChecklistItemAttachment = async (fileId: string) => {
	return await API<Blob>({
		url: `/v1/files/${fileId}/download`,
		method: 'get',
		responseType: 'blob'
	}).then(async response => response.data)
}
export const getResponsibleUsers = async ({
	templateType,
	name,
	projectId
}: {
	templateType: number
	name?: string
	projectId?: string
}) => {
	return await API({
		url: `/v1/checkLists/responsible-users`,
		method: 'get',
		params: { name, templateType, projectId }
	}).then(async response => response.data)
}
export const addIssueInItem = async (itemId: string, issueId: string[]) => {
	return await API({
		url: `/v1/checkListItems/${itemId}/issues/assign`,
		method: 'patch',
		data: issueId
	}).then(async response => response.data)
}
export const getChecklistsOwners = async () => {
	return await API({
		url: `/v1/checkLists/owners`,
		method: 'get'
	}).then(async response => response.data)
}
export const setAcceptedUnderWarranty = async (
	checklistId: string,
	description: string,
	body: FormData,
	conf: any
) => {
	return await API({
		url: `/v1/checkLists/${checklistId}/accepted-under-warranty`,
		method: 'patch',
		data: body,
		...conf
	}).then(async response => response.data)
}

export const setChecklistCancel = async (checklistId: string) => {
	return await API({
		url: `/v1/checkLists/${checklistId}/cancel`,
		method: 'patch'
	}).then(async response => response.data)
}

export const getChecklistLog = async (checklistId: string) => {
	return await API({
		url: `/v1/logCheckList/${checklistId}`,
		method: 'get'
	}).then(async response => response.data)
}

export const deleteChecklist = async (id: string) => {
	return await API({
		url: `/v1/checkLists/${id}`,
		method: 'delete'
	}).then(async response => response.data)
}

export const getResponsibleManagers = async () => {
	return await API<IResponsibleManager[]>({
		url: '/v1/checkLists/responsible-managers',
		method: 'get'
	}).then(async response => response.data)
}
