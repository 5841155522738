import { Button, Divider, Form, FormInstance, FormListFieldData } from 'antd'
import { FC, useEffect, useState } from 'react'
import { ICheckListTemplateSection, ITemplate } from 'interfaces/ITemplate'
import Section from './Section'
import { PlusOutlined } from '@ant-design/icons'
import { useAppSelector } from 'hooks/appReduxHook'

interface IProps {
	form: FormInstance
	isEdited: boolean
	editedTemplate: ITemplate | null
	onRefreshTemplatesTable: () => void
	currentTemplateType: string
	allDisabled: boolean
}

const Sections: FC<IProps> = ({
	form,
	isEdited,
	editedTemplate,
	onRefreshTemplatesTable,
	currentTemplateType,
	allDisabled
}) => {
	const templateDto = useAppSelector(state => state.templates.dto)
	const [checkListTemplateSections, setCheckListTemplateSections] = useState<ICheckListTemplateSection[]>([])
	const [currentItem, setCurrentItem] = useState<FormListFieldData | null>(null)

	const addSection = () => {
		const temp = form.getFieldValue('sections') ? [...form.getFieldValue('sections')] : []
		const newSection = {
			description: '',
			items: [],
			title: '',
			order: !!temp.length ? temp.length : 0
		}
		temp.push(newSection)
		form.setFieldValue('sections', temp)
		setCheckListTemplateSections(temp)
	}

	const removeSection = (removeIdx: number) => {
		const sections: ICheckListTemplateSection[] = form.getFieldValue('sections')
		const temp = sections.filter((item, index) => index !== removeIdx).map((item, index) => ({ ...item, order: index }))
		form.setFieldValue('sections', temp)
		form.validateFields()
		setCheckListTemplateSections(temp)
	}

	useEffect(() => {
		setCheckListTemplateSections(form.getFieldValue('sections'))
	}, [form.getFieldValue('sections')])

	useEffect(() => {
		if (form.getFieldValue('sections')) {
			setCheckListTemplateSections(form.getFieldValue('sections'))
		}
	}, [currentItem])

	return (
		<>
			<Form.Item name="sections" style={{ width: '100%' }}>
				{checkListTemplateSections?.map((section: ICheckListTemplateSection, index: number) => {
					return (
						<>
							<Section
								key={section.id}
								idx={index}
								section={section}
								isEdited={isEdited}
								form={form}
								tempOrder={index}
								onRefreshTemplatesTable={onRefreshTemplatesTable}
								currentTemplateType={currentTemplateType}
								allDisabled={allDisabled}
								onRemove={removeSection}
							/>
							<Divider />
						</>
					)
				})}
			</Form.Item>

			<Button
				type="primary"
				onClick={addSection}
				style={{ width: '100%' }}
				icon={<PlusOutlined rev={undefined} />}
				disabled={allDisabled}
			>
				Добавить раздел
			</Button>
		</>
	)
}

export default Sections
