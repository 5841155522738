import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IContractor, IProject } from "interfaces/IEnvironment"
import { IPermissionFilters, IUser } from "interfaces/IUser"

interface IListState {
    currentPage?: number | null
	totalItems?: number | null
}

interface IFiltersPermission {
    filters: IPermissionFilters
}

interface IState {
    allProjects: IProject[] | []
	contractors: IContractor[] | [] | null
	users: IUser[] | null
	filters: IPermissionFilters | null
	listState: IListState | null
}

const permissionFilters: IPermissionFilters = {
    ids: undefined,
    projectFilterId: undefined,
    contractorId: undefined,
	roleId: undefined,
    search: undefined
}

const permissionListState = {
    currentPage: 1,
    totalItems: 0
}

const initialState: IState = {
	allProjects: [],
	contractors: [],
	users: [],
	filters: permissionFilters,
	listState: permissionListState
}

const settingsUsersSlice = createSlice({
	name: 'settingsUsers',
	initialState,
	reducers: {
		setPermissionFilters(state, action: PayloadAction<IFiltersPermission>) {
			state.filters = action.payload.filters
		},
		setTotalItems(state, action: PayloadAction<number>) {
			state.listState!.totalItems = action.payload
		},
		setCurrentPage(state, action: PayloadAction<number>) {
			state.listState!.currentPage = action.payload
		},
        resetSettingsUsersFilter(state) {
			state.filters = permissionFilters
		}
    }
})

export const {
	setPermissionFilters,
	setTotalItems,
	setCurrentPage,
    resetSettingsUsersFilter,
} = settingsUsersSlice.actions

export default settingsUsersSlice.reducer