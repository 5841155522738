import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect, useState } from 'react'
import { setPageTitle } from 'store/rootSlice'
import { Card, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import Button from 'antd/es/button'
import 'react-quill/dist/quill.snow.css'
import { ITextItem } from 'interfaces/IAnalytics'
import Editor from './components/Editor'
import InformationUnit from './components/InformationUnit'
import { reportService } from 'services/ReportService'
interface IProps {}

const Report: FC<IProps> = () => {
	const dispatch = useAppDispatch()
	const { user: currentUser } = useAppSelector(state => state.environment)
	const [textItem, setTextItem] = useState<ITextItem | null>(null)
	const [textDataItems, setTextDataItems] = useState<ITextItem[]>([])
	const [editorVisible, setEditorVisible] = useState(false)
	const [editIconVisible, setEditIconVisible] = useState(false)

	const handleChangeText = async () => {
		if (textItem?.content) {
			if (!textItem.id) {
				await reportService.setNote(textItem).then(res => console.log(res))
			} else {
				await reportService.editNote(textItem).then(res => console.log(res))
			}
			onRefreshNotes()
			setTextItem(null)
			setEditorVisible(false)
		} else {
			message.error('Введите текст!')
		}
	}
	const handleDeleteItem = async (id: string) => {
		await reportService.deleteNote(id)
		onRefreshNotes()
	}

	const onRefreshNotes = () => {
		reportService.getNotes().then(res => {
			setTextDataItems(res)
		})
	}

	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Отчёты' }))
	}, [])
	useEffect(() => {
		onRefreshNotes()
	}, [])

	return (
		<Card style={{ width: '100%' }}>
			<div>
				{textDataItems.map((item: ITextItem) => {
					const isEdited = item.id === textItem?.id
					isEdited && editorVisible && setEditorVisible(false)
					return (
						<>
							{isEdited ? (
								<Editor
									textItem={textItem}
									setTextItem={setTextItem}
									handleChangeText={handleChangeText}
									setEditorVisible={setEditorVisible}
								/>
							) : (
								<InformationUnit
									item={item}
									setTextItem={setTextItem}
									editIconVisible={editIconVisible}
									setEditIconVisible={setEditIconVisible}
									handleDeleteItem={handleDeleteItem}
								/>
							)}
						</>
					)
				})}
				{editorVisible && (
					<Editor
						textItem={textItem}
						setTextItem={setTextItem}
						handleChangeText={handleChangeText}
						setEditorVisible={setEditorVisible}
					/>
				)}
			</div>
			{currentUser?.isAdmin && !editorVisible && (
				<Button
					onClick={() => setEditorVisible(true)}
					style={{ marginTop: 16 }}
					icon={<PlusOutlined rev={undefined} />}
				>
					Добавить описание
				</Button>
			)}
		</Card>
	)
}
export default Report
