import { Form, Input, Modal } from 'antd'
import modal from 'antd/es/modal'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'
import { memo, useEffect } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { adjustmentApi, IAdjustmentPositionCommentsUpdate } from '..'
import { AdjustmentCol } from '../model/adjustment-helper'
import { useAdjustmentState } from '../model/adjustment-state'

export const AdjustmentNote = memo(function () {
	const checkPermissions = useCheckPermissions()
	const { isLoading, container, note, unSetNote, updateSections } = useAdjustmentState()
	const [estimateNoteForm] = Form.useForm()
	const onClose = () => {
		estimateNoteForm.resetFields()
		unSetNote()
	}
	enum NOTE_TYPE {
		commentSdu = 'Комментарии СДУ',
		justification = 'Обоснование ЕР'
	}

	useEffect(() => {
		note?.main.value && estimateNoteForm.setFieldValue('value', note.main.value)
	}, [note])

	const getPosition = () => {
		switch (true) {
			case note && 'materialName' in note.row && note.main.type === 'commentSdu':
				return AdjustmentCol.EstimatePositionAdjustmentMaterialComment
			case note && 'materialName' in note.row && note.main.type === 'justification':
				return AdjustmentCol.EstimatePositionAdjustmentMaterialJustification
			case note && 'workName' in note.row && note.main.type === 'justification':
				return AdjustmentCol.EstimatePositionAdjustmentJustification
			default:
				return AdjustmentCol.EstimatePositionAdjustmentComment
		}
	}

	const onSaveNote = async (values: any) => {
		if (note) {
			const dto: IAdjustmentPositionCommentsUpdate = {
				estimatePositionId: 'materialName' in note.row ? note.row.estimatePositionId : note.row.id,
				estimatePositionAdjustmentProps: getPosition(),
				estimatePositionMaterialId: 'materialName' in note.row ? note.row.id : null,
				value: values.value
			}
			await adjustmentApi
				.saveStatementComments(dto)
				.then(() => {
					updateSections(note.row.containerSectionId)
					onClose()
				})
				.catch((err: AxiosError<IErrorDetail>) =>
					modal.error({
						title: 'Произошла ошибка',
						content:
							err.response?.data.detail ?? 'Неизвестная ошибка. Обратитесь в службу поддержки'
					})
				)
		}
	}

	const canEdit = checkPermissions([PERMISSIONS.AdjustmentSdu])

	return (
		<Modal
			open={typeof note !== 'undefined'}
			width={768}
			destroyOnClose
			centered
			title={`${note && NOTE_TYPE[note.main.type]} - ${
				note?.main.isWork ? 'Работа' : 'Материал'
			}: ${note?.main.name}`}
			onCancel={onClose}
			onOk={() => (!container?.ready && canEdit ? estimateNoteForm.submit() : onClose())}
			okButtonProps={{ htmlType: 'submit', loading: isLoading }}
		>
			<Form form={estimateNoteForm} name="estimateNoteForm" onFinish={onSaveNote}>
				<Form.Item name="value">
					<Input.TextArea
						disabled={container?.ready || note?.main.disabled || !canEdit}
						allowClear
						autoSize
						showCount
						maxLength={5000}
						placeholder="Примечание"
						style={{ marginBottom: '1rem' }}
						styles={{ textarea: { minHeight: 256 } }}
					/>
				</Form.Item>
			</Form>
		</Modal>
	)
})
