import { IContractDto } from 'pages/guidesPage/ui'
import { API } from './ApiService'
import { IContract } from 'interfaces/IContract'

interface IGetContractsParams {
	contractorId?: string
	subContractorId?: string
	project1CId?: string
	projectId?: string
	ownerId?: string
}

export const getContracts = async ({ ...params }: IGetContractsParams) => {
	return await API<IContract[]>({
		url: `/v1/contracts`,
		method: 'get',
		params: params
	}).then(async response => response.data)
}

export const getAllContracts = async () => {
	return await API<IContract[]>({
		url: `/v1/contracts`,
		method: 'get',
		params: { project1CId: null }
	}).then(async response => response.data)
}

export const updateOrdinanceFineSum = async (contractId: string, dto: IContractDto) => {
	return await API({
		url: `/v1/contracts/${contractId}/AddOrdinanceFine`,
		method: 'patch',
		data: dto
	}).then(async response => response.data)
}
