import { useMutation } from "@tanstack/react-query"
import { HOCFineReportApi } from "../api/hoc-fine-report-api"
import { IHOCFineReportFilters } from "./hoc-fine-report-interface"
import { useHOCFineReportState } from "./hoc-fine-report-state"


export const useHOCFineReport = () => {

    const { setData } = useHOCFineReportState()

    return useMutation(async (filters: IHOCFineReportFilters) => {
        return await HOCFineReportApi.getHOCFineReport(filters)
    }, {
        onSuccess: (data) => setData(data)
    })
}

export const useHOCFineReportExcel = () => {
    return useMutation(async (filters: IHOCFineReportFilters) => {
        return await HOCFineReportApi.getHOCFineReportExcel(filters)
    })
}
