import { useQuery } from '@tanstack/react-query'
import { getDiagram } from 'services/DiagramService'
import { useAppSelector } from './appReduxHook'

interface IViolationTypesAnalytics {
	lable: string
	code: number
	issueFix: number
	issueNoFix: number
}

export const useViolationTypesAnalytics = () => {
	const diagramsContractor = useAppSelector(state => state.environment.diagramsContractor)
	return useQuery({
		queryKey: ['violationTypesAnalytics', diagramsContractor],
		queryFn: async () => {
			const diagram = await getDiagram('issueSubTypes', diagramsContractor).then(data => data as IViolationTypesAnalytics[])
			return diagram.sort((a, b) => {
				if (a.code === 0) return 1
				if (b.code === 0) return -1
				return a.code - b.code
			})
		}
	})
}
