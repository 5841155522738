import { useQuery } from '@tanstack/react-query'
import { TEstimateType } from 'shared/schema'
import { useAdjustmentPositionCommentsState } from 'widgets/adjustment-position-comments'
import { useAdjustmentState } from 'widgets/adjustment/model/adjustment-state'
import { useShallow } from 'zustand/react/shallow'
import { AdjustmentCommentsApi, useAdjustmentCommentsState } from '..'

export const useAdjustmentComments = (
	type: TEstimateType,
	filter?: boolean,
	filterMyComment?: boolean
) => {
	const container = useAdjustmentState(useShallow(state => state.container))
	const showComments = useAdjustmentCommentsState(useShallow(state => state.showComments))
	return useQuery({
		queryKey: ['adjustmentComments', container, showComments, filter, filterMyComment],
		queryFn: async () => {
			if (container && showComments) {
				return await AdjustmentCommentsApi.getAll(container.id, type, filter, filterMyComment).then(
					res => res.data
				)
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}

export const useAdjustmentAuthorCommentStatus = (id: string | null, type: TEstimateType) => {
	const { show, currentId } = useAdjustmentPositionCommentsState()

	return useQuery({
		queryKey: ['adjustmentCommentsAuthors', currentId, show],
		queryFn: async () => {
			if (id !== null && currentId !== null) {
				return await AdjustmentCommentsApi.getAuthors(id, type)
					.then(res => res)
					.catch(() => 'withoutButton')
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}
