import { PayloadAction, createSlice, current } from '@reduxjs/toolkit'
import { FormInstance } from 'antd'
import {
	IChecklistFilters,
	IChecklistSection,
	ICurrentChecklist,
	ICurrentChecklistItem
} from 'interfaces/IChecklist'

interface IState {
	currentChecklistId: string
	initialStatuses: string[]
	currentChecklist: ICurrentChecklist | null
	checklistFilters: FormInstance | null
	currentItem: ICurrentChecklistItem | null
	currentIssueId: string
	isCreatedChecklist: boolean
	doorSections: number[]
}

interface IFiltersCL {
	filters: IChecklistFilters
}

const initialDTO = [
	{
		id: '',
		checkListTemplateId: '',
		number: 0,
		title: '',
		description: '',
		assignedTo: [],
		// period: '',
		plannedDate: null,
		factDate: null,
		templateType: 0,
		location: '',
		status: '',
		contractorId: null,
		subContractorId: null,
		checkboxUk: true,
		isMobile: null,
		checkListItems: [
			{
				id: '',
				name: '',
				description: '',
				answerYes: true,
				answerNo: true,
				answerNa: true,
				checkboxUk: true,
				checkboxCritical: true,
				checkboxFile: true,
				checkboxIssue: true,
				checkListId: '',
				issue: {
					id: '',
					title: ''
				}
			}
		]
	}
]

const state: IState = {
	currentChecklistId: '',
	initialStatuses: ['Новый', 'В работе', 'Завершен', 'Аннулирован', 'Принято под гарантийные обязательства'],
	currentChecklist: null,
	checklistFilters: null,
	currentItem: null,
	currentIssueId: '',
	isCreatedChecklist: false,
	doorSections: []
}

const checklistFilters: IChecklistFilters = {}

// const checklistsSort: IChecklistSort = {
// 	createdAt: undefined,
// 	title: undefined,
// 	number: undefined,
// 	workPackage: undefined,
// 	cipher: undefined,
// 	plannedDate: undefined,
// 	factDate: undefined,
// }

const checkListState = {
	currentPage: 1,
	totalItems: 0
}

const checklistsSlice = createSlice({
	name: 'checklist',
	initialState: {
		init: state,
		dto: initialDTO,
		filters: checklistFilters,
		listState: checkListState,
		sortState: undefined
	},
	reducers: {
		setCurrentChecklistSort(state, action: PayloadAction<number | undefined | any>) {
			state.sortState = action.payload
		},
		setCurrentChecklistFilters(state, action: PayloadAction<IFiltersCL>) {
			state.filters = action.payload.filters
		},
		resetCheckListsState(state) {
			state.filters = checklistFilters
			state.listState = checkListState
			state.sortState = undefined
		},
		setCurrentPage(state, action: PayloadAction<number>) {
			state.listState.currentPage = action.payload
		},
		setTotalItems(state, action: PayloadAction<number>) {
			state.listState.totalItems = action.payload
		},
		setCurrentChecklistId(state, action: PayloadAction<string>) {
			state.init.currentChecklistId = action.payload
		},
		setCurrentChecklist(state, action: PayloadAction<ICurrentChecklist | null>) {
			state.init.currentChecklist = action.payload
		},
		resetCurrentChecklist(state) {
			state.init.currentChecklist = null
		},
		setIssueId(state, action: PayloadAction<string>) {
			state.dto[0].checkListItems[0].issue.id = action.payload
		},
		setChecklistFilters(state, action: PayloadAction<FormInstance | null>) {
			state.init.checklistFilters = action.payload
		},
		setCurrentItem(state, action: PayloadAction<ICurrentChecklistItem | null>) {
			state.init.currentItem = action.payload
		},
		editChecklistItem(state, action: PayloadAction<{ newItem: ICurrentChecklistItem }>) {
			const itemId = action.payload.newItem.id
			if (state.init.currentChecklist) {
				let newSections: any = []
				current(state.init?.currentChecklist).sections.forEach((section: IChecklistSection) => {
					const tempSection: IChecklistSection = { ...section, items: [] }
					section.items.forEach((item: ICurrentChecklistItem) => {
						if (item.id === itemId) {
							tempSection.items.push(action.payload.newItem)
						} else {
							tempSection.items.push(item)
						}
					})
					newSections.push(tempSection)
				})
				state.init.currentChecklist.sections = newSections
			}
		},
		setCurrentIssueId(state, action: PayloadAction<string>) {
			state.init.currentIssueId = action.payload
		},
		setIsCreatedChecklist(state, action: PayloadAction<boolean>) {
			state.init.isCreatedChecklist = action.payload
		}
	}
})

export const {
	setCurrentChecklistSort,
	resetCheckListsState,
	setCurrentPage,
	setTotalItems,
	setCurrentChecklistFilters,
	setCurrentChecklistId,
	setIssueId,
	setCurrentChecklist,
	setChecklistFilters,
	setCurrentItem,
	editChecklistItem,
	setCurrentIssueId,
	setIsCreatedChecklist,
	resetCurrentChecklist
} = checklistsSlice.actions

export default checklistsSlice.reducer
