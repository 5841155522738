import { Card } from 'antd'
import { memo } from 'react'
import { useEstimateQuery } from '..'
import { EstimateVirtualize } from './EstiamateVirtualize'

export const Estimate = memo(function ({ type }: { type: 'wbs' | 'local' }) {
	const { isFetching } = useEstimateQuery(type)

	return (
		<Card size="small">
			<EstimateVirtualize type={type} />
		</Card>
	)
})
