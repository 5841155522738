import { create } from 'zustand'

interface IUserCardState {
	selectedUserId?: string
	showCard: boolean
	setSelectedUserId: (id?: string) => void
	setShowCard: (state: boolean) => void
	unset: () => void
}

export const useUserCardState = create<IUserCardState>(set => ({
	showCard: false,
	setSelectedUserId: id => set(() => ({ selectedUserId: id })),
	setShowCard: state => set(() => ({ showCard: state })),
	unset: () => set(() => ({ selectedUserId: undefined, showCard: false }))
}))
