import { FC, useEffect } from 'react'
import { Typography, Result } from 'antd'
import { NavLink } from 'react-router-dom'
import { useAppDispatch } from 'hooks/appReduxHook'
import { setPageTitle } from 'store/rootSlice'
const { Title, Text } = Typography

const Forbidden: FC = () => {
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: '403' }))
	}, [])

	return (
		<div className="dashboard__block" style={{ display: 'flex', alignItems: 'center' }}>
			<Result
				style={{ margin: 'auto' }}
				status="403"
				title={<Title level={1}>403</Title>}
				subTitle={<Text>У вас недостаточно прав, для просмотра этого раздела</Text>}
				extra={<NavLink to="/">На главную</NavLink>}
			/>
		</div>
	)
}

export default Forbidden
