import { Col, Modal, Row, Button, Divider, Image, Spin, Tooltip } from 'antd'
import { FileImageOutlined, LoadingOutlined } from '@ant-design/icons'

import { FC, useEffect, useState } from 'react'
import { IIssue } from 'interfaces/IIssue'
import { IFile } from 'interfaces/IEnvironment'
import { getFileList, getFile } from 'services/OrdinanceService'
interface IFileViewerProps {
	show: boolean
	onClose: () => void
	src: IIssue | null
}

interface IBase64 {
	id: string
	src: string
}

const loader = <LoadingOutlined style={{ fontSize: '4rem' }} spin rev={undefined} />

const FileViewer: FC<IFileViewerProps> = ({ show, onClose, src }) => {
	const [isLoading, setIsLoading] = useState(true)
	const [fileList, setFileList] = useState<IFile[]>([])
	const [selectedFile, setSelectedFile] = useState<IFile | null>(null)
	const [base64, setBase64] = useState<IBase64[]>([])

	useEffect(() => {
		if (show && src!) {
			getFileList(src?.id!)
				.then(data => setFileList(data))
				.finally(() => setTimeout(() => setIsLoading(false), 1000))
		}
	}, [show])

	useEffect(() => {
		setSelectedFile(fileList[0])
		const getBlobs = async () => {
			const blobList: IBase64[] = await Promise.all(
				fileList.map(async (item): Promise<IBase64> => {
					const blob = await blobToBase64(item.id).then(data => data)
					return { id: item.id, src: blob! }
				})
			)
			setBase64(blobList)
		}
		getBlobs()
	}, [fileList])

	const blobToBase64 = async (id: string) => {
		const blob: Blob = await getFile(id).then(data => data)
		if (blob) {
			return new Promise<string>((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(blob)
				reader.onload = () => resolve(reader.result!.toString())
				reader.onerror = error => reject(error)
			})
		}
	}

	return (
		<Modal
			wrapClassName="at-modal at-modal--dark"
			centered
			open={show}
			onCancel={onClose}
			footer={null}
			width="65vw"
			destroyOnClose={true}
			title="Файлы"
			bodyStyle={{ height: '80vh', padding: '1rem' }}
		>
			<Row gutter={8} style={{ height: '100%' }} wrap={false}>
				<Col span={5}>
					{fileList.map(file => (
						<Tooltip title={file.name.replace(file.extension, '')} placement="right">
							<Button
								icon={<FileImageOutlined rev={undefined} />}
								className="at-btn at-btn--select"
								type={selectedFile?.id === file.id ? 'primary' : 'text'}
								block
								onClick={() => setSelectedFile(file)}
							>
								{file.name.replace(file.extension, '')}
							</Button>
						</Tooltip>
					))}
				</Col>
				<Col>
					<Divider type="vertical" style={{ height: '100%' }} />
				</Col>
				<Col flex={1}>
					{isLoading ? (
						<Spin indicator={loader} />
					) : (
						<Image
							rootClassName="at-image-wrapper"
							preview={false}
							src={base64.find(image => image.id === selectedFile?.id)?.src}
						/>
					)}
				</Col>
			</Row>
		</Modal>
	)
}
export default FileViewer
