import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin rev={undefined} />

const LoaderSpinner = () => {
	return (
		<div className="at-loader" style={{ height: '100%' }}>
			<Spin indicator={antIcon}></Spin>
		</div>
	)
}

export default LoaderSpinner
