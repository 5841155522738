import { Checkbox, Popconfirm } from 'antd'
import { memo } from 'react'
import { useShallow } from 'zustand/react/shallow'

import modal from 'antd/es/modal'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { adjustmentApi } from '../api/adjustment-api'
import {
	IAdjustmentPositionMaterial,
	IAdjustmentToggleIsNominatedSduDto
} from '../model/adjustment-schema'
import { useAdjustmentState } from '../model/adjustment-state'

interface IProps {
	record: IAdjustmentPositionMaterial
}

export const AdjustmentNominatedCell = memo(function (params: IProps) {
	const { record } = params
	const checkPermissions = useCheckPermissions()
	const { updateSections } = useAdjustmentState(
		useShallow(state => ({
			updateSections: state.updateSections
		}))
	)

	const onConfirm = async (values: any) => {
		const dto: IAdjustmentToggleIsNominatedSduDto = {
			estimatePositionMaterialId: record.id,
			isNominated: !record.isNominatedSdu
		}
		await adjustmentApi
			.toggleIsNominatedSdu(dto)
			.then(() => {
				updateSections(record.containerSectionId)
			})
			.catch((err: AxiosError<IErrorDetail>) =>
				modal.error({
					title: 'Произошла ошибка',
					content: err.response?.data.detail ?? 'Неизвестная ошибка. Обратитесь в службу поддержки'
				})
			)
	}

	return (
		<Popconfirm title="Изменить значение?" onConfirm={onConfirm}>
			<Checkbox
				checked={record.isNominatedSdu}
				disabled={!checkPermissions([PERMISSIONS.AdjustmentDzMtr])}
			/>
		</Popconfirm>
	)
})
