import {
	BookTwoTone,
	DeleteOutlined,
	EditOutlined,
	PlusSquareTwoTone,
	SaveTwoTone,
	StopOutlined,
	TagTwoTone,
	XFilled
} from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, Form, Row, Table, Tag, Tooltip } from 'antd'
import { Color } from 'antd/es/color-picker'
import { ColumnsType } from 'antd/es/table'
import { FC, useState } from 'react'
import { WbsStatusCell } from './WbsStatusCell'
import { useWbsStatuses } from './useWbsStatuses'
import { IWbsStatusRegistry, IWbsVisaWithParent, WbsStatusApi } from './wbs-status-api'
import { WbsStatusState } from './wbsStatusState'
import { WbsVisaAdd } from './wbsVisaAdd'
interface IProps {
	isLocal?: boolean
	isAdjustment?: boolean
}

export const WbsStatusGuide: FC<IProps> = ({ isLocal, isAdjustment }) => {
	const key = isLocal ? 'estimateStatusGuide' : 'wbsStatusGuide'
	const adjustmentKey = isLocal ? 'adjustmentEstimateStatusGuide' : 'adjustmentWbsStatusGuide'
	const queryClient = useQueryClient()
	const { data, isFetching } = useWbsStatuses(isLocal, isAdjustment)
	const [statusGuideForm] = Form.useForm()
	const { setVisaAdd } = WbsStatusState()
	const [editing, setEditing] = useState<string | null>(null)

	const onEdit = (record: IWbsStatusRegistry | IWbsVisaWithParent) => {
		if ('roles' in record) {
			statusGuideForm.setFieldsValue({
				name: record.name,
				rolesId: record.roles?.flatMap(r => r.id),
				color: 'color' in record ? '#' + record.color : undefined
			})
		} else {
			statusGuideForm.setFieldsValue({ name: record.description })
		}
		setEditing(record.id)
	}

	const onCancelEditing = () => {
		statusGuideForm.resetFields()
		setEditing(null)
	}

	const onSave = async (record: IWbsStatusRegistry | IWbsVisaWithParent) => {
		const validate = await statusGuideForm
			.validateFields()
			.then(() => true)
			.catch(() => false)
		if (validate) {
			const formData = statusGuideForm.getFieldsValue()
			if ('roles' in record) {
				await WbsStatusApi.updateVisa(record.id, formData).then(() => {
					queryClient.invalidateQueries({
						queryKey: [isAdjustment ? adjustmentKey : key]
					})
					queryClient.invalidateQueries({ queryKey: ['wbsVisas'] })
				})
			} else {
				const dto = {
					name: record.name!,
					description: formData.name!,
					color: formData.color ? (formData.color as Color).toHex()! : record.color,
					order: record.order!,
					visasId: record.children.flatMap(v => v.id)
				}
				await WbsStatusApi.updateStatus(record.id, dto, isLocal, isAdjustment).then(() => {
					queryClient.invalidateQueries({
						queryKey: [isAdjustment ? adjustmentKey : key]
					})
					queryClient.invalidateQueries({ queryKey: ['wbsVisas'] })
				})
			}
			onCancelEditing()
		}
	}

	const removeVisaFromStatus = async (item: IWbsVisaWithParent) => {
		const parent = data?.find(d => d.id === item.parentId)
		if (parent) {
			const dto = {
				name: parent.name!,
				description: parent.description ?? null,
				color: parent.color ?? null,
				order: parent.order,
				visasId: parent.children.filter(v => v.id !== item.id).flatMap(v => v.id) ?? []
			}
			await WbsStatusApi.updateStatus(item.parentId, dto, isLocal).then(() => {
				queryClient.invalidateQueries({
					queryKey: [isAdjustment ? adjustmentKey : key]
				})
			})
		}
	}

	const registryColumns: ColumnsType<IWbsStatusRegistry | IWbsVisaWithParent> = [
		{
			dataIndex: 'name',
			title: 'Наименование',
			width: '30%',
			onCell: record => ({
				title: 'Наименование',
				record,
				dataIndex: 'name',
				editing: editing === record.id
			}),
			render: (_, record) => (
				<Row gutter={8}>
					<Col style={{ marginLeft: !('color' in record) ? '1rem' : 0 }}>
						{!('color' in record) ? (
							<TagTwoTone twoToneColor="#7A77D9" />
						) : (
							<BookTwoTone twoToneColor={record.children.length ? '#1677ff' : 'lightgray'} />
						)}{' '}
					</Col>
					<Col>{!('color' in record) ? record.name : record.description}</Col>
				</Row>
			)
		},
		{
			dataIndex: 'roles',
			title: 'Роли',
			onCell: record => ({
				title: 'Роли',
				record,
				dataIndex: 'rolesId',
				editing: editing === record.id
			}),
			render: (_, record) =>
				'roles' in record &&
				record.roles?.map(r => (
					<Tag color="blue" style={{ fontSize: 14, padding: '4px 8px' }}>
						{r.name}
					</Tag>
				))
		},
		{
			dataIndex: 'color',
			title: 'Цвет',
			width: 64,
			align: 'center',
			render: (value, record) =>
				'color' in record && <XFilled style={{ fontSize: 24, color: `#${value}` }} />,
			onCell: record => ({
				title: 'Цвет',
				record,
				dataIndex: 'color',
				editing: editing === record.id
			})
		},
		{
			width: 200,
			align: 'center',
			render: (_, record) => (
				<Row gutter={8}>
					{editing === record.id ? (
						<>
							<Col>
								<Tooltip title="Сохранить">
									<Button
										icon={<SaveTwoTone />}
										type="text"
										onClick={e => {
											e.stopPropagation()
											onSave(record)
										}}
									/>
								</Tooltip>
							</Col>
							<Col>
								<Tooltip title="Отмена">
									<Button
										danger
										icon={<StopOutlined />}
										type="text"
										onClick={e => {
											e.stopPropagation()
											onCancelEditing()
										}}
									/>
								</Tooltip>
							</Col>
						</>
					) : (
						<>
							<Col>
								<Tooltip title="Редактировать">
									<Button
										icon={<EditOutlined />}
										type="text"
										disabled={editing !== null}
										onClick={e => {
											e.stopPropagation()
											onEdit(record)
										}}
									/>
								</Tooltip>
							</Col>
							{'color' in record && (
								<Col>
									<Tooltip title="Добавить визу">
										<Button
											icon={
												<PlusSquareTwoTone twoToneColor={editing !== null ? 'gray' : '#1677ff'} />
											}
											type="text"
											disabled={editing !== null}
											onClick={e => {
												e.stopPropagation()
												setVisaAdd(record)
											}}
										/>
									</Tooltip>
								</Col>
							)}
							{!('color' in record) && (
								<Col>
									<Tooltip title="Открепить визу">
										<Button
											danger
											icon={<DeleteOutlined />}
											type="text"
											disabled={editing !== null}
											onClick={e => {
												e.stopPropagation()
												'color' in record
													? console.log('delete', record.id)
													: removeVisaFromStatus(record)
											}}
										/>
									</Tooltip>
								</Col>
							)}
						</>
					)}
				</Row>
			)
		}
	]
	return (
		<div className="dashboard__block" style={{ borderRadius: '0 0 .8rem .8rem' }}>
			<WbsVisaAdd isLocal={isLocal} isAdjustment={isAdjustment} />
			<Form form={statusGuideForm} component={false}>
				<Table
					components={{
						body: {
							cell: WbsStatusCell
						}
					}}
					loading={isFetching}
					className="app-wbs-table app-wbs-table--on-top"
					sticky={{ offsetHeader: 0 }}
					bordered
					rowKey={row => row.id}
					dataSource={data}
					columns={registryColumns}
					pagination={false}
					size="small"
					onRow={row => ({
						style: { cursor: 'color' in row ? 'pointer' : 'default' }
					})}
					expandable={{
						showExpandColumn: false,
						columnWidth: 64,
						expandRowByClick: editing === null,
						rowExpandable: row => 'color' in row && !!row.children.length
					}}
				/>
			</Form>
		</div>
	)
}
