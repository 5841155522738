import { IBase } from 'interfaces/IBase'
import { API } from './ApiService'

export interface IViolation extends IBase {
	violationTypes: IBase[]
}

export interface IViolationDto {
	name: string
	violationTypeId: string[]
	id: string
	isDeleted?: boolean
}

export const violationsService = {
	async getList(violationTypeId?: string) {
		return await API<IViolation[]>({
			url: `/v1/violation`,
			method: 'get',
			params: { violationTypeId }
		}).then(response => response.data)
	},
	async add(violationDto: IViolationDto) {
		return await API({
			url: `/v1/violation`,
			method: 'post',
			data: violationDto
		}).then(response => response.data)
	},
	async update(violationDto: IViolationDto) {
		const { id, ...data } = violationDto
		return await API({
			url: `/v1/violation/${id}`,
			method: 'patch',
			data: data
		}).then(response => response.data)
	},
	async delete(id: string) {
		return await API({
			url: `/v1/violation/${id}`,
			method: 'delete'
		}).then(response => response.data)
	}
}
