import { create } from "zustand";

interface IHistory { 
    currentPage: number
	totalItems: number
	totalPages: number | undefined
    onPagination: (page: number) => void
	setTotalItems: (items: number) => void
	setTotalPages: (pages: number) => void
}

export const useHistoryState = create<IHistory>(set => ({
	currentPage: 1,
	totalItems: 1,
	totalPages: undefined,
    onPagination: page => set(() => ({ currentPage: page })),
	setTotalItems: items => set(() => ({ totalItems: items })),
	setTotalPages: pages => set(() => ({ totalPages: pages }))
}))