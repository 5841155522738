import { message } from 'antd'
import dayjs from 'dayjs'
import { IWbsExcel } from 'entities/wbs'
import { IIssuesFilters } from 'interfaces/IIssue'
import { WbsContainerApi } from 'pages/unmodelPage/wbsContainer'
import { API } from './ApiService'

type TExportingObjectName = 'Issue' | 'Ordinance'

export interface IRegistryColumns {
	number: number
	columnNameRu: string
}

interface IHocFineReportQuery {
	projectIds?: string[]
	contractorId?: string
	from?: Date
	to?: Date
}

export const exportService = {
	async getColumns(exportingObjectName: TExportingObjectName = 'Issue') {
		return await API<IRegistryColumns[]>({
			url: '/v1/export',
			method: 'get',
			params: { exportingObjectName }
		}).then(async response => response.data)
	},

	async exportIssuesToXLSX({
		columns,
		filters
	}: {
		columns: number[] | undefined
		filters: IIssuesFilters | undefined
	}) {
		if (filters?.dueDate !== undefined && filters?.dueDate !== null) {
			filters = {
				...filters,
				dueDate: dayjs(filters?.dueDate).format('YYYY-MM-DD')
			}
		}
		if (filters?.createdAt !== undefined && filters?.createdAt !== null) {
			filters = {
				...filters,
				createdAt: dayjs(filters?.createdAt).format('YYYY-MM-DD')
			}
		}
		return await API({
			url: '/v1/export/exportIssueToExcel',
			method: 'post',
			params: { ...filters },
			data: columns,
			responseType: 'blob'
		}).then(async response => response)
	},

	async exportHOCToExcel(settings: any) {
		return await API({
			url: '/v1/export/exportHOCToExcel',
			method: 'get',
			params: { ...settings },
			responseType: 'blob'
		}).then(async response => response)
	},
	async exportHOCFineToExcel(settings?: IHocFineReportQuery) {
		return await API({
			url: '/v1/export/exportHOCFineToExcel',
			method: 'get',
			params: { ...settings },
			responseType: 'blob'
		}).then(async response => response)
	},
	async exportWbsToExcel(data: IWbsExcel, type: 'wbs' | 'local', withPrice?: boolean) {
		if (data) {
			await WbsContainerApi.exportToExcel(data.id, type, withPrice)
				.then(response => {
					const href = URL.createObjectURL(response.data)
					const link = document.createElement('a')
					link.href = href
					link.setAttribute(
						'download',
						`${data?.name} от ${dayjs(data.createdAt).format('DD.MM.YYYY')}.xlsx`
					)
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
					URL.revokeObjectURL(href)
				})
				.catch(() => message.error('Во время загрузки файла произошла ошибка'))
		}
	}
}
