import { BrowserRouter } from 'react-router-dom'
import DashboardBody from './DashboardBody'
import DashboardHeader from './DashboardHeader'

export default function Dashboard() {
  return (
    <BrowserRouter>
      <div className="dashboard">
        <DashboardHeader />
        <DashboardBody />
      </div>
    </BrowserRouter>
  )
}
