import { Button } from 'antd'
import { FC, useState } from 'react'
import { IssueReportModal } from './issueReport.modal'

interface IProps {}

export const IssueReport: FC<IProps> = () => {
	const [show, setShow] = useState(false)
	return (
		<>
			<IssueReportModal show={show} onClose={() => setShow(false)} />
			<Button onClick={() => setShow(true)}>Выгрузка реестра замечаний</Button>
		</>
	)
}
