import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { IReportNotifications } from "interfaces/INotification"
import { IReportNotificationsFilter } from "pages/adminPage/ReportNotificationsModal"

const stateReportNotification: IReportNotifications = {
    notificationType: '',
    user: '',
    email: '',
    createdAt: '',
    object: '',
    project: '',
    contractor: '',
    contactPerson: ''
}

const reportNotificationStateList = {
    currentPage: 1,
    totalItems: 0
}

const sortReportNotification = {
    desc: true
}

const reportNotificationFilters: IReportNotificationsFilter = {}

interface IFiltersReportNotification {
    filters: IReportNotificationsFilter
}

const reportNotificationSlice = createSlice({
    name: 'reportNotification',
    initialState: {
        init: stateReportNotification,
        dto: stateReportNotification,
        listState: reportNotificationStateList,
        filters: reportNotificationFilters,
        sortSate: sortReportNotification
    },
    reducers: {
        setCurrentReportNotification(state, action: PayloadAction<IReportNotifications>) {
            state.dto = action.payload
        },
        setCurrentPage(state, action: PayloadAction<number>) {
			state.listState.currentPage = action.payload
		},
		setTotalItems(state, action: PayloadAction<number>) {
			state.listState.totalItems = action.payload
		},
        setCurrentReportNotificationFilters(state, action: PayloadAction<IFiltersReportNotification>) {
			state.filters = action.payload.filters
		},
        setSortReportNotification(state, action: PayloadAction<boolean>) {
            state.sortSate.desc = action.payload
        },
        resetReportNotification(state) {
            state.filters = reportNotificationFilters
            state.listState = reportNotificationStateList
        }
    }
})

export const {
    setCurrentReportNotification,
    setCurrentPage,
    setTotalItems,
    setCurrentReportNotificationFilters,
    setSortReportNotification,
    resetReportNotification
} = reportNotificationSlice.actions

export default reportNotificationSlice.reducer