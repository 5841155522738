import { useQuery } from "@tanstack/react-query"
import { reportService } from "services/ReportService"
import { setTotalItems } from "store/reportNotificationSlice"
import { useAppDispatch, useAppSelector } from "./appReduxHook"

export const useReportNotification = () => {
    const {project} = useAppSelector(state => state.environment)
	const {filters} = useAppSelector(state => state.reportNotification)
	const {currentPage} = useAppSelector(state => state.reportNotification.listState)
    const {desc} = useAppSelector(state => state.reportNotification.sortSate)
    const dispatch = useAppDispatch()
    return useQuery({
        queryKey: ['reportNotification', filters, currentPage, project.id],
        queryFn: async () => {
            const reportNotificationList = await reportService.getReportNotification({
				filters: filters,
				offset: currentPage! > 1 ? currentPage! * 20 - 20 : 0,
                desc
            }).then(data => data.data)
            dispatch(setTotalItems(reportNotificationList.totalItems))
            return reportNotificationList
        },
		refetchOnWindowFocus: false
    })
}