import { useQuery } from '@tanstack/react-query'
import { RegulatoryRequestApi } from '..'

export const useRegulatoryRequestTypeSections = () => {
	return useQuery({
		queryKey: ['regulatoryRequestTypeSections'],
		queryFn: async () => {
			return await RegulatoryRequestApi.getTypeSection().then(data => data)
		},
		refetchOnWindowFocus: false
	})
}
