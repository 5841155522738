import { useQuery } from '@tanstack/react-query'
import { getDiagram } from 'services/DiagramService'
import { useAppSelector } from './appReduxHook'

interface IIssueAnalytics {
	CloseIssues: number
	FixOut: number
	OpenIssues: number
	ReadyForControlIssues: number
	TotalIssues: number
	FixedOutOpen: number
	fixedOutClosed: number
}

export const useIssueAnalytics = () => {
	const diagramsContractor = useAppSelector(state => state.environment.diagramsContractor)
	return useQuery({
		queryKey: ['issueAnalytics', diagramsContractor],
		queryFn: async () => {
			return await getDiagram('issues', diagramsContractor).then(data => data as IIssueAnalytics)
		}
	})
}
