import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IContainer } from 'interfaces/IContainer'
import { IUnmodelFilters } from 'pages/unmodelPage/UnmodelFilters'

const stateAsm: IContainer = {
	id: '',
	createdDate: '',
	author: null,
	redactors: [],
	constructionObject: null,
	name: '',
	ready: false,
	updateDate: null,
	typeSections: {
		id: '',
		isDeleted: false,
		name: ''
	},
	children: [],
	mainParent: null,
	parent: null
}

const asmStateList = {
	currentPage: 1,
	totalItems: 0
}

export interface ISort {
	name?: number
	typeSection?: number
	createdDate?: number
	author?: number
	ready?: number
}

const asmSort: ISort = {
	name: undefined,
	typeSection: undefined,
	createdDate: undefined,
	author: undefined,
	ready: undefined
}

const asmFilters: IUnmodelFilters = {}

interface IFiltersAsm {
	filters: IUnmodelFilters
}

const asmSlice = createSlice({
	name: 'asm',
	initialState: {
		init: stateAsm,
		dto: stateAsm,
		listState: asmStateList,
		sort: asmSort,
		filters: asmFilters
	},
	reducers: {
		setCurrentAsm(state, action: PayloadAction<IContainer>) {
			state.dto = action.payload
		},
		setCurrentPage(state, action: PayloadAction<number>) {
			state.listState.currentPage = action.payload
		},
		setTotalItems(state, action: PayloadAction<number>) {
			state.listState.totalItems = action.payload
		},
		setCurrentAsmSort(state, action: PayloadAction<ISort>) {
			state.sort = action.payload
		},
		setCurrentAsmFilters(state, action: PayloadAction<IFiltersAsm>) {
			state.filters = action.payload.filters
		},
		resetAsm(state) {
			state.filters = asmFilters
			state.sort = asmSort
			state.listState = asmStateList
		}
	}
})

export const {
	setCurrentAsm,
	setCurrentPage,
	setTotalItems,
	setCurrentAsmSort,
	setCurrentAsmFilters,
	resetAsm
} = asmSlice.actions

export default asmSlice.reducer
