import { FC } from 'react'
import { IIssuesReportData } from 'interfaces/ILightReport'
import AllIssuesTable from './AllIssuesTable'
import IssuesTable from './IssuesTable'
import { Divider } from 'antd/lib'

interface IProps {
	issues: IIssuesReportData | undefined
	onOpenIssue: (issueId: string) => void
}

const MKDTable: FC<IProps> = ({ issues, onOpenIssue }) => {
	return (
		<>
			<Divider />
			{/* <IssuesTable data={issues?.critical} onOpenIssue={onOpenIssue} /> */}
			{!!issues?.notCritical.length && <AllIssuesTable data={issues?.notCritical} onOpenIssue={onOpenIssue} />}
		</>
	)
}

export default MKDTable
