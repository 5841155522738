import { create } from "zustand";

interface IExpandedRowKeys {
    expandedRowKeys: string[]
    setExpandedRowKeys: (keys:string[]) => void
}

const initStateFilters = {}

export const useSectionsState = create<IExpandedRowKeys>(set => ({
    expandedRowKeys: [],
    setExpandedRowKeys: (expandedRowKeys:string[]) => set({expandedRowKeys})
}))