import { Tabs } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import IssuesPage from 'pages/issuesPage'
import OrdinancePage from 'pages/ordinancePage'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { FineRegistry } from 'widgets/fine/ui/fine-registry'
interface IAcceptancePageProps {
	tab: string
}

const AcceptancePage: FC<IAcceptancePageProps> = ({ tab }) => {
	const { permissions } = useAppSelector(state => state.environment)
	const checkPermissions = useCheckPermissions()
	const { id: elemId } = useParams()
	const navigate = useNavigate()
	const [activeTab, setActiveTab] = useState(tab)
	const onTabChange = (value: string) => {
		setActiveTab(value)
		navigate('/control/' + value)
	}

	useEffect(() => {
		navigate('/control/' + tab + `${elemId ? '/' + elemId : ''}`)
	}, [tab])

	return (
		<Tabs
			style={{ width: '100%' }}
			activeKey={activeTab}
			size="large"
			type="line"
			tabBarGutter={16}
			onChange={onTabChange}
			destroyInactiveTabPane={true}
			items={[
				{
					label: 'Замечания',
					key: 'issues',
					children: <IssuesPage />,
					disabled: !checkPermissions([PERMISSIONS.IssueView])
				},
				{
					label: 'Предписания',
					key: 'ordinance',
					children: <OrdinancePage />,
					disabled: !checkPermissions([PERMISSIONS.OrdinanceView])
				},
				{
					label: 'Штрафы',
					key: 'fines',
					children: <FineRegistry />,
					disabled: !checkPermissions([PERMISSIONS.OrdinanceFine])
				}
			]}
		/>
	)
}
export default AcceptancePage
