import {
	DeleteOutlined,
	DownloadOutlined,
	EyeOutlined,
	FileGifOutlined,
	FileImageOutlined,
	FileJpgOutlined,
	FilePdfOutlined,
	LoadingOutlined,
	PlusOutlined
} from '@ant-design/icons'
import { Button, Col, Image, Row, Spin, Table, Tooltip, message } from 'antd'
import { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { ICurrentChecklistItem } from 'interfaces/IChecklist'
import { IPermission } from 'interfaces/IPermissions'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteFile, downloadFile } from 'services/AdminService'
import {
	getChecklist,
	getChecklistItemAttachment,
	getChecklistItemAttachments
} from 'services/ChecklistsService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setCurrentChecklist } from 'store/checklistsSlice'

interface IProps {
	item: ICurrentChecklistItem
	allDisabled: boolean
	onItemUpdate: (item: ICurrentChecklistItem, answer: number | null, issueIndex?: number) => void
	onAddFile: (e: any, itemId: string) => void
	// checkPermissions: (currentPermits: string[]) => boolean
}
export interface IItemFileList {
	id: string
	objectId: string
	name: string
	extension: string
	fileSize: number
	contentType: string
	description: string | null
	uploadDate: Date
	url: string
	blob: string | undefined
}

const SectionFiles: FC<IProps> = ({
	item,
	allDisabled,
	onItemUpdate,
	onAddFile
	// checkPermissions
}) => {
	const checkPermissions = useCheckPermissions()
	const dispatch = useDispatch()
	const [itemFilesList, setItemFilesList] = useState<IItemFileList[]>([])
	const [itemFilesData, setItemFilesData] = useState<IItemFileList[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [disableBtn, setDisableBtn] = useState(true)
	const { currentChecklist } = useAppSelector(state => state.checklists.init)
	const isCreated = currentChecklist?.id === '00000000-0000-0000-0000-000000000000'
	const currentChecklistId = currentChecklist!?.id
	const templateType = currentChecklist!?.templateType
	const isAdmin = useAppSelector(state => state.environment.permissions)?.some(
		(perm: IPermission) => perm.name === 'ProjectAdmin'
	)
	const ImageIcons: Record<string, ReactNode> = {
		pdf: <FilePdfOutlined style={{ color: 'darkOrange', marginRight: 5 }} />,
		jpg: <FileJpgOutlined style={{ color: 'red', marginRight: 5 }} />,
		jpeg: <FileJpgOutlined style={{ color: 'red', marginRight: 5 }} />,
		png: <FileImageOutlined style={{ color: '#4173e6', marginRight: 5 }} />,
		gif: <FileGifOutlined style={{ color: 'grey', marginRight: 5 }} />
	}

	const itemFilesColumns: ColumnsType<any> = [
		{
			dataIndex: 'image',
			width: '5%',
			render: (_, record) =>
				record.contentType === 'application/pdf' ? (
					<>
						{ImageIcons[record.extension.replace('.', '').toLowerCase()]}
						{record.name.length > 25 ? record.name.slice(0, 24) + '...' : record.name}
					</>
				) : (
					<>
						{ImageIcons[record.extension.replace('.', '').toLowerCase()]}
						<Image
							width={50}
							src={record.blob}
							alt={record.name.length > 25 ? record.name.slice(0, 24) + '...' : record.name}
							preview={{
								mask: <EyeOutlined />
							}}
						/>
					</>
				)
		},
		{
			dataIndex: 'name',
			width: '28%',
			render: (_, record) => (
				<Row>
					<Col
						style={{
							maxWidth: '200px',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden'
						}}
					>
						{record.name.replace(record.extension, '')}
					</Col>
				</Row>
			)
		},
		{
			dataIndex: 'uploadDate',
			width: '12%',
			render: (_, record) => dayjs(record.uploadDate).format('DD.MM.YYYY')
		},
		{
			dataIndex: 'fileSize',
			width: '10%',
			render: (_, record) => formatBytes(record.fileSize)
		},
		{
			width: '10%',
			render: (_, record) => (
				<Row gutter={8} wrap={false}>
					<Col>
						<Tooltip placement="topLeft" title="Скачать">
							<Button
								shape="circle"
								size="middle"
								onClick={() =>
									downloadFile(record.id)
										.then(response => {
											const href = URL.createObjectURL(response.data)
											const link = document.createElement('a')
											link.href = href
											link.setAttribute('download', record.name.replace(record.extension, ''))
											document.body.appendChild(link)
											link.click()
											document.body.removeChild(link)
											URL.revokeObjectURL(href)
										})
										.catch(() => message.error('Во время загрузки файла произошла ошибка'))
								}
								icon={<DownloadOutlined rev={undefined} />}
							/>
						</Tooltip>
					</Col>
					<Col>
						{isAdmin && (
							<Tooltip placement="topLeft" title="Удалить">
								<Button
									shape="circle"
									size="middle"
									danger
									onClick={() => onDelete(record.id)}
									icon={<DeleteOutlined rev={undefined} />}
								/>
							</Tooltip>
						)}
					</Col>
				</Row>
			)
		}
	]

	const onDelete = async (id: string) => {
		if (isAdmin) {
			if (item.answer === 1 && item.attachments.length === 1 && item.checkboxFile) {
				onItemUpdate(item, null)
				await deleteFile(id).then(() => {
					const temp = itemFilesList.filter(file => file.id !== id)
					setItemFilesList(temp)
				})
			} else {
				await deleteFile(id).then(() => {
					const temp = itemFilesList.filter(file => file.id !== id)
					setItemFilesList(temp)
				})
				if (currentChecklistId) {
					await getChecklist(currentChecklistId).then(res =>
						dispatch(setCurrentChecklist({ ...res, period: dayjs(res.period) }))
					)
				}
			}
		} else message.error('У вас нет прав на удаление файлов')
	}

	const formatBytes = (bytes: number, decimals = 2) => {
		if (!+bytes) return '0 Bytes'

		const k = 1024
		const dm = decimals < 0 ? 0 : decimals
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

		const i = Math.floor(Math.log(bytes) / Math.log(k))

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
	}

	const blobToBase64 = async (id: string) => {
		const blob: Blob = await getChecklistItemAttachment(id).then(data => data)
		if (blob) {
			return new Promise<string>((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(blob)
				reader.onload = () => resolve(reader.result!.toString())
				reader.onerror = error => reject(error)
			})
		}
	}
	const getAttachments = async (itemId: string) => {
		setIsLoading(true)
		await getChecklistItemAttachments(itemId)
			.then(data => {
				setItemFilesData(data)
			})
			.catch(error => console.log(error))
			.finally(() => setTimeout(() => setIsLoading(false), 0))
	}

	const checkIsDisabled = () => {
		const UKS_NDC = checkPermissions([PERMISSIONS.AttachFilesForChecklistUksNdc])
		const UK_KC = checkPermissions([PERMISSIONS.AttachFilesForChecklistUkKc])

		if (UKS_NDC && UK_KC) {
			setDisableBtn(false)
		}
		if (UKS_NDC && !UK_KC) {
			setDisableBtn(templateType !== 0)
		}
		if (!UKS_NDC && UK_KC) {
			setDisableBtn(templateType !== 1)
		}
	}

	useEffect(() => {
		if (allDisabled === false) checkIsDisabled()
	}, [templateType, allDisabled])

	useEffect(() => {
		console.log('item.id', item.id)
		if (!isCreated || (item.id !== null && item.id !== '00000000-0000-0000-0000-000000000000')) {
			getAttachments(item.id)
		}
	}, [item.attachments])

	useEffect(() => {
		const getBlobs = async () => {
			const blobList: IItemFileList[] = await Promise.all(
				itemFilesData.map(async (file): Promise<IItemFileList> => {
					let blob: string | undefined = ''
					await blobToBase64(file.id).then(data => {
						blob = data
					})
					return { ...file, blob: blob }
				})
			)
			setItemFilesList(blobList)
		}
		getBlobs()
	}, [itemFilesData])

	return (
		<Col style={{ position: 'relative' }}>
			<>
				{itemFilesList.length > 0 && (
					<Table
						className="checklist-files-table"
						size="small"
						pagination={false}
						showHeader={false}
						dataSource={itemFilesList}
						columns={itemFilesColumns}
						bordered
					/>
				)}
				<Button
					type="primary"
					onClick={(e: React.MouseEvent<HTMLElement>) => onAddFile(e!, item.id)}
					style={{ width: '100%', marginTop: 16 }}
					icon={<PlusOutlined />}
					disabled={isCreated ? true : isAdmin ? false : allDisabled ? true : disableBtn}
				>
					Добавить файл
				</Button>
			</>
			{isLoading && (
				<Row
					justify="center"
					style={{
						height: '24px',
						position: 'absolute',
						top: 'calc(50% - 15px)',
						left: 'calc(50% - 12px)'
					}}
				>
					<Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
				</Row>
			)}
		</Col>
	)
}

export default SectionFiles
