import { Modal, Typography } from 'antd'
import { FC } from 'react'

const { Text } = Typography

interface IModalProps {
	title: string
	description: string
	opened: boolean
	onOk: () => void
	onCancel: () => void
}

const AttentionModal: FC<IModalProps> = ({ title, description, opened, onOk, onCancel }) => {
	return (
		<Modal
			title={<Text type="danger">{title}</Text>}
			centered
			open={opened}
			zIndex={1201}
			okText="Подтвердить"
			cancelText="Отмена"
			okButtonProps={{ danger: true }}
			onOk={onOk}
			onCancel={onCancel}
		>
			{description}
		</Modal>
	)
}

export default AttentionModal
