import { Button, Col, DatePicker, Drawer, Form, List, Row, Select, Typography } from 'antd'
import { FC, useEffect, useState } from 'react'

import { IConstructionObject } from 'interfaces/IConstructionObject'
import { IContract } from 'interfaces/IContract'
import { IContractor } from 'interfaces/IEnvironment'
import { IOrdinanceFilters, IStatuses } from 'interfaces/IOrdinance'

import locale from 'antd/es/date-picker/locale/ru_RU'
import 'dayjs/locale/ru'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { getAuthors } from 'services/OrdinanceService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setFilterOrdinance, setTotalPagesOrdinance } from 'store/ordinanceSlice'

const { Option } = Select
const { Text } = Typography

interface IFilterItem {
	title: string | React.ReactNode
	formItemName: string
	list: IStatuses[] | IContract[] | IContractor[] | IConstructionObject[] | IApprovedList[] | null
	date: boolean
	disabled: boolean
}

interface IApprovedList {
	id: string
	name: string
}

interface IOrdinanceFiltersProps {
	open: boolean
	onClose: () => void
}

const OrdinanceFilters: FC<IOrdinanceFiltersProps> = ({ open, onClose }) => {
	const checkPermissions = useCheckPermissions()
	const dispatch = useAppDispatch()
	const [ordinanceFiltersForm] = Form.useForm<IOrdinanceFilters>()
	const [isLoading, setIsLoading] = useState(false)
	const {
		user: currentUser,
		ordinanceStatuses: statuses,
		project,
		contractors,
		contracts,
		permissions
	} = useAppSelector(state => state.environment)
	const ordinanceFilters = useAppSelector(state => state.ordinance.filters)
	const [subContractors, setSubContractors] = useState<IContractor[]>([])
	const [constructionObjects, setConstructionObjects] = useState<IConstructionObject[]>([])
	const [authors, setAuthors] = useState([])
	const allCompanies = checkPermissions([PERMISSIONS.AllCompanies])
	const isApprovedList = [
		{
			id: 'approved',
			name: 'Да'
		},
		{
			id: 'deleted',
			name: 'Нет'
		}
	]

	const isAutoCreatedList = [
		{ id: 'auto', name: 'Да' },
		{ id: 'hand', name: 'Нет' }
	]

	const isOrdinanceFine = [
		{ id: 'withFine', name: 'Да' },
		{ id: 'withoutFine', name: 'Нет' }
	]

	const filterList: IFilterItem[] = [
		{
			title: 'Статус',
			formItemName: 'ordinanceStatusId',
			list: statuses,
			date: false,
			disabled: false
		},
		{
			title: 'Генподрядчик',
			formItemName: 'contractorId',
			list: [...contractors!]!.sort((a, b) => a.name.localeCompare(b.name))!,
			date: false,
			disabled: !checkPermissions([PERMISSIONS.AllCompanies])
		},
		{
			title: 'Субподрядчик',
			formItemName: 'subContractorId',
			list: subContractors!?.sort((a, b) => a.name.localeCompare(b.name))!,
			date: false,
			disabled: false
		},
		{
			title: 'Договор',
			formItemName: 'contractId',
			list: [...contracts!]!?.filter(contract =>
				typeof ordinanceFiltersForm.getFieldValue('contractorId') !== 'undefined'
					? contract.contractor?.id === ordinanceFiltersForm.getFieldValue('contractorId') &&
					  contract.mainContract === null
					: contract.mainContract === null
			),
			date: false,
			disabled: false
		},
		{ title: 'Автор', formItemName: 'createdById', list: authors, date: false, disabled: false },
		{ title: 'Дата создания', formItemName: 'createdAt', list: null, date: true, disabled: false },
		{
			title: 'Срок по предписанию',
			formItemName: 'dateTo',
			list: null,
			date: true,
			disabled: false
		},
		{
			title: 'Согласовано',
			formItemName: 'isApproved',
			list: isApprovedList,
			date: false,
			disabled: false
		},
		{
			title: 'Штраф',
			formItemName: 'ordinanceFine',
			list: isOrdinanceFine,
			date: false,
			disabled: false
		},
		{
			title: 'Автоматическое создание',
			formItemName: 'isAutoCreated',
			list: isAutoCreatedList,
			date: false,
			disabled: false
		}
	]

	const [filterItems, setFilterItems] = useState<IFilterItem[]>(filterList)

	useEffect(() => {
		ordinanceFiltersForm.setFieldsValue({ ...ordinanceFilters })
	}, [])

	// const init = () => {
	// 	getSubContractors(contracts!)
	// 	getConstructionObjects(contracts!)
	// }

	// useEffect(() => {
	// 	init()
	// }, [])

	useEffect(() => {
		if (!allCompanies) {
			checkAllCompanies()
		}
	}, [open])

	const getOrdinanceAuthors = async () => {
		await getAuthors(project?.id!).then(response => {
			setAuthors(
				response.map((owner: { ownerId: string; name: string }) => ({
					id: owner.ownerId,
					name: owner.name
				}))
			)
		})
	}

	const checkAllCompanies = () => {
		const cId = currentUser?.contractorId!
		ordinanceFiltersForm.setFieldValue('contractorId', cId)
		onChangeFilter('contractorId', cId)
	}

	useEffect(() => {
		setFilterItems(filterList)
	}, [statuses, contractors, subContractors, constructionObjects, authors])

	useEffect(() => {
		getSubContractors(contracts!)
		getConstructionObjects(contracts!)
	}, [contracts])

	useEffect(() => {
		// ordinanceFiltersForm.resetFields()
		getOrdinanceAuthors()
	}, [project])

	const getSubContractors = (contractList: IContract[], filters?: IOrdinanceFilters) => {
		if (contractList!.length > 0) {
			const objects: IContract[] = filters
				? contractList!?.filter(
						(contract: IContract) => contract!?.contractor!?.id! === filters!?.contractorId!
				  )
				: contractList!
			let subs: IContractor[] = []
			objects
				.flatMap((contract: IContract) => contract.subContractor)
				.forEach(obj => {
					if (obj !== null)
						if (!subs.some(item => item?.id! === obj?.id!)) {
							subs.push(obj)
						}
				})

			setSubContractors(subs!?.sort((a, b) => a.name.localeCompare(b.name)))
		}
	}

	const getConstructionObjects = (
		contracts: IContract[],
		contractorId?: string,
		contractId?: string
	) => {
		let constructions: IConstructionObject[] = []
		if (checkPermissions([PERMISSIONS.AllCompanies]) === false) {
			contractorId = currentUser!?.contractorId!
		}
		const contractsList =
			typeof contractorId !== 'undefined'
				? typeof contractId !== 'undefined'
					? contracts.filter(
							(contract: IContract) =>
								contract!?.contractor!?.id === contractorId && contract!?.id! === contractId
					  )
					: contracts.filter((contract: IContract) => contract!?.contractor!?.id! === contractorId)
				: typeof contractId !== 'undefined'
				? contracts.filter((contract: IContract) => contract!?.id! === contractId)
				: contracts

		contractsList!
			.flatMap((contract: IContract) => contract.constructionObjects)
			.forEach(obj => {
				if (obj !== null)
					if (!constructions.some(item => item?.id! === obj?.id!)) {
						constructions.push(obj)
					}
			})

		setConstructionObjects(constructions!?.sort((a, b) => a.name.localeCompare(b.name)))
	}

	const onChangeFilter = (field: string, value: any) => {
		const filters: IOrdinanceFilters = ordinanceFiltersForm.getFieldsValue()
		if (field === 'contractorId') {
			if (allCompanies) {
				ordinanceFiltersForm.setFieldValue('contractId', undefined)
				ordinanceFiltersForm.setFieldValue('subContractorId', undefined)
				ordinanceFiltersForm.setFieldValue('contactPersonId', undefined)
			}
			if (typeof filters.contractorId !== 'undefined') {
				getSubContractors(contracts!, filters)
			} else {
				getSubContractors(contracts!)
			}
		}
		setFilterItems(filterList)
	}

	const onSetFilters = (filters: IOrdinanceFilters, show: boolean) => {
		if (filters) {
			dispatch(
				setFilterOrdinance({
					filters: {
						...ordinanceFilters,
						ordinanceStatusId: filters.ordinanceStatusId,
						contractorId: filters.contractorId,
						contractId: filters.contractId,
						createdById: filters.createdById,
						createdAt: filters.createdAt,
						dateTo: filters.dateTo,
						isApproved: filters.isApproved,
						subContractorId: filters.subContractorId,
						isAutoCreated: filters.isAutoCreated,
						ordinanceFine: filters.ordinanceFine
					}
				})
			)
			if (show) {
				onClose()
			}
		}
		dispatch(setTotalPagesOrdinance(1))
	}

	const onConfirmFilters = () => {
		onSetFilters(ordinanceFiltersForm.getFieldsValue(), true)
	}

	const onResetFilters = () => {
		ordinanceFiltersForm.resetFields()
		ordinanceFiltersForm.submit()
		// dispatch(resetOrdinancesState())
		// onClose()
	}

	return (
		<Drawer
			open={open}
			onClose={onClose}
			placement="left"
			title="Фильтры"
			className="at-drawer-left"
			footer={
				<Row gutter={8} justify="end">
					<Col>
						<Button ghost danger onClick={onResetFilters}>
							Сбросить
						</Button>
					</Col>
					<Col>
						<Button type="primary" htmlType="submit" onClick={() => ordinanceFiltersForm.submit()}>
							Применить
						</Button>
					</Col>
				</Row>
			}
		>
			<Form form={ordinanceFiltersForm} name="ordinanceFiltersForm" onFinish={onConfirmFilters}>
				<List
					loading={isLoading}
					dataSource={filterItems}
					renderItem={(item, index) => (
						<Row align="middle" gutter={8}>
							<Col style={{ flex: '0 0 100%' }}>
								<Text strong>{item.title}:</Text>
							</Col>
							<Col style={{ flex: '1 0 100%' }}>
								<Form.Item key={index} name={item.formItemName}>
									{!item.date ? (
										<Select
											loading={isLoading}
											showSearch={item.formItemName !== 'isApproved'}
											allowClear
											optionFilterProp="children"
											disabled={item.disabled || isLoading || item.list?.length === 0}
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? '')
													.toString()
													.toLowerCase()
													.localeCompare((optionB?.label ?? '').toString().toLowerCase())
											}
											style={{ width: '100%' }}
											placeholder="Не выбран"
											onChange={value => onChangeFilter(item.formItemName, value)}
										>
											{item.list!?.map(option => (
												<Option
													key={option.id}
													value={
														item.formItemName === 'isApproved'
															? option.id === 'approved'
																? true
																: false
															: item.formItemName === 'isAutoCreated'
															? option.id === 'auto'
																? true
																: false
															: item.formItemName === 'ordinanceFine'
															? option.id === 'withFine'
																? true
																: false
															: option.id
													}
												>
													{option.name}
												</Option>
											))}
										</Select>
									) : (
										<DatePicker
											locale={locale}
											style={{ width: '100%' }}
											format={'DD.MM.YYYY'}
											onChange={(date, dateSting) => onChangeFilter(item.formItemName, date!)}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>
					)}
				/>
			</Form>
		</Drawer>
	)
}
export default OrdinanceFilters
