import { useQueryClient } from '@tanstack/react-query'
import {
	Badge,
	Button,
	Card,
	DatePicker,
	Descriptions,
	Divider,
	Drawer,
	Typography,
	message
} from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import minMax from 'dayjs/plugin/minMax'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IIssue } from 'interfaces/IIssue'
import { FC, useEffect, useState } from 'react'
import { issuesElementTranslate } from 'services/IssuesService'
import { addOrdinanceByIssues } from 'services/OrdinanceService'
import { setDtoValue } from 'store/ordinanceSlice'
dayjs.extend(minMax)

const { Text } = Typography
interface IIssueOrdinanceProps {
	open: boolean
	issueRows: IIssue[]
	onClose: (mustUpdate: boolean) => void
}

const IssueOrdinance: FC<IIssueOrdinanceProps> = ({ open, issueRows, onClose }) => {
	const queryClient = useQueryClient()
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useAppDispatch()
	const ordinanceDto = useAppSelector(state => state!?.ordinance!?.dto!)
	const environment = useAppSelector(state => state!?.environment!)
	const { allProjects } = useAppSelector(state => state!?.environment)
	const { project } = environment
	const maxDate = dayjs.max(issueRows?.map(r => dayjs(r.dueDate)))

	useEffect(() => {
		const init = async () => {
			queryClient.invalidateQueries({ queryKey: ['appShared'] })
			onPropChange(
				'ordinanceStatusId',
				environment.ordinanceStatuses?.find(status => status.identityName === 'NonEliminated')
					?.id! || ''
			)
			onPropChange('projectId', project!?.id)
			onPropChange('project1CId', project?.project1C!?.id)
			onPropChange('contractorId', issueRows[0]?.contractor!?.id)
			onPropChange('subContractorId', issueRows[0]?.subcontractor!?.id)
			onPropChange('violationTypeId', issueRows[0]?.violationType!?.id)
			onPropChange('dateTo', maxDate?.format('YYYY-MM-DD') ?? '')
			onPropChange('contractId', issueRows[0].contract!?.id! ?? null)
			onPropChange('orgId', issueRows[0].contract!?.orgId! ?? null)
			onPropChange('contactPersonId', issueRows[0].contactPerson!?.id! ?? null)
			onPropChange('buildingPermitId', issueRows[0].buildingPermit!?.id! ?? null)
			const issuesIds = issueRows.map(issue => issue.id!)
			dispatch(setDtoValue({ prop: 'issues', value: issuesIds }))
		}
		open && init()
	}, [open])

	const onPropChange = (prop: string, value: string | string[] | null) => {
		dispatch(setDtoValue({ prop, value }))
	}

	const onOrdinanceConfirm = async () => {
		setIsLoading(true)
		await addOrdinanceByIssues(ordinanceDto)
			.then(() => {
				message.success(`Предписание создано`)
				onClose(true)
			})
			.catch((err: AxiosError) => message.error(`При сохранении произошла ошибка\n ${err.message}`))
			.finally(() => setIsLoading(false))
	}

	return (
		<Drawer
			destroyOnClose={true}
			open={open}
			onClose={() => onClose(false)}
			width={window.outerWidth < 1300 ? '75vw' : '50vw'}
			title="Предпросмотр предписания"
			styles={{ body: { backgroundColor: '#f2f2f2' } }}
			extra={
				<Button
					loading={isLoading}
					data-attr="createOrdinance"
					htmlType="submit"
					type="primary"
					onClick={() => onOrdinanceConfirm()}
				>
					Сформировать
				</Button>
			}
		>
			<Divider children="Проект" orientation="left" style={{ marginTop: 0 }} />
			<Card className="at-workcard">
				<Descriptions
					layout="horizontal"
					column={12}
					bordered
					className="at-descriptions"
					labelStyle={{
						fontWeight: 'bold',
						width: '20%',
						alignItems: 'center',
						padding: '2px 4px'
					}}
					contentStyle={{
						width: '80%',
						padding: '4px 8px'
					}}
				>
					<Descriptions.Item label="Проект:" span={12}>
						<Text style={{ paddingLeft: '6px' }}>{environment!?.project!?.name!}</Text>
					</Descriptions.Item>

					<Descriptions.Item label="Тип:" span={12}>
						<Text style={{ paddingLeft: '6px' }}>
							{issuesElementTranslate(issueRows[0]!?.issueType!?.name!)}
						</Text>
					</Descriptions.Item>

					<Descriptions.Item label="Подтип:" span={12}>
						<Text style={{ paddingLeft: '6px' }}>{issueRows[0]!?.issueSubType!?.name!}</Text>
					</Descriptions.Item>

					<Descriptions.Item label="Тип нарушения:" span={12}>
						<Text style={{ paddingLeft: '6px' }}>{issueRows[0].violationType!?.name}</Text>
					</Descriptions.Item>

					<Descriptions.Item label="Генподрядчик:" span={12}>
						<Text style={{ paddingLeft: '6px' }}>{issueRows[0]!?.contractor!?.name!}</Text>
					</Descriptions.Item>

					<Descriptions.Item label="Договор" span={12}>
						<Text style={{ paddingLeft: '6px' }}>{issueRows[0]!?.contract!?.name!}</Text>
					</Descriptions.Item>

					<Descriptions.Item label="Субподрядчик:" span={12}>
						<Text style={{ paddingLeft: '6px' }}>{issueRows[0]!?.subcontractor!?.name!}</Text>
					</Descriptions.Item>

					<Descriptions.Item label="Разрешение на строительство:" span={12}>
						<Text style={{ paddingLeft: '6px' }}>{issueRows[0]!?.buildingPermit!?.name!}</Text>
					</Descriptions.Item>

					<Descriptions.Item label="Контактное лицо:" span={12}>
						<Text style={{ paddingLeft: '6px' }}>{issueRows[0]!?.contactPerson!?.name!}</Text>
					</Descriptions.Item>

					<Descriptions.Item label="Фаза" span={12}>
						<Text style={{ paddingLeft: '8px' }}>
							{allProjects.find(ap => ap.id === project?.id)?.phase ?? '-'}
						</Text>
					</Descriptions.Item>

					<Descriptions.Item label="Корпус" span={12}>
						<Text style={{ paddingLeft: '8px' }}>
							{allProjects.find(ap => ap.id === project?.id)?.building ?? '-'}
						</Text>
					</Descriptions.Item>

					<Descriptions.Item label="Руководитель строительства" span={12}>
						<Text style={{ paddingLeft: '8px' }}>
							{allProjects.find(ap => ap.id === project?.id)?.responsibleManager?.name ?? '-'}
						</Text>
					</Descriptions.Item>

					<Descriptions.Item label="Срок по предписанию:" span={12}>
						<DatePicker
							value={dayjs(ordinanceDto.dateTo).isValid() ? dayjs(ordinanceDto.dateTo) : null}
							locale={locale}
							style={{ width: '100%', marginLeft: -8 }}
							format={'DD.MM.YYYY'}
							onChange={date => onPropChange('dateTo', date?.format('YYYY-MM-DD') ?? '')}
							allowClear={false}
							disabledDate={current => {
								let pastDate = dayjs().format('YYYY-MM-DD')
								return current && current < dayjs(pastDate, 'YYYY-MM-DD')
							}}
						/>
					</Descriptions.Item>
				</Descriptions>
			</Card>
			<Divider
				children={
					issueRows!?.length > 1 ? (
						<>
							Выбранные замечания{' '}
							<Badge count={issueRows!?.length} style={{ backgroundColor: '#52c41a' }} />
						</>
					) : (
						'Выбранное замечание'
					)
				}
				orientation="left"
			/>
			{issueRows!?.map(issue => (
				<Card className="at-workcard" key={issue!?.id!}>
					<Descriptions
						layout="horizontal"
						column={12}
						bordered
						className="at-descriptions"
						labelStyle={{
							fontWeight: 'bold',
							width: '20%',
							alignItems: 'center',
							padding: '2px 4px'
						}}
						contentStyle={{
							width: '80%',
							padding: '4px 8px'
						}}
					>
						<Descriptions.Item label="Наименование:" span={12}>
							<Text>{issue!?.title!}</Text>
						</Descriptions.Item>
						<Descriptions.Item label="Владелец:" span={12}>
							<Text>{issue!?.owner!?.name!}</Text>
						</Descriptions.Item>
						<Descriptions.Item label="Ответственный:" span={12}>
							<Text>{issue!?.contractor!?.name!}</Text>
						</Descriptions.Item>
						<Descriptions.Item label="Дата устранения:" span={12}>
							<Text>{dayjs(issue!?.dueDate!).format('DD.MM.YYYY')}</Text>
						</Descriptions.Item>
					</Descriptions>
				</Card>
			))}
		</Drawer>
	)
}
export default IssueOrdinance
