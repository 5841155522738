import { IMaintance } from 'interfaces/IMaintance'
import { create } from 'zustand'

interface MaintanceState {
	maintance: IMaintance
	isMaintance: boolean
	setMaintanceNotification: (payload: IMaintance[]) => void
	setMaintanceOnProgress: () => void
	setMaintanceFinished: () => void
}

const useMaintanceState = create<MaintanceState>(set => ({
	maintance: { date: null, description: null, startTime: null, endTime: null },
	isMaintance: false,
	setMaintanceNotification: data => {
		const date = data[data.length - 1]
		if (date?.endTime !== null) {
			function timeChecker() {
				const [hours, minutes] = date?.endTime!.split(':').map(Number) ?? [0, 0]
				const now = new Date()
				const end = new Date()
				end.setHours(hours, minutes, 0, 0)
				return now > end
			}
			if (timeChecker() !== true) {
				set({ maintance: date })
			}
		}
	},
	setMaintanceOnProgress: () => set(state => ({ isMaintance: !state.isMaintance })),
	setMaintanceFinished: () =>
		set({ maintance: { date: null, description: null, startTime: null, endTime: null } })
}))

export default useMaintanceState
