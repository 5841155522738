import { CommentOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { memo } from 'react'
import { useAdjustmentPositionCommentsState } from 'widgets/adjustment-position-comments'
import { IAdjustmentPosition } from '..'

interface IProps {
	row: IAdjustmentPosition
}

const getCommentStatus = (isClosed: boolean, hasComments: boolean) => {
	switch (true) {
		case hasComments && isClosed === false:
			return '#ff3300'
		case hasComments && isClosed === true:
			return '#4a8a48'
		default:
			return 'gray'
	}
}

export const AdjustmentPositionChat = memo(function (params: IProps) {
	const { row } = params
	return (
		<Button
			size="small"
			type="text"
			icon={
				<CommentOutlined
					style={{ color: getCommentStatus(row.isCommentsClosed, row.hasComments) }}
				/>
			}
			onClick={() =>
				useAdjustmentPositionCommentsState.setState({
					show: true,
					currentId: row.id,
					sectionId: row.containerSectionId
				})
			}
		/>
	)
})
