import {
	DeleteOutlined,
	DownloadOutlined,
	FileImageOutlined,
	FileJpgOutlined,
	FilePdfOutlined,
	LoadingOutlined,
	UploadOutlined
} from '@ant-design/icons'
import {
	Button,
	Col,
	Form,
	Modal,
	Row,
	Spin,
	Table,
	Tooltip,
	Typography,
	Upload,
	UploadFile,
	UploadProps,
	message
} from 'antd'
import { FormInstance } from 'antd/lib'
import { ColumnsType } from 'antd/lib/table'
import { RcFile } from 'antd/lib/upload'
import dayjs from 'dayjs'
import { ISSUE_STATUSES } from 'enums'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IIssueAttachments, IIssueDTOExtended, IIssueDrawerPermissions } from 'interfaces/IIssue'
import { FC, ReactNode, useEffect, useState } from 'react'
import { downloadFile } from 'services/AdminService'
import { deleteIssueAttachment, getIssueById } from 'services/IssuesService'
import { formatBytes, getBase64 } from 'services/helperService'
import { setDtoValue } from 'store/issueSlice'
import { ImageCarousel } from '../imageCarousel'

const { Dragger } = Upload
const { Text } = Typography

interface IProps {
	issueForm: FormInstance<IIssueDTOExtended>
	issueId: string
	filesToUpload: UploadFile[]
	permissions: IIssueDrawerPermissions
	disabled: boolean
	setFilesToUpload: (x: UploadFile[]) => void
	attachments: IIssueAttachments[]
}

export interface IIssueFileList {
	id: string
	objectId: string
	name: string
	extension: string
	fileSize: number
	contentType: string
	description: string | null
	uploadDate: Date
	url: string
	blob: string | undefined
	createdBy: {
		id: string
		name: string
		email: string
	}
}

export const IssueAttachments: FC<IProps> = ({
	issueId,
	filesToUpload,
	permissions,
	disabled,
	setFilesToUpload,
	attachments,
	issueForm
}) => {
	const dispatch = useAppDispatch()
	const { user: currentUser } = useAppSelector(state => state.environment)
	const { dto: issueDto } = useAppSelector(state => state.issues)
	const [isLoading, setIsLoading] = useState(false)
	const [selectedAttachment, setSelectedAttachment] = useState<string | null>(null)
	const [previewOpen, setPreviewOpen] = useState(false)
	const [previewImage, setPreviewImage] = useState('')
	const [previewTitle, setPreviewTitle] = useState('')
	const [filesLimit, setFilesLimit] = useState(0)
	const ImageIcons: Record<string, ReactNode> = {
		pdf: <FilePdfOutlined style={{ color: 'darkOrange', fontSize: 24 }} />,
		jpg: <FileJpgOutlined style={{ color: 'red', fontSize: 24 }} />,
		png: <FileImageOutlined style={{ color: '#4173e6', fontSize: 24 }} />
	}
	const totalFiles = attachments.length + filesToUpload.length

	const canDelete = (attachment: IIssueAttachments) => {
		return (
			currentUser!?.isAdmin! ||
			// &&
			// !(issueDto.issueStatusId === ISSUE_STATUSES.ready_to_inspect && !!issueDto.ordinance)
			((issueDto.issueStatusId === ISSUE_STATUSES.open ||
				issueDto.issueStatusId === ISSUE_STATUSES.ready_to_inspect) &&
				// !issueDto.ordinance &&
				(currentUser?.id === attachment?.createdBy?.id || currentUser?.id === issueDto.ownerId))
		)
	}

	const issueFilesColumns: ColumnsType<IIssueAttachments> = [
		{
			dataIndex: 'image',
			width: '5%',
			align: 'center',
			render: (_, record) => ImageIcons[record.extension.replace('.', '').toLowerCase()]
		},
		{
			title: 'Файл',
			dataIndex: 'name',
			width: '30%',
			render: (_, record) => record.name.replace(record.extension, '')
		},
		{
			title: 'Автор',
			width: '16%',
			render: (_, record) => record.createdBy!?.name!
		},
		{
			title: 'Дата загрузки',
			dataIndex: 'uploadDate',
			width: '12%',
			render: (_, record) => dayjs(record.uploadDate).format('HH:mm DD.MM.YYYY')
		},
		{
			title: 'Размер файла',
			dataIndex: 'fileSize',
			width: '12%',
			render: (_, record) => formatBytes(record.fileSize)
		},
		{
			width: '10%',
			render: (_, record) => (
				<Row gutter={8} wrap={false}>
					<Col>
						<Tooltip placement="topLeft" title="Скачать">
							<Button
								shape="circle"
								size="middle"
								onClick={() => onDownload(record)}
								icon={<DownloadOutlined rev={undefined} />}
							/>
						</Tooltip>
					</Col>
					{canDelete(record) && (
						<Col>
							<Tooltip placement="topLeft" title="Удалить">
								<Button
									shape="circle"
									size="middle"
									danger
									onClick={() => onAttachmentDelete(record.id)}
									icon={<DeleteOutlined rev={undefined} />}
								/>
							</Tooltip>
						</Col>
					)}
				</Row>
			)
		}
	]

	const onDownload = (record: IIssueAttachments) => {
		downloadFile(record.id)
			.then(response => {
				const href = URL.createObjectURL(response.data)
				const link = document.createElement('a')
				link.href = href
				link.setAttribute('download', record.name + record.extension!)
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				URL.revokeObjectURL(href)
			})
			.catch(() => message.error('Во время загрузки файла произошла ошибка'))
	}

	const getAttachments = async (id: string) => {
		setIsLoading(true)
		await getIssueById(id)
			.then(data => dispatch(setDtoValue({ prop: 'attachments', value: data.attachments! })))
			.catch(error => console.log(error))
			.finally(() => setTimeout(() => setIsLoading(false), 200))
	}

	const onAttachmentDelete = async (id: string) => {
		const isDelete = await deleteIssueAttachment(issueId, id).then(data => data.status)
		if (isDelete === 200) {
			getAttachments(issueDto.id!)
		}
	}

	const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
		const totalAttachments = attachments.length + newFileList.length
		setFilesLimit(totalAttachments)
		if (totalAttachments > 50) {
			issueForm.validateFields(['filesLimit'])
		} else {
			setFilesToUpload(newFileList)
			issueForm.resetFields(['filesLimit'])
		}
	}

	useEffect(() => {}, [totalFiles])

	const handleCancel = () => setPreviewOpen(false)

	const handlePreview = async (file: UploadFile) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj as RcFile)
		}

		setPreviewImage(file.url || (file.preview as string))
		setPreviewOpen(true)
		setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
	}

	const myBtnSmooth = document.querySelector(`.ant-btn-background-ghost`)
	useEffect(() => {
		myBtnSmooth!?.addEventListener('click', function () {
			const elem = document!
				.querySelector(`#ant-table-cell-scroll`)!
				.querySelector(`.ant-table-thead`)!
			if (elem) {
				elem?.scrollIntoView({ block: 'end', behavior: 'smooth' })
			} else {
				document!
					.querySelector(`#ant-table-cell-scroll`)!
					.querySelector(`.ant-table-cell`)!
					.scrollIntoView({ block: 'end', behavior: 'smooth' })
			}
		})
	}, [myBtnSmooth])

	return (
		<>
			<Form.Item
				name="filesLimit"
				rules={[
					() => ({
						validator(_) {
							if (filesLimit > 50) {
								return Promise.reject(
									new Error(
										'Превышен лимит загрузки файлов. Необходимо изменить количество загружаемых файлов или удалить неактуальные файлы из замечания'
									)
								)
							}
							return Promise.resolve()
						}
					})
				]}
			>
				{attachments && (
					<ImageCarousel
						attachments={attachments.filter(a => !a.extension.includes('pdf'))}
						initialSlide={selectedAttachment}
						show={selectedAttachment !== null}
						onClose={() => setSelectedAttachment(null)}
					/>
				)}
				<Dragger
					disabled={!permissions.attachFiles || disabled}
					multiple={true}
					accept="image/*,.pdf"
					showUploadList={true}
					style={{
						marginBottom: '1rem',
						display: !permissions.attachFiles ? 'none' : 'block',
						cursor: !(totalFiles >= 50) ? 'pointer' : 'not-allowed'
					}}
					listType="picture-card"
					fileList={filesToUpload}
					onPreview={handlePreview}
					onChange={handleChange}
					openFileDialogOnClick={!(totalFiles >= 50)}
					beforeUpload={file => {
						const canUpload =
							!file.name.includes('jfif') &&
							(file.type === 'image/jpeg' ||
								file.type === 'image/jpg' ||
								file.type === 'image/png' ||
								file.type === 'application/pdf')
						if (!canUpload) {
							message.error(`${file.name} - тип файла не поддерживается`)
						}
						return canUpload ? false : Upload.LIST_IGNORE
					}}
				>
					<Row justify="center" gutter={8}>
						<Col>
							<UploadOutlined />
						</Col>
						<Col>
							<Text>Добавить файлы для загрузки</Text>
						</Col>
					</Row>
					<Row justify="center" gutter={8}>
						<Text>
							Доступно:
							<span style={{ color: 50 - totalFiles < 0 ? 'red' : 'black', marginLeft: 5 }}>
								{50 - totalFiles}
							</span>
							/50
						</Text>
					</Row>
				</Dragger>
				<Modal
					open={previewOpen}
					title={previewTitle}
					footer={null}
					onCancel={handleCancel}
					width={window.innerWidth - (window.innerWidth * 50) / 100}
				>
					<img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
				</Modal>
			</Form.Item>
			{!isLoading ? (
				<>
					<Table
						size="small"
						id="ant-table-cell-scroll"
						pagination={false}
						showHeader={attachments.length === 0 ? false : true}
						dataSource={attachments!}
						columns={issueFilesColumns}
						onRow={record => ({
							onDoubleClick: e => {
								e.preventDefault()
								if (record.id !== null) {
									if (!record.extension.includes('pdf')) {
										setSelectedAttachment(record.id)
									} else {
										onDownload(record)
									}
								}
							}
						})}
					/>
				</>
			) : (
				<Row justify="center">
					<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
				</Row>
			)}
		</>
	)
}
