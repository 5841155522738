import { create } from 'zustand'
import { IWbsStatusRegistry } from './wbs-status-api'
interface IWbsStatusState {
	showAddVisaModal: boolean
	addVisaContainer: IWbsStatusRegistry | null
	currentPage: number
	totalItems?: number
	setVisaAdd: (status: IWbsStatusRegistry) => void
	unsetVisaAdd: () => void
}
export const WbsStatusState = create<IWbsStatusState>((set, get) => ({
	showAddVisaModal: false,
	addVisaContainer: null,
	currentPage: 1,
	setVisaAdd: status =>
		set(() => ({
			showAddVisaModal: true,
			addVisaContainer: status
		})),
	unsetVisaAdd: () =>
		set(() => ({
			showAddVisaModal: false,
			addVisaContainer: null
		}))
}))
