import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'hooks/appReduxHook'
import { WbsStatusApi } from './wbs-status-api'
import { WbsStatusState } from './wbsStatusState'

export const useWbsStatuses = (isLocal?: boolean, isAdjustment?: boolean) => {
	const { project } = useAppSelector(state => state.environment)
	const key = isLocal ? 'estimateStatusGuide' : 'wbsStatusGuide'
	const adjustmentKey = isLocal ? 'adjustmentEstimateStatusGuide' : 'adjustmentWbsStatusGuide'
	return useQuery({
		queryKey: [isAdjustment ? adjustmentKey : key, project],
		queryFn: async () => {
			return await WbsStatusApi.getRegistry(isLocal, isAdjustment).then(
				res =>
					res.data
						?.filter(x => !x.isDeleted)
						.map(d => ({ ...d, children: d.children.filter(x => !x.isDeleted) }))
						?.sort((a, b) => a.order - b.order) ?? []
			)
		},
		refetchOnWindowFocus: false
	})
}

export const useWbsVisas = () => {
	const { project } = useAppSelector(state => state.environment)
	const { addVisaContainer } = WbsStatusState()
	return useQuery({
		queryKey: ['wbsVisas', project, addVisaContainer?.id],
		queryFn: async () => {
			return await WbsStatusApi.getVisaRegistry(addVisaContainer?.id).then(res =>
				res.data.filter(d => !d.isDeleted)
			)
		},
		refetchOnWindowFocus: false
	})
}

export const useWbsRoles = () => {
	const { project } = useAppSelector(state => state.environment)
	return useQuery({
		queryKey: ['wbsRoles', project],
		queryFn: async () => {
			return await WbsStatusApi.getRoles()
		},
		refetchOnWindowFocus: false
	})
}
