import { arrayMove } from '@dnd-kit/sortable'
import { message } from 'antd'
import { IColumns } from 'components/columnConfig'
import { IEstimatePositionOrder } from 'interfaces/IEstimatePosition'
import { ITechnology } from 'interfaces/ITechnology'
import lodash, { findLastIndex } from 'lodash'
import {
	IEstimatePositionItemDto,
	INonActualSection,
	ISectionTechnologies,
	IWbsContainerSection,
	IWbsContainerSectionDto,
	IWbsContainerSectionItem,
	IWbsPermissions,
	WbsContainerApi
} from 'pages/unmodelPage/wbsContainer'
import { findNestedObj } from 'shared/helpers'
import { create } from 'zustand'
import { LocalEstimateContainerApi } from '../api/localEstimateApi'
import {
	ILocalEstimate,
	ILocalEstimateContainer,
	ILocalEstimateFilters
} from './localEstimateInterfaces'

interface ILocalEstimateState {
	localEstimateSign?: string
	showCorrectModal: boolean
	correctLocalEstimate: ILocalEstimate | undefined
	localEstimateFilters: ILocalEstimateFilters
	currentPage: number
	totalItems: number
	totalPages: number | undefined
	columnsLocalEstimate: IColumns[]
	currentContainerId: string | undefined
	wbsPermissions: IWbsPermissions
	container: ILocalEstimateContainer | null
	actualizeModalEstimate: boolean
	nonActualPositions: INonActualSection[]
	showHistory: boolean
	showContainerCommentsDrawer: boolean
	hasError: 'delete' | 'project' | null
	sections: IWbsContainerSection[] | null
	sectionBlock?: string
	technologies: ISectionTechnologies[]
	scrollTo: string | null
	selectedEstimatesKeys: string[]
	expandedRowKeys: string[]
	expandedSectionsWithEstimatePositions: string[]
	collapsedSections: string[]
	showColumnsLocalEstimate: IColumns[]
	showRemoveModal: boolean
	toRemove: string[]
	removeSectionId: string | null
	setLocalEstimateSign: (localEstimateSign: string) => void
	setShowCorrectModal: (showCorrectModal: boolean) => void
	seCorrectLocalEstimate: (correctLocalEstimate: ILocalEstimate) => void
	setLocalEstimateFilters: (filters: ILocalEstimateFilters) => void
	onPagination: (page: number) => void
	setTotalItems: (items: number) => void
	setTotalPages: (pages: number) => void
	setShowColumnsLocalEstimate: (showColumnsLocalEstimate: IColumns[]) => void
	setCurrentContainerId: (currentContainerId: string | undefined) => void
	changeContainerReadyState: (state: boolean) => Promise<any>
	setContainer: (container: ILocalEstimateContainer) => void
	unsetContainer: () => void
	setHasError: (hasError: 'delete' | 'project' | null) => void
	setSections: (sections: IWbsContainerSectionDto[]) => void
	setTechnologies: (sectionId: string, data: ITechnology[]) => void
	setSectionItems: (sectionId: string, sectionItems: IEstimatePositionItemDto[]) => void
	setSectionBlocks: (id: string) => void
	setScrollTo: (id: string | null) => void
	setSelectedEstimatesKeys: (keys: string[]) => void
	setDefaultExpanded: () => void
	setExpandedSectionsWithEstimatePositions: (keys: string[]) => void
	setCollapsedSections: (keys: string[]) => void
	updateSection: (sectionId: string) => void
	setExpandedRowKeys: (keys: string[]) => void
	changePosition: (sectionId: string, ids: unknown[], direction: 'up' | 'down') => void
	getCommentStatus: (isClosed: boolean, hasComments?: boolean) => string
	setColumnsWbs: (columnsLocalEstimate: IColumns[]) => void
}

export const useLocalEstimateState = create<ILocalEstimateState>((set, get) => ({
	localEstimateSign: undefined,
	showCorrectModal: false,
	correctLocalEstimate: undefined,
	localEstimateFilters: {},
	currentPage: 1,
	totalItems: 1,
	totalPages: undefined,
	columnsLocalEstimate: [],
	hasError: null,
	wbsPermissions: {
		admin: false,
		edit: false,
		delete: false,
		setDP: false,
		setSDU: false,
		setDZ: false,
		setDZSmr: false,
		outer: false,
		changeReady: false,
		exportWbsToExcel: false,
		importWbsFromExcel: false
	},
	currentContainerId: undefined,
	container: null,
	actualizeModalEstimate: false,
	nonActualPositions: [],
	showHistory: false,
	showContainerCommentsDrawer: false,
	sections: null,
	technologies: [],
	scrollTo: null,
	selectedEstimatesKeys: [],
	expandedRowKeys: [],
	expandedSectionsWithEstimatePositions: [],
	collapsedSections: [],
	showColumnsLocalEstimate: [],
	columnsWbs: [],
	showRemoveModal: false,
	toRemove: [],
	removeSectionId: null,
	setShowColumnsLocalEstimate: showColumnsLocalEstimate =>
		set(() => ({ showColumnsLocalEstimate })),
	setHasError: hasError => set(() => ({ hasError })),
	setLocalEstimateSign: localEstimateSign => set(() => ({ localEstimateSign })),
	setShowCorrectModal: showCorrectModal => set(() => ({ showCorrectModal: showCorrectModal })),
	seCorrectLocalEstimate: correctLocalEstimate =>
		set(() => ({ correctLocalEstimate: correctLocalEstimate })),
	setLocalEstimateFilters: filters => set(() => ({ localEstimateFilters: filters })),
	onPagination: page => set(() => ({ currentPage: page })),
	setTotalItems: items => set(() => ({ totalItems: items })),
	setTotalPages: pages => set(() => ({ totalPages: pages })),
	setCurrentContainerId: currentContainerId => set(() => ({ currentContainerId })),
	changeContainerReadyState: async state => {
		const container = get().container
		if (container) {
			return await LocalEstimateContainerApi.setContainerReadyState(container?.id, state).catch(
				err => {
					message.error('Произошла ошибка! Обратитесь к администратору')
				}
			)
		}
	},
	setContainer: container => set(() => ({ container })),
	unsetContainer: () => set(() => ({})),
	setSections: dto => {
		const currentSections = get().sections

		const dataMap = (section: IWbsContainerSectionDto): IWbsContainerSection => {
			if (section.children && typeof section.children !== 'undefined') {
				return {
					...section,
					totalSumMaterials: section.workTotal?.totalSumMaterials ?? 0,
					totalSumService: section.workTotal?.totalSumService ?? 0,
					totalSum: section.workTotal?.totalSum ?? 0,
					children: section.children.map(c => dataMap(c)),
					isBox: false,
					hasPositions: section.hasPositions,
					hasTechnologies: section.hasTechnologies
				}
			} else {
				if (currentSections && !!currentSections.length) {
					return {
						id: section.id,
						sourceId: section.sourceId,
						name: section.name,
						codifier: section.codifier,
						totalSumMaterials: section.workTotal?.totalSumMaterials ?? 0,
						totalSumService: section.workTotal?.totalSumService ?? 0,
						totalSum: section.workTotal?.totalSum ?? 0,
						isBox: true,
						hasPositions: section.hasPositions,
						hasTechnologies: section.hasTechnologies,
						children: findNestedObj<IWbsContainerSection>(currentSections, 'id', section.id)
							?.children
					}
				} else {
					return {
						id: section.id,
						sourceId: section.sourceId,
						name: section.name,
						codifier: section.codifier,
						totalSumMaterials: section.workTotal?.totalSumMaterials ?? 0,
						totalSumService: section.workTotal?.totalSumService ?? 0,
						totalSum: section.workTotal?.totalSum ?? 0,
						isBox: true,
						hasPositions: section.hasPositions,
						hasTechnologies: section.hasTechnologies,
						children: []
					}
				}
			}
		}
		const data = dto.map(section => dataMap(section))
		set(() => ({ sections: data }))
	},
	setTechnologies: (sectionId, data) => {
		let list: ISectionTechnologies[] = get().technologies
		if (!list.some(tech => tech.sectionId === sectionId)) {
			list.push({
				sectionId,
				technologies: data
			})
			set(() => ({ technologies: list }))
		}
	},
	setSectionItems: (sectionId, sectionItems) => {
		const sections = get().sections
		const items: IWbsContainerSectionItem[] =
			sectionItems?.flatMap(elem => {
				return {
					level: 1,
					id: elem.id,
					sectionId,
					name: elem.name,
					order: elem.order,
					technologyId: elem.technologyId,
					version: elem.version,
					isCopied: elem.isCopied,
					properties: elem.properties?.map(property => ({
						id: property.id,
						identityName: property.identityName,
						name: property.name,
						propertyId: property.propertyId,
						value: property.value,
						valueType: property.valueType
					})),
					children: elem.works.map(work => ({
						level: 2,
						version: elem.version,
						id: work.id,
						isCopied: work.isCopied,
						isExpandable: elem.technology.expandable,
						isFixedPriceMaterial: elem.technology.isFixedPriceMaterial,
						isActualRelationship: work.isActualRelationship,
						estimatePositionId: elem.id,
						sectionId,
						sectionCodifier: '',
						hasComments: elem.hasComments,
						isCommentsClosed: elem.isCommentsClosed,
						technologyId: elem.technologyId,
						name: work.name,
						rate: work.norma,
						measureUnit: work.measureUnit !== null ? work.measureUnit.name : '',
						measureUnitId: work.measureUnit?.id,
						amount: work.amount,
						amountWithRate: work.amountWithRate,
						order: elem.order,
						workId: work.workId,
						formula: work.formula,
						noteDP: work.noteDP,
						noteDZ: work.noteDZ,
						noteSDU: work.noteSDU,
						priceMaterial: work.priceMaterial,
						priceService: work.priceService,
						totalPrice: work.totalPrice,
						totalSum: work.totalSum,
						totalSumMaterials: work.totalSumMaterials,
						totalSumService: work.totalSumService,
						children: work.materials
							.sort((a, b) => a.order - b.order)
							.map(material => ({
								level: 3,
								version: elem.version,
								isExpandable: elem.technology.expandable,
								id: material.id,
								isCopied: material.isCopied,
								estimatePositionId: elem.id,
								technologyId: elem.technologyId,
								sectionId,
								name: material.materialName,
								rate: material.norma,
								measureUnit: material.measureUnit !== null ? material.measureUnit.name : '',
								materialComment: material.materialComment,
								formula: material.formula,
								amount: material.amount,
								amountWithRate: material.amountWithRate,
								key: material.key,
								materialId: material.materialId,
								measureUnitId: material.measureUnit?.id,
								order: material.order,
								typeMaterialId: material?.typeMaterial?.id ?? null,
								noteDP: material.noteDP,
								noteDZ: material.noteDZ,
								noteSDU: material.noteSDU,
								priceMaterial: material.priceMaterial,
								totalSumMaterials: material.totalSumMaterials,
								isNominated: material.isNominated,
								workId: work.id
							}))
					}))
				}
			}) ?? []

		const updSections = lodash.cloneDeepWith(sections, (value: IWbsContainerSection) => {
			return value && value.id === sectionId
				? {
						...value,
						children: items
							.sort((a, b) => a.order - b.order)
							.map(item => ({
								...item,
								children: item.children.map(w => ({ ...w, sectionCodifier: value.codifier }))
							}))
				  }
				: lodash.noop()
		})

		set(() => ({ sections: updSections }))
	},
	setSectionBlocks: id => set(() => ({ sectionBlock: id })),
	setScrollTo: id => set(() => ({ scrollTo: id })),
	setSelectedEstimatesKeys: keys => set(() => ({ selectedEstimatesKeys: keys })),
	setDefaultExpanded: () => {
		const sectionId = get().sectionBlock
		const currentSection = get().sections
		const section = findNestedObj<IWbsContainerSection>(currentSection!, 'id', sectionId)
			?.children as IWbsContainerSectionItem[]
		if (sectionId && currentSection && section) {
			const workKeys = section
				.flatMap(i => i.children)
				.filter(c => !!c.children.length)
				.map(x => x.id)
			const currentExpandedKeys = get().expandedRowKeys
			let updateExpandedKeys = get().expandedRowKeys
			workKeys.forEach(w => {
				if (!currentExpandedKeys.includes(w)) updateExpandedKeys.push(w)
			})
			set(() => ({ expandedRowKeys: updateExpandedKeys }))
		}
	},
	setExpandedSectionsWithEstimatePositions: keys =>
		set(() => ({ expandedSectionsWithEstimatePositions: keys })),
	setCollapsedSections: keys => set(() => ({ collapsedSections: keys })),
	updateSection: sectionId => {
		const sectionBlock = get().sectionBlock
		if (sectionBlock || sectionBlock !== sectionId) {
			set(() => ({ sectionBlock: '' }))
			setTimeout(() => set(() => ({ sectionBlock: sectionId })), 200)
			set(() => ({ sectionBlock: '' }))
		}
	},
	setExpandedRowKeys: keys => set(() => ({ expandedRowKeys: keys })),
	changePosition: (sectionId, ids, direction) => {
		const currentSection = get().sections
		const estimatePositions: IWbsContainerSectionItem[] = findNestedObj<IWbsContainerSection>(
			currentSection!,
			'id',
			sectionId
		)?.children as IWbsContainerSectionItem[]
		if (estimatePositions) {
			let ordersSet: IEstimatePositionOrder[] = []
			let moved = estimatePositions

			const sortedIds = [...ids].sort((a, b) => {
				const indexA = estimatePositions.findIndex(i => i.id === a)
				const indexB = estimatePositions.findIndex(i => i.id === b)
				return direction === 'down' ? indexB - indexA : indexA - indexB
			})

			sortedIds.forEach(id => {
				const item = estimatePositions.find(i => i.id === id)
				const currentIndex = estimatePositions.findIndex(i => i.id === id)
				const nearbyIndex = estimatePositions.findIndex(
					i =>
						i.level === 1 &&
						i.order === (direction === 'down' ? item!?.order + 1 : item!?.order - 1)
				)

				if (nearbyIndex !== -1) {
					moved = arrayMove(moved, currentIndex, nearbyIndex)
					moved.map((item, index) =>
						ordersSet.push({
							estimatePostionId: item.id,
							orderPosition: index + 1
						})
					)
				}
			})

			const lastOrderIndex = findLastIndex(ordersSet, o => {
				return o.orderPosition === 1
			})
			if (ordersSet.slice(lastOrderIndex).length > 0) {
				WbsContainerApi.setEstimatePositionOrder(ordersSet.slice(lastOrderIndex)).then(() => {
					set(() => ({ sectionBlock: '' }))
					setTimeout(() => set(() => ({ sectionBlock: sectionId })), 200)
				})
			}
		}
	},
	getCommentStatus: (isClosed, hasComments = true) => {
		switch (true) {
			case hasComments && isClosed === false:
				return '#ff3300'
			case hasComments && isClosed === true:
				return '#4a8a48'
			default:
				return 'gray'
		}
	},
	setColumnsWbs: columnsLocalEstimate => set(() => ({ columnsLocalEstimate }))
}))
