import { ColorPicker, Form, Input, Select } from 'antd'
import { FC } from 'react'
import { mainSelectProps } from 'shared/helpers'
import { useWbsRoles } from './useWbsStatuses'
import { IWbsStatusRegistry, IWbsVisaWithParent } from './wbs-status-api'

interface EditableCellProps {
	title: React.ReactNode
	record: IWbsStatusRegistry | IWbsVisaWithParent
	dataIndex: 'name' | 'rolesId' | 'color'
	children: React.ReactNode
	editing: boolean
}

export const WbsStatusCell: FC<EditableCellProps> = props => {
	const { title, record, dataIndex, children, editing, ...restProps } = props
	const { data: roles } = useWbsRoles()

	const getRuleMessage = () => {
		switch (true) {
			case dataIndex === 'name':
				return 'Укажите наименование'
			case dataIndex === 'rolesId':
				return 'Выберете хотя бы одну роль'
		}
	}

	const getNode = () => {
		switch (true) {
			case dataIndex === 'name':
				return <Input allowClear></Input>
			case 'parentId' in record && dataIndex === 'rolesId':
				return (
					<Select
						{...mainSelectProps}
						allowClear
						mode="multiple"
						options={roles?.map(item => ({
							key: item.id,
							value: item.id,
							label: item.name
						}))}
					/>
				)
			case 'color' in record && dataIndex === 'color':
				return (
					<ColorPicker format="hex" defaultValue={'color' in record ? record.color : undefined} />
				)
			default:
				return children
		}
	}

	const childNode = editing ? (
		<Form.Item
			name={dataIndex}
			style={{ margin: 0 }}
			rules={[
				{
					required: dataIndex === 'name' || ('roles' in record && dataIndex === 'rolesId'),
					message: getRuleMessage()
				}
			]}
		>
			{getNode()}
		</Form.Item>
	) : (
		children
	)

	return (
		<td {...restProps}>
			<div className="app-wbs-table__cell">{childNode}</div>
		</td>
	)
}
