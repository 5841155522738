import { useQuery } from '@tanstack/react-query'
import { getDiagram } from 'services/DiagramService'
import { useAppSelector } from './appReduxHook'

interface IOrdinanceAnalytics {
	FixOrdinancesLess30: number
	FixOrdinancesMore30: number
	NoFixrdinancesLess30: number
	NoFixrdinancesMore30: number
	TotalOrdinances: number
}

export const useOrdinanceAnalytics = () => {
	const diagramsContractor = useAppSelector(state => state.environment.diagramsContractor)
	return useQuery({
		queryKey: ['ordinanceAnalytics', diagramsContractor],
		queryFn: async () => {
			return await getDiagram('ordinances', diagramsContractor).then(
				data => data as IOrdinanceAnalytics
			)
		}
	})
}
