import { useAppSelector } from 'hooks/appReduxHook';
import { useCallback } from 'react';

export const useCheckPermissions = () => {
	const { user, permissions } = useAppSelector(state => state.environment)

	return useCallback(
		(permits: string[], every?: boolean) => {
			if (user?.isAdmin) return true
			else {
				return permissions
					? every
						? permits.every(check => typeof permissions.find(p => p.name === check) !== 'undefined')
						: permits.some(check => typeof permissions.find(p => p.name === check) !== 'undefined')
					: false
			}
		},
		[user,permissions]
	)
}
