import { CellContext, Row } from '@tanstack/react-table'
import { InputNumber, Tooltip } from 'antd'
import { FC, useEffect, useRef, useState } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { RoundedNumber } from 'widgets/estimate/model/estimate-helper'
import { useShallow } from 'zustand/react/shallow'
import { IAdjustmentPosition, IAdjustmentPositionMaterial, IAdjustmentSection, TCellType } from '..'
import { useAdjustmentState } from '../model/adjustment-state'

interface IProps {
	record:
		| CellContext<IAdjustmentPosition | IAdjustmentPositionMaterial, any>
		| CellContext<IAdjustmentPosition, any>
		| CellContext<IAdjustmentPositionMaterial, any>
	cellType: TCellType
	parents: Row<IAdjustmentSection | IAdjustmentPosition | IAdjustmentPositionMaterial>[]
}

export const AdjustmentCell: FC<IProps> = ({ record, cellType, parents }) => {
	const checkPermissions = useCheckPermissions()
	const inputRef = useRef<HTMLInputElement>(null)
	const [focused, setFocused] = useState(false)

	const { onSavePosition } = useAdjustmentState(
		useShallow(state => ({
			onSavePosition: state.onSavePosition
		}))
	)
	const initialValue = RoundedNumber(record.cell.getValue()) as string
	const [cellValue, setCellValue] = useState<number | null>(
		+initialValue.replace(/,/, '.').replace(/\s+/g, '')
	)
	const [hasError, setHasError] = useState<string | undefined>()

	const validateCell = (value: any) => {
		if (cellType === 'amountSecond' && (value === null || value === 0 || value === undefined)) {
			setHasError('Кол-во не может быть пустым или равным 0')
		} else if (value === null) setHasError('Кол-во не может быть пустым')
		else if (Number.isNaN(value)) {
			setHasError('Может содержать только цифры')
		} else {
			setHasError(undefined)
		}
	}
	useEffect(() => {
		validateCell(cellValue)
	}, [cellValue])

	const onSave = (
		cellType: TCellType,
		record:
			| CellContext<IAdjustmentPosition | IAdjustmentPositionMaterial, any>
			| CellContext<IAdjustmentPosition, any>
			| CellContext<IAdjustmentPositionMaterial, any>,
		parents: Row<IAdjustmentSection | IAdjustmentPosition | IAdjustmentPositionMaterial>[]
	) => {
		if (!hasError && cellValue !== null) {
			onSavePosition(cellValue, cellType, record, parents)
		} else {
			setCellValue(+initialValue.replace(/,/, '.').replace(/\s+/g, ''))
			setTimeout(() => {
				setHasError(undefined)
			}, 2000)
		}
	}

	const checkEditable = () => {
		switch (cellType) {
			case 'amountSecond':
			case 'priceServiceSecond':
			case 'priceMaterialSecond':
				return checkPermissions([PERMISSIONS.AdjustmentExternal])

			case 'priceServiceSdu':
			case 'priceMaterialSdu':
				return checkPermissions([PERMISSIONS.AdjustmentSdu])
		}
	}

	return checkEditable() ? (
		<Tooltip color="volcano" placement="left" title={hasError} open={!!hasError && focused}>
			<InputNumber
				ref={inputRef}
				value={cellValue}
				keyboard={false}
				onKeyDown={event => {
					if (
						!/[0-9]|Backspace|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|\.|\,/.test(event.key)
					)
						event.preventDefault()
				}}
				controls={false}
				changeOnWheel={false}
				decimalSeparator=","
				className="estimate-position-input"
				onChange={setCellValue}
				onFocus={() => {
					setFocused(true)
					if (Number(initialValue) === 0) inputRef.current?.select()
				}}
				onBlur={e => {
					setFocused(false)
					if (initialValue! !== e.currentTarget.value) onSave(cellType, record, parents)
				}}
				onPressEnter={() => inputRef.current?.blur()}
			/>
		</Tooltip>
	) : (
		<>{initialValue}</>
	)
}
