import { Col, Form, Modal, Select, Tooltip, Typography } from 'antd'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useChecklistTemplateList } from 'hooks/useChecklistTemplateList'
import { ITemplate } from 'interfaces/ITemplate'
import { FC, useState } from 'react'
import { getChecklistForm } from 'services/ChecklistsService'
import { setCurrentChecklist } from 'store/checklistsSlice'

const { Option } = Select
const { Title } = Typography

interface IProps {
	modalVisible: boolean
	setDrawerVisible: (value: boolean) => void
	setModalVisible: (value: boolean) => void
	templates?: ITemplate[]
	setTemplates?: (templates: React.SetStateAction<ITemplate[]>) => void
}

const CreateChecklistModal: FC<IProps> = ({ modalVisible, setDrawerVisible, setModalVisible }) => {
	const [form] = Form.useForm()
	const dispatch = useAppDispatch()
	const user = useAppSelector(state => state.environment.user)
	const selectedTemplateType = Form.useWatch('templateType', form)
	const selectedWorkPackages = Form.useWatch('workPackages', form)
	const { data: templates, isFetching } = useChecklistTemplateList()
	const workPackages = useAppSelector(state => state.environment.workPackages)

	const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null)
	const templatesUKS_NDK = templates?.filter(uks =>
		selectedWorkPackages === undefined
			? uks.templateType === 'UKS_NDK'
			: uks.workPackageId === selectedWorkPackages && uks.templateType === 'UKS_NDK'
	)
	const templatesUK_KS = templates?.filter(t => t.templateType === 'UK_KS')

	const typeCheckList = [
		{ id: 0, name: 'УКС НДК' },
		{ id: 1, name: 'УК КС' }
	]

	const onFinish = async () => {
		if (selectedTemplate !== null) {
			await getChecklistForm(selectedTemplate).then(response => {
				dispatch(
					setCurrentChecklist({
						...response,
						owner: { id: user?.id, name: user?.lastName + ' ' + user?.firstName },
						checkListTemplate: { id: selectedTemplate, name: '' }
					})
				)
				setDrawerVisible(true)
				setModalVisible(false)
				form.resetFields()
				setSelectedTemplate(null)
			})
		}
	}

	const onCancel = () => {
		setModalVisible(false)
		form.resetFields()
		setSelectedTemplate(null)
	}

	return (
		<Form form={form} name="CheckListTemplateForm" onFinish={onFinish}>
			<Modal
				title="Создание чек-листа"
				open={modalVisible}
				destroyOnClose={true}
				onOk={() => form.submit()}
				okButtonProps={{ disabled: selectedTemplate === null }}
				okText="Создать"
				onCancel={onCancel}
				cancelText="Закрыть"
			>
				<Col>
					<Title level={5}>Тип чек-листа:</Title>
					<Form.Item name="templateType">
						{
							<Select
								style={{ width: '100%', textOverflow: 'ellipsis' }}
								onChange={() => {
									form.resetFields(['templates'])
									setSelectedTemplate(null)
								}}
							>
								{typeCheckList?.map((item, index) => (
									<Option key={index} value={item.id}>
										<Tooltip
											zIndex={99999999999999}
											title={
												item.name === 'УК КС'
													? 'Передача систем в управляющую компанию'
													: 'Вызов строительного контроля'
											}
										>
											<div style={{ width: '100%' }}>{item.name}</div>
										</Tooltip>
									</Option>
								))}
							</Select>
						}
					</Form.Item>
					{selectedTemplateType === 0 && (
						<>
							<Title level={5}>Пакет работ:</Title>
							<Form.Item name="workPackages">
								<Select
									style={{ width: '100%', textOverflow: 'ellipsis' }}
									allowClear
									onChange={() => {
										form.resetFields(['templates'])
										setSelectedTemplate(null)
									}}
								>
									{workPackages?.map((item, index) => (
										<Option key={index} value={item.id}>
											{item.name}
										</Option>
									))}
								</Select>
							</Form.Item>
						</>
					)}
					{(selectedTemplateType === 0 || selectedTemplateType === 1) && (
						<>
							<Title level={5}>Шаблон:</Title>
							<Form.Item name="templates">
								<Select
									showSearch
									optionFilterProp="children"
									disabled={
										(selectedTemplateType === 0 && templatesUKS_NDK?.length === 0) ||
										(templatesUK_KS?.length === 0 && selectedTemplateType === 1)
									}
									filterOption={(input, option) =>
										(option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())
									}
									options={(selectedTemplateType === 0 ? templatesUKS_NDK : templatesUK_KS)!
										.sort((a, b) =>
											a.cipher!?.localeCompare(b.cipher!, undefined, {
												numeric: true,
												sensitivity: 'base'
											})
										)
										.map(template => ({
											key: template.id,
											value: template.id,
											label:
												(template.cipher === null ? '' : template.cipher + ' ') + template.title
										}))}
									loading={isFetching}
									onChange={(el: string) => {
										setSelectedTemplate(el)
									}}
									style={{ width: '100%', textOverflow: 'ellipsis' }}
								></Select>
							</Form.Item>
						</>
					)}
				</Col>
			</Modal>
		</Form>
	)
}
export default CreateChecklistModal
