import { create } from "zustand"
import { IMobileColumns, IMobileDto } from "./mobile-report-interface"

interface IMobileReportState {
	mobileReport?: IMobileColumns[]
    isBlocked: boolean
    showMobileReportModal: boolean
    currentPage: number
	totalItems: number
    totalPages: number | undefined
    filters?: IMobileDto
	setMobileReport: (mobileReport?: IMobileColumns[]) => void
    setIsBlocked: (isBlocked: boolean) => void
    setShowMobileReportModal: (showMobileReportModal: boolean) => void
    onPagination: (page: number) => void
	setTotalItems: (items: number) => void
	setTotalPages: (pages: number) => void
    setFilters: (filters: IMobileDto) => void
}

export const useMobileReportState = create<IMobileReportState>((set) => ({
    isBlocked: false,
    showMobileReportModal: false,
    currentPage: 1,
	totalItems: 1,
	totalPages: undefined,
    filters: undefined ,
    mobileReport: [],
    setIsBlocked: (isBlocked: boolean) => set(() => ({isBlocked})) ,
	setMobileReport: (mobileReport?: IMobileColumns[]) => set(() => ({mobileReport})),
    setShowMobileReportModal: (showMobileReportModal: boolean) => set(() => ({showMobileReportModal})),
    onPagination: page => set(() => ({ currentPage: page })),
	setTotalItems: items => set(() => ({ totalItems: items })),
	setTotalPages: pages => set(() => ({ totalPages: pages })),
    setFilters: filters => set(() => ({filters}))
}))
