import { Card, Descriptions, Divider } from 'antd'
import { FormInstance } from 'antd/lib'
import { useAppSelector } from 'hooks/appReduxHook'
import { IChecklistSection } from 'interfaces/IChecklist'
import { FC } from 'react'
import Section from './Section'

interface IProps {
	onUploadClickHandler: (e: React.MouseEvent<HTMLElement>, id: string | null | undefined) => void
	setIssueModalVisible: (value: boolean) => void
	// checkPermissions: (currentPermits: string[]) => boolean
	form: FormInstance<any>
}

const Sections: FC<IProps> = ({
	onUploadClickHandler,
	setIssueModalVisible,
	// checkPermissions,
	form
}) => {
	const sections = useAppSelector(state => state.checklists.init.currentChecklist?.sections)

	return (
		<>
			{sections?.map((section: IChecklistSection, index: number) => {
				return (
					<div key={index}>
						<Card
							className="at-workcard"
							style={{
								width: '100%',
								padding: '0',
								margin: 0
							}}
						>
							<Descriptions
								column={12}
								bordered
								labelStyle={{
									fontWeight: 'bold',
									width: '30%',
									alignItems: 'center',
									padding: '2px 8px'
								}}
								contentStyle={{
									width: '70%',
									padding: '0px 8px'
								}}
								className="at-descriptions"
							>
								<Descriptions.Item label="Раздел" span={12}>
									<p style={{ padding: '4px', margin: 0 }}>{section.title}</p>
								</Descriptions.Item>

								<Descriptions.Item label="Описание раздела" span={12}>
									<p style={{ padding: '4px', margin: 0 }}>{section.description}</p>
								</Descriptions.Item>
							</Descriptions>

							<Section
								form={form}
								section={section}
								index={index}
								onUploadClickHandler={onUploadClickHandler}
								setIssueModalVisible={setIssueModalVisible}
								// checkPermissions={checkPermissions}
							/>
						</Card>
						<Divider />
					</div>
				)
			})}
		</>
	)
}

export default Sections
