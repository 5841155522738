import { IAppUser } from 'interfaces/IUser'
import { FC, useState } from 'react'
import { UserMenuDrawer } from './userMenu.drawer'
import { UserMenuPopup } from './userMenu.popup'

export const UserMenu: FC = () => {
	const [showUserMenu, setShowUserMenu] = useState(false)
	const user = localStorage.user
	const parsedUser: IAppUser | null =
		user && localStorage.user !== 'undefined' ? JSON.parse(localStorage.user) : null

	return (
		<>
			<UserMenuDrawer
				open={showUserMenu}
				user={parsedUser!}
				onClose={() => setShowUserMenu(false)}
			/>
			<UserMenuPopup user={parsedUser!} onClick={() => setShowUserMenu(true)} />
		</>
	)
}
