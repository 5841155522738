import { FolderFilled, FolderOpenFilled } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Checkbox, Form, message, Modal, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useAppSelector } from 'hooks/appReduxHook'
import {
	IMovePosition,
	INonActualPosition,
	INonActualSection,
	WbsContainerApi
} from 'pages/unmodelPage/wbsContainer'
import { WbsSelectSectionToMove } from 'pages/unmodelPage/wbsContainer/ui/WbsSelectSectionToMove'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWbsPermits } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'
import { useEstimateState } from '..'
import { useNonActualPositionsQuery } from '../model/estimate-query'

interface IProps {}

export const EstimateNonActualModal: FC<IProps> = props => {
	const { data: nonActualPosition, isFetching } = useNonActualPositionsQuery()
	const user = useAppSelector(state => state.environment.user)
	const permits = useWbsPermits()
	const navigate = useNavigate()
	const queryClient = useQueryClient()
	const [actualizeForm] = Form.useForm()
	const [selectedItems, setSelectedItems] = useState<string[]>([])
	const [data, setData] = useState<INonActualSection[]>([])
	const container = useEstimateState(useShallow(state => state.container))
	const onCloseHandler = () => {
		queryClient.invalidateQueries({ queryKey: ['estimateNonActualPositions'] })
		queryClient.invalidateQueries({ queryKey: ['estimateContainer'] })
		queryClient.invalidateQueries({ queryKey: ['estimate'] })
		setSelectedItems([])
		actualizeForm.resetFields()
	}

	const isAuthor =
		(user?.isAdmin ||
			container?.author.id === user?.id ||
			container?.redactors.some(r => r.id === user?.id)) ??
		false

	useEffect(() => {
		setData(
			nonActualPosition?.map(p => ({
				...p,
				children: p.estimatePositions.sort((a, b) => a.order - b.order)
			})) ?? []
		)
		if (nonActualPosition?.length === 0) {
			onCloseHandler()
		}
	}, [nonActualPosition])

	const columns: ColumnsType<INonActualSection | INonActualPosition> = [
		{
			dataIndex: ['name'],
			title: 'Наименование МТР/Работ',
			render: (value, record) =>
				'codifier' in record ? `${record.codifier} - ${value}` : `${record.order} - ${value}`
		},
		{
			key: 'moveTo',
			title: 'Секция для перемещения',
			width: 800,
			render: (_, record) =>
				!('codifier' in record) && (
					<WbsSelectSectionToMove
						record={record}
						selectedItems={selectedItems}
						containerId={container?.id}
					/>
				)
		},
		{
			key: 'delete',
			width: 100,
			align: 'center',
			title: 'Удалить',
			render: (_, record) =>
				!('codifier' in record) && (
					<Checkbox
						checked={selectedItems.includes(record.id)}
						onChange={e => {
							setSelectedItems(prev =>
								prev.includes(record.id) ? prev.filter(x => x !== record.id) : [...prev, record.id]
							)
							actualizeForm.validateFields()
						}}
					/>
				)
		}
	]

	const actualizePositions = async () => {
		const data = actualizeForm.getFieldsValue()

		const toUpdate: IMovePosition[] = Object.entries(data)
			.filter(x => !selectedItems.includes(x[0]))
			.map(d => ({
				estimatePositionIds: d[0],
				containerSectionId: d[1] as string
			}))
		const toDelete = selectedItems
		let deleteDone = false
		let moveDone = false

		if (toDelete.length) {
			deleteDone = await WbsContainerApi.deleteEstimatePositions(toDelete)
				.then(() => {
					message.success('Выбранные позиции сметы успешно удалены')
					return true
				})
				.catch(() => {
					message.error('Во время удаления произошла ошибка. Обратитесь к администратору')
					queryClient.invalidateQueries({ queryKey: ['wbsNonActual'] })
					return false
				})
		} else {
			deleteDone = true
		}
		if (toUpdate.length) {
			moveDone = await WbsContainerApi.estimatePositionMoveTo(toUpdate)
				.then(() => {
					message.success('Выбранные позиции сметы успешно перенесены')
					return true
				})
				.catch(() => {
					message.error('Во время перемещения произошла ошибка. Обратитесь к администратору')
					queryClient.invalidateQueries({ queryKey: ['wbsNonActual'] })
					return false
				})
		} else {
			moveDone = true
		}
		if (deleteDone && moveDone) {
			onCloseHandler()
		}
	}

	return (
		<Modal
			destroyOnClose
			centered
			width="80vw"
			open={!!data.length && permits.canEdit && isAuthor}
			onOk={() => actualizeForm.submit()}
			onCancel={() => {
				actualizeForm.resetFields()
				setSelectedItems([])
				navigate('/unmodel')
			}}
			title="В ВОР есть созданные ранее позиции сметы, привязанные к разделам, которых нет в обновленной структуре. Позиции сметы необходимо удалить или переместить"
			style={{ maxHeight: '80vh' }}
		>
			<Form
				name="actualizeForm"
				form={actualizeForm}
				component={false}
				onFinish={actualizePositions}
			>
				<Table
					loading={isFetching}
					size="small"
					scroll={{ y: '80vh' }}
					children="estimatePositions"
					rowKey={row => row.id}
					dataSource={data}
					columns={columns}
					pagination={false}
					expandable={{
						expandRowByClick: true,
						expandIcon: ({ expanded, onExpand, record }) =>
							'children' in record ? (
								expanded ? (
									<FolderOpenFilled
										style={{ color: '#feb300', marginRight: 8 }}
										// twoToneColor="#feb300"
										onClick={e => onExpand(record, e)}
									/>
								) : (
									<FolderFilled
										style={{ color: '#feb300', marginRight: 8 }}
										// twoToneColor="#feb300"
										onClick={e => onExpand(record, e)}
									/>
								)
							) : undefined
					}}
				/>
			</Form>
		</Modal>
	)
}
