import { API } from 'services/ApiService'
import {
	IEstimatePositionComments,
	IEstimatePositionCommentsUser,
	IWbsCommentDto
} from '../model/estimate-position-comments-state'

export const EstimatePositionCommentsApi = {
	async getEPComments(epId: string) {
		return await API<IEstimatePositionComments>({
			url: `v1/estimatePositions/${epId}/comments`,
			method: 'get'
		}).then(response => response.data)
	},
	async addEPComments(epId: string, dto: IWbsCommentDto) {
		return await API<IEstimatePositionComments>({
			url: `v1/estimatePositions/${epId}/comments`,
			method: 'post',
			data: { ...dto }
		}).then(response => response.data)
	},
	async getEPCommentsUsers(epId: string) {
		return await API<IEstimatePositionCommentsUser[]>({
			url: `v1/estimatePositions/${epId}/responsible-users-comments`,
			method: 'get'
		}).then(response => response.data)
	},
	async toggleEPChatState(epId: string) {
		return await API<IEstimatePositionCommentsUser[]>({
			url: `v1/estimatePositions/${epId}/comments/state`,
			method: 'patch'
		}).then(response => response.data)
	}
}
