import { create } from "zustand";
import { INotificationsFilters } from "./wbs-notifications-interface";


interface INotificationsState {
	limit: number,
	filters: INotificationsFilters
	currentPage: number
	totalItems: number
	totalPages: number | undefined,
	onPagination: (page: number) => void
	setTotalItems: (items: number) => void
	setTotalPages: (pages: number) => void
	setNotificationsFilters: (filters: INotificationsFilters) => void
	unsetFilters: () => void
}

const initStateFilters = {}

export const useNotificationsState = create<INotificationsState>(set => ({
	limit: 50,
	filters: {},
	currentPage: 1,
	totalItems: 1,
	totalPages: undefined,
	onPagination: page => set(() => ({ currentPage: page })),
	setTotalItems: items => set(() => ({ totalItems: items })),
	setTotalPages: pages => set(() => ({ totalPages: pages })),
	setNotificationsFilters: filters =>
		set(() => ({ filters: filters })),
	unsetFilters: () => set(() => ({ ...initStateFilters }))
}))