import { FC, useState, useEffect } from 'react'
import { Result, Typography } from 'antd'
import { setLegged3Code } from 'services/AdminService'
import LoaderSpinner from 'UI/loaderSpinner'
import { NavLink, useNavigate } from 'react-router-dom'

const { Title, Text } = Typography

interface IOAuth3Props {}

const OAuth3: FC<IOAuth3Props> = () => {
	const navigate = useNavigate()
	const url = window.location.href.toString()
	const [isLoading, setIsLoading] = useState(true)
	const [oAuth, setOAuth] = useState(false)

	useEffect(() => {
		if (url.includes('code')) {
			code = url.split('?code=').pop()!
			setLegged3Code(code)
				.then((data) => setOAuth(data))
				.finally(() => setTimeout(() => setIsLoading(false), 2000))
			if (oAuth) {
				navigate('/admin/forge')
			}
		} else {
			setIsLoading(false)
		}
	}, [])

	let code: string

	return isLoading ? (
		<LoaderSpinner />
	) : !oAuth ? (
		<Result
			style={{ margin: 'auto' }}
			status="500"
			title={<Title level={1}>Ошибка синхронизации</Title>}
			subTitle={<Text>Произошла ошибка во время синхронизации с BIM360, обратитесь в службу поддержки</Text>}
			extra={<NavLink to="/admin">Вернуться в раздел настроек приложения</NavLink>}
		/>
	) : (
		<></>
	)
}
export default OAuth3
