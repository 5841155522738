import { IAppRigths } from 'interfaces/IAppRights'
import { IBuildingPermitDto } from 'interfaces/IIssue'
import { IPagination } from 'interfaces/IPagination'
import { IPermission, IRoleDto } from 'interfaces/IPermissions'
import { IPermissionFilters, IUser, IUserDto, IUserRoleDTO } from 'interfaces/IUser'
import { API } from './ApiService'

export const getPluginInsatllersList = async () => {
	return await API({
		url: `/v1/pluginInstallers`,
		method: 'get'
	}).then(async response => response.data)
}

export const uploadPluginInstaller = async (body: FormData) => {
	return await API({
		url: `/v1/pluginInstallers`,
		method: 'post',
		data: body
	}).then(async response => response)
}

export const downloadFile = async (fileId: string) => {
	return await API({
		url: `/v1/files/${fileId}/download`,
		method: 'get',
		responseType: 'blob'
	}).then(async response => response)
}

export const deleteFile = async (fileId: string) => {
	return await API({
		url: `/v1/files/${fileId}`,
		method: 'delete'
	}).then(async response => response)
}

export const getSysAuthLink = async () => {
	return await API({
		url: `/v1/autodesk/oauth/legged3-authenticate`,
		method: 'get'
	}).then(async response => response.data)
}

export const getRoles = async () => {
	return await API({
		url: `/v1/roles`,
		method: 'get'
	}).then(async response => response.data)
}

export const getRoleById = async (id: string) => {
	return await API<IRoleDto>({
		url: `/v1/roles/${id}`,
		method: 'get'
	}).then(async response => response.data)
}

export const updateRoleById = async (roleDto: IRoleDto) => {
	return await API({
		url: `/v1/roles/${roleDto.id}`,
		method: 'patch',
		data: roleDto
	}).then(async response => response)
}

export const getRolesPermissions = async () => {
	return await API<IAppRigths[]>({
		url: `/v1/roles-permissions`,
		method: 'get'
	}).then(async response => response)
}

export const getUsers = async ({
	ids,
	search,
	limit = 50,
	offset = 0,
	filters
}: {
	ids?: string
	limit?: number
	offset?: number
	search?: string
	filters?: IPermissionFilters
}) => {
	return await API<IPagination<IUser[]>>({
		url: typeof ids === 'undefined' ? `/v1/users` : `/v1/users?${ids!}`,
		method: 'get',
		params: { search: search, limit: limit, offset: offset, ...filters }
	}).then(async response => response.data)
}

export const updateUsersRoles = async (dto: IUserRoleDTO[]) => {
	return await API({
		url: `/v1/users-roles`,
		method: 'patch',
		data: dto
	}).then(async response => response)
}

export const addRole = async (dto: IRoleDto) => {
	return await API({
		url: `/v1/roles`,
		method: 'post',
		data: dto
	}).then(async response => response)
}

export const getLegged3Status = async () => {
	return await API({
		url: `/v1/autodesk/oauth/legged3-authenticate/status`,
		method: 'get'
	}).then(async response => response.data)
}

export const setLegged3Code = async (code: string) => {
	return await API({
		url: `/v1/autodesk/oauth/legged3-authenticate/code`,
		method: 'get',
		params: { code }
	}).then(async response => response.data)
}

export const getAllCompanies = async () => {
	return await API({
		url: `/v1/companies`,
		method: 'get'
	}).then(async response => response.data)
}

export const updateUser = async (user: IUserDto) => {
	return await API<IUserDto>({
		url: `/v1/users/${user.id}`,
		method: 'patch',
		data: user
	}).then(async response => response.data)
}

export const getUser = async (id: string) => {
	return await API<IUserDto>({
		url: `/v1/users/${id}`,
		method: 'get'
	}).then(async response => response.data)
}

export const getPermissions = async () => {
	return await API<IPermission[]>({
		url: `/v1/permissions/`,
		method: 'get'
	}).then(async response => response.data)
}

export const addBuildingPermits = async (dto: IBuildingPermitDto) => {
	return await API({
		url: `/v1/buildingPermits`,
		method: 'post',
		data: [dto]
	}).then(async response => response)
}

export const removeBuildingPermit = async (id: string) => {
	return await API({
		url: `/v1/buildingPermits/${id}`,
		method: 'delete'
	}).then(async response => response)
}

export const deleteUser = async (userId: string) => {
	return await API({
		url: `/v1/set IsDeleted`,
		method: 'delete',
		params: { userId }
	}).then(async response => response)
}
