import { UserOutlined, MailOutlined, CompassOutlined, LogoutOutlined } from '@ant-design/icons'
import { Space, Row, Col, Button, Typography, Popover, Avatar } from 'antd'
import { useAppDispatch } from 'hooks/appReduxHook'
import { IAppUser } from 'interfaces/IUser'
import { FC } from 'react'
import { Logout } from 'services/AuthService'
import { resetEnvironment } from 'store/environmentSlice'

interface IUserMenuPopupProps {
	user: IAppUser
	onClick: () => void
}

const { Title, Text } = Typography

export const UserMenuPopup: FC<IUserMenuPopupProps> = ({ user, onClick }) => {
	const dispatch = useAppDispatch()

	const UserMenuRender = () => {
		return (
			<div className="at-menu">
				<Space direction="vertical">
					<Row align="middle" gutter={8}>
						<Col>
							<UserOutlined style={{ color: '#1677ff' }} />
						</Col>
						<Col>
							{typeof user!?.firstName! !== 'undefined' && (
								<Title level={5} style={{ marginBottom: 0 }}>{`${user!?.firstName!} ${user!?.lastName!}`}</Title>
							)}
						</Col>
					</Row>
					<Row align="middle" gutter={8}>
						<Col>
							<MailOutlined style={{ color: '#1677ff' }} />
						</Col>
						<Col>
							<Text type="secondary">{user!?.email!}</Text>
						</Col>
					</Row>
					<Row align="middle" gutter={8}>
						<Col>
							<CompassOutlined style={{ color: '#1677ff' }} />
						</Col>
						<Col>
							<Text>{user!?.contractorName!}</Text>
						</Col>
					</Row>
				</Space>
				<div className="at-menu__footer">
					<Button
						style={{ textAlign: 'left', paddingLeft: 0 }}
						block
						type="link"
						shape="round"
						icon={<LogoutOutlined rev={undefined} />}
						onClick={() => {
							Logout().finally(() => dispatch(resetEnvironment()))
						}}
					>
						Выход
					</Button>
				</div>
			</div>
		)
	}

	return (
		<Popover content={UserMenuRender}>
			<Avatar
				shape="square"
				gap={2}
				style={{
					display: 'flex',
					alignItems: 'center',
					backgroundColor: '#f30',
					verticalAlign: 'middle',
					cursor: 'pointer'
				}}
				size={32}
				onClick={onClick}
			>
				{user && user!?.firstName! ? user!?.firstName!?.substring(0, 1).toUpperCase() : 'AU'}
				{user && user!?.lastName! && user!?.lastName!?.substring(0, 1).toUpperCase()}
			</Avatar>
		</Popover>
	)
}
