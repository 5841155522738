import { API } from 'services/ApiService'
import {
	INotificationType,
	IUserOption,
	TNotificationControllerType
} from '../model/notification-controller-interface'

export const notificationControllerApi = {
	async getList(type: TNotificationControllerType) {
		const url =
			type === 'admin'
				? '/v1/notification/notificationTypesForAdmin'
				: '/v1/notification/notificationTypes'
		return await API<INotificationType[]>({
			url: url,
			method: 'get'
		}).then(response => response.data)
	},
	async getOptions(type: TNotificationControllerType, destinationId?: string) {
		const url = {
			admin: `/v1/notificationOption/getOptionsByDestinationId`,
			user: '/v1/notificationOption/getOptions'
		}
		return await API<IUserOption[]>({
			url: url[type],
			method: 'get',
			params: { destinationId }
		}).then(response => response.data)
	},
	async addNotification(
		type: TNotificationControllerType,
		notificationTypeId: string[],
		optionSending: number,
		destinationId?: string
	) {
		const url = {
			admin: '/v1/notificationOption/createOptionByAdmin',
			user: '/v1/notificationOption/createOption'
		}
		const params = {
			admin: {
				destinationId,
				notificationTypesId: notificationTypeId,
				destinationOptionSending: optionSending
			},
			user: {
				notificationTypesId: notificationTypeId,
				userOptionSending: optionSending
			}
		}
		return await API({
			url: url[type],
			method: 'post',
			data: params[type]
		}).then(response => response.data)
	},
	async removeNotification(
		type: TNotificationControllerType,
		optionId: any,
		destinationId?: string
	) {
		const url = {
			admin: '/v1/notificationOption/deleteOptionByAdmin',
			user: '/v1/notificationOption/deleteOption'
		}
		const params = {
			admin: {
				destinationId,
				NotificationOptionsId: optionId
			},
			user: {
				NotificationOptionsId: optionId
			}
			
			}
		return await API({
			url: url[type],
			method: 'delete',
			data: params[type]
		}).then(response => response.data)
	}
}
