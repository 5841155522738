import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAppDispatch } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { useLocalEstimateState } from 'pages/unmodelPage/localEstimate/model/localEstimateState'
import { WbsContainerApi, useWbsContainerState } from 'pages/unmodelPage/wbsContainer'
import { useParams } from 'react-router-dom'
import { setPageTitle } from 'store/rootSlice'
import { useWbsStatusModalState } from 'widgets/wbs'
import { useShallow } from 'zustand/react/shallow'
import { EstimateApi } from '../api/estimate-api'
import { useEstimateState } from './estimate-state'

export const useEstimateContainerQuery = (type: 'wbs' | 'local') => {
	const { id: containerId } = useParams()
	const { setContainer, setContainerType } = useEstimateState()
	const {setContainer: setContainerWbs } = useWbsContainerState()
	const dispatch = useAppDispatch()
	return useQuery({
		queryKey: ['estimateContainer', containerId],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			const container = await WbsContainerApi.getContainer(containerId!, type)
				.then(data => data)
				.catch((err: AxiosError<IErrorDetail>) => err)
			if (!(container instanceof AxiosError)) {
				useLocalEstimateState.setState({ container })
				useWbsStatusModalState.setState({ container })
				setContainer(container)
				setContainerWbs(container)
				setContainerType(type)
				dispatch(
					setPageTitle({
						mainTitle: type === 'local' ? 'Список Локальных смет' : 'Список ВОР',
						subTitle: `${type === 'local' ? 'Локальная смета' : 'ВОР'}: ${
							container?.name
						}, Вид раздела: ${container?.typeSections?.name}`
					})
				)
			}
			return container
		},
		cacheTime: 0,
		enabled: typeof containerId !== 'undefined',
		select: data => data
	})
}

export const useEstimateQuery = (type: 'wbs' | 'local') => {
	const { container, setSections } = useEstimateState()
	return useQuery({
		queryKey: ['estimate', container?.id],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			const data = await EstimateApi.getSections(container?.id!, type).then(data => data)
			setSections(data)
			return data
		},
		enabled: typeof container?.id !== 'undefined',
		cacheTime: 0,
		select: data => data
	})
}

export const useNonActualPositionsQuery = () => {
	const container = useEstimateState(useShallow(state => state.container))
	return useQuery({
		queryKey: ['estimateNonActualPositions', container?.id],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await EstimateApi.getNonActualPositions(container?.id!).then(data => data)
		},
		enabled: typeof container?.id !== 'undefined' && container.isActual === true,
		cacheTime: 0
	})
}
