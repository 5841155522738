import { IReleaseNote, IReleaseNoteCreateDto, IReleaseNoteUpdateDto } from 'interfaces/release-note.interface'
import { API } from './ApiService'
import { IPagination } from 'interfaces/IPagination'

export const ReleaseNoteService = {
	async create(dto: IReleaseNoteCreateDto) {
		return await API({
			url: '/v1/releaseNote',
			method: 'post',
			data: dto
		}).then(async response => response.data)
	},

	async getAll() {
		return await API<IPagination<IReleaseNote[]>>({
			url: '/v1/releaseNote/all',
			method: 'get'
		}).then(async response => response.data)
	},

	async getUnread() {
		return await API<IReleaseNote[]>({
			url: '/v1/releaseNote',
			method: 'get'
		}).then(async response => response.data)
	},

	async update(id: string, dto: IReleaseNoteUpdateDto) {
		return await API({
			url: '/v1/releaseNote',
			method: 'patch',
			params: { releaseNoteId: id },
			data: dto
		}).then(async response => response.data)
	},

	async delete(id: string) {
		return await API({
			url: '/v1/releaseNote',
			method: 'delete',
			params: { releaseNoteId: id }
		}).then(async response => response.data)
	}
}
