import { LockOutlined, SyncOutlined, UnlockOutlined, UserOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, Drawer, Row, Table, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useState } from 'react'
import { TEstimateType } from 'shared/schema'
import { useAdjustmentPositionCommentsState } from 'widgets/adjustment-position-comments'
import { EstimatePositionCommentsView } from 'widgets/estimate-position-comments'
import { useShallow } from 'zustand/react/shallow'
import { useAdjustmentComments, useAdjustmentCommentsColumns, useAdjustmentCommentsState } from '..'

interface IProps {
	type: TEstimateType
}

export const AdjustmentComments: FC<IProps> = ({ type }) => {
	const queryClient = useQueryClient()
	const [filter, setFilter] = useState<boolean | undefined>()
	const [filterMyComment, setFilterMyComment] = useState<boolean | undefined>(undefined)
	const { user } = useAppSelector(state => state.environment)
	const { show, toggle } = useAdjustmentCommentsState(
		useShallow(state => ({
			show: state.showComments,
			toggle: state.toggleShowComments
		}))
	)
	const { data, isFetching } = useAdjustmentComments(type, filter, filterMyComment)
	const columns = useAdjustmentCommentsColumns()

	return (
		<Drawer
			open={show}
			onClose={() => toggle()}
			extra={
				<Row align="middle" gutter={8}>
					<Col>
						<Tooltip title="Обновить">
							<Button
								icon={<SyncOutlined />}
								onClick={() => queryClient.invalidateQueries({ queryKey: ['estimate-comments'] })}
							/>
						</Tooltip>
					</Col>
					<Col>
						<Button
							icon={<LockOutlined />}
							type={filter === true ? 'primary' : 'default'}
							onClick={() => {
								setFilter(prev => (prev === true ? undefined : true))
							}}
						>
							Только закрытые
						</Button>
					</Col>
					<Col>
						<Button
							icon={<UnlockOutlined />}
							type={filter === false ? 'primary' : 'default'}
							onClick={() => {
								setFilter(prev => (prev === false ? undefined : false))
							}}
						>
							Только открытые
						</Button>
					</Col>
					<Col>
						<Button
							icon={<UserOutlined />}
							type={filterMyComment === true ? 'primary' : 'default'}
							onClick={() => {
								setFilterMyComment(prev => (prev === true ? undefined : true))
							}}
						>
							Сообщения мне
						</Button>
					</Col>
				</Row>
			}
			destroyOnClose
			title="Чат"
			width="50vw"
		>
			<EstimatePositionCommentsView source="all" type={type} />
			<Table
				loading={isFetching}
				rowKey={row => row.id}
				dataSource={data ?? []}
				columns={columns}
				pagination={false}
				onRow={record => ({
					style: { cursor: 'pointer' },
					onDoubleClick: () => {
						useAdjustmentPositionCommentsState.setState({
							show: true,
							currentId: record.id
						})
					}
				})}
			/>
		</Drawer>
	)
}
/*

*/
