import { FC } from 'react'
import { IChecklistAttachment } from 'interfaces/IChecklist'
import Table, { ColumnsType } from 'antd/lib/table'
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { Tooltip, Button, message, Empty, Card } from 'antd'
import { downloadFile } from 'services/AdminService'

interface ICheckListAttachmentsProps {
	attachments: IChecklistAttachment[]
}

const CheckListAttachments: FC<ICheckListAttachmentsProps> = ({ attachments }) => {
	const fileList = attachments ? [...attachments] : []
	const attachmentsColumns: ColumnsType<IChecklistAttachment> = [
		{
			dataIndex: 'image',
			width: '5%',
			align: 'center',
			render: () => <FilePdfOutlined style={{ color: 'red', fontSize: 24 }} rev={undefined} />
		},
		{
			title: 'Файл',
			dataIndex: 'name',
			width: 200,
			render: (_, record) => record!?.name!?.replace(record!?.extension, '')
		},
		{
			title: 'Описание',
			dataIndex: 'description'
		},
		{
			title: 'Автор',
			width: '16%',
			render: (_, record) => record.createdBy!?.name!
		},
		{
			title: 'Дата загрузки',
			dataIndex: 'uploadDate',
			width: 120,
			render: (_, record) => dayjs(record.uploadDate).format('DD.MM.YYYY')
		},
		{
			width: '10%',
			align: 'center',
			render: (_, record) => (
				<Tooltip placement="topLeft" title="Скачать">
					<Button
						shape="circle"
						size="middle"
						onClick={() =>
							downloadFile(record.id)
								.then(response => {
									const href = URL.createObjectURL(response.data)
									const link = document.createElement('a')
									link.href = href
									link.setAttribute('download', record.name)
									document.body.appendChild(link)
									link.click()
									document.body.removeChild(link)
									URL.revokeObjectURL(href)
								})
								.catch(() => message.error('Во время загрузки файла произошла ошибка'))
						}
						icon={<DownloadOutlined rev={undefined} />}
					/>
				</Tooltip>
			)
		}
	]
	return (
		<>
			{!!attachments!?.length ? (
				<Table
					size="small"
					pagination={false}
					dataSource={fileList.sort((a, b) => dayjs(a.uploadDate).unix() - dayjs(b.uploadDate).unix())}
					columns={attachmentsColumns}
				/>
			) : (
				<Empty />
			)}
		</>
	)
}

export default CheckListAttachments
