import { useQuery } from '@tanstack/react-query'
import { workPackageService } from 'services/workPackageService'

export const useWorkPackages = () => {
	return useQuery({
		queryKey: ['workPackages'],
		queryFn: async () => {
			return await workPackageService.getList().then(data => data)
		},
		refetchOnWindowFocus: false
	})
}
