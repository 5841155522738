import { DownloadOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Form,
	Modal,
	Row,
	Select,
	Space,
	Typography,
	message
} from 'antd'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import useDebounce from 'hooks/useDebounce'
import { useWbsStatuses } from 'pages/guidesPage/ui/wbs-status/useWbsStatuses'
import { FC, useEffect, useState } from 'react'
import { downloadDocument } from 'shared/helpers'
import { WbsStatusReportApi } from '../api/wbs-status-report-api'
import {
	IWbsContainersByProjects,
	IWbsStatusReportFilters
} from '../model/wbs-status-report-interface'
import {
	useWbsContainersByProjects,
	useWbsStatusReportExcel,
	useWbsStatusReportPagination
} from '../model/wbs-status-report-queries'
import { useWbsStatusReportState } from '../model/wbs-status-report-state'

interface IProps {
	show: boolean
	onClose: () => void
}

interface IWbsStatusReportForm {
	projects: string[]
	containers: string[]
	containerStatus: string
	range: [Dayjs, Dayjs] | undefined
	allPeriod: boolean | undefined
}

const { Title, Text } = Typography

export const WbsStatusReportCreateModal: FC<IProps> = ({ show, onClose }) => {
	const [isBlocked, setIsBlocked] = useState(false)
	const [WbsStatusReportForm] = Form.useForm<IWbsStatusReportForm>()
	const allPeriod = Form.useWatch('allPeriod', WbsStatusReportForm)
	// const selectedProjects = Form.useWatch('projects', WbsStatusReportForm)
	const selectedContainers = Form.useWatch('containers', WbsStatusReportForm)
	const selectedContainerStatus = Form.useWatch('containerStatus', WbsStatusReportForm)

	const projects = useAppSelector(state => state.environment.user?.projects)
	const project = useAppSelector(state => state.environment.project)
	const selectedProjectReport = Form.useWatch('projects', WbsStatusReportForm)
	const { projectReport, download, setProjectReport } = useWbsStatusReportState()
	// const { data: wbs } = useAsmReport(projectReport?.id!)
	const { data: containerStatus } = useWbsStatuses()
	const { data: wbsContainersByProjects } = useWbsContainersByProjects(selectedProjectReport)
	const [isLoading, setIsLoading] = useState(false)
	const [containers, setContainers] = useState<IWbsContainersByProjects[] | null>(null)
	const queryClient = useQueryClient()

	const { filters, currentPage, setFilters, setData, onPagination } = useWbsStatusReportState()
	// const { mutate: getWbsStatusReport } = useWbsStatusReport()
	const { data: wbsStatusReportData } = useWbsStatusReportPagination(filters)
	const { mutate: getWbsStatusReportExcel } = useWbsStatusReportExcel()

	const onModalClose = () => {
		WbsStatusReportForm.resetFields()
		WbsStatusReportForm.setFieldsValue({ allPeriod: true, range: undefined })
		onClose()
		setContainers(null)
	}

	useEffect(() => {
		WbsStatusReportForm.validateFields()
	}, [allPeriod])

	useEffect(() => {
		setProjectReport(projects?.filter(p => WbsStatusReportForm.getFieldValue('projects'))!)
	}, [selectedProjectReport])

	useEffect(() => {
		WbsStatusReportApi.getWbsContainersByProjects(selectedProjectReport!)
			.then(data => {
				setContainers(data)
				queryClient.invalidateQueries(['asmReport'])
			})
			.finally(() => setIsLoading(false))
	}, [projectReport])

	useEffect(() => {
		!WbsStatusReportForm.getFieldValue('projects')?.length &&
			WbsStatusReportForm.setFieldsValue({
				allPeriod: true,
				range: undefined,
				projects: [project.id]
			})
	}, [show])

	const onFinish = async () => {
		setIsBlocked(true)
		const data = WbsStatusReportForm.getFieldsValue()
		const filters: IWbsStatusReportFilters = {
			projectIds: data.projects,
			containerIds: data.containers,
			containerStatusId: data.containerStatus,
			createdFrom: data.allPeriod ? null : dayjs(data.range?.[0]).format('YYYY-MM-DD'),
			createdTo: data.allPeriod ? null : dayjs(data.range?.[1]).format('YYYY-MM-DD')
		}
		setFilters(filters)
		if (download) {
			await getWbsStatusReportExcel(filters, {
				onSuccess: data => {
					downloadDocument(data, 'Отчет по статусам ВОР')
					onModalClose()
				},
				onError: () => message.error('Во время загрузки файла произошла ошибка'),
				onSettled: () => setIsBlocked(false)
			})
		} else {
			WbsStatusReportApi.getWbsStatusReport({
				settings: filters,
				limit: 50,
				offset: currentPage === 1 ? 0 : currentPage * 50 - 50
			})
				.then(data => {
					setData(data.containers.data)
					onClose()
				})
				.catch(() => message.error('Во время получения данных произошла ошибка'))
				.finally(() => setIsBlocked(false))
			onPagination(1)
		}
		// else {
		// 	await getWbsStatusReport(filters, {
		// 		onSuccess: () => onModalClose(),
		// 		onError: () => message.error('Во время получения данных произошла ошибка'),
		// 		onSettled: () => setIsBlocked(false)
		// 	})
		// }
		setContainers(null)
		useWbsStatusReportState.setState({ download: false })
	}

	const setAllProjects = () => {
		WbsStatusReportForm.setFieldValue(
			'projects',
			projects?.map(p => p.id)
		)
		WbsStatusReportForm.validateFields()
	}

	const debounceFetch = (value: string) => {
		if (value.length >= 3) {
			// setIsLoading(true)
		} else {
			setContainers(wbsContainersByProjects!)
			setIsLoading(false)
		}
	}
	const debouncer = useDebounce((deb: string) => debounceFetch(deb), 1000)

	return (
		<Form
			form={WbsStatusReportForm}
			name="WbsStatusReportForm"
			onFinish={onFinish}
			disabled={isBlocked}
		>
			<Modal
				destroyOnClose
				onCancel={onClose}
				centered
				open={show}
				title={'Создать отчет'}
				okButtonProps={{ disabled: isBlocked }}
				footer={
					<Row justify="space-between">
						<Col>
							<Button
								danger
								disabled={isBlocked}
								onClick={() => {
									useWbsStatusReportState.setState({ download: false })
									WbsStatusReportForm.resetFields()
								}}
							>
								Сбросить
							</Button>
						</Col>
						<Col>
							<Space>
								{/* <Button disabled={isBlocked} onClick={onModalClose}>
									Отмена
								</Button> */}

								<Button
									disabled={isBlocked}
									type="primary"
									onClick={() => WbsStatusReportForm.submit()}
								>
									Сформировать
								</Button>
								<Button
									disabled={isBlocked}
									type="primary"
									icon={<DownloadOutlined />}
									onClick={() => {
										useWbsStatusReportState.setState({ download: true })
										WbsStatusReportForm.submit()
									}}
								>
									Скачать
								</Button>
							</Space>
						</Col>
					</Row>
				}
			>
				<Row justify="center">
					<Col
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '.5rem',
							padding: '1rem',
							width: '100%'
						}}
					>
						<>
							<Col>
								<Row align="middle" justify="space-between">
									<Col>
										<Title level={5}>Проекты:</Title>
									</Col>
									<Col>
										<Button type="link" size="small" onClick={setAllProjects}>
											Выбрать все
										</Button>
									</Col>
								</Row>
								<Form.Item
									name="projects"
									rules={[
										{
											required: true,
											message: 'Выберите проект'
										}
									]}
								>
									<Select
										mode="multiple"
										maxTagCount="responsive"
										allowClear
										style={{ width: '100%' }}
										onClear={() => WbsStatusReportForm.setFieldValue('contractor', undefined)}
										optionFilterProp="children"
										onChange={() => WbsStatusReportForm.resetFields(['containers'])}
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										options={projects?.map(p => ({
											key: p.id,
											label: p.name,
											value: p.id
										}))}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Title level={5}>ВОР:</Title>
								<Form.Item name="containers">
									<Select
										mode="multiple"
										loading={isLoading}
										disabled={
											wbsContainersByProjects?.length === 0 ||
											selectedProjectReport?.length === 0 ||
											selectedContainerStatus?.length > 0
										}
										allowClear
										onSearch={debouncer}
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										style={{ width: '100%' }}
										options={(containers ? containers : wbsContainersByProjects)?.map(p => ({
											key: p.id,
											label: p.name,
											value: p.id
										}))}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Title level={5}>Статус ВОР:</Title>
								<Form.Item name="containerStatus">
									<Select
										disabled={
											containerStatus?.length === 0 ||
											selectedProjectReport?.length === 0 ||
											selectedContainers?.length > 0
										}
										allowClear
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) =>
											(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
										}
										filterSort={(optionA, optionB) =>
											(optionA?.label ?? '')
												.toLowerCase()
												.localeCompare((optionB?.label ?? '').toLowerCase())
										}
										style={{ width: '100%' }}
										options={containerStatus?.map(p => ({
											key: p.id,
											label: p.description,
											value: p.id
										}))}
									/>
								</Form.Item>
							</Col>
							<Col>
								<Title level={5}>Период:</Title>
								<Form.Item
									name="range"
									rules={[
										{
											required: !allPeriod,
											message: 'Укажите период'
										}
									]}
								>
									<DatePicker.RangePicker
										disabled={allPeriod}
										format="DD.MM.YYYY"
										style={{ width: '100%' }}
									/>
								</Form.Item>
							</Col>
							<Row justify="end">
								<Text style={{ marginRight: '1rem' }}>За всё время</Text>
								<Form.Item name="allPeriod">
									<Checkbox
										checked={allPeriod}
										onChange={value =>
											WbsStatusReportForm.setFieldValue('allPeriod', value.target.checked)
										}
									/>
								</Form.Item>
							</Row>
						</>
					</Col>
				</Row>
			</Modal>
		</Form>
	)
}
