import { Form, Modal, Select, Switch, message } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { trafficLightService } from 'services/LightReportService'

interface IProps {
	show: boolean
	onClose: () => void
}

export const TrafficLightDownloadModal: FC<IProps> = ({ show, onClose }) => {
	const projects = useAppSelector(state => state.environment.projectsList)
	const [projectForm] = Form.useForm()
	const selectedProject = Form.useWatch('project', projectForm)

	const onCancel = () => {
		projectForm.resetFields()
		onClose()
	}

	const onGetReport = async () => {
		const settings = { ...projectForm.getFieldsValue(), title: projects.find(p => p.id === selectedProject)?.name }
		await trafficLightService
			.downloadReport(settings)
			.then(onCancel)
			.catch(() => message.error('Произошла ошибка при формировании отчёта'))
	}

	return (
		<Form
			name="projectForm"
			form={projectForm}
			onFinish={onGetReport}
			initialValues={{
				project: undefined,
				critical: false,
				collapsed: false
			}}
		>
			<Modal
				open={show}
				onCancel={onCancel}
				title="Выгрузка отчёта по проекту"
				okText="Скачать"
				onOk={() => projectForm.submit()}
				okButtonProps={{ icon: <DownloadOutlined />, disabled: typeof selectedProject === 'undefined' }}
			>
				<Form.Item name="project">
					<Select
						allowClear
						showSearch
						placeholder="Выберите проект"
						optionFilterProp="children"
						filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
						filterSort={(optionA, optionB) =>
							(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
						}
						options={projects.map(p => ({
							key: p.id,
							label: p.name,
							value: p.id
						}))}
					/>
				</Form.Item>
				<Form.Item labelCol={{ span: 8 }} labelAlign="left" name="critical" label="Только критичные">
					<Switch size="small" />
				</Form.Item>
				<Form.Item labelCol={{ span: 8 }} labelAlign="left" name="collapsed" label="Развёрнуто / свёрнуто">
					<Switch size="small" />
				</Form.Item>
			</Modal>
		</Form>
	)
}
